import React, { FC, memo } from 'react';
import { Box, Typography } from '@mui/material';
import CustomInputWithLabel from '../../common/CustomInputWithLabel';
import { BtgNumericDomain } from '../../../models/inputTypes/BtgFields';

const styles = {
  spacingContainer: {
    display: 'flex',
    alignItems: 'center',

    '& >:nth-child(n)': {
      my: 1,
      mr: { xs: 2, md: 3, xl: 4 },
    },
  },
  flexStartBox: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  coordinates: {
    '& >:nth-child(2)': {
      ml: 1,
    },
  },
};
type NumericalDomainInputsGroupProps = {
  inputState: BtgNumericDomain;
  setInputState: (value: BtgNumericDomain) => void;
};
const NumericalDomainInputsGroup: FC<NumericalDomainInputsGroupProps> = ({ inputState, setInputState }) => {
  const onNumberChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setInputState({ ...inputState, [event.target.name]: event.target.value });
  };

  return (
    <>
      <Box sx={{ flexWrap: 'wrap', ...styles.spacingContainer }}>
        <Box>
          <Typography mb={1} variant={'subtitle2'}>
            Number of nodes
          </Typography>
          <Box sx={{ ...styles.flexStartBox, ...styles.coordinates }}>
            <CustomInputWithLabel
              required
              min={1}
              step={1}
              errorText={'Enter positive integer'}
              type={'number'}
              onChange={onNumberChange}
              value={inputState.nodeX}
              name={'nodeX'}
              label={'X'}
            />
            <CustomInputWithLabel
              required
              min={1}
              step={1}
              errorText={'Enter positive integer'}
              type={'number'}
              onChange={onNumberChange}
              value={inputState.nodeY}
              name={'nodeY'}
              label={'Y'}
            />
          </Box>
        </Box>
        <Box>
          <Typography mb={1} variant={'subtitle2'}>
            Grid cell size (m)
          </Typography>
          <Box sx={{ ...styles.flexStartBox, ...styles.coordinates }}>
            <CustomInputWithLabel
              min={0.000000000001}
              step={'any'}
              required
              errorText={'Enter positive number'}
              type={'number'}
              onChange={onNumberChange}
              value={inputState.sizeDX}
              name={'sizeDX'}
              label={'DX'}
            />
            <CustomInputWithLabel
              min={0.000000000001}
              step={'any'}
              required
              errorText={'Enter positive number'}
              type={'number'}
              onChange={onNumberChange}
              value={inputState.sizeDY}
              name={'sizeDY'}
              label={'DY'}
            />
          </Box>
        </Box>
        {/*<Box>*/}
        {/*  <Typography mb={1} variant={'subtitle2'}>*/}
        {/*    Land value*/}
        {/*  </Typography>*/}
        {/*  <CustomInput*/}
        {/*    required*/}
        {/*    min={0}*/}
        {/*    step={1}*/}
        {/*    errorText={'Enter positive integer'}*/}
        {/*    type={'number'}*/}
        {/*    onChange={onNumberChange}*/}
        {/*    value={inputState.landValue}*/}
        {/*    name={'landValue'}*/}
        {/*  />*/}
        {/*</Box>*/}
      </Box>
    </>
  );
};

export default memo(NumericalDomainInputsGroup);
