import React, { FC, memo } from 'react';
import { Box, SelectChangeEvent, Typography } from '@mui/material';
import CustomMenuItem from '../../custom/CustomMenuItem';
import CustomInput from '../../custom/CustomInput';
import CustomSelect from '../../custom/CustomSelect';
import { BtgInterpolation } from '../../../models/inputTypes/BtgFields';
import LabelWrapper from '../../common/LabelWrapper';
import { btgInterpolationInitialState } from '../../../utils/initialStates/btgInputState';
import BoxComponent from '../../custom/BoxComponent';

const styles = {
  spacingContainer: {
    display: 'flex',
    alignItems: 'center',

    '& >:nth-child(n)': {
      my: 1,
      mr: { xs: 2, lg: 2, xl: 4 },
    },
  },
  flexStartBox: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  coordinates: {
    '& >:nth-child(2)': {
      ml: 1,
    },
  },
};
type InterpolationInputsProps = {
  inputState: BtgInterpolation;
  setInputState: (value: BtgInterpolation) => void;
};
const InterpolationInputsGroup: FC<InterpolationInputsProps> = ({ inputState, setInputState }) => {
  const onInputChange = (
    event: SelectChangeEvent<unknown> | React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setInputState({
      ...inputState,
      searchRadius: btgInterpolationInitialState.searchRadius,
      [event.target.name]: event.target.value,
    });
  };

  const onNumberChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setInputState({ ...inputState, [event.target.name]: event.target.value });
  };

  return (
    <>
      <Box sx={{ flexWrap: 'wrap', ...styles.spacingContainer }}>
        <Box>
          <LabelWrapper label={'Interpolation method'}>
            <CustomSelect
              required
              onChange={onInputChange}
              value={inputState.interpolation}
              name={'interpolation'}
              minWidth={'140px'}
              defaultValue={''}
              displayEmpty
            >
              <CustomMenuItem value={'1'}>
                <Typography variant={'subtitle2'}>Linear</Typography>
              </CustomMenuItem>
              <CustomMenuItem value={'2'}>
                <Typography variant={'subtitle2'}>Nearest-neighbor</Typography>
              </CustomMenuItem>
              <CustomMenuItem value={'3'}>
                <Typography variant={'subtitle2'}>Inverse distance weighting</Typography>
              </CustomMenuItem>
            </CustomSelect>
          </LabelWrapper>
        </Box>
        <BoxComponent disabled={inputState.interpolation != '3'}>
          <LabelWrapper label={'Search radius (m)'}>
            <CustomInput
              disabled={inputState.interpolation != '3'}
              required
              min={2}
              max={200}
              minWidth={'130px'}
              errorText={'Enter number between 2 and 200'}
              fullWidth
              type={'number'}
              onChange={onNumberChange}
              value={inputState.searchRadius}
              name={'searchRadius'}
            />
          </LabelWrapper>
        </BoxComponent>
      </Box>
    </>
  );
};

export default memo(InterpolationInputsGroup);
