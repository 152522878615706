import React, { FC, useState } from 'react';
import PageContainer from '../components/structural/PageContainer';
import { Grid, Typography } from '@mui/material';
import CustomImageButton from '../components/custom/CustomImageButton';
import LatestProjectGroup from '../components/groups/home/LatestProjectsGroup';
import { useHistory } from 'react-router';
import ConfirmationModal from '../components/dialogs/ConfirmationModal';
import { Projects } from '../models/types/Projects';
import { useDeleteProjectMutation } from '../redux/RTK/mutations/projectMutations';
import { useDeleteAllProjectFilesMutation } from '../redux/RTK/mutations/projectFileMutations';
import { openErrorToast, setGlobalLoading } from '../redux/slices/appSlice';
import { useAppDispatch } from '../redux/store';

const styles = {
  gridTop: {
    marginTop: 3.5,
  },
  buttonSpace: {
    marginLeft: 2,
  },
  gridContainerSpace: {
    paddingRight: { sm: 0, md: 2, lg: 4 },
  },
  gridContainerSecondSpace: {
    marginTop: { sm: 2, md: 0 },
  },
  topTableSpace: {
    marginTop: 3,
  },
  topTableSpace2: {
    marginTop: { sm: 2, md: 4 },
  },
};

const Home: FC = () => {
  const history = useHistory();
  const [deleteProject, setDeleteProject] = useState<Projects | undefined>(undefined);
  const [deleteProjects] = useDeleteProjectMutation();
  const [deleteProjectFiles] = useDeleteAllProjectFilesMutation();
  const dispatch = useAppDispatch();
  const onAddProject = () => {
    history.push('/projects');
  };

  const onModalClose = () => setDeleteProject(undefined);

  const onProjectDelete = async () => {
    if (deleteProject?.id) {
      try {
        dispatch(setGlobalLoading(true));
        await deleteProjectFiles(deleteProject.id);
        await deleteProjects(deleteProject.id);
        dispatch(setGlobalLoading(false));
      } catch (e) {
        dispatch(setGlobalLoading(false));
        dispatch(openErrorToast('Error deleting project!'));
      }
    }
    onModalClose();
  };

  return (
    <PageContainer>
      <Grid sx={styles.gridTop} container alignItems={'center'}>
        <Grid item>
          <Typography variant={'h1'}>HOME</Typography>
        </Grid>
        <Grid item sx={styles.buttonSpace}>
          <CustomImageButton onClick={onAddProject} variant={'contained'} color={'success'}>
            <Typography color={'white'} variant={'subtitle1'}>
              Create New
            </Typography>
          </CustomImageButton>
        </Grid>
        <Grid item sx={styles.topTableSpace} xs={12}>
          <LatestProjectGroup setDeleteProject={setDeleteProject} />
        </Grid>
      </Grid>
      <ConfirmationModal
        open={!!deleteProject}
        severe={'WARNING!'}
        closeLabel={'Cancel'}
        acceptLabel={'Confirm'}
        handleClose={onModalClose}
        message={`All files related to this project will be lost. Are you sure you want to delete ${deleteProject?.name}?`}
        handleAccept={onProjectDelete}
      />
    </PageContainer>
  );
};

export default Home;
