import {
  ShorelineNumericalDomain,
  ShorelineSimulationTime,
  ShorelineWaveClima,
  ShorelineModelFeatures,
  ShorelineStructure,
  ShorelineNourishment,
  ShorelinePlotProperties,
  ShorelinePlotLabelPdf,
  ShorelineOutput,
} from '../../models/inputTypes/ShorelineFields';
import { PmsSubmission } from '../../models/inputTypes/PmsFields';
import { ShorelineType } from '../../models/jobModels/shorelineType';
import { dynamicInputTxtFilePreparation } from '../functions/dynamicInputFilePreparation';
import { NourishmentsObject, ShorefaceNourishmentsObject } from '../../models/inputTypes/ShorelineFields';
import {
  prepareNourishments,
  prepareShorefaceNourishments,
  prepareColors,
  prepareLabels,
  prepareX,
  prepareY,
} from '../functions/shorelineFilePreparation';

export const shorelinePreparation = (
  numericalState: ShorelineNumericalDomain,
  simulationTime: ShorelineSimulationTime,
  waveClimaState: ShorelineWaveClima,
  modelFeaturesState: ShorelineModelFeatures,
  structureState: ShorelineStructure,
  nourishmentState: ShorelineNourishment,
  plotPropetriesState: ShorelinePlotProperties,
  labelPdfState: ShorelinePlotLabelPdf,
  output: ShorelineOutput,
  submissionState: PmsSubmission,
): ShorelineType | unknown => {
  const inputFiles = dynamicInputTxtFilePreparation(
    {
      I_Coast: numericalState.coastlineFile,
      phifFile: numericalState.orientationFile,
      I_WaveClima: waveClimaState.waveclimaFile,
      I_Timeseries: waveClimaState.timeseriesFile,
      I_Struct: structureState.structFile,
      I_Revet: structureState.revetFile,
    },
    waveClimaState.timeseries,
    waveClimaState.waveclima,
    plotPropetriesState.refCoast,
  );

  const nourishments: NourishmentsObject[] = prepareNourishments(nourishmentState.nourishments);
  const shorefaceNour: ShorefaceNourishmentsObject[] = prepareShorefaceNourishments(nourishmentState.shorefaceNour);

  const labels = prepareLabels(plotPropetriesState.refCoast);
  const colors = prepareColors(plotPropetriesState.refCoast);
  const X = prepareX(
    waveClimaState.wavegenType === 'timeseries'
      ? waveClimaState.timeseries
      : waveClimaState.wavegenType === 'wave_climate'
      ? waveClimaState.waveclima
      : [],
  );
  const Y = prepareY(
    waveClimaState.wavegenType === 'timeseries'
      ? waveClimaState.timeseries
      : waveClimaState.wavegenType === 'wave_climate'
      ? waveClimaState.waveclima
      : [],
  );

  const logoFile = labelPdfState.logoFile?.currentFile?.name
    ? { logo_file: { fileid: labelPdfState.logoFile.currentFile.name, project: numericalState.project.toString() } }
    : undefined;

  const labelPdf = plotPropetriesState.pdf
    ? {
        frame: +labelPdfState.frame,
        plot_basemap: labelPdfState.plotBasemap ? 1 : 0,
        input_epsg: +labelPdfState.inpEPSG,
        basemap_provider: +labelPdfState.mapProvider,
        orientation: +labelPdfState.pageOrientation,
        label: labelPdfState.label ? 1 : 0,
        userID: labelPdfState.userIdType === 'username' ? 1 : 2,
        ...(labelPdfState.userIdType === 'username' ? { userName: labelPdfState.username.toString() } : undefined),
        ...logoFile,
        clientName: labelPdfState.client.toString(),
        projectTitle: labelPdfState.projectTitle.toString(),
        figureDescription: labelPdfState.figureDescription.toString(),
        figureNumber: labelPdfState.figureNumber.toString(),
        figureTitle: labelPdfState.figureTitle.toString(),
        date: labelPdfState.date.toString(),
      }
    : undefined;

  const preparedValues: ShorelineType = {
    simulation: 'shorelineS',
    project: numericalState.project.toString(),
    cores: +submissionState.vCpus,
    name: submissionState.simulationFile,
    parameters: {
      input_files: inputFiles,

      cellS: +numericalState.cellS,
      d: +numericalState.d,
      ddeep: +numericalState.ddeep,
      dnearshore: +numericalState.dnearshore,
      orientationType: numericalState.orientationType === 'constant' ? 1 : 0,
      phif: numericalState.phif ? +numericalState.phif : undefined,
      ld: +numericalState.ld,
      bndrStart: numericalState.boundaryTypeStart.toString(),
      bndrEnd: numericalState.boundaryTypeEnd.toString(),

      reftime: simulationTime.reftime.toString(),
      simend: simulationTime.simend.toString(),
      timestep: +simulationTime.timestep,
      timeType: simulationTime.timeType === 'constant' ? 1 : 0,

      wavegenType:
        waveClimaState.wavegenType === 'wave_climate' ? 2 : waveClimaState.wavegenType === 'timeseries' ? 1 : 0,
      hm: +waveClimaState.hm,
      ts: +waveClimaState.ts,
      direction: +waveClimaState.direction,
      spread: +waveClimaState.spread,
      numTime: +waveClimaState.numTime,
      numWave: +waveClimaState.numWave,
      X: X,
      Y: Y,

      wv_diff: modelFeaturesState.wave_diffraction ? 1 : 0,
      cl_chng: modelFeaturesState.clima_change ? 1 : 0,
      slrate: +modelFeaturesState.slrate,
      sediment: modelFeaturesState.sediment.toString(),
      qscal: +modelFeaturesState.qscal,
      calfc: +modelFeaturesState.calfc,
      tper: +modelFeaturesState.tper,
      d50: +modelFeaturesState.d50,
      porosity: +modelFeaturesState.porosity,
      tanbeta: +modelFeaturesState.tanbeta,
      rhos: +modelFeaturesState.rhos,
      rhow: +modelFeaturesState.rhow,
      gamma: +modelFeaturesState.gamma,
      smoothC: modelFeaturesState.smoothC ? 1 : 0,
      smoothR: modelFeaturesState.smoothR ? 1 : 0,
      smoothfac: +modelFeaturesState.smoothfac,
      smoothrefrac: +modelFeaturesState.smoothrefrac,

      struct: structureState.struct ? 1 : 0,
      revetment: structureState.revetment ? 1 : 0,

      nourish: nourishmentState.nourish ? 1 : 0,
      shoreface: nourishmentState.shoreface ? 1 : 0,
      numNourishments: +nourishmentState.numNourishments,
      nourishments: nourishments,
      numShorefaceNour: +nourishmentState.numShorefaceNour,
      shorefaceNour: shorefaceNour,

      swX: +plotPropetriesState.swX,
      swY: +plotPropetriesState.swY,
      neX: +plotPropetriesState.neX,
      neY: +plotPropetriesState.neY,
      fignryear: +plotPropetriesState.fignryear,
      usefill: plotPropetriesState.usefill ? 1 : 0,
      plotwavequiver: plotPropetriesState.plotwavequiver ? 1 : 0,
      plotX: plotPropetriesState.plotX ? +plotPropetriesState.plotX : undefined,
      plotY: plotPropetriesState.plotY ? +plotPropetriesState.plotY : undefined,
      scale: plotPropetriesState.scale ? +plotPropetriesState.scale : undefined,
      labels: labels,
      colors: colors,
      pltObjNum: plotPropetriesState.pltObjNum,
      llocation: plotPropetriesState.llocation,
      include_pdf: plotPropetriesState.pdf ? 1 : 0,

      kwargs: {
        ...labelPdf,
      },

      outputName: output.outputFileName.toString(),
    },
  };
  return preparedValues;
};
