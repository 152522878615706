import axios from 'axios';
import store from '../redux/store';
import { clearUser } from '../redux/slices/authenticationSlice';
const baseUrl = process.env['REACT_APP_API_URL'];

const axiosInstance = axios.create({
  baseURL: baseUrl,
});

export const get = (endpoint: string, params?: any) => {
  return axiosInstance.get(`${endpoint}`, { ...params });
};

export const put = (endpoint: string, params?: any) => {
  return axiosInstance.put(`${endpoint}`, { ...params });
};

export const post = (endpoint: string, params?: any) => {
  return axiosInstance.post(`${endpoint}`, { ...params });
};
export const deleteRequest = (endpoint: string, params?: any) => {
  return axiosInstance.delete(`${endpoint}`, { ...params });
};

axiosInstance.interceptors.request.use(
  (axiosConfig: any) => {
    const accessToken = store.getState().authentication.data.accessToken;
    if (accessToken) {
      axiosConfig.headers.Authorization = `Token ${accessToken}`;
    }
    return axiosConfig;
  },
  (error) => Promise.reject(error),
);

axiosInstance.interceptors.response.use(
  (config) => {
    return config;
  },
  (error) => {
    const originalRequest = error.config;
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      originalRequest._retry = true;
      //REFETCH TOKEN OR LOGOUT
      store.dispatch(clearUser());
    }
    return Promise.reject(error);
  },
);
