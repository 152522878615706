import { HmsPlotArea } from '../../models/inputTypes/HmsFields';
import { hmsPlotAreasInitialState } from '../initialStates/hmsInputState';

export const hmsPlotAreaArrayPreparation = (
  plotAreaValues: HmsPlotArea[],
  newValue: number,
  customInitialState?: HmsPlotArea,
) => {
  const plotAreaStartState = customInitialState ?? hmsPlotAreasInitialState;
  const tempArray = plotAreaValues;
  const tempLength = plotAreaValues.length;
  if (tempLength !== newValue) {
    if (newValue > tempLength) {
      for (let i = tempLength; i < newValue; i++) {
        tempArray.push({
          ...plotAreaStartState,
          name_h: plotAreaStartState.name_h + `_${i + 1}`,
          name_z: plotAreaStartState.name_z + `_${i + 1}`,
          name_rad_xx: plotAreaStartState.name_rad_xx + `_${i + 1}`,
          name_rad_xy: plotAreaStartState.name_rad_xy + `_${i + 1}`,
          name_rad_yy: plotAreaStartState.name_rad_yy + `_${i + 1}`,
          name_init_depths: plotAreaStartState.name_init_depths + `_${i + 1}`,
        });
      }
    } else {
      for (let i = 0; i < tempLength - newValue; i++) {
        tempArray.pop();
      }
    }
  }
  return tempArray;
};
