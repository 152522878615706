import React, { FC, memo } from 'react';
import { styled, Typography } from '@mui/material';
import { Box } from '@mui/system';

export type CustomInputProps = {
  fullWidth?: boolean;
  maxWidth?: string;
  errorText?: string;
  minWidth?: string;
  error?: boolean;
  warningText?: string;
};

type InputType = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> &
  CustomInputProps;

const StyledInput = styled((props: InputType) => <input {...props} />)(({ theme, error, warningText, fullWidth }) => ({
  '&[type=number]': {
    '-moz-appearance': 'textfield',
  },
  '&::-webkit-outer-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0,
  },
  '&::-webkit-inner-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0,
  },
  '&:not(:focus):not(:placeholder-shown):invalid': {
    boxShadow: `0 0 0 1px ${theme.palette.error.main}`,
  },
  width: fullWidth ? '100%' : 'auto',
  padding: '0px 6px',
  height: 40,
  position: 'relative',
  background: 'white',
  boxShadow: `0 0 0 1px ${
    error ? theme.palette.error.main : warningText ? theme.palette.warning.light : theme.palette.info.light
  }`,
  borderRadius: 4,
  outline: 'none',
  border: 0,
  fontSize: theme.typography.subtitle2.fontSize,
}));

const styles = {
  container: {
    width: 'auto',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    '.errorMessage': {
      position: 'absolute',
      minHeight: 40,
      left: 0,
      right: 0,
      overflowWrap: 'break-word',
      bottom: -42,
      minWidth: 64,
    },
    '.hide': {
      display: 'none',
    },
    'input:not(:focus):not(:placeholder-shown):invalid ~ .errorMessage': {
      display: 'block',
    },
    'input:not(:focus):not(:placeholder-shown):invalid ~ .warn': {
      display: 'none',
    },
  },
} as const;
const CustomInput: FC<InputType> = (props) => {
  return (
    <Box
      sx={{
        ...styles.container,
        maxWidth: props.maxWidth ? props.maxWidth : 'auto',
        minWidth: props.minWidth ? props.minWidth : 'auto',
      }}
    >
      <StyledInput {...props} />
      <Typography
        className={`errorMessage ${props.error ? undefined : 'hide'}`}
        align={'center'}
        variant={'caption'}
        color={'error.main'}
      >
        {props.errorText}
      </Typography>
      {props.warningText ? (
        <Typography className={'errorMessage warn'} align={'center'} variant={'caption'} color={'warning.main'}>
          {props.warningText}
        </Typography>
      ) : undefined}
    </Box>
  );
};

export default memo(CustomInput);
