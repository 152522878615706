import { OutputFile } from '../../models/jobModels/ModelsFileTypes';

export const findOutputFileName = (
  outputFiles: OutputFile[],
  name: string,
  index: number,
  defaultValue: string,
  isDefault?: boolean,
) => {
  return isDefault
    ? `${defaultValue}_${index}`
    : outputFiles.find((file) => file.alias === `${name}_${index}.csv`)?.name.slice(0, -4) ||
        `${defaultValue}_${index}`;
};
