import { FileForm } from '../../../models/inputTypes/FileType';
import { customApi } from '../Api';
import { openErrorToast, openSuccessToast, setGlobalLoading } from '../../slices/appSlice';

const extendedApi = customApi.injectEndpoints({
  endpoints: (builder) => ({
    getProjectFiles: builder.query<FileForm[], string>({
      query: (id) => ({
        url: `/api/projects/${id}/files/`,
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (err: any) {
          dispatch(openErrorToast(err.error.data.error));
        }
      },
      providesTags: ['projectFiles'],
    }),
    getProjectFileJSON: builder.query<any, { projectId: string; fileName: string }>({
      query: ({ projectId, fileName }) => ({
        url: `/api/projects/${projectId}/files/${fileName}`,
        headers: { 'content-type': 'text/csv' },
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          dispatch(setGlobalLoading(true));
          await queryFulfilled;
          dispatch(openSuccessToast('File fetched successfully!'));
        } catch (err: any) {
          dispatch(openErrorToast('Error fetching file data. Try again!'));
        }
        dispatch(setGlobalLoading(false));
      },
    }),
  }),
  overrideExisting: false,
});

export const { useGetProjectFilesQuery, useGetProjectFileJSONQuery } = extendedApi;
