import React, { FC } from 'react';
import { closeToast } from '../../redux/slices/appSlice';
import { Snackbar, Typography } from '@mui/material';
import CustomAlert from '../custom/CustomAlert';
import { useAppDispatch, useAppSelector } from '../../redux/store';

const SnackBarContainer: FC = ({ ...props }) => {
  const dispatch = useAppDispatch();

  const open = useAppSelector((state) => state.app.toast.isOpen);
  const message = useAppSelector((state) => state.app.toast.message);
  const severity = useAppSelector((state) => state.app.toast.severity);

  const handleClose = (event: any, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(closeToast());
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      open={open}
      autoHideDuration={4000}
      onClose={handleClose}
      {...props}
    >
      <CustomAlert closeText={undefined} icon={false} variant={'filled'} severity={severity}>
        <Typography align={'center'} variant={'h5'}>
          {message}
        </Typography>
      </CustomAlert>
    </Snackbar>
  );
};

export default SnackBarContainer;
