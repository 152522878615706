import { isRejectedWithValue, Middleware } from '@reduxjs/toolkit';
import { clearUser } from '../slices/authenticationSlice';
import { customApi } from '../RTK/Api';

export const authenticatedMiddleware: Middleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    if (isRejectedWithValue(action)) {
      if (action?.payload?.status === 401) {
        dispatch(clearUser());
        dispatch(customApi.util.resetApiState());
      }
    }
    return next(action);
  };
