import {
  PlotArea,
  PlotLine,
  PmsBoundaryConditions,
  PmsDissipation,
  PmsModelFeatures,
  PmsNumericDomain,
  PmsOutput,
  PmsSubmission,
} from '../../models/inputTypes/PmsFields';

export const pmsNumericDomainInitialState: PmsNumericDomain = {
  project: '',
  simulationSetup: undefined,
  cellX: 1,
  cellY: 1,
  cellSizeX: 2.5,
  cellSizeY: 2.5,
  bathymetricData: undefined,
  waterLevel: 0,
};

export const pmsBoundaryConditionsInitialState: PmsBoundaryConditions = {
  boundaryType: '1',
  coord1: '2',
  coord2: '2',
  seaState: '2',
  hm: 2,
  ts: 10,
  direction: 270,
  numberOfPeriods: 5,
  spectrum: '1',
  depthWavemaker: 10.0,
  minPeriod: 2.5,
  maxPeriod: 16.0,
  peakFactorY: 3.3,
};

export const pmsModelFeaturesInitialState: PmsModelFeatures = {
  nonlinearDispersion: true,
  iterations: 3,
  relation: '2',
  approximitation: '1',
  degrees: 10,
};

export const pmsDissipationInitialState: PmsDissipation = {
  waveBreaking: true,
  a: 1,
  y1: 1,
  y2: 0.8,
  bottomFriction: true,
  waveBreakingType: 'constant',
  nikuradse: '0.002',
  frictionFile: undefined,
};

export const pmsSubmissionInitialState: PmsSubmission = {
  simulationFile: '',
  vCpus: 0,
};

export const pmsOutputInitialState: PmsOutput = {
  plotAreas: 0,
  plotLines: 0,
  plotAreaValues: [],
  plotLineValues: [],
};

export const plotLinesInitialState: PlotLine = {
  fp_x: 1,
  fp_y: 1,
  lp_x: 1,
  lp_y: 1,
  h: 0,
  c: 0,
  z: 0,
  dir: 0,

  name_h: 'H_Line',
  name_z: 'Z_Line',
  name_c: 'C_Line',
  name_dir: 'MWD_Line',
};

export const plotAreasInitialState: PlotArea = {
  sw_x: 1,
  sw_y: 1,
  ne_x: 1,
  ne_y: 1,
  h: 0,
  c: 0,
  z: 0,
  dir: 0,
  rad: 0,
  init_depths: 0,

  name_h: 'H_Area',
  name_c: 'C_Area',
  name_z: 'Z_Area',
  name_dir: 'MWD_Area',
  name_rad_xx: 'Sxx_Area',
  name_rad_xy: 'Sxy_Area',
  name_rad_yy: 'Syy_Area',
  name_init_depths: 'In_Depths_Area',
};

export const pmsPlotLinesAliases = {
  name_h: 'O_H_Line',
  name_z: 'O_Z_Line',
  name_c: 'O_C_Line',
  name_dir: 'O_MWD_Line',
};

export const pmsPlotAreasAliases = {
  name_h: 'O_H_Area',
  name_c: 'O_C_Area',
  name_z: 'O_Z_Area',
  name_dir: 'O_MWD_Area',
  name_rad_xx: 'O_Sxx_Area',
  name_rad_xy: 'O_Sxy_Area',
  name_rad_yy: 'O_Syy_Area',
  name_init_depths: 'O_In_Depths_Area',
};
