import { BtgSubmission } from '../../models/inputTypes/BtgFields';

import {
  MarisPlotDataSelection,
  MarisPlotFigureProperties,
  MarisPlotLabelPdf,
  MarisPlotPrintSetup,
} from '../../models/inputTypes/MarisPlotFields';
import { PlotType } from '../../models/jobModels/plotType';
import {
  marisPlotDataSelectionInitialState,
  marisPlotFigurePropertiesInitialState,
  marisPlotLabelPdfInitialState,
  marisPlotPrintSetupInitialState,
} from '../initialStates/marisPlotState';

export type MarisSimulationPreparationResponse = {
  dataSelection: MarisPlotDataSelection;
  figureProperties: MarisPlotFigureProperties;
  printSetup: MarisPlotPrintSetup;
  pdfLabel: MarisPlotLabelPdf;
  submission: BtgSubmission;
};

export const marisSimulationPreparation = (
  simulationFile: PlotType,
): MarisSimulationPreparationResponse | undefined => {
  const dataFile = simulationFile.parameters.input_file;
  const direction = simulationFile.parameters.kwargs.vector_file;
  const landDataFile = simulationFile.parameters.kwargs.depth_file;
  const logoFile = simulationFile.parameters.kwargs.logo_file;

  const dataSelection: MarisPlotDataSelection = {
    project: simulationFile.project,
    dataFile: dataFile
      ? { currentFile: { project: +dataFile.project, name: dataFile.fileid }, fileName: dataFile.fileid }
      : undefined,
    plotVector: simulationFile.parameters.kwargs.plot_vectors == 1,
    directionFile: direction
      ? { currentFile: { project: +direction.project, name: direction.fileid }, fileName: direction.fileid }
      : undefined,
    numericalX: +simulationFile.parameters.ni,
    numericalY: +simulationFile.parameters.nj,
    numericalDX: +simulationFile.parameters.dx,
    numericalDY: +simulationFile.parameters.dy,
    nePointX: +simulationFile.parameters.kwargs.ix_northeast ?? marisPlotDataSelectionInitialState.nePointX,
    nePointY: +simulationFile.parameters.kwargs.jy_northeast ?? marisPlotDataSelectionInitialState.nePointY,
    swPointX: +simulationFile.parameters.kwargs.ix_southwest ?? marisPlotDataSelectionInitialState.swPointX,
    swPointY: +simulationFile.parameters.kwargs.jy_southwest ?? marisPlotDataSelectionInitialState.swPointY,
    subSeriesType: simulationFile.parameters.kwargs.plot_mode == 1 ? 'time-dependent' : 'stationary',
    frame: +simulationFile.parameters.kwargs.frame,
  };
  const figureProperties: MarisPlotFigureProperties = {
    title: simulationFile.parameters.kwargs.palette_title,
    paletteColors: simulationFile.parameters.kwargs.plot_palette.toString(),
    minMaxValues: simulationFile.parameters.kwargs.estimate_minmax == 0,
    minValue: +simulationFile.parameters.kwargs.bounds_min,
    maxValue: +simulationFile.parameters.kwargs.bounds_max,
    paletteNumberOfLevels: +simulationFile.parameters.kwargs.cmap_lvls,
    paletteFontSize: +simulationFile.parameters.kwargs.font_cbar,

    isoLines: simulationFile.parameters.kwargs.plot_contours == 1,
    transparency: +simulationFile.parameters.kwargs.contour_transparency,
    contoursNumberOfLevels: +simulationFile.parameters.kwargs.contour_lvls,
    contoursFontSize: +simulationFile.parameters.kwargs.font_contours,

    vectorColors:
      simulationFile.parameters?.kwargs.vector_color?.toString() ?? marisPlotFigurePropertiesInitialState.vectorColors,
    plotVector: simulationFile.parameters?.kwargs.plot_reference_vector == 1,
    vectorIntervalX:
      simulationFile.parameters.kwargs?.vector_interval_x ?? marisPlotFigurePropertiesInitialState.vectorIntervalX,
    vectorIntervalY:
      simulationFile.parameters.kwargs?.vector_interval_y ?? marisPlotFigurePropertiesInitialState.vectorIntervalY,
    vectorScaling:
      simulationFile.parameters.kwargs?.vector_scaling ?? marisPlotFigurePropertiesInitialState.vectorScaling,

    plotLand: simulationFile.parameters.kwargs.plot_land == 1,
    plotLandFile: landDataFile
      ? { currentFile: { project: +landDataFile.project, name: landDataFile.fileid }, fileName: landDataFile.fileid }
      : undefined,
    landColor: simulationFile.parameters.kwargs.land_color.toString(),

    plotBasemap: simulationFile.parameters.kwargs.plot_basemap == 1,
    basemapCodeValue: +simulationFile.parameters.kwargs.basemap_code,
    inpEPSG: +simulationFile.parameters.kwargs.input_epsg,
    originX: +simulationFile.parameters.kwargs.x_origin,
    originY: +simulationFile.parameters.kwargs.y_origin,
    mapProvider: +simulationFile.parameters.kwargs.basemap_provider,

    axesType: simulationFile.parameters.kwargs.axis_type == 2 ? 'grid' : 'meter',
    majorGridLines: simulationFile.parameters.kwargs.plot_gridlines == 1,
    yAxisTitle: simulationFile.parameters.kwargs.axis_y,
    xAxisTitle: simulationFile.parameters.kwargs.axis_x,
    axeFontSize: +simulationFile.parameters.kwargs.font_axis,
  };

  const printSetup: MarisPlotPrintSetup = {
    north_arrow: simulationFile.parameters.kwargs?.north_arrow == 1,
    north_arrow_pos:
      simulationFile.parameters.kwargs?.north_arrow_pos ?? marisPlotPrintSetupInitialState.north_arrow_pos,
    north_arrow_colour:
      simulationFile.parameters.kwargs?.north_arrow_colour ?? marisPlotPrintSetupInitialState.north_arrow_colour,
    north_arrow_rot:
      simulationFile.parameters.kwargs?.north_arrow_rot ?? marisPlotPrintSetupInitialState.north_arrow_rot,

    label: simulationFile.parameters.kwargs.include_label == 1,
    subSeriesType: simulationFile.parameters.kwargs.filetype == 2 ? 'png' : 'pdf',
    pageOrientation: simulationFile.parameters.kwargs.orientation.toString(),
    imageDPI: simulationFile.parameters.kwargs.figure_dpi.toString(),
  };

  const pdfLabel: MarisPlotLabelPdf = {
    userIdType: simulationFile.parameters.kwargs.userID == 2 ? 'logo' : 'username',
    username: simulationFile.parameters.kwargs.userName ?? marisPlotLabelPdfInitialState.username,
    logoFile: logoFile
      ? { currentFile: { project: +logoFile.project, name: logoFile.fileid }, fileName: logoFile.fileid }
      : undefined,
    modelApplied:
      simulationFile.parameters.kwargs.modelApplied?.toString() ?? marisPlotLabelPdfInitialState.modelApplied,
    client: simulationFile.parameters.kwargs.clientName?.toString() ?? marisPlotLabelPdfInitialState.client,
    projectTitle: simulationFile.parameters.kwargs.projectTitle ?? marisPlotLabelPdfInitialState.projectTitle,
    figureDescription:
      simulationFile.parameters.kwargs.figureDescription ?? marisPlotLabelPdfInitialState.figureDescription,
    figureNumber: simulationFile.parameters.kwargs.figureNumber ?? marisPlotLabelPdfInitialState.figureNumber,
    figureTitle: simulationFile.parameters.kwargs.figureTitle ?? marisPlotLabelPdfInitialState.figureTitle,
    date: simulationFile.parameters.kwargs.date ?? marisPlotLabelPdfInitialState.date,
  };

  const submission: BtgSubmission = {
    outputFileName: simulationFile.parameters.output_file?.name.slice(0, -4) ?? '',
    simulationFile: '',
  };
  return { dataSelection, figureProperties, printSetup, pdfLabel, submission };
};
