import React, { FC, useEffect, useState } from 'react';
import { Box, CircularProgress, Grid, styled, Typography } from '@mui/material';
import loginIcon from '../../assets/images/login.png';
import { useHistory } from 'react-router';
import { ReactComponent as ScientiaLogo } from '../../assets/images/scientiaMaris.svg';
import LoginInput from '../../components/common/LoginInput';
import LabelWrapper from '../../components/common/LabelWrapper';
import CustomButton from '../../components/custom/CustomButton';
// import { useLoginMutation } from '../redux/RTK/mutations/loginMutations';
// import { Redirect } from 'react-router-dom';

const BoxBackground = styled(Box)(() => ({
  overflow: 'auto',
  background: `url(${loginIcon})`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  height: '100vh',
  width: '100vw',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const Card = styled(Box)(({ theme }) => ({
  background: '#FAFAFA',
  width: '280px',
  height: '345px',
  borderRadius: 4,
  boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.24)',
  padding: '38px 32px',
  '& >:first-child': {
    display: 'flex',
    margin: theme.spacing(0, 'auto', 4.5, 'auto'),
  },

  form: {
    '& div': {
      marginBottom: theme.spacing(2),
    },
  },
}));

const SentEmail: FC = () => {
  useEffect(() => {
    document.title = 'Reset your password - Scientia Maris';
  }, []);

  return (
    <BoxBackground>
      <Card>
        <ScientiaLogo />
        <Box>
          <Typography variant={'h5'} align={'center'}>
            Confirm password reset
          </Typography>
          <Typography fontSize={13} align={'center'} mt={'50px'}>
            We have emailed you instructions for resetting your password, if an account exists for the email address you
            entered.
          </Typography>
        </Box>
      </Card>
    </BoxBackground>
  );
};

export default SentEmail;
