import React, { FC } from 'react';
import { Backdrop, CircularProgress, Grid, Paper, Theme, Typography, Box } from '@mui/material';
import DxfMap from '../../common/DxfMap';
import BoxInputContainer from '../../custom/BoxInputContainer';

const classes = {
  map: {
    width: '100%',
    height: '100%',
  },
  paper: {
    backgroundColor: (theme: Theme) => theme.palette.grey[100],
    padding: 2,
    width: '100%',
    height: '100%',
  },
  zoomOut: {
    marginLeft: (theme: Theme) => theme.spacing(1),
  },
  backdropContainer: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  backdrop: {
    position: 'absolute',
    zIndex: (theme: Theme) => theme.zIndex.drawer + 1,
    color: (theme: Theme) => theme.palette.common.white,
    borderRadius: 4,
    width: '100%',
    height: '100%',
    // marginTop: 1,
    // marginLeft: 1,
  },
} as const;

const FileHeatmapCard: FC<{
  dxfMap: any;
  dxfLoading: boolean;
  errorDxf: boolean;
}> = ({ dxfMap, dxfLoading, errorDxf, ...rest }) => {
  return (
    <BoxInputContainer borderRadius={'4px'} sx={{ padding: '8px', width: '100%', height: '100%' }}>
      {/* <Grid item xs={12} sx={classes.backdropContainer} alignItems={'flex-start'}> */}
      <Box sx={classes.backdropContainer}>
        <DxfMap dxfMap={dxfMap} />
        <Backdrop sx={classes.backdrop} open={dxfLoading || errorDxf}>
          {!dxfLoading && errorDxf ? (
            <Typography variant={'body2'}>Error loading dxf file</Typography>
          ) : (
            <CircularProgress color={'inherit'} />
          )}
        </Backdrop>
      </Box>
      {/* </Grid> */}
    </BoxInputContainer>
  );
};

export default FileHeatmapCard;
