import { BtgBathymetric, BtgInterpolation, BtgNumericDomain, BtgSubmission } from '../../models/inputTypes/BtgFields';
import { BtgType } from '../../models/jobModels/btgType';
import { dynamicInputFilePreparation } from '../functions/dynamicInputFilePreparation';

export const btgPreparation = (
  bathymetric: BtgBathymetric,
  numerical: BtgNumericDomain,
  interpolation: BtgInterpolation,
  submission: BtgSubmission,
): BtgType | unknown => {
  const inputFiles = dynamicInputFilePreparation({
    I_Points: bathymetric.dataPoints,
    I_Polygons: bathymetric.polygonsData,
  });
  const preparedValues: BtgType = {
    simulation: 'btg',
    project: bathymetric.project.toString(),
    name: submission.simulationFile,
    parameters: {
      input_files: inputFiles,
      output_files: [
        {
          alias: 'O_Depths.csv',
          name: `${submission.outputFileName}.csv`,
          project: bathymetric.project.toString(),
        },
      ],
      ocxin: +bathymetric.coordX,
      ocyin: +bathymetric.coordY,
      nply: +bathymetric.closedPolygons,
      // ikm: +bathymetric.points,
      imin: +numerical.nodeX,
      jmin: +numerical.nodeY,
      dx: +numerical.sizeDX,
      dy: +numerical.sizeDY,
      // xinland: +numerical.landValue,
      inmeth: +interpolation.interpolation,
      sradius: +interpolation.searchRadius,
    },
  };
  return preparedValues;
};
