import { openErrorToast, openSuccessToast, setGlobalLoading } from '../../slices/appSlice';
import { customApi } from '../Api';
import { SrfRefEddyCalculator } from '../../../models/inputTypes/SrfFields';

const extendedApi = customApi.injectEndpoints({
  endpoints: (builder) => ({
    calculateRef: builder.query<any, SrfRefEddyCalculator>({
      query: ({ sea_state, Hs, Tp, depth, width }) => ({
        url: `/api/custom/srfref/`,
        method: 'POST',
        body: { sea_state, Hs, Tp, depth, width },
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          dispatch(setGlobalLoading(true));
          await queryFulfilled;
          dispatch(openSuccessToast('Reflection - Eddy graph updated!'));
        } catch (err) {
          dispatch(openErrorToast('Error submitting. Please try again!'));
        }
        dispatch(setGlobalLoading(false));
      },
    }),
  }),
});

export const { useLazyCalculateRefQuery } = extendedApi;
