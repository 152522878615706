import React, { FC, memo } from 'react';
import { Box } from '@mui/material';
import LabelWrapper from '../../common/LabelWrapper';
import CustomInput from '../../custom/CustomInput';
import { HydInitialConditions } from '../../../models/inputTypes/HydFields';

const styles = {
  spacingContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    '& >:nth-child(n)': {
      my: 1,
      mr: { xs: 3, lg: 4, xl: 4 },
    },
  },
} as const;

type InputsProps = {
  inputState: HydInitialConditions;
  setInputState: (value: HydInitialConditions) => void;
};

const HydColdStartInputsGroup: FC<InputsProps> = ({ inputState, setInputState }) => {
  const onNumberChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setInputState({ ...inputState, [event.target.name]: event.target.value });
  };
  return (
    <>
      <Box sx={{ ...styles.spacingContainer }}>
        <Box>
          <LabelWrapper label={'U-Velocity component (m/s)'}>
            <CustomInput
              required
              step="any"
              errorText={'Enter positive number'}
              type={'number'}
              onChange={onNumberChange}
              name={'uVelocity'}
              value={inputState.uVelocity}
            />
          </LabelWrapper>
        </Box>
        <Box>
          <LabelWrapper label={'V-Velocity component (m/s)'}>
            <CustomInput
              required
              step="any"
              errorText={'Enter positive number'}
              type={'number'}
              onChange={onNumberChange}
              name={'vVelocity'}
              value={inputState.vVelocity}
            />
          </LabelWrapper>
        </Box>
        <Box>
          <LabelWrapper label={'Z-Surface elevation (m)'}>
            <CustomInput
              required
              step="any"
              errorText={'Enter positive number'}
              type={'number'}
              onChange={onNumberChange}
              name={'zVelocity'}
              value={inputState.zVelocity}
            />
          </LabelWrapper>
        </Box>
      </Box>
    </>
  );
};

export default memo(HydColdStartInputsGroup);
