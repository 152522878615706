import { Breadcrumbs, Typography } from '@mui/material';
import BoxInputContainer from '../custom/BoxInputContainer';
import React, { FC, memo } from 'react';

const PageBreadCrumbs: FC<{ path?: string[] }> = ({ path }) => {
  return (
    <BoxInputContainer borderRadius={'4px'} sx={{ padding: '8px' }}>
      <Breadcrumbs color={'info.dark'} separator={'>>'}>
        {path?.map((item, i) => (
          <Typography variant={'subtitle1'} color={i != 0 ? 'info.dark' : 'info.main'} key={item}>
            {item}
          </Typography>
        ))}
      </Breadcrumbs>
    </BoxInputContainer>
  );
};

export default memo(PageBreadCrumbs);
