import {
  MarisPlotDataSelection,
  MarisPlotFigureProperties,
  MarisPlotLabelPdf,
  MarisPlotPrintSetup,
} from '../../models/inputTypes/MarisPlotFields';

import { BtgSubmission } from '../../models/inputTypes/BtgFields';
import { PlotType } from '../../models/jobModels/plotType';

export const plotPreparation = (
  dataSelectionState: MarisPlotDataSelection,
  figurePropertiesState: MarisPlotFigureProperties,
  printSetupState: MarisPlotPrintSetup,
  labelPdfState: MarisPlotLabelPdf,
  submissionState: BtgSubmission,
): PlotType => {
  const outputFile = submissionState.outputFileName
    ? {
        output_file: {
          project: dataSelectionState.project.toString(),
          name: `${submissionState.outputFileName}.${printSetupState.subSeriesType}`,
        },
      }
    : undefined;
  const inputFile = dataSelectionState.dataFile?.currentFile?.name
    ? {
        input_file: {
          project: dataSelectionState.project.toString(),
          fileid: dataSelectionState.dataFile.currentFile.name,
        },
      }
    : undefined;
  const vectorFile = dataSelectionState.directionFile?.currentFile?.name
    ? {
        vector_file: {
          project: dataSelectionState.project.toString(),
          fileid: dataSelectionState.directionFile.currentFile.name,
        },
      }
    : undefined;

  const depthFile = figurePropertiesState.plotLandFile?.currentFile?.name
    ? {
        depth_file: {
          project: dataSelectionState.project.toString(),
          fileid: figurePropertiesState.plotLandFile.currentFile.name,
        },
      }
    : undefined;

  const logoFile = labelPdfState.logoFile?.currentFile?.name
    ? { logo_file: { fileid: labelPdfState.logoFile.currentFile.name, project: dataSelectionState.project.toString() } }
    : undefined;

  const labelPdf = printSetupState.label
    ? {
        userID: labelPdfState.userIdType === 'username' ? 1 : 2,
        ...(labelPdfState.userIdType === 'username' ? { userName: labelPdfState.username.toString() } : undefined),
        ...logoFile,
        modelApplied: +labelPdfState.modelApplied,
        clientName: labelPdfState.client.toString(),
        projectTitle: labelPdfState.projectTitle.toString(),
        figureDescription: labelPdfState.figureDescription.toString(),
        figureNumber: labelPdfState.figureNumber.toString(),
        figureTitle: labelPdfState.figureTitle.toString(),
        date: labelPdfState.date.toString(),
      }
    : undefined;
  const preparedValues: PlotType = {
    simulation: 'plot',
    project: dataSelectionState.project.toString(),
    name: submissionState.simulationFile,
    parameters: {
      ...inputFile,
      ...outputFile,
      ni: +dataSelectionState.numericalX,
      nj: +dataSelectionState.numericalY,
      dx: +dataSelectionState.numericalDX,
      dy: +dataSelectionState.numericalDY,
      kwargs: {
        ...vectorFile,
        ...depthFile,
        plot_vectors: dataSelectionState.plotVector ? 1 : 0, //??
        ix_southwest: +dataSelectionState.swPointX, // # I of southwest point
        jy_southwest: +dataSelectionState.swPointY, //  # J of southwest point
        ix_northeast: +dataSelectionState.nePointX, // # I of northeast point
        jy_northeast: +dataSelectionState.nePointY, // # J of northeast point

        plot_mode: dataSelectionState.subSeriesType === 'stationary' ? 0 : 1, //?? is this the subseries selection ??????
        frame: +dataSelectionState.frame, //?? is this the correct frame??????

        estimate_minmax: figurePropertiesState.minMaxValues ? 0 : 1, //?
        palette_title: figurePropertiesState.title,
        plot_palette: +figurePropertiesState.paletteColors, // is it correct???
        bounds_min: +figurePropertiesState.minValue, //# min value of pallete
        bounds_max: +figurePropertiesState.maxValue, //# max value of pallete
        cmap_lvls: +figurePropertiesState.paletteNumberOfLevels, //??
        font_cbar: +figurePropertiesState.paletteFontSize, //palette font size???

        plot_contours: figurePropertiesState.isoLines ? 1 : 0, //is plot contures the isolines????
        contour_transparency: +figurePropertiesState.transparency,
        contour_lvls: +figurePropertiesState.contoursNumberOfLevels,
        font_contours: +figurePropertiesState.contoursFontSize,

        ...(vectorFile
          ? {
              vector_color: +figurePropertiesState.vectorColors,
              plot_reference_vector: figurePropertiesState.plotVector ? 1 : 0,
              vector_interval_x: +figurePropertiesState.vectorIntervalX,
              vector_interval_y: +figurePropertiesState.vectorIntervalY,
              vector_scaling: +figurePropertiesState.vectorScaling,
            }
          : undefined),

        plot_land: figurePropertiesState.plotLand ? 1 : 0,
        land_color: +figurePropertiesState.landColor,

        axis_type: figurePropertiesState.axesType === 'meter' ? 1 : 2,
        plot_gridlines: figurePropertiesState.majorGridLines ? 1 : 0,
        axis_x: figurePropertiesState.xAxisTitle.toString(),
        axis_y: figurePropertiesState.yAxisTitle.toString(),
        font_axis: figurePropertiesState.axeFontSize,

        plot_basemap: figurePropertiesState.plotBasemap ? 1 : 0,
        basemap_code: +figurePropertiesState.basemapCodeValue,
        input_epsg: +figurePropertiesState.inpEPSG,
        x_origin: +figurePropertiesState.originX,
        y_origin: +figurePropertiesState.originY,
        basemap_provider: +figurePropertiesState.mapProvider,

        filetype: printSetupState.subSeriesType === 'pdf' ? 1 : 2,
        orientation: +printSetupState.pageOrientation,
        figure_dpi: +printSetupState.imageDPI,
        include_label: printSetupState.label ? 1 : 0,

        north_arrow: printSetupState.north_arrow ? 1 : 0,
        north_arrow_pos: +printSetupState.north_arrow_pos,
        north_arrow_colour: +printSetupState.north_arrow_colour,
        north_arrow_rot: +printSetupState.north_arrow_rot,

        ...labelPdf,
      },
    },
  };

  return preparedValues;
};

// const available_key_arguments = {
//   estimate_minmax: 1, // # 0 ->set levels from bounding values , 1->code finds min/max
//   plot_mode: 0, //# 0->stationary plot , 1->time dependent plot
//   frame: 1,
//   // # Palette
//   palette_title: '', // # give name of title
//   plot_palette: 2, // # Color Palettes (1-5 sequential colormaps,6-8 bathymetry colormaps,9-11 diverging colormaps, 12-13 Custom)
//   cmap_lvls: 21, //# levels of palette
//   font_cbar: 5, //# Contours - Isolines
//   plot_contours: 0, //# 0->exclude , 1->include
//   contour_transparency: 0.6,
//   contour_lvls: 11, //# levels of contours - isolines
//   font_contours: 5, //# Vectors
//   plot_vectors: 0, // # 0->exclude , 1->include
//   vector_color: 1, //  # 1->black , 2->white
//   plot_reference_vector: 0, // # 0->exclude , 1->include
//   vector_interval_x: 10,
//   vector_interval_y: 10,
//   vector_scaling: 0.05,
//   min_vector_to_plot: 0.000001,
//   normalize_vectors: 0, // #0-> dont normalize, #1 normalize
//   //# Land
//   plot_land: 0, // # 0->exclude , 1->include
//   land_color: 1, // # 1->darkgrey , 2->white, 3->yellow
//   //# Axis
//   axis_type: 2, // # 1->length, 2->grid
//   axis_x: 'nodes in x-direction', //# give title in x-axis
//   axis_y: 'nodes in y-direction', //# give title in y-axis
//   font_axis: 5,
//   plot_gridlines: 0, //# 0->exclude , 1->include# Print
//   filetype: 2, // # 1->PDF , 2->jpg
//   orientation: 1, //# 1->Portrait , 2->Landscape
//   figure_dpi: 2, // # 1->100, 2->300, 3->600, 4->800
//   include_label: 0, // # 0->exclude , 1->include
//   tmp_dir: '/tmp',
//   // # Label defaults not needed
//   //######### VALUES BELOW ARE OPTIONAL AND THUS DO NOT REQUIRE A DEFAULT ##########
//   ix_southwest: 81, // # I of southwest point
//   jy_southwest: 210, //  # J of southwest point
//   ix_northeast: 630, // # I of northeast point
//   jy_northeast: 659, // # J of northeast point
//   frame_start: 1,
//   frame_end: 1,
//   bounds_min: 0, //# min value of pallete
//   bounds_max: 4, //# max value of pallete
//   depth_file: 'FULL NAME',
//   vector_file: 'FULL NAME',
//   userID: 1, // # 1->Name 2-> Logo
//   userName: 'xxxxxxxxxx',
//   modelApplied: 1, // # 1=BTG, 2=PMS, 3=HMS, 4=HYD, 5=SDT, 6=BSQ, 7=SPW
//   clientName: 'xxxxxxxxxxxxxx',
//   projectTitle: 'Default Title',
//   figureDescription: 'Default Fig Description',
//   figureNumber: 'Default Fig Number',
//   figureTitle: 'Default Fig Title',
//   date: '01/01/1970',
// };
