export enum ModelEnum {
  BTG = 'btg',
  SRF = 'srf',
  PMS = 'pms',
  HMS = 'hms',
  HYD = 'hyd',
  SDT = 'sdt',
  PLOT = 'plot',
  EVI = 'evi',
  SHORELINES = 'shorelineS',
}
