import React, { FC, memo, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, styled, Typography } from '@mui/material';
import BarLink from '../navigation/BarLink';
import { ExpandMore } from '@mui/icons-material';

const StyledAccordionSummary = styled(AccordionSummary)(() => ({
  padding: '8px 16px',
  margin: 0,
  minHeight: 0,
  background: '#f2f2f2',
}));

const StyledAccordion = styled(Accordion)(() => ({
  padding: 0,
  minHeight: 0,
  margin: 0,
  '& .MuiAccordionSummary-content': {
    margin: 0,
  },
}));

const StyledAccordionDetails = styled(AccordionDetails)((theme) => ({
  padding: 0,
  margin: 0,
  background: theme.theme.palette.info.contrastText,
}));

type NavAccordionProps = {
  title: string;
  barLinks: { to: string; title: string; image?: string; exact?: boolean }[];
};

const NavAccordion: FC<NavAccordionProps> = ({ title, barLinks }) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const handleChange = (event: React.SyntheticEvent, expanded: boolean) => {
    setIsExpanded(expanded);
  };
  return (
    <StyledAccordion disableGutters elevation={0} expanded={isExpanded} onChange={handleChange}>
      <StyledAccordionSummary expandIcon={<ExpandMore />}>
        <Typography variant={'subtitle1'}>{title}</Typography>
      </StyledAccordionSummary>
      <StyledAccordionDetails>
        <Box width={'100%'}>
          {barLinks.map((link) => (
            <BarLink key={link.title} title={link.title} to={link.to} image={link.image} exact={link.exact} />
          ))}
        </Box>
      </StyledAccordionDetails>
    </StyledAccordion>
  );
};

export default memo(NavAccordion);
