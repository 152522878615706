import React, { FC } from 'react';
import { Backdrop, CircularProgress, Grid, Paper, Theme, Typography } from '@mui/material';
import Heatmap from '../../common/Heatmap/Heatmap';
import FileDataSheet from '../../common/FileDataSheet/FileDataSheet';

const classes = {
  paper: {
    backgroundColor: (theme: Theme) => theme.palette.grey[100],
    padding: 2,
  },
  zoomOut: {
    marginLeft: (theme: Theme) => theme.spacing(1),
  },
  backdropContainer: {
    position: 'relative',
  },
  backdrop: {
    position: 'absolute',
    zIndex: (theme: Theme) => theme.zIndex.drawer + 1,
    color: (theme: Theme) => theme.palette.common.white,
    borderRadius: 4,
    width: '100%',
    height: '100%',
    marginTop: 1,
    marginLeft: 1,
  },
} as const;

const FileHeatmapCard: FC<{
  levels: number;
  errorHeat?: boolean;
  errorGrid?: boolean;
  heatmap: any;
  grid: any[][] | undefined;
  setGrid: (grid: any[][]) => void;
  frameCoords: { x: number; y: number };
  setFrameCoords: (frameCoords: { x: number; y: number }) => void;
  gridLoading: boolean;
  heatmapLoading: boolean;
}> = ({
  levels,
  errorHeat = false,
  errorGrid = false,
  heatmap,
  grid,
  setGrid,
  frameCoords,
  setFrameCoords,
  gridLoading,
  heatmapLoading,
  ...rest
}) => {
  return (
    <Paper sx={classes.paper} elevation={0} {...rest}>
      <Grid container spacing={2} alignItems={'flex-start'}>
        <Grid item xs={12} sx={classes.backdropContainer}>
          <Heatmap
            levels={levels}
            imageBase64={heatmap?.image}
            dimensions={heatmap?.dimensions}
            frameCoords={frameCoords}
            setFrameCoords={setFrameCoords}
            values={heatmap?.values}
            gradientColors={heatmap?.rgbaValues}
          />
          <Backdrop sx={classes.backdrop} open={heatmapLoading || !heatmap || (!heatmap && errorHeat)}>
            {!heatmapLoading && !heatmap && errorHeat ? (
              <Typography variant={'body2'}>Error loading Heatmap</Typography>
            ) : (
              <CircularProgress color={'inherit'} />
            )}
          </Backdrop>
        </Grid>
        <Grid item xs={12} sx={classes.backdropContainer}>
          <FileDataSheet
            grid={grid}
            setGrid={setGrid}
            mask={heatmap?.mask}
            coordinates={frameCoords}
            dimensions={heatmap?.dimensions}
            setCoordinates={setFrameCoords}
            disabled={gridLoading || !heatmap}
          />
          <Backdrop sx={classes.backdrop} open={gridLoading || !grid || (!grid && errorGrid)}>
            {errorGrid ? (
              <Typography variant={'body2'}>Error loading data sheet</Typography>
            ) : (
              <CircularProgress color={'inherit'} />
            )}
          </Backdrop>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default FileHeatmapCard;
