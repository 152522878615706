import React, { FC, memo } from 'react';
import { Box, Grid, SelectChangeEvent, Typography } from '@mui/material';
import CustomMenuItem from '../../custom/CustomMenuItem';
import CustomSelect from '../../custom/CustomSelect';
import LabelWrapper from '../../common/LabelWrapper';
import CustomInput from '../../custom/CustomInput';
import CustomRadioButton from '../../custom/CustomRadioButton';
import BoxComponent from '../../custom/BoxComponent';
import { ShorelinePlotLabelPdf } from '../../../models/inputTypes/ShorelineFields';
import ButtonFileDialog from '../../common/ButtonFileDialog';
import { FileType } from '../../../models/inputTypes/FileType';
import { shorelinePlotLabelPdfInitialState } from '../../../utils/initialStates/shorelineInputState';
import CustomTextArea from '../../custom/CustomTextArea';
import CustomSwitch from '../../custom/CustomSwitch';

const styles = {
  itemSpacing: {
    '& >:nth-child(n)': {
      my: 1,
      mr: 4,
    },
  },
  wrap: {
    flexWrap: 'wrap',
  },
  line: {
    bgcolor: 'info.light',
    height: 2,
  },
  flexStartBox: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  childMargin: {
    '& >:nth-child(n)': {
      my: 1,
      mr: 4,
    },
  },
  flexStartContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
  },
} as const;

type InputsProps = {
  projectId: string;
  inputState: ShorelinePlotLabelPdf;
  setInputState: (value: ShorelinePlotLabelPdf) => void;
};

const ShorelinePlotLabelInputsGroup: FC<InputsProps> = ({ projectId, inputState, setInputState }) => {
  const onInputChange = (
    event: SelectChangeEvent<unknown> | React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setInputState({ ...inputState, [event.target.name]: event.target.value });
  };

  const onRadioClick = (value: any) => {
    setInputState({
      ...inputState,
      userIdType: value,
      username: shorelinePlotLabelPdfInitialState.username,
      logoFile: undefined,
    });
  };

  const onFileChange = (file: FileType | undefined, fieldName: string) => {
    setInputState({ ...inputState, [fieldName]: file });
  };

  const onNumberChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setInputState({ ...inputState, [event.target.name]: event.target.value === '' ? undefined : +event.target.value });
  };

  const switchChange = (e: any) => {
    setInputState({ ...inputState, [e.target.name]: e.target.checked });
  };

  return (
    <Box>
      <Typography my={2} variant={'subtitle1'}>
        Select snapshot
      </Typography>
      <Box>
        <LabelWrapper label={'Frame'}>
          <CustomInput
            required
            maxWidth={'85px'}
            errorText={'Enter positive number'}
            type={'number'}
            value={inputState.frame}
            name={'frame'}
            onChange={onNumberChange}
          />
        </LabelWrapper>
      </Box>
      <Typography my={2} variant={'subtitle1'}>
        Basemap
      </Typography>
      <Box sx={{ ...styles.childMargin, ...styles.flexStartContainer }}>
        <Box>
          <LabelWrapper label={'Plot Basemap'}>
            <Grid container justifyContent={'center'} width={'100px'}>
              <CustomSwitch checked={inputState.plotBasemap} name={'plotBasemap'} onChange={switchChange} />
            </Grid>
          </LabelWrapper>
        </Box>
        <Box>
          <LabelWrapper label={'CRS EPSG code'}>
            <CustomInput
              required
              placeholder={'-'}
              min={1024}
              max={32767}
              step={1}
              maxWidth={'85px'}
              disabled={!inputState.plotBasemap}
              errorText={'Enter number 1024-32767'}
              type={'number'}
              value={inputState.inpEPSG}
              name={'inpEPSG'}
              onChange={onNumberChange}
            />
          </LabelWrapper>
        </Box>
        <Box>
          <LabelWrapper label={'Map Layer'}>
            <CustomSelect
              required
              placeholder={'-'}
              disabled={!inputState.plotBasemap}
              value={inputState.mapProvider}
              name={'mapProvider'}
              onChange={onInputChange}
              minWidth={'140px'}
              defaultValue={''}
              displayEmpty
            >
              <CustomMenuItem key={'map-1'} value={1}>
                <Typography variant={'subtitle2'}>World Satellite (ESRI)</Typography>
              </CustomMenuItem>
              <CustomMenuItem key={'map-2'} value={2}>
                <Typography variant={'subtitle2'}>World Street Map (ESRI)</Typography>
              </CustomMenuItem>
              <CustomMenuItem key={'map-3'} value={3}>
                <Typography variant={'subtitle2'}>World Topo Map (ESRI)</Typography>
              </CustomMenuItem>
            </CustomSelect>
          </LabelWrapper>
        </Box>
      </Box>
      <Typography my={2} variant={'subtitle1'}>
        Label
      </Typography>
      <Box>
        <LabelWrapper label={'Include label'}>
          <Grid container justifyContent={'center'} width={'100px'}>
            <CustomSwitch checked={inputState.label} name={'label'} onChange={switchChange} />
          </Grid>
        </LabelWrapper>
      </Box>
      {inputState.label && (
        <>
          <Typography my={2} variant={'subtitle1'}>
            User #ID
          </Typography>
          <Box sx={{ ...styles.flexStartBox }}>
            <Box mr={10}>
              <CustomRadioButton
                checked={inputState.userIdType === 'username'}
                onButtonClick={onRadioClick}
                value={'username'}
                textLabel={'Username'}
              />
            </Box>
            <BoxComponent disabled={inputState.userIdType != 'username'}>
              <LabelWrapper label={'Enter username'}>
                <CustomInput
                  errorText={'Please enter text'}
                  required
                  disabled={inputState.userIdType != 'username'}
                  value={inputState.username}
                  name={'username'}
                  onChange={onInputChange}
                />
              </LabelWrapper>
            </BoxComponent>
          </Box>
          <Box mt={2} sx={{ ...styles.flexStartBox }}>
            <Box mr={14}>
              <CustomRadioButton
                checked={inputState.userIdType === 'logo'}
                onButtonClick={onRadioClick}
                value={'logo'}
                textLabel={'Logo'}
              />
            </Box>
            <BoxComponent disabled={inputState.userIdType !== 'logo'}>
              <LabelWrapper label={'Insert logo file'}>
                <ButtonFileDialog
                  typePage={'PLOT'}
                  imageFileOnly
                  disabled={!projectId || inputState.userIdType !== 'logo'}
                  projectId={projectId}
                  required
                  file={inputState.logoFile}
                  setFile={(file) => onFileChange(file, 'logoFile')}
                />
              </LabelWrapper>
            </BoxComponent>
          </Box>

          <Box mx={5} my={2} sx={styles.line} />

          <Box sx={{ ...styles.flexStartBox, ...styles.itemSpacing, ...styles.wrap }}>
            <Box>
              <LabelWrapper label={'Figure number'}>
                <CustomInput
                  required
                  placeholder={'-'}
                  errorText={'Please enter text'}
                  value={inputState.figureNumber}
                  name={'figureNumber'}
                  onChange={onInputChange}
                />
              </LabelWrapper>
            </Box>
            <Box>
              <LabelWrapper label={'Figure title'}>
                <CustomInput
                  required
                  errorText={'Please enter text'}
                  placeholder={'-'}
                  value={inputState.figureTitle}
                  name={'figureTitle'}
                  onChange={onInputChange}
                />
              </LabelWrapper>
            </Box>
            <Box>
              <LabelWrapper label={'Date'}>
                <CustomInput
                  errorText={'Please enter text'}
                  required
                  placeholder={'-'}
                  value={inputState.date}
                  name={'date'}
                  onChange={onInputChange}
                />
              </LabelWrapper>
            </Box>
            <Box>
              <LabelWrapper label={'Client'}>
                <CustomInput
                  minWidth={'380px'}
                  required
                  errorText={'Please enter text'}
                  placeholder={'-'}
                  value={inputState.client}
                  name={'client'}
                  onChange={onInputChange}
                />
              </LabelWrapper>
            </Box>
            <Box>
              <LabelWrapper label={'Project title'}>
                <CustomInput
                  required
                  minWidth={'380px'}
                  errorText={'Please enter text'}
                  placeholder={'-'}
                  value={inputState.projectTitle}
                  name={'projectTitle'}
                  onChange={onInputChange}
                />
              </LabelWrapper>
            </Box>
            <Box>
              <LabelWrapper label={'Figure description'}>
                <CustomTextArea
                  rows={7}
                  minWidth={'380px'}
                  required
                  errorText={'Please enter text'}
                  placeholder={'-'}
                  value={inputState.figureDescription}
                  name={'figureDescription'}
                  onChange={onInputChange}
                />
              </LabelWrapper>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

export default memo(ShorelinePlotLabelInputsGroup);
