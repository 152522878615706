import React, { FC } from 'react';
import { Container, Grid, Typography } from '@mui/material';

const styles = {
  root: {
    position: 'sticky',
    height: '90px',
    zIndex: 1,
    top: '0px',
    bgcolor: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
} as const;

type PageHeaderProps = {
  title: string;
  subTitle?: string;
  projectName?: string;
};

const PageHeader: FC<PageHeaderProps> = ({ title, subTitle, projectName }) => {
  return (
    <Container maxWidth={'xl'} sx={{ ...styles.root }}>
      <Grid container alignItems={'center'}>
        <Typography mr={2} variant={'h1'}>
          {title}
        </Typography>
        <Typography color={'info.main'} variant={'h4'}>
          {subTitle}
        </Typography>
      </Grid>
      {projectName ? (
        <Grid container direction={'column'} alignItems={'flex-end'}>
          <Typography>Project Name:</Typography>
          <Typography color={'primary'} variant={'subtitle1'}>
            {projectName}
          </Typography>
        </Grid>
      ) : undefined}
    </Container>
  );
};

export default PageHeader;
