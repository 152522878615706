import React, { FC } from 'react';
import { Box, BoxProps, Typography } from '@mui/material';
import CustomButton from './CustomButton';
import BoxComponent from './BoxComponent';

const styles = {
  root: {
    background: 'white',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    padding: '2px',
    borderRadius: '4px',
    boxShadow: '0 0 0 1px #B6B6B6',
    cursor: 'pointer',
  },
  boxContainer: {
    '& input': {
      boxShadow: 'none',
      color: 'gray',
      cursor: 'pointer',
      caretColor: 'transparent',
      outline: 'none',
      border: 0,
    },
  },
};

type CustomFileSelectInputProps = {
  buttonTitle?: string;
  selectedTitle?: string;
  disabled?: boolean;
  required?: boolean;
  textWidth?: string;
};

const CustomFileSelectInput: FC<CustomFileSelectInputProps & BoxProps> = ({
  required,
  textWidth = '190px',
  disabled = false,
  selectedTitle,
  buttonTitle = 'Choose file',
  ...props
}) => {
  return (
    <BoxComponent sx={styles.root} disabled={disabled} width={'300px'} {...props}>
      <CustomButton variant={'contained'} minWidth={'100px'} borderRadius={2} color={'primary'}>
        <Typography variant={'subtitle2'}>{buttonTitle} </Typography>
      </CustomButton>
      <Box ml={1} sx={styles.boxContainer} width={textWidth}>
        <input
          disabled={disabled}
          required={required}
          placeholder={'No file chosen'}
          value={selectedTitle ? selectedTitle : ''}
        />
      </Box>
    </BoxComponent>
  );
};

export default CustomFileSelectInput;
