import { customApi } from '../Api';
import { openErrorToast, openSuccessToast, setGlobalLoading } from '../../slices/appSlice';
import { FileType } from '../../../models/inputTypes/FileType';

const extendedApi = customApi.injectEndpoints({
  endpoints: (builder) => ({
    deleteProjectFile: builder.mutation<unknown, { projectId: string; fileName: string }>({
      query: ({ projectId, fileName }) => ({
        url: `/api/projects/${projectId}/files/${fileName}`,
        method: 'DELETE',
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          dispatch(setGlobalLoading(true));
          await queryFulfilled;
          dispatch(openSuccessToast('File deleted successfully!'));
          dispatch(setGlobalLoading(false));
        } catch (err) {
          dispatch(openErrorToast('Error deleting file. Please try again!'));
          dispatch(setGlobalLoading(false));
        }
      },
      invalidatesTags: ['projectFiles'],
    }),

    deleteAllProjectFiles: builder.mutation<unknown, number>({
      query: (projectId) => ({
        url: `/api/projects/${projectId}/files/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['projectFiles'],
    }),

    chainProjectFile: builder.mutation<any, { projectId: string; fileName: string; file: FileType }>({
      query: ({ projectId, fileName, file }) => ({
        url: `/api/projects/${projectId}/files/${fileName}/reconcile`,
        method: 'POST',
        body: file,
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          dispatch(setGlobalLoading(true));
          await queryFulfilled;
        } catch (err) {}
        dispatch(setGlobalLoading(false));
      },
      invalidatesTags: ['projectFiles'],
    }),

    addProjectFile: builder.mutation<unknown, { id?: string; name: string; file: any }>({
      query: ({ id, file, name }) => ({
        url: id ? `/api/projects/${id}/files/${name}` : `/api/files/${name}`,
        method: 'PUT',
        body: file,
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          dispatch(setGlobalLoading(true));
          await queryFulfilled;
          dispatch(openSuccessToast('File created successfully!'));
        } catch (err: any) {
          const errorMsg = err?.error?.data?.name || 'Error creating file. Please try again!';
          dispatch(openErrorToast(errorMsg));
        }
        dispatch(setGlobalLoading(false));
      },
      invalidatesTags: ['projectFiles'],
    }),

    getProjectFileCustom: builder.mutation<any, { projectId: string; fileName: string }>({
      query: ({ projectId, fileName }) => ({
        url: `/api/projects/${projectId}/files/${fileName}`,
        method: 'GET',
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          dispatch(setGlobalLoading(true));
          await queryFulfilled;
        } catch (err) {
          dispatch(openErrorToast('Error fetching file. Please try again!'));
        }
        dispatch(setGlobalLoading(false));
      },
    }),

    moveProjectFile: builder.mutation<
      any,
      { projectId: string; moveProjectId: string; fileName: string; operation: 'move' | 'copy' }
    >({
      query: ({ moveProjectId, operation, projectId, fileName }) => ({
        url: `/api/projects/${projectId}/files/${fileName}/mvcp`,
        method: 'POST',
        body: {
          operation,
          name: fileName,
          project: moveProjectId || null,
        },
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          dispatch(setGlobalLoading(true));
          await queryFulfilled;
          dispatch(openSuccessToast(`File ${id.operation === 'move' ? 'moved' : 'copied'} successfully!`));
        } catch (err) {
          dispatch(openErrorToast(`Error ${id.operation === 'move' ? 'moving' : 'coping'} file. Please try again!`));
        }
        dispatch(setGlobalLoading(false));
      },
      invalidatesTags: ['projectFiles'],
    }),
  }),

  overrideExisting: false,
});

export const {
  useMoveProjectFileMutation,
  useDeleteProjectFileMutation,
  useDeleteAllProjectFilesMutation,
  useAddProjectFileMutation,
  useChainProjectFileMutation,
  useGetProjectFileCustomMutation,
} = extendedApi;
