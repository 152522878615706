import React, { FC, memo } from 'react';
import { Box } from '@mui/material';
import LabelWrapper from '../../common/LabelWrapper';
import { HydInitialConditions } from '../../../models/inputTypes/HydFields';
import { FileType } from '../../../models/inputTypes/FileType';
import ButtonFileDialog from '../../common/ButtonFileDialog';

const styles = {
  spacingContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    '& >:nth-child(n)': {
      my: 1,
      mr: { xs: 3, lg: 4, xl: 4 },
    },
  },
} as const;

type InputsProps = {
  projectId: string;
  inputState: HydInitialConditions;
  setInputState: (value: HydInitialConditions) => void;
};

const HydHotStartInputsGroup: FC<InputsProps> = ({ projectId, inputState, setInputState }) => {
  const onFileChange = (file: FileType | undefined, fieldName: string) => {
    setInputState({ ...inputState, [fieldName]: file });
  };
  return (
    <>
      <Box sx={{ ...styles.spacingContainer }}>
        <Box>
          <LabelWrapper label={'Insert U-Hotstart file'}>
            <ButtonFileDialog
              typePage={'HYD'}
              dataFilesOnly
              required
              projectId={projectId}
              disabled={!projectId || inputState.coldStart}
              file={inputState.uFile}
              setFile={(file) => onFileChange(file, 'uFile')}
            />
          </LabelWrapper>
        </Box>
        <Box>
          <LabelWrapper label={'Insert V-Hotstart file'}>
            <ButtonFileDialog
              typePage={'HYD'}
              dataFilesOnly
              required
              projectId={projectId}
              disabled={!projectId || inputState.coldStart}
              file={inputState.vFile}
              setFile={(file) => onFileChange(file, 'vFile')}
            />
          </LabelWrapper>
        </Box>
        <Box>
          <LabelWrapper label={'Insert Z-Hotstart file'}>
            <ButtonFileDialog
              typePage={'HYD'}
              dataFilesOnly
              required
              projectId={projectId}
              disabled={!projectId || inputState.coldStart}
              file={inputState.zFile}
              setFile={(file) => onFileChange(file, 'zFile')}
            />
          </LabelWrapper>
        </Box>
      </Box>
    </>
  );
};

export default memo(HydHotStartInputsGroup);
