import React, { FC, memo } from 'react';
import { Box } from '@mui/material';
import LabelWrapper from '../../common/LabelWrapper';
import CustomInput from '../../custom/CustomInput';
import CustomSwitch from '../../custom/CustomSwitch';
import BoxComponent from '../../custom/BoxComponent';
import { HmsSimulationTime } from '../../../models/inputTypes/HmsFields';
import { hmsSimulationTimeInitialState } from '../../../utils/initialStates/hmsInputState';

const styles = {
  spacingContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    '& >:nth-child(n)': {
      my: 1,
      mr: { xs: 3, lg: 4, xl: 4 },
    },
  },
  spacingJustifyContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
} as const;

type InputsProps = {
  inputState: HmsSimulationTime;
  setInputState: (value: HmsSimulationTime) => void;
};

const HmsSimulationInputsGroup: FC<InputsProps> = ({ inputState, setInputState }) => {
  const onNumberChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setInputState({ ...inputState, [event.target.name]: event.target.value });
  };

  const onSwitchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputState({ ...inputState, convergence: e.target.checked, criterion: hmsSimulationTimeInitialState.criterion });
  };
  return (
    <>
      <Box sx={{ ...styles.spacingContainer }}>
        <Box>
          <LabelWrapper label={'Simulation time(s)'}>
            <CustomInput
              min={0.000000001}
              step={'any'}
              required
              errorText={'Enter positive number'}
              type={'number'}
              onChange={onNumberChange}
              value={inputState.simulationTime}
              name={'simulationTime'}
            />
          </LabelWrapper>
        </Box>
        <Box>
          <LabelWrapper label={'Time step(s)'}>
            <CustomInput
              min={0.000000001}
              step={'any'}
              required
              errorText={'Enter positive number'}
              type={'number'}
              onChange={onNumberChange}
              value={inputState.timeStep}
              name={'timeStep'}
            />
          </LabelWrapper>
        </Box>

        <Box>
          <LabelWrapper mb={2} label={'Steady state convergence'}>
            <Box sx={{ ...styles.spacingJustifyContainer }}>
              <CustomSwitch checked={inputState.convergence} onChange={onSwitchChange} />
            </Box>
          </LabelWrapper>
        </Box>

        <BoxComponent disabled={!inputState.convergence}>
          <LabelWrapper label={'Convergence criterion (%)'}>
            <CustomInput
              disabled={!inputState.convergence}
              min={0.000000001}
              step={'any'}
              required
              errorText={'Enter positive number'}
              type={'number'}
              onChange={onNumberChange}
              value={inputState.criterion}
              name={'criterion'}
            />
          </LabelWrapper>
        </BoxComponent>
      </Box>
    </>
  );
};

export default memo(HmsSimulationInputsGroup);
