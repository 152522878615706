import {
  SrfNumericalDomain,
  SrfReflection,
  SrfSponge,
  SrfSubmission,
  SrfSwitches,
} from '../../models/inputTypes/SrfFields';
import { srfType } from '../../models/jobModels/srfType';
import { dynamicInputFilePreparation } from '../functions/dynamicInputFilePreparation';

export const srfPreparation = (
  numericalState: SrfNumericalDomain,
  spongeState: SrfSponge,
  switchBoxes: SrfSwitches,
  reflectionState: SrfReflection,
  submissionState: SrfSubmission,
): srfType => {
  const inputFiles = dynamicInputFilePreparation({ I_Depths: numericalState.bathymetryFile });

  const outputFiles = srfFileOutputPreparation({
    spongeFileName: switchBoxes.sponge ? spongeState.nameSpongeLayer : undefined,
    eddyFileName: switchBoxes.ref ? reflectionState.reflectionMap : undefined,
    projectId: numericalState.project,
  });
  const preparedValues: srfType = {
    simulation: 'srf',
    cores: +submissionState.vCpus,
    project: numericalState.project.toString(),
    name: submissionState.simulationFile,
    parameters: {
      input_files: inputFiles,
      output_files: outputFiles,

      im: +numericalState.cellX,
      jm: +numericalState.cellY,
      dx: +numericalState.cellSizeX,
      dy: +numericalState.cellSizeY,
      sw_x: +numericalState.swX,
      sw_y: +numericalState.swY,
      ne_x: +numericalState.neX,
      ne_y: +numericalState.neY,

      sponge_map: switchBoxes.sponge ? 1 : 0,
      sponge_back_val: +spongeState.backgroundValue,
      sponge_back_layers: +spongeState.layerNumber,
      sponge_back_add: +spongeState.addValues,
      sponge_base: +spongeState.baseValue,
      sponge_power: +spongeState.powerValue,

      layer_map: switchBoxes.ref ? 1 : 0,
      eddy: +reflectionState.backgroundValue,
      codes_length: +reflectionState.landCodeValues,
      codes_list: reflectionState.codes_list.map((item) => ({
        code: +item.code,
        base: +item.base,
        layers: +item.layers,
      })),
    },
  };
  return preparedValues;
};

export const srfFileOutputPreparation = (files: {
  spongeFileName?: string;
  eddyFileName?: string;
  projectId: string;
}) => {
  const arrayOfFiles = [];
  if (files?.spongeFileName) {
    arrayOfFiles.push({
      alias: 'O_Sponge.csv',
      project: files.projectId.toString(),
      name: `${files.spongeFileName}.csv`,
    });
  }
  if (files?.eddyFileName) {
    arrayOfFiles.push({
      alias: 'O_Eddy.csv',
      project: files.projectId.toString(),
      name: `${files.eddyFileName}.csv`,
    });
  }

  return arrayOfFiles;
};
