import React, { FC, memo, useMemo, useState } from 'react';
import CustomSelect from '../../custom/CustomSelect';
import CustomMenuItem from '../../custom/CustomMenuItem';
import { Box, Grid, SelectChangeEvent, Typography } from '@mui/material';
import LabelWrapper from '../../common/LabelWrapper';
import CustomMoveImageButton from '../../custom/CustomMoveImageButton';
import ButtonFileDialog from '../../common/ButtonFileDialog';
import { FileType } from '../../../models/inputTypes/FileType';
import { useGetProjectsQuery } from '../../../redux/RTK/queries/projectQuery';
import { useMoveProjectFileMutation } from '../../../redux/RTK/mutations/projectFileMutations';

const styles = {
  itemLayout: { display: 'flex', columnGap: '32px', flexWrap: 'wrap', alignItems: 'flex-end' },
} as const;

type UploadFilesProjectProps = unknown;

const UploadFilesProject: FC<UploadFilesProjectProps> = () => {
  const { data, isFetching } = useGetProjectsQuery({});
  const [moveFiles] = useMoveProjectFileMutation();
  const [file, setFile] = useState<FileType | undefined>(undefined);
  const [inputState, setInputState] = useState<{ project: string; moveProject: string }>({
    project: '',
    moveProject: '',
  });

  const projectItems = useMemo(() => {
    if (data?.length) {
      return data.map((item, i) => (
        <CustomMenuItem key={i} value={item.id}>
          <Typography variant={'subtitle2'}>{item.name} </Typography>
        </CustomMenuItem>
      ));
    }
  }, [data]);

  const onInputChange = (event: SelectChangeEvent<unknown>) => {
    setInputState({ ...inputState, [event.target.name]: event.target.value });
  };

  const onMoveButtonClick = (event: any) => {
    event.preventDefault();
    const { id } = event.nativeEvent.submitter;
    if (file?.fileName && id)
      moveFiles({
        projectId: inputState.project,
        moveProjectId: inputState.moveProject.toString(),
        operation: id,
        fileName: file.fileName,
      });
  };

  return (
    <Grid container>
      <form onSubmit={onMoveButtonClick}>
        <Box sx={{ ...styles.itemLayout, rowGap: 1 }}>
          <Box>
            <LabelWrapper label={'Select project'}>
              <CustomSelect
                required
                minWidth="180px"
                title={'Select Project'}
                displayEmpty
                name={'project'}
                value={inputState.project}
                isLoading={isFetching}
                onChange={onInputChange}
              >
                <CustomMenuItem value={''}>
                  <Typography variant={'subtitle2'}>select project</Typography>
                </CustomMenuItem>
                {projectItems}
              </CustomSelect>
            </LabelWrapper>
          </Box>
          <Box>
            <LabelWrapper label={'Insert file here'}>
              <ButtonFileDialog
                typePage={'ALL'}
                required
                multipleFiles
                disabled={!inputState.project}
                projectId={inputState.project}
                file={file}
                setFile={setFile}
              />
            </LabelWrapper>
          </Box>
          <Typography mb={1} variant="subtitle2" color={'info.light'}>
            or
          </Typography>
          <Box sx={styles.itemLayout}>
            <Box>
              <LabelWrapper label={'Move files to project'}>
                <CustomSelect
                  required
                  minWidth="180px"
                  title={'Select Project'}
                  displayEmpty
                  name={'moveProject'}
                  value={inputState.moveProject}
                  isLoading={isFetching}
                  onChange={onInputChange}
                >
                  <CustomMenuItem value={''}>
                    <Typography variant={'subtitle2'}>select project</Typography>
                  </CustomMenuItem>
                  {projectItems}
                </CustomSelect>
              </LabelWrapper>
            </Box>
            <CustomMoveImageButton
              id="copy"
              type={'submit'}
              disabled={!file || !inputState.moveProject}
              variant={'contained'}
              color={'primary'}
            >
              <Typography variant={'subtitle2'} color={'white'}>
                Copy
              </Typography>
            </CustomMoveImageButton>
            <CustomMoveImageButton
              id="move"
              type={'submit'}
              disabled={!file || !inputState.moveProject}
              variant={'contained'}
              color={'secondary'}
            >
              <Typography variant={'subtitle2'} color={'white'}>
                Move
              </Typography>
            </CustomMoveImageButton>
          </Box>
        </Box>
      </form>
    </Grid>
  );
};
export default memo(UploadFilesProject);
