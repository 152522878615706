import React, { FC, memo, useMemo } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import CustomInput from '../../custom/CustomInput';
import { FileType } from '../../../models/inputTypes/FileType';
import ButtonFileDialog from '../../common/ButtonFileDialog';
import { EviNumerical } from '../../../models/inputTypes/EviFields';
import { SedimentInitialObject } from '../../../utils/initialStates/eviInitialStates';
import { ReactComponent as Add } from '../../../assets/images/add.svg';
import LabelWrapper from '../../common/LabelWrapper';

type EviSedimentInputsProps = {
  projectId: string;
  inputState: EviNumerical;
  setInputState: React.Dispatch<React.SetStateAction<EviNumerical>>;
};

const EviSedimentInputs: FC<EviSedimentInputsProps> = ({ inputState, setInputState }) => {
  const isAddDisabled = useMemo(() => {
    return inputState.sedimentList.length > 14;
  }, [inputState.sedimentList]);

  const onDynamicFileChange = (file: FileType | undefined, fieldName: string, index: number) => {
    const newCodeArray = inputState.sedimentList.slice();
    newCodeArray[index] = { ...newCodeArray[index], [fieldName]: file };
    setInputState({ ...inputState, sedimentList: newCodeArray });
  };
  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputState({
      ...inputState,
      [event.target.name]: event.target.value,
    });
  };
  const onDynamicInputChange = (index: number, e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const newCodeArray = inputState.sedimentList.slice();
    let sum = 1;
    newCodeArray[index] = { ...newCodeArray[index], [e.target.name]: e.target.value };
    newCodeArray?.forEach((item) => (sum = sum - +item.weight));

    setInputState({ ...inputState, sedimentList: newCodeArray, calmPeriod: +sum.toFixed(4) });
  };

  const handleAddFile = () => {
    const tempQtList = inputState.sedimentList.slice();
    tempQtList.push(SedimentInitialObject);
    setInputState({ ...inputState, sedimentList: tempQtList });
  };

  const eviInputs = () => {
    return inputState.sedimentList.map((item, i) => (
      <>
        <Box display={'flex'} alignItems={'center'}>
          <Typography variant={'subtitle1'} mr={2}>
            {i + 1}
          </Typography>
          <ButtonFileDialog
            typePage={'EVI'}
            required
            dataFilesOnly
            disabled={!inputState.project}
            projectId={inputState.project}
            file={item.qtx}
            setFile={(file) => onDynamicFileChange(file, 'qtx', i)}
          />
        </Box>
        <ButtonFileDialog
          typePage={'EVI'}
          required
          dataFilesOnly
          disabled={!inputState.project}
          projectId={inputState.project}
          file={item.qty}
          setFile={(file) => onDynamicFileChange(file, 'qty', i)}
        />
        <Box>
          <CustomInput
            maxWidth={'90px'}
            errorText={item.weight < 0 ? 'Enter positive number' : 'Enter number (0-1)'}
            type={'number'}
            min={0}
            max={1}
            step={'any'}
            value={item.weight}
            name={'weight'}
            onChange={(e) => onDynamicInputChange(i, e)}
          />
        </Box>
      </>
    ));
  };

  return (
    <>
      <Box display={'grid'} mt={4} rowGap={2} columnGap={3} gridTemplateColumns={'1fr 1fr 1fr'} maxWidth={'700px'}>
        <Typography ml={3} variant={'subtitle2'}>
          Qtx
        </Typography>
        <Typography ml={3} variant={'subtitle2'}>
          Qty
        </Typography>
        <Typography variant={'subtitle2'}>Weights</Typography>
        {eviInputs()}
      </Box>
      <Box ml={3} my={2} display={'flex'} maxWidth={'756px'} alignItems={'center'} justifyContent={'space-between'}>
        <Box>
          <Button
            disabled={isAddDisabled}
            variant={'contained'}
            color={'success'}
            startIcon={<Add />}
            onClick={handleAddFile}
          >
            <Typography color={'info.contrastText'} variant={'subtitle2'}>
              Add file
            </Typography>
          </Button>
        </Box>
        <Box>
          <LabelWrapper label={'Calm period'}>
            <CustomInput
              maxWidth={'101px'}
              errorText={'Total weights should be 0≤ total ≤1'}
              type={'number'}
              step={'any'}
              min={0}
              max={1}
              value={inputState.calmPeriod}
              name={'calmPeriod'}
              onChange={onInputChange}
            />
          </LabelWrapper>
        </Box>
      </Box>
      <Stack direction={'row'} gap={6}>
        <Box>
          <LabelWrapper label={'Enter Magnitude file name'}>
            <CustomInput
              type={'text'}
              required
              placeholder={'Enter name'}
              value={inputState.magnitude}
              name={'magnitude'}
              onChange={onInputChange}
            />
          </LabelWrapper>
        </Box>
        <Box>
          <LabelWrapper label={'Enter Direction file name'}>
            <CustomInput
              type={'text'}
              required
              placeholder={'Enter name'}
              value={inputState.direction}
              name={'direction'}
              onChange={onInputChange}
            />
          </LabelWrapper>
        </Box>
      </Stack>
    </>
  );
};

export default memo(EviSedimentInputs);
