import { Box, BoxProps, styled } from '@mui/material';
import React from 'react';

type CustomBoxProps = {
  disabled?: boolean;
};

const BoxComponent = styled((props: BoxProps & CustomBoxProps) => <Box {...props} />)`
  opacity: ${(props: CustomBoxProps) => (props.disabled ? 0.5 : 1)};
  pointer-events: ${(props: CustomBoxProps) => (props.disabled ? 'none' : 'initial')};
`;

export default BoxComponent;
