import {
  HmsBoundaryConditions,
  HmsEnergyDissipation,
  HmsModelFeatures,
  HmsOutput,
  HmsPlotArea,
  HmsPlotLine,
  HmsSimulationTime,
} from '../../models/inputTypes/HmsFields';
export const generatorInitialState = {
  wg_fpx: 1,
  wg_fpy: 1,
  wg_lpx: 1,
  wg_lpy: 1,
};
export const hmsBoundaryConditionsInitialState: HmsBoundaryConditions = {
  seaState: '2',
  hm: 2,
  ts: 10,
  direction: 0,
  generatorNumber: 1,

  numberOfPeriods: 5,
  spectrum: '1',
  depthWavemaker: 10.0,
  minPeriod: 3.5,
  maxPeriod: 16.0,
  peakFactorY: 3.3,
  mwdDeviation: 15,
  directionalSpreading: 3,
  numberOfDirections: 1,
  wave_generators_line_coordinates: [generatorInitialState],
};

export const hmsSimulationTimeInitialState: HmsSimulationTime = {
  simulationTime: 600,
  timeStep: 0.05,
  convergence: false,
  criterion: 0.01,
};

export const hmsModelFeaturesInitialState: HmsModelFeatures = {
  dispersion: '1',
  startingTime: 0.05,
  relation: '2',
  celerityFile: undefined,
};

export const hmsOutputInitialState: HmsOutput = {
  plotAreas: 0,
  plotLines: 0,
  plotAreaValues: [],
  plotLineValues: [],
};

export const hmsPlotLinesInitialState: HmsPlotLine = {
  fp_x: 1,
  fp_y: 1,
  lp_x: 1,
  lp_y: 1,
  h: 0,
  z: 0,

  name_h: 'H_Line',
  name_z: 'Z_Line',

  start_step: 1,
  end_step: 16000,
  interval: 1000,
};

export const hmsPlotAreasInitialState: HmsPlotArea = {
  ne_x: 1,
  ne_y: 1,
  sw_x: 1,
  sw_y: 1,
  h: 0,
  z: 0,
  rad: 0,
  init_depths: 0,

  name_h: 'H_Area',
  name_z: 'Z_Area',
  name_rad_xx: 'Sxx_Area',
  name_rad_xy: 'Sxy_Area',
  name_rad_yy: 'Syy_Area',
  name_init_depths: 'In_Depths_Area',

  start_step: 1,
  end_step: 16000,
  interval: 1000,
};

export const hmsPlotAreasAliases = {
  name_h: 'O_H_Area',
  name_z: 'O_Z_Area',
  name_rad_xx: 'O_Sxx_Area',
  name_rad_xy: 'O_Sxy_Area',
  name_rad_yy: 'O_Syy_Area',
  name_init_depths: 'O_In_Depths_Area',
};

export const hmsPlotLinesAliases = {
  name_h: 'O_H_Line',
  name_z: 'O_Z_Line',
};

export const hmsEnergyDissipationInitialState: HmsEnergyDissipation = {
  waveBreaking: true,
  a: 1,
  y1: 1,
  y2: 0.8,
  bottomFriction: true,
  bottomFrictionType: 'constant',
  coefficient: '0.02',
  frictionFile: undefined,

  spongeLayer: false,
  spongeLayerType: 'perimetric',
  west: 0,
  north: 0,
  east: 0,
  south: 0,
  spongeFile: undefined,

  eddyLayer: false,
  eddyType: 'constant',
  eddyCoefficient: '0.1',
  eddyFile: undefined,
};
