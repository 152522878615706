import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from '../store';
const URL = process.env['REACT_APP_API_URL'];

const baseQuery = fetchBaseQuery({
  baseUrl: URL,
  prepareHeaders: (headers, { getState }) => {
    const accessToken = (getState() as RootState).authentication.data.accessToken;
    if (accessToken) {
      headers.set('Authorization', `Token ${accessToken}`);
    }
    return headers;
  },
});

export const customApi = createApi({
  baseQuery,
  reducerPath: 'maris',
  tagTypes: ['projects', 'jobs', 'projectFiles', 'user'],
  endpoints: () => ({}),
});
