import React, { FC } from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import { Box, styled, Typography } from '@mui/material';

const barLinkStyles = {
  link: {
    display: 'flex',
    alignItems: 'center',
  },
  numberSpace: {
    marginRight: 1.5,
  },
};

type BarLinkProps = {
  image?: string;
  number?: string;
  title: string;
};

const StyledNavLink = styled((props: NavLinkProps) => <NavLink {...props} />)`
  text-decoration: none;
  color: black;
  display: flex;
  padding: 8px 16px;
  border-bottom: 1px solid #f2f2f2;
  &.active {
    background: #e0eff5;
  }
`;

const BarLink: FC<BarLinkProps & NavLinkProps> = ({ image, number, title, ...props }) => {
  return (
    <StyledNavLink {...props}>
      <Box sx={barLinkStyles.link}>
        {image ? (
          <img style={barLinkStyles.numberSpace} src={image} alt={'alt'} />
        ) : (
          <Typography sx={barLinkStyles.numberSpace} variant={'subtitle1'}>
            {number}
          </Typography>
        )}
        <Typography variant={'subtitle2'}>{title}</Typography>
      </Box>
    </StyledNavLink>
  );
};

export default BarLink;
