import React, { FC, memo } from 'react';
import {
  Box,
  Typography,
  Theme,
  Checkbox,
  FormControlLabel,
  RadioGroup,
  InputAdornment,
  IconButton,
} from '@mui/material';
import CustomButton from '../../custom/CustomButton';
import CustomInput from '../../custom/CustomInput';
import BoxInputContainer from '../../custom/BoxInputContainer';
import BoxComponent from '../../custom/BoxComponent';
import CustomRadio from '../../custom/CustomRadio';
import CustomSelect from '../../custom/CustomSelect';
import { ReactComponent as SelectArrow } from '../../../assets/images/ChevronDown.svg';
import CustomMenuItem from '../../custom/CustomMenuItem';
import maskValues from '../../../dummy/maskValues';
import { StyledInput } from '../../common/LoginInput';
import { ReactComponent as Close } from '../../../assets/images/close.svg';

const styles = {
  inputGrid: { display: 'grid', alignItems: 'center', gridTemplateColumns: '80px 2fr', gap: '12px 24px' },
  boxInputs: {
    padding: (theme: Theme) => theme.spacing(1.5, 4, 1.5, 2),
    background: '#DFE1E6',
  },
  buttonPosition: {
    display: 'flex',
    margin: 'auto',
    marginTop: 3,
    background: (theme: Theme) => theme.palette.primary.dark,
  },
  valuesPosition: {
    display: 'flex',
    marginTop: 1,
    marginBottom: 3,
    gridTemplateColumns: '1.5fr 1fr',
    gap: '12px 12px',
    alignItems: 'center',
  },
} as const;

type HeatMapInputProps = {
  onSave: () => void;
  saveName: string | undefined;
  setSaveName: (name: string) => void;
  saveLoading: boolean | undefined;
  autoSave: boolean;
  setAutoSave: (autoSave: boolean) => void;
  replaceValue: string;
  setReplaceValueType: (type: 'table' | 'file') => void;
  value: number | undefined;
  setValue: (value: any | undefined) => void;
  checkValue: number | undefined;
  setCheckValue: (checkValue: any | undefined) => void;
  timeframe: number;
  operation: any | undefined;
  setOperation: (operation: any | undefined) => void;
};
const FileSaveCard: FC<HeatMapInputProps> = ({
  timeframe,
  operation,
  setOperation,
  value,
  setValue,
  checkValue,
  setCheckValue,
  replaceValue,
  setReplaceValueType,
  onSave,
  saveName,
  setSaveName,
  saveLoading,
  autoSave,
  setAutoSave,
  ...rest
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSaveName(event.target.value?.trim());
  };

  const handleAutoSaveChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAutoSave(event.target.checked);
  };

  return (
    <BoxInputContainer sx={{ ...styles.boxInputs }} borderRadius={'6px'}>
      <Typography variant={'subtitle1'}>Replace Values</Typography>
      <BoxComponent width={'140px'} style={{ paddingTop: 10 }} disabled={!replaceValue || timeframe > 1}>
        <RadioGroup
          style={{ display: 'block' }}
          aria-label="eddyType"
          name="eddyType"
          value={replaceValue}
          onChange={(e) => setReplaceValueType(e.target.value as any)}
        >
          <FormControlLabel
            style={{ marginLeft: 0 }}
            labelPlacement={'start'}
            value="file"
            control={<CustomRadio />}
            label={
              <Typography variant={'subtitle2'} color={'info.main'}>
                File
              </Typography>
            }
          />
          <FormControlLabel
            labelPlacement={'start'}
            value="table"
            control={<CustomRadio />}
            label={
              <Typography align={'right'} variant={'subtitle2'} color={'info.main'}>
                Table
              </Typography>
            }
          />
        </RadioGroup>
      </BoxComponent>

      <Box sx={styles.valuesPosition}>
        <CustomSelect
          disabled={timeframe > 1}
          value={operation ? operation : ''}
          onChange={(event) => setOperation(event.target.value ? event.target.value : undefined)}
          fullWidth
          IconComponent={SelectArrow}
        >
          <CustomMenuItem value={''}>
            <Typography variant={'subtitle2'}>none</Typography>
          </CustomMenuItem>
          {Object.entries(maskValues).map(([key, value]) => (
            <CustomMenuItem key={key} value={value}>
              {value}
            </CustomMenuItem>
          ))}
        </CustomSelect>
        <StyledInput
          disabled={!operation || timeframe > 1}
          value={checkValue}
          onChange={(event) => setCheckValue(event.target.value ? Number(event.target.value) : undefined)}
          fullWidth
          type={'number'}
          endAdornment={
            checkValue !== undefined ? (
              <InputAdornment position="end">
                <IconButton size="small" onClick={() => setCheckValue('')}>
                  <Close />
                </IconButton>
              </InputAdornment>
            ) : undefined
          }
        />
        <Typography textAlign={'center'} variant={'subtitle1'} align={'center'}>
          With
        </Typography>
        <StyledInput
          disabled={!operation || timeframe > 1}
          value={value}
          onChange={(event) => setValue(event.target.value ? Number(event.target.value) : undefined)}
          fullWidth
          type={'number'}
          endAdornment={
            value !== undefined ? (
              <InputAdornment position="end">
                <IconButton size="small" onClick={() => setValue('')}>
                  <Close />
                </IconButton>
              </InputAdornment>
            ) : undefined
          }
        />
      </Box>
      <Box sx={{ ...styles.inputGrid, gridTemplateColumns: '60px 1fr' }}>
        <Typography variant={'subtitle1'} align={'right'}>
          Save as:
        </Typography>
        <CustomInput value={saveName} onChange={handleChange} fullWidth />
      </Box>

      <CustomButton
        loading={saveLoading}
        disabled={!saveName || saveLoading}
        minWidth={'120px'}
        sx={styles.buttonPosition}
        size={'small'}
        variant={'contained'}
        color={'primary'}
        onClick={onSave}
      >
        Save
      </CustomButton>
      <FormControlLabel
        control={<Checkbox color={'primary'} checked={autoSave} onChange={handleAutoSaveChange} name="autoSave" />}
        label={<Typography variant={'body2'}>Auto Save</Typography>}
      />
    </BoxInputContainer>
  );
};

export default memo(FileSaveCard);
