import React, { FC, useMemo } from 'react';
import {
  Box,
  Button,
  Grid,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableProps,
  TableRow,
  Typography,
} from '@mui/material';
import { ReactComponent as Arrow } from '../../assets/images/arrow.svg';
import { downloadAxiosFile } from '../../utils/functions/downloadFile';
import { ReactComponent as DownloadIcon } from '../../assets/images/download.svg';
import { ReactComponent as DeleteIcon } from '../../assets/images/delete.svg';

const TableComponent = styled((props: TableProps) => <Table {...props} />)(({ theme }) => ({
  thead: {
    th: {
      // height: '40px',
      padding: theme.spacing(1, 2),
      border: 'none',
      background: '#F2F2F2',
    },
    '& .arrow': {
      marginLeft: '8px',
      transition: '0.2s',
    },
    '& .rotate': {
      transform: 'rotate(180deg)',
    },
    '& .disabledArrow': {
      opacity: 0.2,
    },
  },
  tbody: {
    tr: {
      '&.Mui-selected': {
        transition: '0.1s',
        boxShadow: `0px 0px 4px 2px  ${theme.palette.warning.main} inset, 0px -0.5px 0px 1px  ${theme.palette.warning.main} inset`,
        backgroundColor: theme.palette.info.contrastText,
        '&:hover': {
          backgroundColor: theme.palette.info.contrastText,
        },
      },
    },
    td: {
      // height: '40px',
      padding: theme.spacing(1, 2),
      borderBottom: '1px solid #F2F2F2',
    },
    background: theme.palette.info.contrastText,
  },
}));

const styles = {
  tableContainer: {
    boxShadow: '0 0 0 1px #F2F2F2',
    borderRadius: 0.5,
    minWidth: 500,
  },
};
const disabledButton = {
  '&:disabled': {
    opacity: '0.2',
  },
};

type headerType = { title: string; align: any; sort?: boolean }[];

type CustomTableProps = {
  filePerProject?: string;
  selectProjectIds?: string[];
  setDeleteFile?: () => void;
  maxHeight?: any;
  header?: headerType;
  onMoreClick?: () => void;
  sortBy?: { title: string; ascending: boolean };
  onSortClick?: (val: { title: string; ascending: boolean }) => void;
  disable?: boolean;
};

const initialHeader: headerType = [
  { title: 'Name', align: 'flex-start' },
  { title: 'Date Created', align: 'center' },
  { title: '', align: 'flex-start' },
];

const CustomTable: FC<CustomTableProps> = ({
  filePerProject,
  selectProjectIds,
  setDeleteFile,
  onSortClick,
  sortBy,
  maxHeight,
  onMoreClick,
  disable,
  header = initialHeader,
  children,
}) => {
  const headerCells = useMemo(
    () =>
      header?.map((item, i) => {
        let sortArrow = undefined;
        let onHeadClick = undefined;
        if (item?.sort && sortBy) {
          onHeadClick = onSortClick
            ? () => onSortClick({ title: item.title, ascending: !sortBy.ascending })
            : undefined;
          sortArrow = <Arrow className={'arrow disabledArrow'} />;

          if (sortBy.title === item.title && item.title !== '') {
            sortArrow = sortBy.ascending ? <Arrow className={'arrow'} /> : <Arrow className={'arrow rotate'} />;
          }
        }
        return (
          <TableCell sx={item?.sort ? { cursor: 'pointer' } : undefined} onClick={onHeadClick} key={i}>
            <Grid container justifyContent={item.align} flexWrap={'nowrap'} alignItems={'center'}>
              <Typography variant={'subtitle1'}>{item.title}</Typography>
              {sortArrow}
            </Grid>
          </TableCell>
        );
      }),
    [header, sortBy],
  );

  const buttonActions = () => {
    return (
      <TableCell>
        <Box style={{ display: 'flex', justifyContent: 'center' }}>
          <IconButton
            sx={{ ...disabledButton }}
            disabled={selectProjectIds?.length === 0}
            onClick={() =>
              filePerProject && selectProjectIds ? downloadAxiosFile(filePerProject, selectProjectIds) : null
            }
          >
            <DownloadIcon />
          </IconButton>
          <IconButton
            sx={{ ...disabledButton }}
            disabled={selectProjectIds?.length === 0}
            onClick={() => (setDeleteFile ? setDeleteFile() : null)}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      </TableCell>
    );
  };

  const viewMoreRow = useMemo(
    () =>
      onMoreClick ? (
        <TableRow>
          <TableCell colSpan={100}>
            <Button color={'inherit'} fullWidth disabled={disable} onClick={onMoreClick}>
              <Typography color={'info.light'} align={'center'} variant={'subtitle2'}>
                View more...
              </Typography>
            </Button>
          </TableCell>
        </TableRow>
      ) : undefined,
    [onMoreClick],
  );

  return (
    <TableContainer sx={{ ...styles.tableContainer, maxHeight: maxHeight ? maxHeight : 'auto' }}>
      <TableComponent stickyHeader>
        <TableHead>
          <TableRow>
            {headerCells}
            {setDeleteFile ? buttonActions() : null}
          </TableRow>
        </TableHead>
        <TableBody>
          {children}
          {viewMoreRow}
        </TableBody>
      </TableComponent>
    </TableContainer>
  );
};

export default CustomTable;
