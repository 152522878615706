import { BedLevelObject, EviNumerical } from '../../models/inputTypes/EviFields';
import { EviType } from '../../models/jobModels/eviType';
import {
  bedLevelInitialObject,
  eviNumericalInitialState,
  SedimentInitialObject,
} from '../initialStates/eviInitialStates';
import { InputFile } from '../../models/jobModels/ModelsFileTypes';
import { PmsSubmission } from '../../models/inputTypes/PmsFields';
import { srfSubmissionInitialState } from '../initialStates/SrfInputState';

export type EviSimulationPreparationResponse = {
  numeric: EviNumerical;
  submission: PmsSubmission;
};

const prepareInputBedLevel = (inputFiles: Partial<InputFile>[], weights: number[]): BedLevelObject[] => {
  return inputFiles.map((file, i) => {
    const currentFile = {
      currentFile: { project: +(file.project ?? ''), name: file.fileid },
      fileName: file.fileid ?? '',
    };
    return { file: currentFile, weight: weights[i] };
  });
};

const prepareInputSediment = (inputFiles: Partial<InputFile>[], weights: number[]): any[] => {
  const middleIndex = Math.ceil(inputFiles.length / 2);
  const copy = inputFiles.slice();
  const firstHalf = copy.splice(0, middleIndex);
  const secondHalf = copy.splice(-middleIndex);

  return weights.map((weight, i) => {
    const qtxFile = firstHalf[i];
    const qtyFile = secondHalf[i];
    const xFile = {
      currentFile: { project: +(qtxFile.project ?? ''), name: qtxFile.fileid },
      fileName: qtxFile.fileid,
    };
    const yFile = {
      currentFile: { project: +(qtyFile.project ?? ''), name: qtyFile.fileid },
      fileName: qtyFile.fileid,
    };
    return { qtx: xFile, qty: yFile, weight: weight };
  });
};

export const eviSimulationPreparation = (simulationFile: EviType): EviSimulationPreparationResponse | undefined => {
  // const outputFile = simulationFile.parameters.output_file[0];
  const isBedLevel = simulationFile.parameters.filetype == 1;
  const preparedBedLevel = isBedLevel
    ? prepareInputBedLevel(simulationFile.parameters.input_file, simulationFile.parameters.weights)
    : undefined;

  const preparedSediment = !isBedLevel
    ? prepareInputSediment(simulationFile.parameters.input_file, simulationFile.parameters.weights)
    : undefined;

  const magnitude = !isBedLevel ? simulationFile.parameters.output_file?.[0]?.name?.split('.csv')[0] : '';
  const direction = !isBedLevel ? simulationFile.parameters.output_file?.[1]?.name?.split('.csv')[0] : '';
  const outputFileName = isBedLevel ? simulationFile.parameters.output_file?.[0]?.name?.split('.csv')[0] : '';

  const numeric: EviNumerical = {
    project: simulationFile.project ? simulationFile.project.toString() : eviNumericalInitialState.project,
    simulationSetup: undefined,
    fileType: simulationFile.parameters.filetype,
    frame: simulationFile.parameters.frame,
    calmPeriod: simulationFile.parameters.calm_period,
    bedLevelList: preparedBedLevel?.length ? preparedBedLevel : [bedLevelInitialObject, bedLevelInitialObject],
    sedimentList: preparedSediment?.length ? preparedSediment : [SedimentInitialObject, SedimentInitialObject],

    magnitude: magnitude ?? eviNumericalInitialState.magnitude,
    direction: direction ?? eviNumericalInitialState.direction,
    outputFileName: outputFileName ?? eviNumericalInitialState.outputFileName,
  };

  const submission: PmsSubmission = {
    vCpus: simulationFile.cores ? simulationFile.cores : srfSubmissionInitialState.vCpus,
    simulationFile: srfSubmissionInitialState.simulationFile,
  };
  return { numeric, submission };
};
