import { styled, Tabs } from '@mui/material';

const CustomTabs = styled(Tabs)(() => ({
  '& .MuiTabs-indicator': {
    display: 'none',
  },
  '& .MuiTabs-scrollButtons': {
    height: 32,
  },
}));

export default CustomTabs;
