import React, { FC } from 'react';
import { Box } from '@mui/material';
import Sidebar from './Sidebar';
import Loading from '../../pages/Loading';
import FileViewDialog from '../dialogs/FileViewDialog';

const width = 270;

const layoutStyles = {
  root: {
    display: 'flex',
    height: '100vh',
  },
  pageContainer: {
    width: `calc(100vw - ${width}px)`,
    overflow: 'auto',
  },
};

const Layout: FC = ({ children }) => {
  return (
    <Box sx={{ ...layoutStyles.root }}>
      <Sidebar width={width} />
      <Box sx={{ ...layoutStyles.pageContainer }}>
        <FileViewDialog>{children}</FileViewDialog>
      </Box>
      <Loading />
    </Box>
  );
};

export default Layout;
