import React, { FC, memo } from 'react';
import { Box, Typography } from '@mui/material';
import CustomMenuItem from '../../custom/CustomMenuItem';
import CustomSelect from '../../custom/CustomSelect';
import { HydOutput, HydPlotArea, HydPlotLine } from '../../../models/inputTypes/HydFields';
import { hydPlotAreaArrayPreparation } from '../../../utils/functions/HydPlotAreaArrayPreparation';
import { hydPlotLineArrayPreparation } from '../../../utils/functions/HydPlotLineArrayPreparation';

const styles = {
  spacingContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    '& >:nth-child(n)': {
      my: 1,
      mr: { xs: 3, lg: 4, xl: 4 },
    },
  },
} as const;

type HydOutputInputsGroupProps = {
  inputState: HydOutput;
  setInputState: (value: HydOutput) => void;
  areaInitial: HydPlotArea;
  lineInitial: HydPlotLine;
};

const HydOutputInputsGroup: FC<HydOutputInputsGroupProps> = ({
  lineInitial,
  areaInitial,
  inputState,
  setInputState,
}) => {
  const prepareSelectItems = () => {
    return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item, i) => (
      <CustomMenuItem key={i} value={item}>
        <Typography variant={'subtitle2'}>{item}</Typography>
      </CustomMenuItem>
    ));
  };
  const onSelectAreaChange = (e: any) => {
    const newValue = parseInt(e.target.value);
    const tempArray = hydPlotAreaArrayPreparation(inputState.plotAreaValues.slice(), newValue, areaInitial);
    setInputState({ ...inputState, plotAreaValues: tempArray, plotAreas: newValue });
  };

  const onSelectLineChange = (e: any) => {
    const newValue = parseInt(e.target.value);
    const tempArray = hydPlotLineArrayPreparation(inputState.plotLineValues.slice(), newValue, lineInitial);
    setInputState({ ...inputState, plotLineValues: tempArray, plotLines: newValue });
  };

  return (
    <>
      <Box sx={{ ...styles.spacingContainer }}>
        <Box>
          <Typography mb={1} variant={'subtitle2'}>
            Select plot areas
          </Typography>
          <CustomSelect
            required
            value={inputState.plotAreas}
            onChange={onSelectAreaChange}
            name={'plotAreas'}
            minWidth={'140px'}
            defaultValue={''}
            displayEmpty
          >
            <CustomMenuItem value={0}>
              <Typography variant={'subtitle2'}>0</Typography>
            </CustomMenuItem>
            {prepareSelectItems()}
          </CustomSelect>
        </Box>
        <Box>
          <Typography mb={1} variant={'subtitle2'}>
            Select plot lines
          </Typography>
          <CustomSelect
            required
            minWidth={'140px'}
            value={inputState.plotLines}
            onChange={onSelectLineChange}
            name={'plotLines'}
            defaultValue={0}
            displayEmpty
          >
            <CustomMenuItem value={0}>
              <Typography variant={'subtitle2'}>0</Typography>
            </CustomMenuItem>
            {prepareSelectItems()}
          </CustomSelect>
        </Box>
      </Box>
    </>
  );
};

export default memo(HydOutputInputsGroup);
