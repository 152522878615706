import React, { FC, FormEvent, useEffect, useMemo, useState } from 'react';
import PageContainer from '../../components/structural/PageContainer';
import { Grid, Typography } from '@mui/material';
import BoxInputContainer from '../../components/custom/BoxInputContainer';
import NumericalDomainInputsGroup from '../../components/groups/btg/NumericalDomainInputsGroup';
import InterpolationInputsGroup from '../../components/groups/btg/InterpolationInputsGroup';
import SubmissionInputsGroup from '../../components/groups/btg/SubmissionInputsGroup';
import CustomPrompt from '../../components/custom/CustomPrompt';
import { BtgBathymetric, BtgInterpolation, BtgNumericDomain, BtgSubmission } from '../../models/inputTypes/BtgFields';
import {
  btgBathymetricInitialState,
  btgInterpolationInitialState,
  btgNumericDomainInitialState,
  btgSubmissionInitialState,
} from '../../utils/initialStates/btgInputState';
import { useCreateJobsMutation } from '../../redux/RTK/mutations/jobsMutations';
import { useGetProjectsQuery } from '../../redux/RTK/queries/projectQuery';
import { btgPreparation } from '../../utils/modelPreparation/btgPreparation';
import { BtgSimulationPreparationResponse } from '../../utils/simulationFileToState/btgSimulationPreparation';
import { useAddProjectFileMutation } from '../../redux/RTK/mutations/projectFileMutations';
import { openWarningToast } from '../../redux/slices/appSlice';
import { FileType } from '../../models/inputTypes/FileType';
import { useAppDispatch } from '../../redux/store';
import BtgBathymetricInputsGroup from '../../components/groups/btg/BtgBathymetricInputsGroup';
import useSimulationPreparation from '../../utils/hooks/jsonParamHook';
import { ModelEnum } from '../../models/types/ModelEnum';

const styles = {
  miniInputsSpacing: {
    '&> :first-child': {
      paddingRight: {
        sm: 0,
        md: 1,
        lg: 1.5,
      },
    },
    '&> :last-child': {
      paddingLeft: {
        sm: 0,
        md: 1,
        lg: 2.5,
      },
    },
  },
};

const MarisBtgPage: FC = () => {
  const [createJob] = useCreateJobsMutation();
  const { data } = useGetProjectsQuery({});
  const [uploadFile] = useAddProjectFileMutation();
  const dispatch = useAppDispatch();

  const [bathymetricState, setBathymetricState] = useState<BtgBathymetric>(btgBathymetricInitialState);
  const [numericalState, setNumericalState] = useState<BtgNumericDomain>(btgNumericDomainInitialState);
  const [interpolationState, setInterpolationState] = useState<BtgInterpolation>(btgInterpolationInitialState);
  const [submissionState, setSubmissionState] = useState<BtgSubmission>(btgSubmissionInitialState);
  const [stateHasChanged, setStateHasChanged] = useState(false);
  const { preparedData, error, currentFile } = useSimulationPreparation(ModelEnum.BTG);

  useEffect(() => {
    if (preparedData && !error && currentFile) {
      onSimulationFileChange(preparedData, currentFile);
    }
  }, [preparedData, error, currentFile]);

  useEffect(() => {
    if (!stateHasChanged) {
      const hasBathChanged = bathymetricState != btgBathymetricInitialState;
      const hasNumericalChanged = numericalState != btgNumericDomainInitialState;
      const hasInterChanged = interpolationState != btgInterpolationInitialState;
      const hasSubChanged = submissionState != btgSubmissionInitialState;
      setStateHasChanged(hasBathChanged || hasNumericalChanged || hasInterChanged || hasSubChanged);
    }
  }, [bathymetricState, numericalState, interpolationState, submissionState]);

  const selectedProjectName = useMemo(() => {
    if (data?.length) {
      const found = data.find((item) => item.id.toString() === bathymetricState.project.toString());
      return found?.name;
    }
  }, [bathymetricState.project]);

  const onSubmissionClick = (event: FormEvent<any>) => {
    event.preventDefault();
    const preparedValue = btgPreparation(bathymetricState, numericalState, interpolationState, submissionState);
    if (preparedValue) createJob(preparedValue);
  };

  const onSimulationSave = () => {
    const preparedValue = btgPreparation(bathymetricState, numericalState, interpolationState, submissionState);
    if (preparedValue && bathymetricState.project && submissionState?.simulationFile) {
      uploadFile({
        id: bathymetricState.project,
        name: `${submissionState?.simulationFile}.json`,
        file: preparedValue,
      });
    } else {
      dispatch(
        openWarningToast(
          !bathymetricState.project ? 'You have to select project first!' : 'simulation file name required!',
        ),
      );
    }
  };

  const onSimulationFileChange = (value: BtgSimulationPreparationResponse, file?: FileType) => {
    setBathymetricState({ ...value.bathymetric, simulationSetup: file });
    setNumericalState(value.numeric);
    setInterpolationState(value.interpolation);
    setSubmissionState({ ...value.submission, simulationFile: file?.fileName?.split('.json')[0] ?? '' });
  };

  return (
    <PageContainer title={'NUMERICAL TOOLS'} subTitle={'Maris BTG'} projectName={selectedProjectName}>
      <form onSubmit={onSubmissionClick}>
        <CustomPrompt
          when={stateHasChanged}
          message={(params) =>
            params.pathname == '/designTools/maris-btg' ? true : 'Changes that you made may not be saved.'
          }
        />
        <Typography variant={'h4'}>Bathymetric Data</Typography>
        <BoxInputContainer borderRadius={'4px'} mt={2}>
          <BtgBathymetricInputsGroup
            simulationChangeFile={onSimulationFileChange}
            inputState={bathymetricState}
            setInputState={setBathymetricState}
          />
        </BoxInputContainer>
        <Grid container sx={{ ...styles.miniInputsSpacing }}>
          <Grid item sm={12} md={6}>
            <Typography mt={2} variant={'h4'}>
              Numerical Domain
            </Typography>
            <BoxInputContainer borderRadius={'4px'} mt={2}>
              <NumericalDomainInputsGroup inputState={numericalState} setInputState={setNumericalState} />
            </BoxInputContainer>
          </Grid>
          <Grid item sm={12} md={6}>
            <Typography mt={2} variant={'h4'}>
              Interpolation
            </Typography>
            <BoxInputContainer borderRadius={'4px'} mt={2}>
              <InterpolationInputsGroup inputState={interpolationState} setInputState={setInterpolationState} />
            </BoxInputContainer>
          </Grid>
        </Grid>
        <Typography mt={2} variant={'h4'}>
          Submission
        </Typography>
        <BoxInputContainer borderRadius={'4px'} mt={2}>
          <SubmissionInputsGroup
            onSaveClick={onSimulationSave}
            inputState={submissionState}
            setInputState={setSubmissionState}
          />
        </BoxInputContainer>
      </form>
    </PageContainer>
  );
};

export default MarisBtgPage;
