import { get } from '../../axios';
import { openErrorToast, setGlobalLoading } from '../../redux/slices/appSlice';
import store from '../../redux/store';

export const downloadAxiosFile = async (projectId: string, fileNames: string[]) => {
  const storeDispatch = store.dispatch;
  storeDispatch(setGlobalLoading(true));
  try {
    const promises = fileNames.map(
      (file) =>
        new Promise((resolve) => {
          get(`/api/projects/${projectId}/files/${file}`, {
            responseType: 'blob',
          }).then((res) => {
            resolve(res);
          });
        }),
    );

    const results = await Promise.all(promises);

    results.map((response: any, index) => {
      const hiddenElement = document.createElement('a');
      hiddenElement.href = URL.createObjectURL(response.data);
      hiddenElement.target = '_blank';
      hiddenElement.download = fileNames[index];
      hiddenElement.click();
      hiddenElement.remove();
    });
  } catch (e) {
    storeDispatch(openErrorToast('Error downloading File!'));
  }
  storeDispatch(setGlobalLoading(false));
};
