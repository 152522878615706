import { OutputFile } from '../../../models/jobModels/ModelsFileTypes';
import { SdtPlotArea, SdtPlotLine } from '../../../models/inputTypes/SdtFields';
import {
  sdtAreaAliases,
  sdtLineAliases,
  sdtPlotAreaInitialState,
  sdtPlotLineInitialState,
} from '../../initialStates/sdtInputState';

export const sdtOutputAreaFilePreparation = (plotAreas: SdtPlotArea[], projectId: string) => {
  const areaOutput: OutputFile[] = [];
  plotAreas.forEach((area, i) => {
    if (area.bl) {
      areaOutput.push({
        alias: sdtAreaAliases.name_bl + `_${i + 1}.csv`,
        project: projectId.toString(),
        name: (area.name_bl || sdtPlotAreaInitialState.name_bl + `_${i + 1}`) + '.csv',
      });
    }
    if (area.dhdt) {
      areaOutput.push({
        alias: sdtAreaAliases.name_dhdt + `_${i + 1}.csv`,
        project: projectId.toString(),
        name: (area.name_dhdt || sdtPlotAreaInitialState.name_dhdt + `_${i + 1}`) + '.csv',
      });
    }
    if (area.qtx) {
      areaOutput.push({
        alias: sdtAreaAliases.name_qtx + `_${i + 1}.csv`,
        project: projectId.toString(),
        name: (area.name_qtx || sdtPlotAreaInitialState.name_qtx + `_${i + 1}`) + '.csv',
      });
    }

    if (area.qty) {
      areaOutput.push({
        alias: sdtAreaAliases.name_qty + `_${i + 1}.csv`,
        project: projectId.toString(),
        name: (area.name_qty || sdtPlotAreaInitialState.name_qty + `_${i + 1}`) + '.csv',
      });
    }
    if (area.qtmag) {
      areaOutput.push({
        alias: sdtAreaAliases.name_qtmag + `_${i + 1}.csv`,
        project: projectId.toString(),
        name: (area.name_qtmag || sdtPlotAreaInitialState.name_qtmag + `_${i + 1}`) + '.csv',
      });
    }
    if (area.qtdir) {
      areaOutput.push({
        alias: sdtAreaAliases.name_qtdir + `_${i + 1}.csv`,
        project: projectId.toString(),
        name: (area.name_qtdir || sdtPlotAreaInitialState.name_qtdir + `_${i + 1}`) + '.csv',
      });
    }
    if (area.init_depths) {
      areaOutput.push({
        alias: sdtAreaAliases.name_init_depths + `_${i + 1}.csv`,
        project: projectId.toString(),
        name: (area.name_init_depths || sdtPlotAreaInitialState.name_init_depths + `_${i + 1}`) + '.csv',
      });
    }
  });
  return areaOutput;
};

export const sdtOutputLineFilePreparation = (plotLines: SdtPlotLine[], projectId: string) => {
  const lineOutput: OutputFile[] = [];
  plotLines.forEach((line, i) => {
    if (line.bl) {
      lineOutput.push({
        alias: sdtLineAliases.name_bl + `_${i + 1}.csv`,
        project: projectId.toString(),
        name: (line.name_bl || sdtPlotLineInitialState.name_bl + `_${i + 1}`) + '.csv',
      });
    }
    if (line.dhdt) {
      lineOutput.push({
        alias: sdtLineAliases.name_dhdt + `_${i + 1}.csv`,
        project: projectId.toString(),
        name: (line.name_dhdt || sdtPlotLineInitialState.name_dhdt + `_${i + 1}`) + '.csv',
      });
    }
  });
  return lineOutput;
};
