import React, { FC, memo, useMemo, useState } from 'react';
import { Box, FormControlLabel, RadioGroup, SelectChangeEvent, Typography } from '@mui/material';
import CustomMenuItem from '../../custom/CustomMenuItem';
import CustomSelect from '../../custom/CustomSelect';
import CustomInputWithLabel from '../../common/CustomInputWithLabel';
import CustomRadio from '../../custom/CustomRadio';
import LabelWrapper from '../../common/LabelWrapper';
import { FileType } from '../../../models/inputTypes/FileType';
import { useGetProjectsQuery } from '../../../redux/RTK/queries/projectQuery';
import { ShorelineSimulationPreparationResponse } from '../../../utils/simulationFileToState/shorelineSimulationPreparation';
import { ShorelineNumericalDomain } from '../../../models/inputTypes/ShorelineFields';
import { useHistory, useParams } from 'react-router';
import BathymetricFileContainer from '../../common/fileComponents/BathymetricFileContainer';
import ButtonFileDialog from '../../common/ButtonFileDialog';
import SimulationFileContainer, {
  SimulationContainerSetter,
} from '../../common/fileComponents/SimulationFileContainer';
import { ModelEnum } from '../../../models/types/ModelEnum';
import CustomInput from '../../custom/CustomInput';
import BoxComponent from '../../custom/BoxComponent';
import { shorelineNumericalDomainInitialState } from '../../../utils/initialStates/shorelineInputState';

const styles = {
  spaceBetweenContainer: {
    flexWrap: 'wrap',
    display: 'flex',
    maxWidth: '730px',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& >:nth-child(n)': {
      my: 1,
    },
  },
  spacingContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  flexStartBox: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  childSpacing: {
    '& >:nth-child(n)': {
      my: 1,
      mr: { xs: 2, md: 3, xl: 4 },
    },
  },
  coordinates: {
    '& >:nth-child(2)': {
      ml: 1,
    },
  },
} as const;

type ShorelineNumericalDomainInputsProps = {
  inputState: ShorelineNumericalDomain;
  setInputState: (value: ShorelineNumericalDomain) => void;
  simulationChangeFile?: (value: ShorelineSimulationPreparationResponse, file?: FileType) => void;
};

const ShorelineNumericalDomainInputsGroup: FC<ShorelineNumericalDomainInputsProps> = ({
  simulationChangeFile,
  inputState,
  setInputState,
}) => {
  const { name, fileId } = useParams<{ id?: string; name?: string; fileId?: string }>();
  const history = useHistory();
  const [valueX, setValueX] = useState(1);
  const [valueY, setValueY] = useState(1);
  const { data, isFetching } = useGetProjectsQuery({});

  const projectItems = useMemo(() => {
    if (data?.length) {
      return data.map((item, i) => (
        <CustomMenuItem key={i} value={item.id}>
          <Typography variant={'subtitle2'}>{item.name} </Typography>
        </CustomMenuItem>
      ));
    }
  }, [data]);

  const onInputChange = (
    event: SelectChangeEvent<unknown> | React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    if (event.target.name == 'boundaryTypeStart' || event.target.name == 'boundaryTypeEnd') {
      setInputState({ ...inputState, [event.target.name]: event.target.value });
      return;
    }
    setInputState({ ...inputState, [event.target.name]: event.target.value, simulationSetup: undefined });
    if (name && fileId) {
      history.push(`/numerical-models/shorelineS`);
    }
  };

  const onNumberChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setInputState({ ...inputState, [event.target.name]: event.target.value });
  };

  const onSimulationFileChange = ({ loadedSimulation, file }: SimulationContainerSetter) => {
    if (simulationChangeFile && loadedSimulation && file) {
      return simulationChangeFile(loadedSimulation, file);
    }
    setInputState({ ...inputState, simulationSetup: undefined });
  };

  const onFileChange = (file: FileType | undefined, fieldName: string) => {
    setInputState({ ...inputState, [fieldName]: file });
  };

  const onChangeRadio = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value as any;
    setInputState({
      ...inputState,
      orientationType: newValue,
      phif: shorelineNumericalDomainInitialState.phif,
      orientationFile: undefined,
    });
  };

  const warning = useMemo(
    () => (inputState.phif == undefined ? 'Leave empty for auto-calculation' : undefined),
    [inputState.phif],
  );

  return (
    <>
      <Box sx={{ ...styles.spaceBetweenContainer }}>
        <Box>
          <LabelWrapper label={'Select project'}>
            <CustomSelect
              required
              value={inputState.project}
              onChange={onInputChange}
              name={'project'}
              minWidth={'180px'}
              isLoading={isFetching}
              defaultValue={''}
              displayEmpty
            >
              <CustomMenuItem value={''}>
                <Typography variant={'subtitle2'}>select project</Typography>
              </CustomMenuItem>
              {projectItems}
            </CustomSelect>
          </LabelWrapper>
        </Box>
        <Box>
          <LabelWrapper label={'Insert simulation setup'}>
            <SimulationFileContainer
              typePage={'SHORELINES'}
              modelType={ModelEnum.SHORELINES}
              redirectLocation={'/numerical-models/shorelineS'}
              project={inputState.project}
              file={inputState.simulationSetup}
              setSimulationInputs={onSimulationFileChange}
            />
          </LabelWrapper>
        </Box>
      </Box>
      <Box mt={2} sx={{ ...styles.spaceBetweenContainer }}>
        <Box></Box>
        <Box>
          <LabelWrapper label={'Insert coastline file'}>
            <ButtonFileDialog
              typePage={'SHORELINES'}
              txtFilesOnly
              required
              projectId={inputState.project}
              disabled={!inputState.project}
              file={inputState.coastlineFile}
              setFile={(file) => onFileChange(file, 'coastlineFile')}
            />
          </LabelWrapper>
        </Box>
      </Box>
      <Typography my={1} variant={'subtitle1'}>
        Geometry & Discretization
      </Typography>
      <BoxComponent sx={{ ...styles.flexStartBox, ...styles.childSpacing }}>
        <Box>
          <LabelWrapper label={'Cell size (m)'}>
            <CustomInput
              required
              min={0}
              step={'any'}
              errorText={'Enter positive number'}
              type={'number'}
              value={inputState.cellS}
              onChange={onNumberChange}
              name={'cellS'}
            />
          </LabelWrapper>
        </Box>
        <Box>
          <LabelWrapper label={'Profile height (m)'}>
            <CustomInput
              min={0}
              required
              step={'any'}
              errorText={'Enter positive number'}
              type={'number'}
              onChange={onNumberChange}
              value={inputState.d}
              name={'d'}
            />
          </LabelWrapper>
        </Box>
        <Box>
          <LabelWrapper label={'Offshore refraction depth (m)'}>
            <CustomInput
              min={0}
              required
              step={'any'}
              errorText={'Enter positive number'}
              type={'number'}
              onChange={onNumberChange}
              value={inputState.ddeep}
              name={'ddeep'}
            />
          </LabelWrapper>
        </Box>
        <Box>
          <LabelWrapper label={'Nearshore refraction depth (m)'}>
            <CustomInput
              min={0}
              required
              step={'any'}
              errorText={'Enter positive number'}
              type={'number'}
              onChange={onNumberChange}
              value={inputState.dnearshore}
              name={'dnearshore'}
            />
          </LabelWrapper>
        </Box>
        <Box>
          <LabelWrapper label={'Land fill width (m)'}>
            <CustomInput
              min={0}
              required
              step={'any'}
              errorText={'Enter positive number'}
              type={'number'}
              onChange={onNumberChange}
              value={inputState.ld}
              name={'ld'}
            />
          </LabelWrapper>
        </Box>
      </BoxComponent>
      <Typography mt={3} variant={'subtitle1'}>
        Shore-Normal orientation
      </Typography>
      <Box sx={{ ...styles.spaceBetweenContainer }}>
        <BoxComponent sx={{ ...styles.flexStartBox, ...styles.childSpacing }}>
          <Box>
            <RadioGroup
              value={inputState.orientationType}
              onChange={onChangeRadio}
              aria-label="orientationType"
              name="orientationType"
            >
              <FormControlLabel
                labelPlacement={'end'}
                value="constant"
                control={<CustomRadio />}
                label={<Typography variant={'subtitle2'}>Constant</Typography>}
              />
              <FormControlLabel
                labelPlacement={'end'}
                value="varying"
                control={<CustomRadio />}
                label={<Typography variant={'subtitle2'}>Varying</Typography>}
              />
            </RadioGroup>
          </Box>
          <BoxComponent disabled={inputState.orientationType !== 'constant'}>
            <LabelWrapper label={'Angle \u03C6 (\u00B0)'}>
              <CustomInput
                min={0}
                step={'any'}
                errorText={'Enter positive number'}
                type={'number'}
                warningText={warning}
                onChange={onNumberChange}
                value={inputState.phif}
                name={'phif'}
              />
            </LabelWrapper>
          </BoxComponent>
        </BoxComponent>
        <BoxComponent disabled={inputState.orientationType !== 'varying'} my={1}>
          <LabelWrapper label={'Insert shoreface orientation file'}>
            <ButtonFileDialog
              typePage={'SHORELINES'}
              txtFilesOnly
              required
              projectId={inputState.project}
              disabled={!inputState.project || inputState.orientationType !== 'varying'}
              file={inputState.orientationFile}
              setFile={(file) => onFileChange(file, 'orientationFile')}
            />
          </LabelWrapper>
        </BoxComponent>
      </Box>
      <Typography my={1} variant={'subtitle1'}>
        Coastline Boundary Condition
      </Typography>
      <Box sx={{ ...styles.flexStartBox, ...styles.childSpacing }}>
        <Box>
          <LabelWrapper sx={{ textTransform: 'capitalize' }} label={'Start'}>
            <CustomSelect
              required
              value={inputState.boundaryTypeStart}
              onChange={onInputChange}
              name={'boundaryTypeStart'}
              minWidth={'140px'}
              defaultValue={'Closed'}
              displayEmpty
            >
              <CustomMenuItem value={'Closed'}>
                <Typography variant={'subtitle2'}>Closed</Typography>
              </CustomMenuItem>
              <CustomMenuItem value={'Fixed'}>
                <Typography variant={'subtitle2'}>Fixed</Typography>
              </CustomMenuItem>
              <CustomMenuItem value={'Fixed Orientation'}>
                <Typography variant={'subtitle2'}>Fixed Orientation</Typography>
              </CustomMenuItem>
              <CustomMenuItem value={'Periodic'}>
                <Typography variant={'subtitle2'}>Periodic</Typography>
              </CustomMenuItem>
            </CustomSelect>
          </LabelWrapper>
        </Box>
        <Box>
          <LabelWrapper sx={{ textTransform: 'capitalize' }} label={'End'}>
            <CustomSelect
              required
              value={inputState.boundaryTypeEnd}
              onChange={onInputChange}
              name={'boundaryTypeEnd'}
              minWidth={'140px'}
              defaultValue={'Closed'}
              displayEmpty
            >
              <CustomMenuItem value={'Closed'}>
                <Typography variant={'subtitle2'}>Closed</Typography>
              </CustomMenuItem>
              <CustomMenuItem value={'Fixed'}>
                <Typography variant={'subtitle2'}>Fixed</Typography>
              </CustomMenuItem>
              <CustomMenuItem value={'Fixed Orientation'}>
                <Typography variant={'subtitle2'}>Fixed Orientation</Typography>
              </CustomMenuItem>
              <CustomMenuItem value={'Periodic'}>
                <Typography variant={'subtitle2'}>Periodic</Typography>
              </CustomMenuItem>
            </CustomSelect>
          </LabelWrapper>
        </Box>
      </Box>
    </>
  );
};

export default memo(ShorelineNumericalDomainInputsGroup);
