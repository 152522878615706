import { InputFile } from '../../models/jobModels/ModelsFileTypes';
import { FileType } from '../../models/inputTypes/FileType';
import { PlotObject, WaveGenObject } from '../../models/inputTypes/ShorelineFields';

interface DynamicFileObject {
  [key: string]: FileType | undefined;
}

export const dynamicInputFilePreparation = (fileObj: DynamicFileObject) => {
  const arrayOfFiles: InputFile[] = [];
  const keys = Object.keys(fileObj);
  keys.forEach((item) => {
    const project = fileObj[item]?.currentFile?.project;
    const name = fileObj[item]?.currentFile?.name;
    if (project && name) {
      arrayOfFiles.push({
        alias: `${item}.csv`,
        project: project.toString(),
        fileid: name,
      });
    }
  });
  return arrayOfFiles;
};

export const dynamicInputTxtFilePreparation = (
  fileObj: DynamicFileObject,
  list1: WaveGenObject[],
  list2: WaveGenObject[],
  list3: PlotObject[],
) => {
  const arrayOfFiles: InputFile[] = [];
  const keys = Object.keys(fileObj);
  keys.forEach((item) => {
    const project = fileObj[item]?.currentFile?.project;
    const name = fileObj[item]?.currentFile?.name;
    if (project && name) {
      arrayOfFiles.push({
        alias: `${item}.txt`,
        project: project.toString(),
        fileid: name,
      });
    }
  });
  list1.forEach((item, i) => {
    const project = item?.file?.currentFile?.project;
    const name = item?.file?.currentFile?.name;
    if (project && name) {
      arrayOfFiles.push({
        alias: `Timeseries_${i}.txt`,
        project: project.toString(),
        fileid: name,
      });
    }
  });
  list2.forEach((item, i) => {
    const project = item?.file?.currentFile?.project;
    const name = item?.file?.currentFile?.name;
    if (project && name) {
      arrayOfFiles.push({
        alias: `WaveClima_${i}.txt`,
        project: project.toString(),
        fileid: name,
      });
    }
  });
  list3.forEach((item, i) => {
    const project = item?.file?.currentFile?.project;
    const name = item?.file?.currentFile?.name;
    if (project && name) {
      arrayOfFiles.push({
        alias: `LDBPlot_${i}.txt`,
        project: project.toString(),
        fileid: name,
      });
    }
  });
  return arrayOfFiles;
};
