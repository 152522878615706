import React, { FC, useEffect, useMemo, useState } from 'react';
import { Typography, Box, SelectChangeEvent, IconButton } from '@mui/material';
import PageContainer from '../components/structural/PageContainer';
import CustomInput from '../components/custom/CustomInput';
import LoginInput from '../components/common/LoginInput';
import CpuComponent from '../components/common/CpuComponent';
import StorageComponent from '../components/common/StorageComponent';
import SimsComponent from '../components/common/SimsComponent';
import CustomButton from '../components/custom/CustomButton';
import CustomDatePicker from '../components/common/CustomDatePicker';
import { User } from '../models/types/User';
import moment from 'moment';
import { stringify } from 'querystring';
import LabelWrapper from '../components/common/LabelWrapper';
import { useUserQuery } from '../redux/RTK/queries/userQuery';
import { useEditUserMutation } from '../redux/RTK/mutations/editUserMutation';
import { useChangePasswordMutation } from '../redux/RTK/mutations/changePasswordMutations';
import { useStorageQuery } from '../redux/RTK/queries/storageQuery';
import CustomSelect from '../components/custom/CustomSelect';
import CustomMenuItem from '../components/custom/CustomMenuItem';
import utcFile from '../assets/utc/utc.json';
import { useUpdateTimezoneMutation } from '../redux/RTK/mutations/storageMutation';
import DownloadIcon from '@mui/icons-material/Download';
import { downloadAxiosFile } from '../utils/functions/downloadGuides';

const styles = {
  gridInputs: { display: 'grid', gap: '32px', gridTemplateColumns: '200px 200px' },
} as const;

const dummyProfile = {
  email: '',
  first_name: '',
  last_name: '',
  pk: 0,
  username: '',
};

const passwordDummy = { new_password1: '', new_password2: '', old_password: '' };

const Profile: FC = () => {
  const { data } = useUserQuery({});
  const { data: storage } = useStorageQuery({});

  const [updateTimezone, { isSuccess: timeSuccess, isLoading: timeLoading }] = useUpdateTimezoneMutation();
  const [updateUser, { isSuccess: userSuccess, isLoading }] = useEditUserMutation();
  const [changePassword, { isSuccess: passSuccess, isLoading: passLoading, error }] = useChangePasswordMutation();

  const [isEditMode, setIsEditMode] = useState(false);
  const [profile, setProfile] = useState<User>(dummyProfile);
  const [passwords, setPasswords] = useState(passwordDummy);
  const [timezone, setTimezone] = useState<string>('');
  const [sub_start_date, setStartDate] = useState<string>('');
  const [sub_end_date, setEndDate] = useState<string>('');
  const [passwordError, setPasswordError] = useState({ new_password2: '', old_password: '' });

  useEffect(() => {
    if (error) {
      const errorValue = (error as any)?.data;
      setPasswordError({
        new_password2: errorValue?.new_password2?.[0],
        old_password: errorValue?.old_password?.[0],
      });
    }
  }, [error]);

  useEffect(() => {
    if (storage?.profile) {
      setTimezone(storage.profile.timezone);
    }
  }, [storage]);

  useEffect(() => {
    if (storage?.profile) {
      setStartDate(storage.profile.sub_start_date);
    }
  }, [storage]);

  useEffect(() => {
    if (storage?.profile) {
      setEndDate(storage.profile.sub_end_date);
    }
  }, [storage]);

  useEffect(() => {
    if (data) {
      setProfile({ ...data });
    }
  }, [data]);

  useEffect(() => {
    if (!passLoading && !isLoading && !timeLoading) {
      if (passSuccess && userSuccess && timeSuccess) {
        setIsEditMode(false);
        setPasswords(passwordDummy);
      }
      if (stringify(passwords) === stringify(passwordDummy) && (userSuccess || timeSuccess)) {
        setIsEditMode(false);
      }
      if (stringify(passwords) !== stringify(passwordDummy) && (passSuccess || timeSuccess)) {
        setIsEditMode(false);
        setPasswords(passwordDummy);
      }
    }
  }, [passSuccess, userSuccess, timeSuccess]);

  const cores = useMemo(() => {
    if (storage?.profile) {
      const coresUsed = storage.profile?.cores_used ?? 0;
      const coresQuota = storage.profile?.cores_quota ?? 0;
      return { progress: (coresUsed / coresQuota) * 100, used: coresUsed, total: coresQuota };
    }
    return { progress: 0, used: 0, total: 0 };
  }, [storage]);

  const preparedStorage = useMemo(() => {
    if (storage?.profile) {
      const storage_quota = storage.profile?.storage_quota ?? 0;
      const storage_used = storage.profile?.storage_used ?? 0;
      const progress = (storage_used / storage_quota) * 100;
      const total = (storage_quota / (1024 * 1024 * 1024)).toFixed(1);
      const used = (storage_used / (1024 * 1024 * 1024)).toFixed(1);
      return { progress, total, used };
    }
    return { progress: 0, total: 0, used: 0 };
  }, [storage]);

  const sims = useMemo(() => {
    if (storage?.profile) {
      const finished_jobs = storage.profile?.finished_jobs ?? 0;
      const jobsQuota = storage.profile?.finished_jobs_quota ?? 0;
      return { progress: (finished_jobs / jobsQuota) * 100, used: finished_jobs, total: jobsQuota };
    }
    return { progress: 0, used: 0, total: 0 };
  }, [storage]);

  const handleEditClick = () => {
    if (isEditMode) {
      if (stringify(profile) !== stringify(data)) {
        updateUser(profile);
      }
      if (timezone && storage && storage.profile.timezone !== timezone) {
        updateTimezone({ username: storage.username, timezone: timezone });
      }
      if (stringify(passwords) !== stringify(passwordDummy)) {
        changePassword(passwords);
      }
      return;
    }
    setIsEditMode(true);
  };

  const handleCancelClick = () => {
    setIsEditMode(false);
    setProfile(data ?? dummyProfile);
    setTimezone(storage?.profile.timezone || '');
    setPasswords(passwordDummy);
    setPasswordError({ new_password2: '', old_password: '' });
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setPasswords({ ...passwords, [event.target.name]: event.target.value });
    setPasswordError({ new_password2: '', old_password: '' });
  };

  const handleChange = (
    event: SelectChangeEvent<unknown> | React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setProfile({ ...profile, [event.target.name]: event.target.value });
  };

  const handleDateChange = (value: unknown, name: string) => {
    setProfile({ ...profile, [name]: value });
  };

  const dataInitial = stringify(data) === stringify(profile);
  const timeEqual = timezone === storage?.profile.timezone;
  const hasPassFilled = passwords.new_password2 || passwords.new_password1 || passwords.old_password;
  const isPassEmptyNonEqual =
    passwords.new_password1 !== passwords.new_password2 ||
    !passwords.old_password ||
    !passwords.new_password1 ||
    !passwords.new_password2;
  const profileIsEmpty = !timezone || !profile.first_name || !profile.last_name || !profile.username;

  const saveDisabled = hasPassFilled
    ? isPassEmptyNonEqual || profileIsEmpty
    : (dataInitial && timeEqual) || profileIsEmpty;

  const dataTimezone = utcFile.utc;

  const timezoneItems = () => {
    return dataTimezone.map((item, i) => (
      <CustomMenuItem key={i} value={item}>
        <Typography variant={'subtitle2'}>{item}</Typography>
      </CustomMenuItem>
    ));
  };

  const onChangeTimezone = (
    event: SelectChangeEvent<any> | React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setTimezone(event.target.value);
  };

  return (
    <PageContainer subTitle={'Profile'}>
      <Box>
        <Typography my={4} variant="h1">
          Profile
        </Typography>
        <Box>
          <Typography variant={'h4'}>Personal Details</Typography>
          <Box mt={2} sx={styles.gridInputs}>
            <Box>
              <LabelWrapper label={'First name'}>
                <CustomInput
                  fullWidth
                  disabled={!isEditMode}
                  value={profile.first_name}
                  name={'first_name'}
                  onChange={handleChange}
                />
              </LabelWrapper>
            </Box>
            <Box>
              <LabelWrapper label={'Last name'}>
                <CustomInput
                  fullWidth
                  disabled={!isEditMode}
                  value={profile.last_name}
                  name={'last_name'}
                  onChange={handleChange}
                />
              </LabelWrapper>
            </Box>
          </Box>
          <Box mt={4} sx={styles.gridInputs}>
            <Box>
              <LabelWrapper label={'Username'}>
                <CustomInput
                  fullWidth
                  disabled={!isEditMode}
                  value={profile.username}
                  name={'username'}
                  onChange={handleChange}
                />
              </LabelWrapper>
            </Box>
            <Box>
              <LabelWrapper label={'Password'}>
                <LoginInput
                  fullWidth
                  error={!!passwordError.old_password && !!hasPassFilled}
                  errorText={passwordError.old_password ? passwordError.old_password : ''}
                  isPasswordType
                  disabled={!isEditMode}
                  value={passwords.old_password}
                  name={'old_password'}
                  onChange={handlePasswordChange}
                />
              </LabelWrapper>
            </Box>
          </Box>
          <Box mt={4} sx={{ ...styles.gridInputs, opacity: !isEditMode ? 0.4 : 1 }}>
            <Box>
              <LabelWrapper label={'Enter new password'}>
                <LoginInput
                  fullWidth
                  error={!!passwordError.new_password2 || passwords.new_password2 !== passwords.new_password1}
                  errorText={passwordError.new_password2 ? passwordError.new_password2 : 'Passwords dont match!'}
                  isPasswordType
                  disabled={!isEditMode}
                  value={passwords.new_password1}
                  name={'new_password1'}
                  onChange={handlePasswordChange}
                />
              </LabelWrapper>
            </Box>
            <Box>
              <LabelWrapper label={'Re-enter new password'}>
                <LoginInput
                  fullWidth
                  error={!!passwordError.new_password2 || passwords.new_password2 !== passwords.new_password1}
                  errorText={passwordError.new_password2 ? passwordError.new_password2 : 'Passwords dont match!'}
                  isPasswordType
                  disabled={!isEditMode}
                  value={passwords.new_password2}
                  name={'new_password2'}
                  onChange={handlePasswordChange}
                />
              </LabelWrapper>
            </Box>
          </Box>
          {/* <Box mt={4} maxWidth={'435px'}>
            <LabelWrapper label={'Organization'}>
              <CustomInput fullWidth disabled={true} />
            </LabelWrapper>
          </Box> */}
          <Box mt={4}>
            <LabelWrapper label={'Timezone'}>
              <CustomSelect
                required
                value={timezone}
                name={'timezone'}
                onChange={onChangeTimezone}
                minWidth={'250px'}
                disabled={!isEditMode}
                isLoading={false}
                defaultValue={''}
                displayEmpty
              >
                {timezoneItems()}
              </CustomSelect>
            </LabelWrapper>
          </Box>
        </Box>

        <Box mt={'30px'} sx={{ display: 'flex', gap: '20px', flexWrap: 'wrap' }}>
          {!isEditMode ? (
            <CustomButton variant={'contained'} color={'success'} onClick={handleEditClick}>
              <Typography variant={'subtitle2'} color={'white'}>
                Edit Profile
              </Typography>
            </CustomButton>
          ) : (
            <CustomButton
              disabled={saveDisabled}
              loading={isLoading || passLoading || timeLoading}
              variant={'contained'}
              color={'success'}
              onClick={handleEditClick}
            >
              <Typography variant={'subtitle2'} color={'white'}>
                Save Changes
              </Typography>
            </CustomButton>
          )}

          {isEditMode ? (
            <CustomButton
              disabled={isLoading || passLoading || timeLoading}
              color={'error'}
              variant={'outlined'}
              onClick={handleCancelClick}
            >
              <Typography variant={'subtitle2'}>Cancel</Typography>
            </CustomButton>
          ) : undefined}
        </Box>

        <Box mt={4}>
          <Typography variant={'h4'}>Subscription Details</Typography>
          <Box sx={{ padding: '16px 0px 16px 0px', display: 'flex', gap: '32px', flexWrap: 'wrap' }}>
            <Box>
              <Typography mb={'6px'} variant={'subtitle2'}>
                Start Date
              </Typography>
              <CustomInput
                fullWidth
                disabled={true}
                value={sub_start_date}
                name={'sub_start_date'}
                onChange={handleChange}
              />
            </Box>
            <Box>
              <Typography mb={'6px'} variant={'subtitle2'}>
                End Date
              </Typography>
              <CustomInput
                fullWidth
                disabled={true}
                value={sub_end_date}
                name={'sub_end_date'}
                onChange={handleChange}
              />
            </Box>
          </Box>
          <Box mt={'20px'} sx={{ display: 'flex', gap: '66px', flexWrap: 'wrap' }}>
            <SimsComponent progress={sims.progress} usedCount={sims.used} totalCount={sims.total} />
            <CpuComponent progress={cores.progress} usedCount={cores.used} totalCount={cores.total} />
            <StorageComponent
              progress={preparedStorage.progress}
              usedCount={+preparedStorage.used}
              totalCount={+preparedStorage.total}
            />
          </Box>
        </Box>

        <Box mt={4}>
          <Typography variant={'h4'}>Learning</Typography>
          <Typography mt={'15px'} sx={{ fontSize: 12 }}>
            Download the latest user guides version
            <IconButton onClick={() => downloadAxiosFile()}>
              <DownloadIcon fontSize="small" />
            </IconButton>
          </Typography>
        </Box>
      </Box>
    </PageContainer>
  );
};
export default Profile;
