import { FileForm } from '../../../models/inputTypes/FileType';
import { customApi } from '../Api';
import { openErrorToast, setGlobalLoading } from '../../slices/appSlice';
import { CsvBathymetricSize } from '../../../models/types/CsvBathymetricSize';

const extendedApi = customApi.injectEndpoints({
  endpoints: (builder) => ({
    getFiles: builder.query<FileForm[], { projectId?: string }>({
      query: ({ projectId }) => ({
        url: projectId ? `/api/projects/${projectId}/files/` : `/api/files/`,
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (err) {
          dispatch(openErrorToast('Error fetching data. Try again!'));
        }
      },
      providesTags: ['projectFiles'],
    }),
    bathymetricFileSize: builder.query<CsvBathymetricSize, { projectId: number; fileName: string }>({
      query: ({ projectId, fileName }) => ({
        url: `/api/projects/${projectId}/files/${fileName}/csvdims`,
        method: 'POST',
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        dispatch(setGlobalLoading(true));
        try {
          await queryFulfilled;
        } catch (err) {
          dispatch(openErrorToast('Error fetching data. Try again!'));
        }
        dispatch(setGlobalLoading(false));
      },
    }),
  }),
  overrideExisting: false,
});

export const { useGetFilesQuery, useLazyBathymetricFileSizeQuery } = extendedApi;
