import React, { FC, memo, useMemo } from 'react';
import { Box, Grid, SelectChangeEvent, Typography } from '@mui/material';
import CustomInput from '../../custom/CustomInput';
import LabelWrapper from '../../common/LabelWrapper';
import BoxComponent from '../../custom/BoxComponent';
import CustomMenuItem from '../../custom/CustomMenuItem';
import CustomSelect from '../../custom/CustomSelect';
import CustomSwitch from '../../custom/CustomSwitch';
import { ShorelineModelFeatures } from '../../../models/inputTypes/ShorelineFields';
import { FileType } from '../../../models/inputTypes/FileType';

const styles = {
  spaceBetweenContainer: {
    flexWrap: 'wrap',
    display: 'flex',
    maxWidth: '730px',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& >:nth-child(n)': {
      my: 1,
    },
  },
  childSpacing: {
    '& >:nth-child(n)': {
      my: 1,
      mr: { xs: 2, md: 3, xl: 4 },
    },
  },
  spacing: {
    '& >:nth-child(n)': {
      mr: { xs: 2, md: 3, xl: 4 },
    },
  },
  wrap: {
    flexWrap: 'wrap',
  },
  flexCenterBox: {
    display: 'flex',
    justifyContent: 'center',
  },
  spaceBetweenBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    maxWidth: '800px',
    // flexWrap: 'wrap',
  },
  flexStartBox: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
  },
} as const;
type InputsProps = {
  projectId: string;
  inputState: ShorelineModelFeatures;
  setInputState: (value: ShorelineModelFeatures) => void;
};
const ShorelineModelFeaturesInputsGroup: FC<InputsProps> = ({ projectId, inputState, setInputState }) => {
  const onNumberChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setInputState({ ...inputState, [event.target.name]: event.target.value });
  };

  const onInputChange = (
    event: SelectChangeEvent<unknown> | React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setInputState({ ...inputState, [event.target.name]: event.target.value });
  };

  const switchChange = (e: any) => {
    setInputState({ ...inputState, [e.target.name]: e.target.checked });
  };

  return (
    <>
      <Typography mb={1} variant={'subtitle1'}>
        Wave diffraction
      </Typography>
      <Box mb={3} minWidth={'200px'}>
        <LabelWrapper label={'Include wave diffraction'}>
          <Grid container justifyContent={'center'} width={'120px'}>
            <CustomSwitch checked={inputState.wave_diffraction} name={'wave_diffraction'} onChange={switchChange} />
          </Grid>
        </LabelWrapper>
      </Box>
      <Typography variant={'subtitle1'}>Sea level</Typography>
      <Box mb={3} sx={{ ...styles.flexStartBox, ...styles.childSpacing }}>
        <Box minWidth={'200px'}>
          <LabelWrapper label={'Include Sea Level Rise'}>
            <Grid container justifyContent={'center'} width={'120px'}>
              <CustomSwitch checked={inputState.clima_change} name={'clima_change'} onChange={switchChange} />
            </Grid>
          </LabelWrapper>
        </Box>
        <BoxComponent disabled={!inputState.clima_change}>
          <LabelWrapper label={'Sea level rise rate (mm/year)'}>
            <CustomInput
              required
              step={'any'}
              errorText={'Enter positive number'}
              type={'number'}
              onChange={onNumberChange}
              value={inputState.slrate}
              disabled={!inputState.clima_change}
              name={'slrate'}
            />
          </LabelWrapper>
        </BoxComponent>
      </Box>
      <Typography variant={'subtitle1'}>Sediment Transport</Typography>
      <Box mb={3} sx={{ ...styles.flexStartBox, ...styles.childSpacing }}>
        <Box>
          <LabelWrapper label={'Sediment transport formula'}>
            <CustomSelect
              onChange={onInputChange}
              value={inputState.sediment}
              name={'sediment'}
              minWidth={'200px'}
              defaultValue={'CERC'}
              displayEmpty
            >
              <CustomMenuItem value={'CERC'}>
                <Typography variant={'subtitle2'}>CERC</Typography>
              </CustomMenuItem>
              <CustomMenuItem value={'CERC2'}>
                <Typography variant={'subtitle2'}>CERC2</Typography>
              </CustomMenuItem>
              <CustomMenuItem value={'CERC3'}>
                <Typography variant={'subtitle2'}>CERC3</Typography>
              </CustomMenuItem>
              <CustomMenuItem value={'KAMP'}>
                <Typography variant={'subtitle2'}>Kamphuis</Typography>
              </CustomMenuItem>
              <CustomMenuItem value={'MILH'}>
                <Typography variant={'subtitle2'}>Mil-Homens</Typography>
              </CustomMenuItem>
              {/* <CustomMenuItem value={'VR14'}>
                <Typography variant={'subtitle2'}>VR14</Typography>
              </CustomMenuItem> */}
            </CustomSelect>
          </LabelWrapper>
        </Box>
        {inputState.sediment === 'CERC' ? (
          <Box sx={{ ...styles.flexStartBox, ...styles.spacing }}>
            <BoxComponent>
              <LabelWrapper label={'Calibration factor'}>
                <CustomInput
                  min={0}
                  required
                  step={'any'}
                  errorText={'Enter positive number'}
                  type={'number'}
                  onChange={onNumberChange}
                  value={inputState.qscal}
                  name={'qscal'}
                  maxWidth={'120px'}
                />
              </LabelWrapper>
            </BoxComponent>
            <BoxComponent>
              <LabelWrapper label={'Cerc coefficient'}>
                <CustomInput
                  min={0}
                  required
                  step={'any'}
                  errorText={'Enter positive number'}
                  type={'number'}
                  onChange={onNumberChange}
                  value={inputState.calfc}
                  name={'calfc'}
                  maxWidth={'100px'}
                />
              </LabelWrapper>
            </BoxComponent>
          </Box>
        ) : undefined}
        {inputState.sediment === 'CERC2' || inputState.sediment === 'CERC3' ? (
          <Box sx={{ ...styles.flexStartBox, ...styles.spacing }}>
            <BoxComponent>
              <LabelWrapper label={'Calibration factor'}>
                <CustomInput
                  min={0}
                  required
                  step={'any'}
                  errorText={'Enter positive number'}
                  type={'number'}
                  onChange={onNumberChange}
                  value={inputState.qscal}
                  name={'qscal'}
                  maxWidth={'120px'}
                />
              </LabelWrapper>
            </BoxComponent>
            <BoxComponent>
              <LabelWrapper label={'Porosity'}>
                <CustomInput
                  min={0}
                  required
                  step={'any'}
                  errorText={'Enter positive number'}
                  type={'number'}
                  onChange={onNumberChange}
                  value={inputState.porosity}
                  name={'porosity'}
                  maxWidth={'65px'}
                />
              </LabelWrapper>
            </BoxComponent>
            <BoxComponent>
              <LabelWrapper label={'Breaking coefficient'}>
                <CustomInput
                  min={0}
                  required
                  step={'any'}
                  errorText={'Enter positive number'}
                  type={'number'}
                  onChange={onNumberChange}
                  value={inputState.gamma}
                  name={'gamma'}
                  maxWidth={'120px'}
                />
              </LabelWrapper>
            </BoxComponent>
            <BoxComponent>
              <LabelWrapper label={'Sediment density (kg/m\u00B3)'}>
                <CustomInput
                  min={0}
                  required
                  step={'any'}
                  errorText={'Enter positive number'}
                  type={'number'}
                  onChange={onNumberChange}
                  value={inputState.rhos}
                  name={'rhos'}
                  maxWidth={'160px'}
                />
              </LabelWrapper>
            </BoxComponent>
            <BoxComponent>
              <LabelWrapper label={'Water density (kg/m\u00B3)'}>
                <CustomInput
                  min={0}
                  required
                  step={'any'}
                  errorText={'Enter positive number'}
                  type={'number'}
                  onChange={onNumberChange}
                  value={inputState.rhow}
                  name={'rhow'}
                  maxWidth={'140px'}
                />
              </LabelWrapper>
            </BoxComponent>
          </Box>
        ) : undefined}
        {inputState.sediment === 'KAMP' || inputState.sediment === 'MILH' ? (
          <Box sx={{ ...styles.flexStartBox, ...styles.spacing, ...styles.wrap }}>
            <BoxComponent>
              <LabelWrapper label={'Calibration factor'}>
                <CustomInput
                  min={0}
                  required
                  step={'any'}
                  errorText={'Enter positive number'}
                  type={'number'}
                  onChange={onNumberChange}
                  value={inputState.qscal}
                  name={'qscal'}
                  maxWidth={'120px'}
                />
              </LabelWrapper>
            </BoxComponent>
            <BoxComponent>
              <LabelWrapper label={'Porosity'}>
                <CustomInput
                  min={0}
                  required
                  step={'any'}
                  errorText={'Enter positive number'}
                  type={'number'}
                  onChange={onNumberChange}
                  value={inputState.porosity}
                  name={'porosity'}
                  maxWidth={'65px'}
                />
              </LabelWrapper>
            </BoxComponent>
            <BoxComponent>
              <LabelWrapper label={'Breaking coefficient'}>
                <CustomInput
                  min={0}
                  required
                  step={'any'}
                  errorText={'Enter positive number'}
                  type={'number'}
                  onChange={onNumberChange}
                  value={inputState.gamma}
                  name={'gamma'}
                  maxWidth={'120px'}
                />
              </LabelWrapper>
            </BoxComponent>
            <BoxComponent>
              <LabelWrapper label={'Sediment density (kg/m\u00B3)'}>
                <CustomInput
                  min={0}
                  required
                  step={'any'}
                  errorText={'Enter positive number'}
                  type={'number'}
                  onChange={onNumberChange}
                  value={inputState.rhos}
                  name={'rhos'}
                  maxWidth={'160px'}
                />
              </LabelWrapper>
            </BoxComponent>
            <BoxComponent>
              <LabelWrapper label={'Water density (kg/m\u00B3)'}>
                <CustomInput
                  min={0}
                  required
                  step={'any'}
                  errorText={'Enter positive number'}
                  type={'number'}
                  onChange={onNumberChange}
                  value={inputState.rhow}
                  name={'rhow'}
                  maxWidth={'140px'}
                />
              </LabelWrapper>
            </BoxComponent>
            <BoxComponent>
              <LabelWrapper label={'Bed slope'}>
                <CustomInput
                  min={0}
                  required
                  step={'any'}
                  errorText={'Enter positive number'}
                  type={'number'}
                  onChange={onNumberChange}
                  value={inputState.tanbeta}
                  name={'tanbeta'}
                  maxWidth={'75px'}
                />
              </LabelWrapper>
            </BoxComponent>
            <BoxComponent>
              <LabelWrapper label={'d50 (m)'}>
                <CustomInput
                  min={0}
                  required
                  step={'any'}
                  errorText={'Enter positive number'}
                  type={'number'}
                  onChange={onNumberChange}
                  value={inputState.d50}
                  name={'d50'}
                  maxWidth={'65px'}
                />
              </LabelWrapper>
            </BoxComponent>
          </Box>
        ) : undefined}
      </Box>
      <Typography variant={'subtitle1'}>Smoothing</Typography>
      <Box sx={{ ...styles.flexStartBox, ...styles.childSpacing }}>
        <Box minWidth={'200px'}>
          <LabelWrapper label={'Include coastline  smoothing'}>
            <Grid container justifyContent={'center'} width={'120px'}>
              <CustomSwitch checked={inputState.smoothC} name={'smoothC'} onChange={switchChange} />
            </Grid>
          </LabelWrapper>
        </Box>
        <BoxComponent disabled={!inputState.smoothC}>
          <LabelWrapper label={'Smoothing factor'}>
            <CustomInput
              required
              step={'any'}
              errorText={'Enter positive number'}
              type={'number'}
              onChange={onNumberChange}
              value={inputState.smoothfac}
              disabled={!inputState.smoothC}
              name={'smoothfac'}
            />
          </LabelWrapper>
        </BoxComponent>
      </Box>
      <Box sx={{ ...styles.flexStartBox, ...styles.childSpacing }}>
        <Box minWidth={'200px'}>
          <LabelWrapper label={'Include refraction smoothing'}>
            <Grid container justifyContent={'center'} width={'120px'}>
              <CustomSwitch checked={inputState.smoothR} name={'smoothR'} onChange={switchChange} />
            </Grid>
          </LabelWrapper>
        </Box>
        <BoxComponent disabled={!inputState.smoothR}>
          <LabelWrapper label={'Smoothing factor'}>
            <CustomInput
              required
              step={'any'}
              errorText={'Enter positive number'}
              type={'number'}
              onChange={onNumberChange}
              value={inputState.smoothrefrac}
              disabled={!inputState.smoothR}
              name={'smoothrefrac'}
            />
          </LabelWrapper>
        </BoxComponent>
      </Box>
    </>
  );
};

export default memo(ShorelineModelFeaturesInputsGroup);
