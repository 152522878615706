import React, { FC, memo, useState } from 'react';
import { Box, Typography } from '@mui/material';
import CustomInputWithLabel from '../../common/CustomInputWithLabel';
import LabelWrapper from '../../common/LabelWrapper';
import CustomInput from '../../custom/CustomInput';
import BoxComponent from '../../custom/BoxComponent';
import { PlotLine } from '../../../models/inputTypes/PmsFields';
import CustomCheckbox from '../../custom/CustomCheckbox';

const styles = {
  spacingContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  alignEndContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    flexWrap: 'wrap',
  },
  largeChildSpacing: {
    '& >:nth-child(n)': {
      my: 1,
      mr: { xs: 4, md: 6, lg: 8 },
    },
  },
  smallChildSpacing: {
    '& >:nth-child(n)': {
      my: 3,
      mr: 4,
    },
  },

  smallVariableSpacing: {
    '& >:nth-child(n)': {
      my: 1,
      mr: 4,
    },
    '& >:first-child': {
      my: 1,
      mr: 2,
    },
  },
  coordinates: {
    '& >:nth-child(2)': {
      ml: 1,
    },
  },
} as const;

type PmsPlotLineInputsProps = {
  inputState: PlotLine;
  setInputState: (value: PlotLine) => void;
};

const PmsPlotLineInputsGroup: FC<PmsPlotLineInputsProps> = ({ inputState, setInputState }) => {
  const [valueX, setValueX] = useState(1);
  const [valueY, setValueY] = useState(1);
  const onCheckboxClick = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    const currentValue: 'h' | 'z' | 'dir' | 'c' = e.target.id;
    const newValue = inputState[currentValue] ? 0 : 1;
    setInputState({ ...inputState, [currentValue]: newValue });
  };

  const onNumberChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    // if (event.target.name === 'fp_x') {
    //   setInputState({ ...inputState, fp_x: +event.target.value, lp_x: +event.target.value });
    //   return;
    // }
    // if (event.target.name === 'fp_y') {
    //   setInputState({ ...inputState, fp_y: +event.target.value, lp_y: +event.target.value });
    //   return;
    // }
    setInputState({ ...inputState, [event.target.name]: event.target.value });
  };

  const onInputChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setInputState({ ...inputState, [event.target.name]: event.target.value });
  };

  const onInputChangeX = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setValueX(+event.target.value);
    setInputState({ ...inputState, [event.target.name]: event.target.value });
  };
  const onInputChangeY = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setValueY(+event.target.value);
    setInputState({ ...inputState, [event.target.name]: event.target.value });
  };

  return (
    <Box>
      <Typography mb={1} variant={'subtitle1'}>
        Bounding Points
      </Typography>
      <Box mb={2} sx={{ ...styles.spacingContainer, ...styles.largeChildSpacing }}>
        <Box>
          <LabelWrapper label={'First point'}>
            <Box sx={{ display: 'flex', ...styles.coordinates }}>
              <CustomInputWithLabel
                min={1}
                step={1}
                required
                errorText={'Enter positive integer'}
                type={'number'}
                onChange={onNumberChange}
                value={inputState.fp_x}
                name={'fp_x'}
                label={'X'}
              />
              <CustomInputWithLabel
                min={1}
                step={1}
                required
                errorText={'Enter positive integer'}
                type={'number'}
                onChange={onNumberChange}
                value={inputState.fp_y}
                name={'fp_y'}
                label={'Y'}
              />
            </Box>
          </LabelWrapper>
        </Box>
        <Box>
          <LabelWrapper label={'Last point'}>
            <Box sx={{ display: 'flex', ...styles.coordinates }}>
              <CustomInputWithLabel
                min={inputState.fp_x}
                step={1}
                required
                errorText={
                  valueX < inputState.fp_x && valueX > 0
                    ? 'Number should be >= than first point'
                    : 'Enter positive integer'
                }
                type={'number'}
                onChange={onInputChangeX}
                value={inputState.lp_x}
                name={'lp_x'}
                label={'X'}
              />
              <CustomInputWithLabel
                min={inputState.fp_y}
                step={1}
                required
                errorText={
                  valueY < inputState.fp_y && valueY > 0
                    ? 'Number should be >= than first point'
                    : 'Enter positive integer'
                }
                type={'number'}
                onChange={onInputChangeY}
                value={inputState.lp_y}
                name={'lp_y'}
                label={'Y'}
              />
            </Box>
          </LabelWrapper>
        </Box>
      </Box>
      <Typography mx={1} variant={'subtitle1'}>
        Variables
      </Typography>
      <Box sx={{ ...styles.smallChildSpacing }}>
        <Box sx={{ ...styles.alignEndContainer }}>
          <Box mr={2}>
            <CustomCheckbox checked={!!inputState.h} id={'h'} onClick={onCheckboxClick} />
          </Box>
          <BoxComponent disabled={!inputState.h}>
            <LabelWrapper label={'Wave height'}>
              <CustomInput
                required
                disabled={!inputState.h}
                minWidth={'160px'}
                errorText={'Please enter file name'}
                type={'text'}
                maxLength={30}
                onChange={onInputChange}
                value={inputState.name_h}
                name={'name_h'}
              />
            </LabelWrapper>
          </BoxComponent>
        </Box>
        <Box sx={{ ...styles.alignEndContainer }}>
          <Box mr={2}>
            <CustomCheckbox checked={!!inputState.c} id={'c'} onClick={onCheckboxClick} />
          </Box>
          <BoxComponent disabled={!inputState.c}>
            <LabelWrapper label={'Wave celerity'}>
              <CustomInput
                required
                disabled={!inputState.c}
                minWidth={'160px'}
                errorText={'Please enter file name'}
                type={'text'}
                maxLength={30}
                onChange={onInputChange}
                value={inputState.name_c}
                name={'name_c'}
              />
            </LabelWrapper>
          </BoxComponent>
        </Box>
        <Box sx={{ ...styles.alignEndContainer }}>
          <Box mr={2}>
            <CustomCheckbox checked={!!inputState.z} id={'z'} onClick={onCheckboxClick} />
          </Box>
          <BoxComponent disabled={!inputState.z}>
            <LabelWrapper label={'Surface elevation'}>
              <CustomInput
                required
                disabled={!inputState.z}
                minWidth={'160px'}
                errorText={'Please enter file name'}
                type={'text'}
                maxLength={30}
                onChange={onInputChange}
                value={inputState.name_z}
                name={'name_z'}
              />
            </LabelWrapper>
          </BoxComponent>
        </Box>
        <Box sx={{ ...styles.alignEndContainer }}>
          <Box mr={2}>
            <CustomCheckbox checked={!!inputState.dir} id={'dir'} onClick={onCheckboxClick} />
          </Box>
          <BoxComponent disabled={!inputState.dir}>
            <LabelWrapper label={'Wave Direction'}>
              <CustomInput
                required
                disabled={!inputState.dir}
                minWidth={'160px'}
                errorText={'Please enter file name'}
                type={'text'}
                maxLength={30}
                onChange={onInputChange}
                value={inputState.name_dir}
                name={'name_dir'}
              />
            </LabelWrapper>
          </BoxComponent>
        </Box>
      </Box>
    </Box>
  );
};

export default memo(PmsPlotLineInputsGroup);
