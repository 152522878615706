import React, { FC, memo } from 'react';
import { Box, Typography } from '@mui/material';
import CustomInput from '../../custom/CustomInput';
import CustomButton from '../../custom/CustomButton';
import { ReactComponent as SaveIcon } from '../../../assets/images/save.svg';
import SquaredIconButton from '../../custom/SquaredIconButton';
import LabelWrapper from '../../common/LabelWrapper';
import { BtgSubmission } from '../../../models/inputTypes/BtgFields';

const styles = {
  spacingContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    flexWrap: 'wrap',
  },
  childMarginBig: {
    '& >:nth-child(n)': {
      my: 1,
      mr: { xs: 2, lg: 2, xl: 4 },
    },
  },
} as const;
type SubmissionInputsProps = {
  inputState: BtgSubmission;
  setInputState: (value: BtgSubmission) => void;
  onSaveClick?: () => void;
};
const SubmissionInputsGroup: FC<SubmissionInputsProps> = ({ onSaveClick, inputState, setInputState }) => {
  const onInputChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setInputState({ ...inputState, [event.target.name]: event.target.value });
  };

  return (
    <>
      <Box sx={{ ...styles.childMarginBig, ...styles.spacingContainer }}>
        <Box sx={{ ...styles.spacingContainer }}>
          <Box mr={2}>
            <LabelWrapper label={'Create Simulation File'}>
              <CustomInput
                errorText={'Please enter valid text'}
                required
                maxLength={30}
                placeholder={'Enter name'}
                onChange={onInputChange}
                value={inputState.simulationFile}
                name={'simulationFile'}
              />
            </LabelWrapper>
          </Box>
          <SquaredIconButton onClick={onSaveClick} variant={'contained'} color={'success'}>
            <SaveIcon />
          </SquaredIconButton>
        </Box>
        <Box>
          <LabelWrapper label={'Enter an Output file name'}>
            <CustomInput
              errorText={'Please enter valid text'}
              required
              min={0}
              maxLength={30}
              onChange={onInputChange}
              value={inputState.outputFileName}
              name={'outputFileName'}
              placeholder={'Enter name'}
            />
          </LabelWrapper>
        </Box>
        <CustomButton type={'submit'} variant={'contained'} color={'success'}>
          <Typography color={'info.contrastText'} variant={'subtitle2'}>
            Submit
          </Typography>
        </CustomButton>
      </Box>
    </>
  );
};

export default memo(SubmissionInputsGroup);
