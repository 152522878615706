import { customApi } from '../Api';
import { openErrorToast } from '../../slices/appSlice';
import { Job } from '../../../models/types/Job';

const extendedApi = customApi.injectEndpoints({
  endpoints: (builder) => ({
    getJobs: builder.query<Job[], unknown>({
      query: () => ({
        url: '/api/jobs/',
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (err: any) {
          dispatch(openErrorToast(err.error.data.error));
        }
      },
      providesTags: ['jobs'],
    }),
  }),
  overrideExisting: false,
});

export const { useGetJobsQuery } = extendedApi;
