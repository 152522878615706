import { BtgBathymetric, BtgInterpolation, BtgNumericDomain, BtgSubmission } from '../../models/inputTypes/BtgFields';
import { BtgType } from '../../models/jobModels/btgType';
import { btgBathymetricInitialState, btgSubmissionInitialState } from '../initialStates/btgInputState';
import { prepareInputFileSimulation } from '../functions/prepareInputFileSimulation';

export type BtgSimulationPreparationResponse = {
  bathymetric: BtgBathymetric;
  numeric: BtgNumericDomain;
  interpolation: BtgInterpolation;
  submission: BtgSubmission;
};

export const btgSimulationPreparation = (simulationFile: BtgType): BtgSimulationPreparationResponse | undefined => {
  const O_Depths = simulationFile.parameters.output_files.find((data) => data.alias === 'O_Depths.csv');
  const preparedPolygonFile = prepareInputFileSimulation(simulationFile.parameters.input_files, 'I_Polygons.csv');
  const preparedPointsFile = prepareInputFileSimulation(simulationFile.parameters.input_files, 'I_Points.csv');

  const bathymetric: BtgBathymetric = {
    project: simulationFile?.project ? simulationFile.project.toString() : btgBathymetricInitialState.project,
    coordX: simulationFile.parameters.ocxin,
    coordY: simulationFile.parameters.ocyin,
    closedPolygons: +simulationFile.parameters.nply,
    polygonsData: preparedPolygonFile,
    // points: simulationFile.parameters.ikm,
    dataPoints: preparedPointsFile,
  };
  const numeric: BtgNumericDomain = {
    nodeX: simulationFile.parameters.imin,
    nodeY: simulationFile.parameters.jmin,
    sizeDX: simulationFile.parameters.dx,
    sizeDY: simulationFile.parameters.dy,
    // landValue: simulationFile.parameters.xinland,
  };

  const interpolation: BtgInterpolation = {
    interpolation: simulationFile.parameters.inmeth.toString(),
    searchRadius: +simulationFile.parameters.sradius,
  };

  const submission: BtgSubmission = {
    simulationFile: btgSubmissionInitialState.simulationFile,
    outputFileName: O_Depths?.name ? O_Depths.name.split('.csv')[0] : btgSubmissionInitialState.outputFileName,
  };
  return { bathymetric, numeric, interpolation, submission };
};
