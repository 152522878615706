import React, { FC, FormEvent, useEffect, useMemo, useState } from 'react';
import PageContainer from '../../components/structural/PageContainer';
import { Collapse, Grid, Typography } from '@mui/material';
import BoxInputContainer from '../../components/custom/BoxInputContainer';
import PmsSubmissionInputsGroup from '../../components/groups/pms/PmsSubmissionInputsGroup';
import CustomTabs from '../../components/custom/CustomTabs';
import CustomSwitch from '../../components/custom/CustomSwitch';
import HydBoundaryConditionsInputsGroup from '../../components/groups/hyd/HydBoundaryConditionsInputsGroup';
import HydSourceSinksInputsGroup from '../../components/groups/hyd/HydSourceSinksInputsGroup';
import HydColdStartInputsGroup from '../../components/groups/hyd/HydColdStartInputsGroup';
import HydHotStartInputsGroup from '../../components/groups/hyd/HydHotStartInputsGroup';
import CustomPrompt from '../../components/custom/CustomPrompt';
import { PmsSubmission } from '../../models/inputTypes/PmsFields';
import { pmsSubmissionInitialState } from '../../utils/initialStates/pmsInputStates';
import { plotTabPreparation } from '../../utils/functions/PlotTabPreparation';
import {
  HydBoundaryConditions,
  HydInitialConditions,
  HydModelDefinition,
  HydModelFeatures,
  HydNumericalDomain,
  HydOutput,
  HydPlotArea,
  HydPlotLine,
  HydSourceLinks,
} from '../../models/inputTypes/HydFields';
import {
  hydBoundaryConditionsInitialState,
  hydInitialConditionsInitialState,
  hydModelDefinitionInitialState,
  hydModelFeaturesInitialState,
  hydNumericalDomainInitialState,
  hydOutputInitialState,
  hydPlotAreasInitialState,
  hydPlotLinesInitialState,
  hydSourceLinksInitialState,
} from '../../utils/initialStates/hydInputState';
import HydModelDefinitionInputsGroup from '../../components/groups/hyd/HydModelDefinitionInputsGroup';
import HydModelFeaturesInputsGroup from '../../components/groups/hyd/HydModelFeaturesInputsGroup';
import { useGetProjectsQuery } from '../../redux/RTK/queries/projectQuery';
import { HydPreparation } from '../../utils/modelPreparation/hydPreparation';
import HydOutputInputsGroup from '../../components/groups/hyd/HydOutputInputsGroup';
import HydPlotAreasInputsGroup from '../../components/groups/hyd/HydPlotAreasInputsGroup';
import HydPlotLinesInputsGroup from '../../components/groups/hyd/HydPlotLinesInputsGroup';
import HydNumericalDomainInputsGroup from '../../components/groups/hyd/HydNumericalDomainInputsGroup';
import { openWarningToast } from '../../redux/slices/appSlice';
import { useAddProjectFileMutation } from '../../redux/RTK/mutations/projectFileMutations';
import { FileType } from '../../models/inputTypes/FileType';
import { HydSimulationPreparationResponse } from '../../utils/simulationFileToState/hydSimulationPreparation';
import { useCreateJobsMutation } from '../../redux/RTK/mutations/jobsMutations';
import ConfirmationModal from '../../components/dialogs/ConfirmationModal';
import { useAppDispatch } from '../../redux/store';
import { hmsPlotAreasInitialState } from '../../utils/initialStates/hmsInputState';
import useSimulationPreparation from '../../utils/hooks/jsonParamHook';
import { ModelEnum } from '../../models/types/ModelEnum';
import { useStorageQuery } from '../../redux/RTK/queries/storageQuery';

const styles = {
  gridSpacing: {
    '& >:nth-child(odd)': {
      paddingRight: { sm: 0, md: 4 },
    },
  },
};

const MarisHydPage: FC = () => {
  const { data } = useGetProjectsQuery({});
  const { data: profile } = useStorageQuery({});
  const [uploadFile] = useAddProjectFileMutation();
  const [createJob] = useCreateJobsMutation();
  const dispatch = useAppDispatch();

  const [selectedPlots, setSelectedPlots] = useState<{ line: number; area: number }>({ line: 0, area: 0 });

  const [numericalState, setNumericalState] = useState<HydNumericalDomain>(hydNumericalDomainInitialState);
  const [modelDefinitionState, setModelDefinitionState] = useState<HydModelDefinition>(hydModelDefinitionInitialState);
  const [initialState, setInitialState] = useState<HydInitialConditions>(hydInitialConditionsInitialState);
  const [boundaryState, setBoundaryState] = useState<HydBoundaryConditions>(hydBoundaryConditionsInitialState);
  const [sourceState, setSourceState] = useState<HydSourceLinks>(hydSourceLinksInitialState);
  const [modelState, setModelState] = useState<HydModelFeatures>(hydModelFeaturesInitialState);
  const [outputState, setOutputState] = useState<HydOutput>(hydOutputInitialState);
  const [submissionState, setSubmissionState] = useState<PmsSubmission>(pmsSubmissionInitialState);
  const [saveNoOutput, setSaveNoOutput] = useState(false);
  const [stateHasChanged, setStateHasChanged] = useState(false);
  const [areaInitial, setAreaInitial] = useState<HydPlotArea>(hydPlotAreasInitialState);
  const [lineInitial, setLineInitial] = useState<HydPlotLine>(hydPlotLinesInitialState);
  const { preparedData, error, currentFile } = useSimulationPreparation(ModelEnum.HYD);
  const [disable, setDisable] = useState(false);

  useEffect(() => {
    if (profile?.profile) {
      setDisable(!profile.profile.enable_models.includes('hyd') && !profile.profile.enable_models.includes('all'));
    }
  }, [profile]);

  useEffect(() => {
    if (preparedData && !error && currentFile) {
      onSimulationFileChange(preparedData, currentFile);
    }
  }, [preparedData, error, currentFile]);

  useEffect(() => {
    if (!stateHasChanged) {
      const numChanged = numericalState != hydNumericalDomainInitialState;
      const defChanged = modelDefinitionState != hydModelDefinitionInitialState;
      const condChanged = initialState != hydInitialConditionsInitialState;
      const boundChanged = boundaryState != hydBoundaryConditionsInitialState;
      const sourceChanged = sourceState != hydSourceLinksInitialState;
      const FeatChanged = modelState != hydModelFeaturesInitialState;
      const outChanged = outputState != hydOutputInitialState;
      const subChanged = submissionState != pmsSubmissionInitialState;
      setStateHasChanged(
        boundChanged ||
          defChanged ||
          numChanged ||
          condChanged ||
          subChanged ||
          sourceChanged ||
          outChanged ||
          FeatChanged,
      );
    }
  }, [
    numericalState,
    modelDefinitionState,
    initialState,
    boundaryState,
    sourceState,
    modelState,
    outputState,
    submissionState,
  ]);

  useEffect(() => {
    setAreaInitial({
      ...areaInitial,
      ne_x: numericalState.cellX ?? hmsPlotAreasInitialState.ne_x,
      ne_y: numericalState.cellY ?? hmsPlotAreasInitialState.ne_y,
    });
  }, [numericalState.cellX, numericalState.cellY]);

  useEffect(() => {
    const floorValue = Math.floor(modelDefinitionState.simulationTime / modelDefinitionState.timeSteps);
    setAreaInitial({
      ...areaInitial,
      end_step: floorValue,
      interval: floorValue,
    });
    setLineInitial({
      ...lineInitial,
      end_step: floorValue,
      interval: floorValue,
    });
  }, [modelDefinitionState.simulationTime, modelDefinitionState.timeSteps]);

  const selectedProjectName = useMemo(() => {
    if (data?.length) {
      const found = data.find((item) => item.id.toString() === numericalState.project.toString());
      return found?.name;
    }
  }, [numericalState.project]);

  const plotAreaTabs = useMemo(() => {
    return plotTabPreparation(outputState.plotAreas, 'area');
  }, [outputState.plotAreas]);

  const plotLineTabs = useMemo(() => {
    return plotTabPreparation(outputState.plotLines, 'line');
  }, [outputState.plotLines]);

  const onSwitchColdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInitialState({ ...hydInitialConditionsInitialState, coldStart: e.target.checked, hotStart: !e.target.checked });
  };
  const onSwitchHotChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInitialState({ ...hydInitialConditionsInitialState, hotStart: e.target.checked, coldStart: !e.target.checked });
  };

  const onLineChange = (event: any, newValue: number) => {
    setSelectedPlots({ ...selectedPlots, [event.currentTarget.id]: newValue });
  };

  const onSimulationSave = () => {
    const preparedValue = HydPreparation(
      numericalState,
      modelDefinitionState,
      initialState,
      boundaryState,
      sourceState,
      modelState,
      outputState,
      submissionState,
    );
    if (preparedValue && numericalState.project && submissionState.simulationFile) {
      uploadFile({
        id: numericalState.project,
        name: `${submissionState?.simulationFile}.json`,
        file: preparedValue,
      });
    } else {
      dispatch(
        openWarningToast(
          !numericalState.project ? 'You have to select project first!' : 'simulation file name required!',
        ),
      );
    }
  };

  const onNoOutputAccept = () => {
    const preparedValue = HydPreparation(
      numericalState,
      modelDefinitionState,
      initialState,
      boundaryState,
      sourceState,
      modelState,
      outputState,
      submissionState,
    );
    if (preparedValue) createJob(preparedValue);
    setSaveNoOutput(false);
  };

  const onSubmissionClick = (event: FormEvent<any>) => {
    event.preventDefault();
    if (!outputState.plotAreas && !outputState.plotLines) {
      setSaveNoOutput(true);
      return;
    }
    const preparedValue = HydPreparation(
      numericalState,
      modelDefinitionState,
      initialState,
      boundaryState,
      sourceState,
      modelState,
      outputState,
      submissionState,
    );
    if (preparedValue) createJob(preparedValue);
  };

  const onSimulationFileChange = (value: HydSimulationPreparationResponse, file?: FileType) => {
    setNumericalState({ ...value.numerical, simulationSetup: file });
    setModelDefinitionState(value.modelDefinition);
    setInitialState(value.initialState);
    setBoundaryState(value.boundaries);
    setSourceState(value.sourceLinks);
    setModelState(value.modelFeatures);
    setOutputState(value.output);
    setSubmissionState({ ...value.submission, simulationFile: file?.fileName?.split('.json')[0] ?? '' });
  };

  return (
    <PageContainer title={'NUMERICAL MODELS'} subTitle={'Maris HYD'} projectName={selectedProjectName}>
      <CustomPrompt
        when={stateHasChanged}
        message={(params) =>
          params.pathname == '/numerical-models/maris-hyd' ? true : 'Changes that you made may not be saved.'
        }
      />
      <Typography variant={'h4'}>Numerical Domain</Typography>
      <form onSubmit={onSubmissionClick}>
        <BoxInputContainer borderRadius={'4px'} mt={2}>
          <HydNumericalDomainInputsGroup
            simulationChangeFile={onSimulationFileChange}
            inputState={numericalState}
            setInputState={setNumericalState}
          />
        </BoxInputContainer>

        <Typography mt={2} variant={'h4'}>
          Model Definition
        </Typography>
        <BoxInputContainer borderRadius={'4px'} mt={2}>
          <HydModelDefinitionInputsGroup inputState={modelDefinitionState} setInputState={setModelDefinitionState} />
        </BoxInputContainer>

        <Typography mt={2} variant={'h4'}>
          Initial Conditions
        </Typography>
        <Grid mt={2} maxWidth={'180px'} justifyContent={'space-between'} container alignItems={'center'}>
          <Typography>Cold Start</Typography>
          <CustomSwitch checked={initialState.coldStart} name={'coldStart'} onChange={onSwitchColdChange} />
        </Grid>
        <Collapse in={initialState.coldStart} collapsedSize={40}>
          <BoxInputContainer borderRadius={'4px'} mt={2}>
            <HydColdStartInputsGroup inputState={initialState} setInputState={setInitialState} />
          </BoxInputContainer>
        </Collapse>

        <Grid mt={2} maxWidth={'180px'} justifyContent={'space-between'} container alignItems={'center'}>
          <Typography>Hot Start</Typography>
          <CustomSwitch checked={initialState.hotStart} name={'hotStart'} onChange={onSwitchHotChange} />
        </Grid>
        <Collapse in={initialState.hotStart} collapsedSize={40}>
          <BoxInputContainer borderRadius={'4px'} mt={2}>
            <HydHotStartInputsGroup
              projectId={numericalState.project}
              inputState={initialState}
              setInputState={setInitialState}
            />
          </BoxInputContainer>
        </Collapse>

        <Grid sx={{ ...styles.gridSpacing }} container>
          <Grid mt={2} item sm={12} md={6}>
            <Typography variant={'h4'}>Boundary Conditions</Typography>
            <BoxInputContainer borderRadius={'4px'} mt={2}>
              <HydBoundaryConditionsInputsGroup inputState={boundaryState} setInputState={setBoundaryState} />
            </BoxInputContainer>
          </Grid>
          <Grid mt={2} item sm={12} md={6}>
            <Typography variant={'h4'}>Source/Sinks</Typography>
            <BoxInputContainer borderRadius={'4px'} mt={2}>
              <HydSourceSinksInputsGroup inputState={sourceState} setInputState={setSourceState} />
            </BoxInputContainer>
          </Grid>
        </Grid>

        <Typography mt={2} variant={'h4'}>
          Model Features
        </Typography>
        <BoxInputContainer borderRadius={'4px'} mt={2}>
          <HydModelFeaturesInputsGroup
            projectId={numericalState.project}
            inputState={modelState}
            setInputState={setModelState}
          />
        </BoxInputContainer>

        <Typography mt={2} variant={'h4'}>
          Output
        </Typography>
        <BoxInputContainer borderRadius={'4px'} mt={2}>
          <HydOutputInputsGroup
            inputState={outputState}
            setInputState={setOutputState}
            areaInitial={areaInitial}
            lineInitial={lineInitial}
          />
        </BoxInputContainer>
        <Typography mt={2} variant={'h4'}>
          Plot Areas
        </Typography>
        <BoxInputContainer borderRadius={'4px'} mt={2}>
          <Collapse in={outputState.plotAreas !== 0} collapsedSize={1}>
            <CustomTabs
              variant={'scrollable'}
              scrollButtons={'auto'}
              value={selectedPlots.area}
              name={'area'}
              onChange={onLineChange}
            >
              {plotAreaTabs}
            </CustomTabs>
            {outputState.plotAreaValues[selectedPlots.area] ? (
              <HydPlotAreasInputsGroup
                inputState={outputState.plotAreaValues[selectedPlots.area]}
                setInputState={(newArea) => {
                  const newPlotArray = outputState.plotAreaValues.slice();
                  newPlotArray[selectedPlots.area] = { ...newPlotArray[selectedPlots.area], ...newArea };
                  setOutputState({ ...outputState, plotAreaValues: newPlotArray });
                }}
              />
            ) : undefined}
          </Collapse>
        </BoxInputContainer>
        <Typography mt={2} variant={'h4'}>
          Plot Lines
        </Typography>
        <BoxInputContainer borderRadius={'4px'} mt={2}>
          <Collapse in={outputState.plotLines !== 0} collapsedSize={5}>
            <CustomTabs
              variant={'scrollable'}
              scrollButtons={'auto'}
              value={selectedPlots.line}
              name={'line'}
              onChange={onLineChange}
            >
              {plotLineTabs}
            </CustomTabs>
            {outputState.plotLineValues[selectedPlots.line] ? (
              <HydPlotLinesInputsGroup
                inputState={outputState.plotLineValues[selectedPlots.line]}
                setInputState={(newLine) => {
                  const newPlotArray = outputState.plotLineValues.slice();
                  newPlotArray[selectedPlots.line] = newLine;
                  setOutputState({ ...outputState, plotLineValues: newPlotArray });
                }}
              />
            ) : undefined}
          </Collapse>
        </BoxInputContainer>

        <Typography mt={2} variant={'h4'}>
          Submission
        </Typography>
        <BoxInputContainer borderRadius={'4px'} mt={2}>
          <PmsSubmissionInputsGroup
            onSaveClick={onSimulationSave}
            inputState={submissionState}
            setInputState={setSubmissionState}
            disable={disable}
          />
        </BoxInputContainer>
      </form>
      <ConfirmationModal
        open={saveNoOutput}
        handleClose={() => setSaveNoOutput(false)}
        message={'You are about to make a job without output! Are you sure you want to do that?'}
        handleAccept={onNoOutputAccept}
      />
    </PageContainer>
  );
};

export default MarisHydPage;
