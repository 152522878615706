import React, { FC, memo, useMemo } from 'react';
import { Box, SelectChangeEvent, Typography } from '@mui/material';
import CustomInput from '../../custom/CustomInput';
import CustomMenuItem from '../../custom/CustomMenuItem';
import CustomSelect from '../../custom/CustomSelect';
import LabelWrapper from '../../common/LabelWrapper';
import { PmsBoundaryConditions } from '../../../models/inputTypes/PmsFields';
import BoxComponent from '../../custom/BoxComponent';
import { pmsBoundaryConditionsInitialState } from '../../../utils/initialStates/pmsInputStates';

const styles = {
  spacingContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  childSpacing: {
    '& >:nth-child(n)': {
      my: 1,
      mr: { xs: 2, md: 3, xl: 4 },
    },
  },
  flexStartBox: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  coordinates: {
    '& >:nth-child(2)': {
      ml: 1,
    },
  },
} as const;

type PmsBoundaryConditionsInputsProps = {
  inputState: PmsBoundaryConditions;
  setInputState: (value: PmsBoundaryConditions) => void;
};

const PmsBoundaryConditionsInputsGroup: FC<PmsBoundaryConditionsInputsProps> = ({ inputState, setInputState }) => {
  const onNumberChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setInputState({ ...inputState, [event.target.name]: event.target.value });
  };

  const onInputChange = (
    event: SelectChangeEvent<unknown> | React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    if (event.target.name === 'boundaryType') {
      if (event.target.value === '1') {
        setInputState({ ...inputState, direction: 270, boundaryType: event.target.value });
      }
      if (event.target.value === '2') {
        setInputState({ ...inputState, direction: 0, boundaryType: event.target.value });
      }
      if (event.target.value === '3') {
        setInputState({ ...inputState, direction: 90, boundaryType: event.target.value });
      }
      if (event.target.value === '4') {
        setInputState({ ...inputState, direction: 180, boundaryType: event.target.value });
      }
      return;
    }
    if (event.target.name === 'seaState' && event.target.value === '1') {
      setInputState({
        ...inputState,
        [event.target.name]: event.target.value,
        numberOfPeriods: pmsBoundaryConditionsInitialState.numberOfPeriods,
        spectrum: pmsBoundaryConditionsInitialState.spectrum,
        depthWavemaker: pmsBoundaryConditionsInitialState.depthWavemaker,
        minPeriod: pmsBoundaryConditionsInitialState.minPeriod,
        maxPeriod: pmsBoundaryConditionsInitialState.maxPeriod,
        peakFactorY: pmsBoundaryConditionsInitialState.peakFactorY,
      });
      return;
    }
    if (event.target.name === 'spectrum') {
      setInputState({
        ...inputState,
        spectrum: event.target.value as string,
        depthWavemaker: pmsBoundaryConditionsInitialState.depthWavemaker,
      });
      return;
    }
    setInputState({ ...inputState, [event.target.name]: event.target.value });
  };

  const customSelectTwo = useMemo(() => {
    let label = 'north';
    if (inputState.boundaryType == '2') label = 'east';
    if (inputState.boundaryType == '3') label = 'south';
    if (inputState.boundaryType == '4') label = 'west';

    return (
      <LabelWrapper sx={{ textTransform: 'capitalize' }} label={label}>
        <CustomSelect
          value={inputState.coord1}
          onChange={onInputChange}
          name={'coord1'}
          minWidth={'140px'}
          defaultValue={''}
          displayEmpty
        >
          <CustomMenuItem value={'1'}>
            <Typography variant={'subtitle2'}>Lateral - Reflective</Typography>
          </CustomMenuItem>
          <CustomMenuItem value={'2'}>
            <Typography variant={'subtitle2'}>Lateral – Transmitting</Typography>
          </CustomMenuItem>
        </CustomSelect>
      </LabelWrapper>
    );
  }, [inputState]);

  const customSelectThree = useMemo(() => {
    let label = 'south';
    if (inputState.boundaryType == '2') label = 'west';

    if (inputState.boundaryType == '3') label = 'north';

    if (inputState.boundaryType == '4') label = 'east';

    return (
      <LabelWrapper sx={{ textTransform: 'capitalize' }} label={label}>
        <CustomSelect
          required
          value={inputState.coord2}
          onChange={onInputChange}
          name={'coord2'}
          minWidth={'140px'}
          defaultValue={''}
          displayEmpty
        >
          <CustomMenuItem value={'1'}>
            <Typography variant={'subtitle2'}>Lateral - Reflective</Typography>
          </CustomMenuItem>
          <CustomMenuItem value={'2'}>
            <Typography variant={'subtitle2'}>Lateral – Transmitting</Typography>
          </CustomMenuItem>
        </CustomSelect>
      </LabelWrapper>
    );
  }, [inputState]);

  const hasError = useMemo(() => {
    if (inputState.boundaryType === '2') {
      return inputState.direction > 90 && inputState.direction < 270;
    }
  }, [inputState.boundaryType === '2', inputState.direction]);

  const directionLimits = useMemo(() => {
    if (inputState.boundaryType === '2') {
      return { min: 0, max: 360, errorText: 'Number must be 0-90 and 270-360' };
    }
    if (inputState.boundaryType === '3') {
      return { min: 0, max: 180, errorText: 'Number must be 0-180' };
    }
    if (inputState.boundaryType === '4') {
      return { min: 90, max: 270, errorText: 'Number must be 90-270' };
    }
    return { min: 180, max: 360, errorText: 'Number must be 180-360' };
  }, [inputState.boundaryType]);

  return (
    <Box sx={{ ...styles.childSpacing, ...styles.spacingContainer }}>
      <Box>
        <Typography mb={2} variant={'subtitle1'}>
          Boundary Type
        </Typography>
        <Box sx={{ ...styles.spacingContainer }}>
          <Box pt={3} mr={3}>
            <CustomSelect
              required
              value={inputState.boundaryType}
              onChange={onInputChange}
              name={'boundaryType'}
              minWidth={'140px'}
              defaultValue={''}
              displayEmpty
            >
              <CustomMenuItem value={'1'}>
                <Typography variant={'subtitle2'}>West</Typography>
              </CustomMenuItem>
              <CustomMenuItem value={'2'}>
                <Typography variant={'subtitle2'}>North</Typography>
              </CustomMenuItem>
              <CustomMenuItem value={'3'}>
                <Typography variant={'subtitle2'}>East</Typography>
              </CustomMenuItem>
              <CustomMenuItem value={'4'}>
                <Typography variant={'subtitle2'}>South</Typography>
              </CustomMenuItem>
            </CustomSelect>
          </Box>
          <Box mr={3}>{customSelectTwo}</Box>
          <Box>{customSelectThree}</Box>
        </Box>
      </Box>
      <Box>
        <Typography mb={2} variant={'subtitle1'}>
          Wave Generation
        </Typography>
        <Box sx={{ ...styles.spacingContainer }}>
          <Box mr={3}>
            <LabelWrapper label={'Sea state'}>
              <CustomSelect
                required
                value={inputState.seaState}
                onChange={onInputChange}
                name={'seaState'}
                minWidth={'140px'}
                defaultValue={''}
                displayEmpty
              >
                <CustomMenuItem value={'1'}>
                  <Typography variant={'subtitle2'}>Regular</Typography>
                </CustomMenuItem>
                <CustomMenuItem value={'2'}>
                  <Typography variant={'subtitle2'}>Irregular</Typography>
                </CustomMenuItem>
              </CustomSelect>
            </LabelWrapper>
          </Box>
          <Box mr={3} sx={{ ...styles.spacingContainer }}>
            <Box mr={1}>
              <LabelWrapper label={'Hs (m)'}>
                <CustomInput
                  min={0.000000000001}
                  step={'any'}
                  required
                  errorText={'Enter positive number'}
                  type={'number'}
                  value={inputState.hm}
                  onChange={onNumberChange}
                  name={'hm'}
                  maxWidth={'65px'}
                />
              </LabelWrapper>
            </Box>
            <Box>
              <LabelWrapper label={'Tp (s)'}>
                <CustomInput
                  min={0.000000000001}
                  step={'any'}
                  required
                  errorText={'Enter positive number'}
                  type={'number'}
                  value={inputState.ts}
                  onChange={onNumberChange}
                  name={'ts'}
                  maxWidth={'65px'}
                />
              </LabelWrapper>
            </Box>
          </Box>
          <Box>
            <LabelWrapper label={'Direction (deg)'}>
              <CustomInput
                error={hasError}
                min={directionLimits.min}
                max={directionLimits.max}
                step={'any'}
                required
                errorText={directionLimits.errorText}
                type={'number'}
                value={inputState.direction}
                onChange={onNumberChange}
                name={'direction'}
              />
            </LabelWrapper>
          </Box>
        </Box>
      </Box>

      <BoxComponent disabled={inputState.seaState !== '2'}>
        <Box sx={{ ...styles.spacingContainer }}>
          <Box mt={1} mr={3}>
            <LabelWrapper label={'Number of Periods'}>
              <CustomInput
                min={1}
                step={1}
                required
                errorText={'Enter positive integer'}
                type={'number'}
                value={inputState.numberOfPeriods}
                onChange={onNumberChange}
                name={'numberOfPeriods'}
              />
            </LabelWrapper>
          </Box>
          <Box mt={1} mr={4}>
            <LabelWrapper label={'Spectrum'}>
              <CustomSelect
                required
                value={inputState.spectrum}
                onChange={onInputChange}
                name={'spectrum'}
                minWidth={'140px'}
                defaultValue={''}
                displayEmpty
              >
                <CustomMenuItem value={'1'}>
                  <Typography variant={'subtitle2'}>JONSWAP</Typography>
                </CustomMenuItem>
                <CustomMenuItem value={'2'}>
                  <Typography variant={'subtitle2'}>TMA</Typography>
                </CustomMenuItem>
              </CustomSelect>
            </LabelWrapper>
          </Box>
          <BoxComponent disabled={inputState.spectrum === '1'} mt={1} mr={5}>
            <LabelWrapper label={'Depth at Wavemaker (m)'}>
              <CustomInput
                min={0.000000001}
                required
                step={'any'}
                disabled={inputState.spectrum === '1'}
                errorText={'Enter positive number'}
                type={'number'}
                value={inputState.depthWavemaker}
                onChange={onNumberChange}
                name={'depthWavemaker'}
              />
            </LabelWrapper>
          </BoxComponent>
          <Box mt={1} mr={3}>
            <LabelWrapper label={'Min Period (s)'}>
              <CustomInput
                min={0.000000001}
                required
                step={'any'}
                errorText={'Enter positive number'}
                type={'number'}
                value={inputState.minPeriod}
                onChange={onNumberChange}
                name={'minPeriod'}
              />
            </LabelWrapper>
          </Box>
          <Box mt={1} mr={6}>
            <LabelWrapper label={'Max Period (s)'}>
              <CustomInput
                min={0.000000001}
                required
                step={'any'}
                errorText={'Enter positive number'}
                type={'number'}
                value={inputState.maxPeriod}
                onChange={onNumberChange}
                name={'maxPeriod'}
              />
            </LabelWrapper>
          </Box>
          <Box mt={1}>
            <LabelWrapper label={'Peak Factor y'}>
              <CustomInput
                min={0.000000001}
                required
                step={'any'}
                errorText={'Enter positive number'}
                type={'number'}
                value={inputState.peakFactorY}
                onChange={onNumberChange}
                name={'peakFactorY'}
              />
            </LabelWrapper>
          </Box>
        </Box>
      </BoxComponent>
    </Box>
  );
};

export default memo(PmsBoundaryConditionsInputsGroup);
