import React, { FC } from 'react';
import { Prompt, PromptProps } from 'react-router';

const CustomPrompt: FC<PromptProps> = ({ ...props }) => {
  window.onbeforeunload = (e: any) => {
    if (props.when) {
      e.preventDefault();
      e.returnValue = '';
    }
  };

  return <Prompt {...props} />;
};

export default CustomPrompt;
