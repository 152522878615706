import { Alert, styled } from '@mui/material';

const CustomAlert = styled(Alert)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minWidth: '200px',
  padding: '0 32px',
  height: '56px',
}));

export default CustomAlert;
