import React from 'react';
import { Button, ButtonProps, styled } from '@mui/material';
import { ReactComponent as MoveIcon } from '../../assets/images/moveButton.svg';

const CustomMoveImageButton = styled((props: ButtonProps) => <Button startIcon={<MoveIcon />} {...props} />)`
  padding: 6px 20px;
  box-shadow: none;
  text-transform: capitalize;
`;

export default CustomMoveImageButton;
