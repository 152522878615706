import React, { FC, memo } from 'react';
import { Box, FormControlLabel, RadioGroup, SelectChangeEvent, Typography } from '@mui/material';
import CustomInput from '../../custom/CustomInput';
import LabelWrapper from '../../common/LabelWrapper';
import BoxComponent from '../../custom/BoxComponent';
import CustomRadio from '../../custom/CustomRadio';
import CustomMenuItem from '../../custom/CustomMenuItem';
import CustomSelect from '../../custom/CustomSelect';
import { SdtInputForcing } from '../../../models/inputTypes/SdtFields';
import { FileType } from '../../../models/inputTypes/FileType';
import ButtonFileDialog from '../../common/ButtonFileDialog';
import { sdtInputForcingInitialState } from '../../../utils/initialStates/sdtInputState';

const styles = {
  childSpacing: {
    '& >:nth-child(n)': {
      my: 1,
      mr: { xs: 2, md: 3, xl: 4 },
    },
  },
  childSpacingLarge: {
    '& >:nth-child(n)': {
      my: 1,
      mr: { xs: 2, md: 4, lg: 5 },
    },
  },
  wrap: {
    flexWrap: 'wrap',
  },
  flexStartBox: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
  },
} as const;

type InputsProps = {
  projectId: string;
  inputState: SdtInputForcing;
  sediment: string;
  calculationDisabled?: boolean;

  setInputState: (value: SdtInputForcing) => void;
};

const SdtInputForcingInputsGroup: FC<InputsProps> = ({
  calculationDisabled,
  projectId,
  sediment,
  inputState,
  setInputState,
}) => {
  const onInputChange = (
    event: SelectChangeEvent<unknown> | React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setInputState({ ...inputState, [event.target.name]: event.target.value });
  };

  const onNumberChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (event.target.name == 'fluxSources' && event.target.value == '0') {
      setInputState({
        ...inputState,
        fluxSources: event.target.value as any,
        startExternalSources: sdtInputForcingInitialState.startExternalSources,
        endExternalSources: sdtInputForcingInitialState.endExternalSources,
        I_Sources: undefined,
      });
      return;
    }
    setInputState({ ...inputState, [event.target.name]: event.target.value });
  };

  const onFileChange = (file: FileType | undefined, fieldName: string) => {
    setInputState({ ...inputState, [fieldName]: file });
  };

  const onChangeRadio = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputState({
      ...inputState,
      [e.target.name]: e.target.value,
      wavePeriod: sdtInputForcingInitialState.wavePeriod,
      wavePeriodFile: undefined,
    });
  };

  return (
    <Box>
      <Typography mb={1} variant={'subtitle1'}>
        Hydrodynamic field
      </Typography>
      <Box sx={{ ...styles.flexStartBox, ...styles.childSpacingLarge, ...styles.wrap }}>
        <Box>
          <LabelWrapper label={'Insert U-Velocity component file'}>
            <ButtonFileDialog
              typePage={'SDT'}
              dataFilesOnly
              required
              projectId={projectId}
              disabled={!projectId}
              file={inputState.uVelocityFile}
              setFile={(file) => onFileChange(file, 'uVelocityFile')}
            />
          </LabelWrapper>
        </Box>
        <Box>
          <LabelWrapper label={'Insert V-Velocity component file'}>
            <ButtonFileDialog
              typePage={'SDT'}
              dataFilesOnly
              required
              projectId={projectId}
              disabled={!projectId}
              file={inputState.vVelocityFile}
              setFile={(file) => onFileChange(file, 'vVelocityFile')}
            />
          </LabelWrapper>
        </Box>
        <Box>
          <LabelWrapper label={'Insert water depth file'}>
            <ButtonFileDialog
              typePage={'SDT'}
              dataFilesOnly
              required
              projectId={projectId}
              disabled={!projectId}
              file={inputState.waterDepthFile}
              setFile={(file) => onFileChange(file, 'waterDepthFile')}
            />
          </LabelWrapper>
        </Box>
      </Box>
      <Typography mt={1} variant={'subtitle1'}>
        Wave field
      </Typography>
      <Box sx={{ ...styles.flexStartBox, ...styles.childSpacingLarge, ...styles.wrap }}>
        <BoxComponent disabled={inputState.waveFieldType !== 'varying'}>
          <LabelWrapper label={'Insert wave period file'}>
            <ButtonFileDialog
              typePage={'SDT'}
              dataFilesOnly
              required
              projectId={projectId}
              disabled={!projectId || inputState.waveFieldType !== 'varying'}
              file={inputState.wavePeriodFile}
              setFile={(file) => onFileChange(file, 'wavePeriodFile')}
            />
          </LabelWrapper>
        </BoxComponent>
        <Box>
          <Typography variant={'subtitle2'}>Wave Period</Typography>
          <RadioGroup
            value={inputState.waveFieldType}
            onChange={onChangeRadio}
            aria-label="waveFieldType"
            name="waveFieldType"
          >
            <FormControlLabel
              labelPlacement={'end'}
              value="constant"
              control={<CustomRadio />}
              label={<Typography variant={'subtitle2'}>Constant</Typography>}
            />
            <FormControlLabel
              labelPlacement={'end'}
              value="varying"
              control={<CustomRadio />}
              label={<Typography variant={'subtitle2'}>Varying</Typography>}
            />
          </RadioGroup>
        </Box>
        <BoxComponent disabled={inputState.waveFieldType !== 'constant'}>
          <LabelWrapper label={'Wave period (s)'}>
            <CustomInput
              disabled={inputState.waveFieldType !== 'constant'}
              min={0.00000000001}
              required
              step={'any'}
              errorText={'Enter positive number'}
              type={'number'}
              onChange={onNumberChange}
              value={inputState.wavePeriod}
              name={'wavePeriod'}
            />
          </LabelWrapper>
        </BoxComponent>
      </Box>
      <Box mt={2} sx={{ ...styles.flexStartBox, ...styles.childSpacingLarge, ...styles.wrap }}>
        <BoxComponent>
          <LabelWrapper label={'Insert wave heights file'}>
            <ButtonFileDialog
              typePage={'SDT'}
              dataFilesOnly
              required
              projectId={projectId}
              disabled={!projectId}
              file={inputState.waveHeightFile}
              setFile={(file) => onFileChange(file, 'waveHeightFile')}
            />
          </LabelWrapper>
        </BoxComponent>
        <BoxComponent disabled={sediment !== '4'}>
          <LabelWrapper label={'Insert wave direction file'}>
            <ButtonFileDialog
              typePage={'SDT'}
              dataFilesOnly
              required
              projectId={projectId}
              disabled={!projectId || sediment !== '4'}
              file={inputState.waveDirectionFile}
              setFile={(file) => onFileChange(file, 'waveDirectionFile')}
            />
          </LabelWrapper>
        </BoxComponent>
        <Box>
          <LabelWrapper width={'200px'} label={'Calculation method of wave orbital velocity'}>
            <CustomSelect
              fullWidth
              onChange={onInputChange}
              value={inputState.waveVelocity}
              name={'waveVelocity'}
              defaultValue={''}
              displayEmpty
            >
              <CustomMenuItem value={'1'}>
                <Typography variant={'subtitle2'}>Spectral</Typography>
              </CustomMenuItem>
              <CustomMenuItem value={'2'}>
                <Typography variant={'subtitle2'}>Regular</Typography>
              </CustomMenuItem>
              <CustomMenuItem value={'3'}>
                <Typography variant={'subtitle2'}>High order strokes</Typography>
              </CustomMenuItem>
            </CustomSelect>
          </LabelWrapper>
        </Box>
      </Box>

      <Typography mt={2} mb={1} variant={'subtitle1'}>
        Sources
      </Typography>
      <BoxComponent disabled={calculationDisabled} sx={{ ...styles.flexStartBox, ...styles.childSpacing }}>
        <Box>
          <LabelWrapper maxWidth={'200px'} label={'Number of external sediment flux sources'}>
            <CustomInput
              min={0}
              step={1}
              disabled={calculationDisabled}
              required
              errorText={'Enter positive integer'}
              fullWidth
              type={'number'}
              onChange={onNumberChange}
              value={inputState.fluxSources}
              name={'fluxSources'}
            />
          </LabelWrapper>
        </Box>
        <BoxComponent disabled={inputState.fluxSources < 1}>
          <LabelWrapper maxWidth={'200px'} label={'Starting time step for external sources'}>
            <CustomInput
              disabled={inputState.fluxSources < 1}
              min={0}
              step={1}
              required
              errorText={'Enter positive integer'}
              fullWidth
              type={'number'}
              onChange={onNumberChange}
              value={inputState.startExternalSources}
              name={'startExternalSources'}
            />
          </LabelWrapper>
        </BoxComponent>
        <BoxComponent disabled={inputState.fluxSources < 1}>
          <LabelWrapper maxWidth={'200px'} label={'End time step for external sources'}>
            <CustomInput
              disabled={inputState.fluxSources < 1}
              min={0}
              step={1}
              required
              errorText={'Enter positive integer'}
              fullWidth
              type={'number'}
              onChange={onNumberChange}
              value={inputState.endExternalSources}
              name={'endExternalSources'}
            />
          </LabelWrapper>
        </BoxComponent>
        <ButtonFileDialog
          typePage={'SDT'}
          dataFilesOnly
          required
          projectId={projectId}
          disabled={!projectId || inputState.fluxSources < 1}
          file={inputState.I_Sources}
          setFile={(file) => onFileChange(file, 'I_Sources')}
        />
      </BoxComponent>
    </Box>
  );
};

export default memo(SdtInputForcingInputsGroup);
