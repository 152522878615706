import React from 'react';
import { LinearProgress, linearProgressClasses, LinearProgressProps, styled, Typography } from '@mui/material';
import { Box } from '@mui/material';

type customProgressProps = {
  title?: string;
  progresStatus: string;
  progresValue: number;
};

const CustomProgress = styled((props: LinearProgressProps & customProgressProps) => (
  <Box sx={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
    <Box sx={{ width: '100%', mr: 1 }}>
      <LinearProgress variant="determinate" {...props} />
    </Box>
    <Typography color="white" variant="subtitle2" sx={{ position: 'absolute', width: '100%' }}>{`${Math.round(
      props.progresValue,
    )}%`}</Typography>
  </Box>
))(({ theme, progresStatus: progresStatus }) => ({
  height: '16px',
  borderRadius: '99px',
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.info.light,
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: '0px 99px 99px 0px',
    backgroundColor: progresStatus === 'failed' ? theme.palette.info.main : theme.palette.success.main,
  },
}));

export default CustomProgress;
