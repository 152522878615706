import React, { FC } from 'react';
import { Typography, TypographyProps, Box } from '@mui/material';
import { ReactComponent as CpuIcon } from '../../assets/images/CpuIcon.svg';
import CustomSpaceProgress from '../custom/CustomSpaceProgress';

type StorageComponentProps = {
  label?: string;
  usedCount?: number;
  totalCount?: number;
  progress: number;
};

const StorageComponent: FC<StorageComponentProps & TypographyProps> = ({
  label = 'vCPUs',
  usedCount = 0,
  totalCount = 0,
  ...props
}) => {
  return (
    <Box pt={'8px'}>
      <Box sx={{ display: 'flex', padding: '0px 0px 11px 0px', gap: '11px', alignItems: 'center' }}>
        <CpuIcon />
        <Typography variant={'subtitle1'}>vCPUs</Typography>
      </Box>
      <CustomSpaceProgress value={props.progress} sx={{ width: '228px' }} />
      <Typography variant={'caption'}>
        {usedCount} vCPUs of {totalCount} vCPUs used
      </Typography>
    </Box>
  );
};

export default StorageComponent;
