import React, { FC } from 'react';
import { CircularProgress, Select, SelectProps, styled } from '@mui/material';
import { ReactComponent as SelectArrow } from '../../assets/images/selectArrowDown.svg';
import { Box } from '@mui/system';

type customSelectProps = {
  minWidth?: string;
  isLoading?: boolean;
};

const MenuProps = {
  PaperProps: {
    sx: {
      '&::-webkit-scrollbar': {
        width: '6px',
      },
      '&::-webkit-scrollbar-track': {
        background: 'rgba(0,0,0,0.05)',
        borderBottomRightRadius: '50%',
      },
      '&::-webkit-scrollbar-thumb': {
        bgcolor: 'info.light',
        borderRadius: 7,
      },
      maxHeight: '220px',
    },
    style: {
      transform: 'translateY(-4px)',
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      borderBottomRightRadius: 4,
      borderBottomLeftRadius: 4,
      boxShadow: '-2px -2px 0px -1px #B6B6B6 inset,2px -2px 0px -1px #B6B6B6 inset',
    },
  },
};

const MiniProgress: FC = () => (
  <Box sx={{ position: 'absolute', right: 8, top: 14 }}>
    <CircularProgress color={'info'} size={12} />
  </Box>
);

const CustomSelect = styled((props: SelectProps & customSelectProps) => (
  <Select MenuProps={MenuProps} IconComponent={props.isLoading ? MiniProgress : SelectArrow} {...props} />
))(({ theme, minWidth, disabled }) => ({
  ...theme.typography.subtitle2,
  background: 'white',
  opacity: disabled ? 0.4 : 1,
  minWidth: minWidth ? minWidth : 'auto',
  '& .MuiOutlinedInput-notchedOutline': {
    border: `1px solid ${theme.palette.info.light} !important`,
  },
  '& .MuiInputBase-input': {
    padding: '12px 8px 8px 8px',
  },
}));

export default CustomSelect;
