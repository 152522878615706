import React, { FC, memo } from 'react';
import { Box, Typography } from '@mui/material';
import CustomInput from '../../custom/CustomInput';
import LabelWrapper from '../../common/LabelWrapper';
import CustomRadioButton from '../../custom/CustomRadioButton';
import BoxComponent from '../../custom/BoxComponent';
import { SdtModelDefinition } from '../../../models/inputTypes/SdtFields';
import { sdtModelDefinitionInitialState } from '../../../utils/initialStates/sdtInputState';

const styles = {
  childSpacing: {
    '& >:nth-child(n)': {
      my: 1,
      mr: { xs: 2, md: 3, xl: 4 },
    },
  },
  flexStartBox: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
} as const;

type InputsProps = {
  inputState: SdtModelDefinition;
  setInputState: (value: SdtModelDefinition) => void;
};

const SdtModelDefinitionInputsGroup: FC<InputsProps> = ({ inputState, setInputState }) => {
  const onNumberChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setInputState({ ...inputState, [event.target.name]: event.target.value });
  };

  const onRadioClick = (value: any) => {
    setInputState({
      ...inputState,
      stationaryHyd: sdtModelDefinitionInitialState.stationaryHyd,
      varyingHyd: sdtModelDefinitionInitialState.varyingHyd,
      varyingWave: sdtModelDefinitionInitialState.varyingWave,
      stationaryWave: sdtModelDefinitionInitialState.stationaryWave,
      simulationTime: sdtModelDefinitionInitialState.simulationTime,
      calculationType: value,
    });
  };

  return (
    <Box>
      <Typography mb={1} variant={'subtitle1'}>
        Calculation Mode
      </Typography>
      <Box sx={{ ...styles.flexStartBox, ...styles.childSpacing }}>
        <Box width={'110px'}>
          <CustomRadioButton
            checked={inputState.calculationType === 'stationary'}
            value={'stationary'}
            onButtonClick={onRadioClick}
            textLabel={'Stationary'}
          />
        </Box>
        <BoxComponent disabled={inputState.calculationType !== 'stationary'} width={'240px'}>
          <LabelWrapper label={'Specify frame in HYD input files'}>
            <CustomInput
              min={1}
              required
              step={1}
              errorText={'Enter positive integer'}
              fullWidth
              type={'number'}
              onChange={onNumberChange}
              value={inputState.stationaryHyd}
              name={'stationaryHyd'}
            />
          </LabelWrapper>
        </BoxComponent>
        <BoxComponent disabled={inputState.calculationType !== 'stationary'} width={'240px'}>
          <LabelWrapper label={'Specify frame in Wave input files'}>
            <CustomInput
              min={1}
              required
              step={1}
              errorText={'Enter positive integer'}
              fullWidth
              type={'number'}
              onChange={onNumberChange}
              value={inputState.stationaryWave}
              name={'stationaryWave'}
            />
          </LabelWrapper>
        </BoxComponent>
      </Box>
      <Box sx={{ ...styles.flexStartBox, ...styles.childSpacing }}>
        <Box width={'110px'}>
          <CustomRadioButton
            checked={inputState.calculationType === 'varying'}
            value={'varying'}
            onButtonClick={onRadioClick}
            textLabel={'Time varying'}
          />
        </Box>
        <BoxComponent disabled={inputState.calculationType !== 'varying'} width={'240px'}>
          <LabelWrapper label={'Number of frames in HYD input file'}>
            <CustomInput
              min={1}
              required
              step={1}
              errorText={'Enter positive integer'}
              fullWidth
              type={'number'}
              onChange={onNumberChange}
              value={inputState.varyingHyd}
              name={'varyingHyd'}
            />
          </LabelWrapper>
        </BoxComponent>
        <BoxComponent disabled={inputState.calculationType !== 'varying'} width={'240px'}>
          <LabelWrapper label={'Number of frames in Wave input files'}>
            <CustomInput
              min={1}
              required
              step={1}
              errorText={'Enter positive integer'}
              fullWidth
              type={'number'}
              onChange={onNumberChange}
              value={inputState.varyingWave}
              name={'varyingWave'}
            />
          </LabelWrapper>
        </BoxComponent>
      </Box>

      <Typography my={1} variant={'subtitle1'}>
        Time Parameters
      </Typography>

      <BoxComponent sx={{ ...styles.flexStartBox, ...styles.childSpacing }}>
        <Box>
          <LabelWrapper label={'Time step(s)'}>
            <CustomInput
              min={0.00000000001}
              required
              step={'any'}
              errorText={'Enter positive number'}
              fullWidth
              type={'number'}
              onChange={onNumberChange}
              value={inputState.timeStep}
              name={'timeStep'}
            />
          </LabelWrapper>
        </Box>
        <BoxComponent disabled={inputState.calculationType == 'stationary'}>
          <LabelWrapper label={'Simulation time(s)'}>
            <CustomInput
              min={0.00000000001}
              required
              disabled={inputState.calculationType == 'stationary'}
              step={'any'}
              errorText={'Enter positive number'}
              fullWidth
              type={'number'}
              onChange={onNumberChange}
              value={inputState.simulationTime}
              name={'simulationTime'}
            />
          </LabelWrapper>
        </BoxComponent>
      </BoxComponent>
    </Box>
  );
};

export default memo(SdtModelDefinitionInputsGroup);
