import { plotAreasInitialState } from '../initialStates/pmsInputStates';
import { PlotArea } from '../../models/inputTypes/PmsFields';

export const plotAreaArrayPreparation = (plotAreaValues: PlotArea[], newValue: number, customPlotArea?: PlotArea) => {
  const initialPlotArea = customPlotArea ?? plotAreasInitialState;
  const tempArray = plotAreaValues;
  const tempLength = plotAreaValues.length;
  if (tempLength !== newValue) {
    if (newValue > tempLength) {
      for (let i = tempLength; i < newValue; i++) {
        tempArray.push({
          ...initialPlotArea,
          name_c: initialPlotArea.name_c + `_${i + 1}`,
          name_h: initialPlotArea.name_h + `_${i + 1}`,
          name_z: initialPlotArea.name_z + `_${i + 1}`,
          name_dir: initialPlotArea.name_dir + `_${i + 1}`,
          name_rad_xx: initialPlotArea.name_rad_xx + `_${i + 1}`,
          name_rad_xy: initialPlotArea.name_rad_xy + `_${i + 1}`,
          name_rad_yy: initialPlotArea.name_rad_yy + `_${i + 1}`,
          name_init_depths: initialPlotArea.name_init_depths + `_${i + 1}`,
        });
      }
    } else {
      for (let i = 0; i < tempLength - newValue; i++) {
        tempArray.pop();
      }
    }
  }
  return tempArray;
};
