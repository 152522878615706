import React, { FC, memo, useMemo, useState } from 'react';
import { Box, FormControlLabel, RadioGroup, SelectChangeEvent, Typography } from '@mui/material';
import CustomMenuItem from '../../custom/CustomMenuItem';
import CustomSelect from '../../custom/CustomSelect';
import CustomInputWithLabel from '../../common/CustomInputWithLabel';
import LabelWrapper from '../../common/LabelWrapper';
import CustomSwitch from '../../custom/CustomSwitch';
import CustomRadio from '../../custom/CustomRadio';
import CustomInput from '../../custom/CustomInput';
import { FileType } from '../../../models/inputTypes/FileType';
import ButtonFileDialog from '../../common/ButtonFileDialog';
import { MarisPlotDataSelection } from '../../../models/inputTypes/MarisPlotFields';
import BoxComponent from '../../custom/BoxComponent';
import { useGetProjectsQuery } from '../../../redux/RTK/queries/projectQuery';
import { marisPlotDataSelectionInitialState } from '../../../utils/initialStates/marisPlotState';
import { MarisSimulationPreparationResponse } from '../../../utils/simulationFileToState/plotSimulationPreparation';
import { useHistory, useParams } from 'react-router';
import SimulationFileContainer, {
  SimulationContainerSetter,
} from '../../common/fileComponents/SimulationFileContainer';
import { ModelEnum } from '../../../models/types/ModelEnum';
import BathymetricFileContainer from '../../common/fileComponents/BathymetricFileContainer';

const styles = {
  itemSpacing: {
    '& >:nth-child(n)': {
      my: 1.5,
      mr: 4,
    },
  },
  flexEndBox: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  wrap: {
    flexWrap: 'wrap',
  },
  flexStartBox: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  coordinates: {
    '& >:nth-child(2)': {
      ml: 1,
    },
  },
} as const;

type InputsProps = {
  inputState: MarisPlotDataSelection;
  setInputState: (value: MarisPlotDataSelection) => void;
  simulationChangeFile: (value: MarisSimulationPreparationResponse, file?: FileType) => void;
};

const MarisPlotDataSelectionInputsGroup: FC<InputsProps> = ({ simulationChangeFile, inputState, setInputState }) => {
  const { name, fileId } = useParams<{ id?: string; name?: string; fileId?: string }>();
  const history = useHistory();
  const [valueX, setValueX] = useState(1);
  const [valueY, setValueY] = useState(1);
  const { data, isFetching } = useGetProjectsQuery({});

  const projectItems = useMemo(() => {
    if (data?.length) {
      return data.map((item, i) => (
        <CustomMenuItem key={i} value={item.id}>
          <Typography variant={'subtitle2'}>{item.name} </Typography>
        </CustomMenuItem>
      ));
    }
  }, [data]);

  const onInputChange = (
    event: SelectChangeEvent<unknown> | React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setInputState({ ...inputState, [event.target.name]: event.target.value, plotSetupFile: undefined });
    if (name && fileId) {
      history.push(`/plots-animation/maris-plot`);
    }
  };

  const onNumberChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (event.target.name === 'numericalX') {
      setInputState({ ...inputState, [event.target.name]: +event.target.value, nePointX: +event.target.value });
      return;
    }
    if (event.target.name === 'numericalY') {
      setInputState({ ...inputState, numericalY: +event.target.value, nePointY: +event.target.value });
      return;
    }
    setInputState({ ...inputState, [event.target.name]: event.target.value });
  };

  const onFileChange = (file: FileType | undefined, fieldName: string) => {
    setInputState({ ...inputState, [fieldName]: file });
  };

  const onChangeSwitch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputState({ ...inputState, [e.target.name]: e.target.checked, directionFile: undefined });
  };

  const onChangeRadio = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputState({ ...inputState, [e.target.name]: e.target.value, frame: marisPlotDataSelectionInitialState.frame });
  };

  const onSimulationFileChange = ({ loadedSimulation, file }: SimulationContainerSetter) => {
    if (loadedSimulation && file) {
      return simulationChangeFile(loadedSimulation, file);
    }
    setInputState({ ...inputState, plotSetupFile: undefined });
  };
  const onBathymetricFileChange = ({ file, cellX, cellY }: { file: FileType; cellX?: number; cellY?: number }) => {
    if (!cellX || !cellY) return setInputState({ ...inputState, dataFile: file });
    setInputState({
      ...inputState,
      dataFile: file,
      numericalX: cellX,
      numericalY: cellY,
      nePointY: cellY,
      nePointX: cellX,
    });
  };

  const onInputChangeX = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setValueX(+event.target.value);
    setInputState({ ...inputState, [event.target.name]: event.target.value });
  };
  const onInputChangeY = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setValueY(+event.target.value);
    setInputState({ ...inputState, [event.target.name]: event.target.value });
  };

  return (
    <>
      <Box sx={{ ...styles.flexStartBox, ...styles.itemSpacing, ...styles.wrap }}>
        <Box>
          <LabelWrapper label={'Select project'}>
            <CustomSelect
              required
              minWidth={'180px'}
              onChange={onInputChange}
              value={inputState.project}
              isLoading={isFetching}
              name={'project'}
              defaultValue={''}
              displayEmpty
            >
              <CustomMenuItem value={''}>
                <Typography variant={'subtitle2'}>select project</Typography>
              </CustomMenuItem>
              {projectItems}
            </CustomSelect>
          </LabelWrapper>
        </Box>
        <Box>
          <LabelWrapper label={'Insert plot setup'}>
            <SimulationFileContainer
              typePage={'PLOT'}
              modelType={ModelEnum.PLOT}
              redirectLocation={`/plots-animation/maris-plot`}
              project={inputState.project}
              file={inputState.plotSetupFile}
              setSimulationInputs={onSimulationFileChange}
            />
          </LabelWrapper>
        </Box>
        <Box>
          <LabelWrapper label={'Insert data file'}>
            <BathymetricFileContainer
              typePage={'PLOT'}
              requestDims={!inputState.plotSetupFile}
              setBathymetricInputs={onBathymetricFileChange}
              project={inputState.project}
              file={inputState.dataFile}
            />
          </LabelWrapper>
        </Box>
      </Box>

      <Box sx={{ ...styles.flexStartBox, ...styles.itemSpacing }}>
        <Box>
          <LabelWrapper width={'140px'} label={'Plot vectors'}>
            <CustomSwitch checked={inputState.plotVector} name={'plotVector'} onChange={onChangeSwitch} />
          </LabelWrapper>
        </Box>
        <BoxComponent disabled={!inputState.plotVector}>
          <LabelWrapper label={'Insert direction file'}>
            <ButtonFileDialog
              typePage={'PLOT'}
              dataFilesOnly
              required
              projectId={inputState.project}
              disabled={!inputState.project || !inputState.plotVector}
              file={inputState.directionFile}
              setFile={(file) => onFileChange(file, 'directionFile')}
            />
          </LabelWrapper>
        </BoxComponent>
      </Box>
      <Box sx={{ ...styles.flexEndBox, ...styles.wrap }} mr={4}>
        <Box>
          <Typography mt={1} variant={'subtitle1'}>
            Numerical Domain
          </Typography>
          <Box sx={{ ...styles.flexStartBox, ...styles.itemSpacing }}>
            <Box>
              <LabelWrapper label={'Number of cells'}>
                <Box sx={{ ...styles.flexStartBox, ...styles.coordinates }}>
                  <CustomInputWithLabel
                    min={0}
                    required
                    step={'any'}
                    errorText={'Enter positive number'}
                    type={'number'}
                    onChange={onNumberChange}
                    value={inputState.numericalX}
                    name={'numericalX'}
                    label={'X'}
                  />
                  <CustomInputWithLabel
                    min={0}
                    required
                    step={'any'}
                    errorText={'Enter positive number'}
                    type={'number'}
                    onChange={onNumberChange}
                    value={inputState.numericalY}
                    name={'numericalY'}
                    label={'Y'}
                  />
                </Box>
              </LabelWrapper>
            </Box>
            <Box>
              <LabelWrapper label={'Cell Size (m)'}>
                <Box sx={{ ...styles.flexStartBox, ...styles.coordinates }}>
                  <CustomInputWithLabel
                    min={0}
                    required
                    step={'any'}
                    errorText={'Enter positive number'}
                    type={'number'}
                    onChange={onNumberChange}
                    value={inputState.numericalDX}
                    name={'numericalDX'}
                    label={'DX'}
                  />
                  <CustomInputWithLabel
                    min={0}
                    required
                    step={'any'}
                    errorText={'Enter positive number'}
                    type={'number'}
                    onChange={onNumberChange}
                    value={inputState.numericalDY}
                    name={'numericalDY'}
                    label={'DY'}
                  />
                </Box>
              </LabelWrapper>
            </Box>
          </Box>
        </Box>

        <Box>
          <Typography mt={1} variant={'subtitle1'}>
            Subarea bounding points
          </Typography>
          <Box sx={{ ...styles.flexStartBox, ...styles.itemSpacing }}>
            <Box>
              <LabelWrapper label={'Southwestern point'}>
                <Box sx={{ ...styles.flexStartBox, ...styles.coordinates }}>
                  <CustomInputWithLabel
                    min={1}
                    required
                    step={1}
                    errorText={'Enter positive integer'}
                    type={'number'}
                    onChange={onNumberChange}
                    value={inputState.swPointX}
                    name={'swPointX'}
                    label={'X'}
                  />
                  <CustomInputWithLabel
                    min={1}
                    required
                    step={1}
                    errorText={'Enter positive integer'}
                    type={'number'}
                    onChange={onNumberChange}
                    value={inputState.swPointY}
                    name={'swPointY'}
                    label={'Y'}
                  />
                </Box>
              </LabelWrapper>
            </Box>
            <Box>
              <LabelWrapper label={'Northeastern point'}>
                <Box sx={{ ...styles.flexStartBox, ...styles.coordinates }}>
                  <CustomInputWithLabel
                    min={inputState.swPointX}
                    max={inputState.numericalX}
                    required
                    step={1}
                    errorText={
                      inputState.numericalX < valueX
                        ? 'Number should be <= than total grid length'
                        : inputState.swPointX > valueX
                        ? 'Number should be >= than first point'
                        : 'Enter positive integer'
                    }
                    type={'number'}
                    onChange={onInputChangeX}
                    value={inputState.nePointX}
                    name={'nePointX'}
                    label={'X'}
                  />
                  <CustomInputWithLabel
                    min={inputState.swPointY}
                    max={inputState.numericalY}
                    required
                    step={1}
                    errorText={
                      inputState.numericalY < valueY
                        ? 'Number should be <= than total grid length'
                        : inputState.swPointY > valueY
                        ? 'Number should be >= than first point'
                        : 'Enter positive integer'
                    }
                    type={'number'}
                    onChange={onInputChangeY}
                    value={inputState.nePointY}
                    name={'nePointY'}
                    label={'Y'}
                  />
                </Box>
              </LabelWrapper>
            </Box>
          </Box>
        </Box>

        <Box>
          <Typography mb={2} mt={1} variant={'subtitle1'}>
            Subseries Selection
          </Typography>
          <Box sx={{ ...styles.flexStartBox }}>
            <Box>
              <RadioGroup
                aria-label="subSeriesType"
                name="subSeriesType"
                value={inputState.subSeriesType}
                onChange={onChangeRadio}
              >
                <FormControlLabel
                  labelPlacement={'end'}
                  value="stationary"
                  control={<CustomRadio />}
                  label={<Typography variant={'subtitle2'}>Stationary</Typography>}
                />
                <FormControlLabel
                  labelPlacement={'end'}
                  value="time-dependent"
                  control={<CustomRadio />}
                  label={<Typography variant={'subtitle2'}>Specify frame</Typography>}
                />
              </RadioGroup>
            </Box>
            <BoxComponent disabled={inputState.subSeriesType !== 'time-dependent'}>
              <LabelWrapper label={'Frame'}>
                <CustomInput
                  required
                  min={0}
                  step={'any'}
                  type={'number'}
                  onChange={onNumberChange}
                  value={inputState.frame}
                  name={'frame'}
                />
              </LabelWrapper>
            </BoxComponent>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default memo(MarisPlotDataSelectionInputsGroup);
