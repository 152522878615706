import React, { FC, memo, useEffect, useMemo, useState } from 'react';
import CustomSelect from '../../custom/CustomSelect';
import CustomSwitch from '../../custom/CustomSwitch';
import CustomMenuItem from '../../custom/CustomMenuItem';
import { Box, Grid, SelectChangeEvent, Typography } from '@mui/material';
import { useGetProjectsQuery } from '../../../redux/RTK/queries/projectQuery';
import { useHistory } from 'react-router';
import CustomInput from '../../custom/CustomInput';
import useDebounce from '../../../utils/hooks/debounce';

type UploadFilesProjectProps = {
  setDebounce: (search: string) => void;
  checked: boolean;
  setChecked: (val: boolean) => void;
  filePerProject: string;
  setFilePerProject: (value: string) => void;
};

const FilesPerProject: FC<UploadFilesProjectProps> = ({ setDebounce, checked, setChecked, filePerProject }) => {
  const history = useHistory();

  const { data, isFetching } = useGetProjectsQuery({});
  const [search, setSearch] = useState<string>('');
  const debouncedSearch = useDebounce(search, 500);

  useEffect(() => {
    setDebounce(debouncedSearch);
  }, [debouncedSearch]);

  useEffect(() => {
    if (filePerProject) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }, [filePerProject]);

  const projectItems = useMemo(() => {
    if (data?.length) {
      return data.map((item, i) => (
        <CustomMenuItem key={i} value={item.id}>
          <Typography variant={'subtitle2'}>{item.name} </Typography>
        </CustomMenuItem>
      ));
    }
  }, [data]);

  const onInputChange = (event: SelectChangeEvent<any>) => {
    const value = event.target.value;
    history.push(`/projects/${value}`);
  };

  const onSwitchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(e.target.checked);
  };

  return (
    <Grid container>
      <Box sx={{ display: 'flex', columnGap: '32px', flexWrap: 'wrap' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: '8px', flexWrap: 'wrap' }}>
          <Typography variant="subtitle2">Select project</Typography>
          <CustomSelect
            displayEmpty
            minWidth="180px"
            title={'Select Project'}
            value={filePerProject}
            isLoading={isFetching}
            onChange={onInputChange}
          >
            <CustomMenuItem value={''}>
              <Typography variant={'subtitle2'}>select project</Typography>
            </CustomMenuItem>
            {projectItems}
          </CustomSelect>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: '16px', flexWrap: 'wrap' }}>
          <Typography variant="subtitle2">Show files</Typography>
          <CustomSwitch checked={checked} onChange={onSwitchChange} disabled={!filePerProject} />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'end', flexDirection: 'column', flexWrap: 'wrap' }}>
          <CustomInput placeholder={'Search'} value={search} onChange={(e) => setSearch(e.target.value)} />
        </Box>
      </Box>
    </Grid>
  );
};
export default memo(FilesPerProject);
