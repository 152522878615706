import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Dialog, DialogContent, DialogProps, Grid, Theme, ButtonBase } from '@mui/material';
import PageBreadCrumbs from '../common/PageBreadCrumbs';
import { FileForm } from '../../models/inputTypes/FileType';
import {
  performGetFrame,
  performGetHeatmap,
  performPutFile,
  resetEditFile,
  selectEditFileError,
  selectEditFileFrame,
  selectEditFileHeatmap,
  selectEditFileLoading,
  selectEditFileSaved,
  setLoadingEditFile,
  setSavedFile,
} from '../../redux/slices/editFileSlice';
import { putFile } from '../../axios/endpoints/editFile';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { HotKeys } from 'react-hotkeys';
import FileSaveCard from '../groups/heatMapModal/FileSaveCard';
import FileInputsCard from '../groups/heatMapModal/FileInputsCard';
import FileNameCard from '../groups/heatMapModal/FileNameCard';
import FileHeatmapCard from '../groups/heatMapModal/FileHeatmapCard';
import FileTimeframeCard from '../groups/heatMapModal/FileTimeFrameCard';
import { useGetProjectsQuery } from '../../redux/RTK/queries/projectQuery';
import { ReactComponent as Close } from '../../assets/images/close.svg';

const styles = {
  dialog: {
    overflowY: 'visible',
    height: '100%',
    maxHeight: 'calc(100% - 16px)',
  },
  closeButton: {
    position: 'absolute',
    right: -10,
    top: -5,
    boxShadow: '0px 0px 5px 1px gray',
    zIndex: '200000 !important',
    borderRadius: '50%',
    width: '35px',
    padding: 0,
    minWidth: 0,
    color: (theme: Theme) => theme.palette.info.dark,
    background: 'white',
    height: '35px',
  },
  gridContainer: {
    minHeight: '100%',
  },
} as const;

interface CustomHeatmapModalProps {
  handleClose: () => void;
  file?: FileForm;
  open: boolean;
}

const HeatmapModal: FC<CustomHeatmapModalProps & Exclude<DialogProps, 'open'>> = ({
  open,
  file,
  handleClose,
  ...props
}) => {
  const dispatch = useAppDispatch();
  const fileName = useMemo(() => file?.name || '', [file]);
  const projectId = useMemo(() => file?.project.toString() || '', [file]);
  const { data: projects } = useGetProjectsQuery({});

  const [frameCoords, setFrameCoords] = useState<{ x: number; y: number }>({ x: -1, y: -1 });
  const [min, setMin] = useState<number | undefined>();
  const [max, setMax] = useState<number | undefined>();
  const [levels, setLevels] = useState<number | undefined>();
  const [color, setColor] = useState<string>('rainbow');
  const [mask, setMask] = useState<any | undefined>();
  const [operation, setOperation] = useState<any | undefined>();
  const [value, setValue] = useState<number | undefined>(undefined);
  const [checkValue, setCheckValue] = useState<number | undefined>(undefined);
  const [timeframe, setTimeframe] = useState<number>(1);
  const [grid, setGrid] = useState<any[][]>();
  const [gridEditHistory, setGridEditHistory] = useState<any[][][]>([]);
  const [saveName, setSaveName] = useState<string | undefined>(fileName);
  const [autoSave, setAutoSave] = useState<boolean>(false);
  const [replaceValueType, setReplaceValueType] = useState<'table' | 'file'>('file');

  const project = useMemo(() => projects?.find((item) => item.id === +projectId), [projects]);

  const heatmap = useAppSelector(selectEditFileHeatmap);
  const frame = useAppSelector(selectEditFileFrame);
  const editFileError = useAppSelector(selectEditFileError);
  const editFileSaved = useAppSelector(selectEditFileSaved);
  const editFileLoading = useAppSelector(selectEditFileLoading);
  const beforeUnload = useCallback(
    (event) => {
      if (gridEditHistory.length > 0) {
        event.preventDefault();
        if (autoSave) {
          handleAutoSave();
        }
        const confirmationMessage = 'Changes that you made may not be saved.';
        event.returnValue = confirmationMessage;
        return confirmationMessage;
      }
    },
    [gridEditHistory, autoSave],
  );

  const undoShortcut = useCallback(() => {
    const gridHistory = gridEditHistory.slice();
    if (gridHistory.length > 0) {
      gridHistory.pop();
      setGridEditHistory(gridHistory);
      if (gridHistory.length > 0) {
        setGrid(gridHistory[gridHistory.length - 1]);
      } else {
        setGrid(frame.points);
      }
    }
  }, [gridEditHistory, frame]);

  const getOperation = () => {
    if (operation === '<') {
      return {
        '<': operation,
        type: replaceValueType,
        check_value: checkValue,
        set_value: value,
      };
    } else if (operation === '=') {
      return {
        '=': operation,
        type: replaceValueType,
        check_value: checkValue,
        set_value: value,
      };
    } else {
      return {
        '>': operation,
        type: replaceValueType,
        check_value: checkValue,
        set_value: value,
      };
    }
  };

  useEffect(() => {
    window.addEventListener('beforeunload', beforeUnload);

    return () => {
      window.removeEventListener('beforeunload', beforeUnload);
    };
  }, [beforeUnload, gridEditHistory, autoSave]);

  useEffect(() => {
    return () => {
      dispatch(resetEditFile());
    };
  }, []);

  useEffect(() => {
    if (heatmap) {
      if (frameCoords.x == -1 || frameCoords.y === -1) {
        setFrameCoords({ x: 0, y: heatmap.dimensions?.[0] });
        dispatch(performGetFrame(projectId, fileName, timeframe, 0, heatmap.dimensions?.[0]));
      }
    }
  }, [heatmap]);

  useEffect(() => {
    setFrameCoords({ x: -1, y: -1 });
    setGrid(undefined);
    const replace_operation = getOperation();

    dispatch(performGetHeatmap(projectId, fileName, max, min, levels, color, mask, replace_operation, timeframe));
  }, [timeframe]);

  useEffect(() => {
    const replace_operation = getOperation();

    if (frameCoords.x >= 0 && frameCoords.y >= 0) {
      dispatch(performGetFrame(projectId, fileName, timeframe, frameCoords?.x, frameCoords?.y));
      if (gridEditHistory.length > 0) {
        dispatch(performGetHeatmap(projectId, fileName, max, min, levels, color, mask, replace_operation, timeframe));
      }
    }
  }, [frameCoords]);

  useEffect(() => {
    if (frame) {
      setGridEditHistory([]);
      setGrid(frame.points);
    }
  }, [frame]);

  useEffect(() => {
    const replace_operation = getOperation();

    if (editFileSaved?.destinationFile) {
      dispatch(setSavedFile(undefined));
      handleClose();
    } else if (editFileSaved?.fileName) {
      const projectid = `${editFileSaved.projectId}`;
      const filename = `${editFileSaved.fileName}`;
      dispatch(setSavedFile(undefined));
      if (projectId === projectid && fileName === filename) {
        dispatch(performGetHeatmap(projectId, fileName, max, min, levels, color, mask, replace_operation, timeframe));
      }
    }
  }, [editFileSaved]);

  const handleSave = async () => {
    const replace_operation = getOperation();
    await dispatch(
      performPutFile(
        projectId,
        fileName,
        saveName !== fileName ? saveName : undefined,
        timeframe,
        grid,
        frameCoords?.x,
        frameCoords?.y,
        replace_operation,
      ),
    );
    await dispatch(performGetFrame(projectId, fileName, timeframe, frameCoords?.x, frameCoords?.y));
  };

  const handleAutoSave = (nextCoords?: { x: number; y: number } | undefined, nextTimeframe?: number) => {
    const replace_operation = getOperation();
    if (editFileLoading.save) return;
    dispatch(setLoadingEditFile({ key: 'save', value: true }));
    dispatch(setLoadingEditFile({ key: 'heatmap', value: true }));
    if (nextCoords || nextTimeframe) {
      dispatch(setLoadingEditFile({ key: 'frame', value: true }));
    }
    putFile(projectId, fileName, undefined, timeframe, grid, frameCoords?.x, frameCoords?.y, replace_operation)
      .then((r) => {
        setGridEditHistory([]);
        dispatch(setLoadingEditFile({ key: 'save', value: false }));
        if (nextTimeframe) {
          setTimeframe(nextTimeframe);
        } else {
          const replace_operation = getOperation();
          dispatch(performGetHeatmap(projectId, fileName, max, min, levels, color, mask, replace_operation, timeframe));
        }
        if (nextCoords) {
          setFrameCoords(nextCoords);
        }
      })
      .catch((e) => {
        dispatch(setLoadingEditFile({ key: 'save', value: false }));
        dispatch(setLoadingEditFile({ key: 'heatmap', value: false }));
        if (nextCoords || nextTimeframe) {
          dispatch(setLoadingEditFile({ key: 'frame', value: false }));
        }
      });
  };

  const handlePlot = () => {
    const replace_operation = getOperation();

    dispatch(
      performGetHeatmap(
        projectId,
        fileName,
        max,
        min,
        levels,
        color,
        mask,
        replace_operation,
        timeframe,
        grid,
        frameCoords?.x,
        frameCoords?.y,
      ),
    );
  };

  const handleEditGrid = (values: any[][]) => {
    const gridHistory = gridEditHistory.slice();
    gridHistory.push(values);
    setGridEditHistory(gridHistory);
    setGrid(values);
  };

  const handleSetFrameCoords = (values: { x: number; y: number }) => {
    if (values.x >= 0 && values.y >= 0 && (frameCoords.x !== values?.x || frameCoords.y !== values?.y)) {
      if (autoSave && gridEditHistory.length > 0) {
        handleAutoSave(values);
      } else {
        setFrameCoords(values);
      }
    }
  };

  const handleSetTimeframe = (value: number) => {
    if (autoSave && gridEditHistory.length > 0) {
      handleAutoSave(undefined, value);
    } else {
      setTimeframe(value);
    }
  };

  const keyMap = {
    UNDO: ['command+z', 'ctrl+z'],
  };

  const handlers = {
    UNDO: undoShortcut,
  };

  return (
    <Dialog
      maxWidth={false}
      PaperProps={{ style: styles.dialog }}
      fullWidth
      onClose={handleClose}
      open={open}
      {...props}
    >
      <ButtonBase sx={styles.closeButton} onClick={handleClose}>
        <Close />
      </ButtonBase>
      <DialogContent>
        <HotKeys keyMap={keyMap} handlers={handlers} allowChanges>
          <Grid sx={styles.gridContainer} container spacing={2} justifyContent={'space-between'}>
            <Grid item xs={4} container spacing={2} alignContent={'flex-start'}>
              <Grid item container xs={12} spacing={2}>
                <Grid item xs={12}>
                  <PageBreadCrumbs
                    path={[
                      'Projects',
                      project?.name?.toString() ?? file?.project.toString() ?? 'unavailable',
                      file?.name ?? 'unavailable',
                    ]}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FileNameCard
                    dimensions={heatmap?.dimensions}
                    projectName={project?.name?.toString() ?? file?.project.toString() ?? 'unavailable'}
                    fileName={fileName}
                  />
                </Grid>
              </Grid>
              <Grid item container xs={12} spacing={2}>
                <Grid item xs={12}>
                  <FileInputsCard
                    disabled={editFileLoading.heatmap}
                    min={min}
                    setMin={setMin}
                    max={max}
                    setMax={setMax}
                    levels={levels}
                    setLevels={setLevels}
                    color={color}
                    setColor={setColor}
                    mask={mask}
                    setMask={setMask}
                    onPlot={handlePlot}
                  />
                </Grid>
              </Grid>
              <Grid item container xs={12} spacing={2}>
                <Grid item xs={12}>
                  <FileSaveCard
                    timeframe={heatmap?.totalTimeFrames}
                    replaceValue={replaceValueType}
                    setReplaceValueType={setReplaceValueType}
                    operation={operation}
                    setOperation={setOperation}
                    value={value}
                    setValue={setValue}
                    checkValue={checkValue}
                    setCheckValue={setCheckValue}
                    saveName={saveName}
                    setSaveName={setSaveName}
                    onSave={handleSave}
                    saveLoading={editFileLoading.save}
                    autoSave={autoSave}
                    setAutoSave={setAutoSave}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={8} container spacing={2} alignContent={'space-between'}>
              <Grid item xs={12}>
                <FileTimeframeCard
                  timeframe={timeframe}
                  setTimeframe={handleSetTimeframe}
                  totalTimeFrames={heatmap?.totalTimeFrames}
                />
              </Grid>
              <Grid item xs={12} container>
                <FileHeatmapCard
                  levels={levels ?? 11}
                  errorHeat={!!editFileError.heatmap}
                  errorGrid={!!editFileError.frame}
                  grid={grid}
                  setGrid={handleEditGrid}
                  gridLoading={editFileLoading.frame}
                  heatmap={heatmap}
                  frameCoords={frameCoords}
                  setFrameCoords={handleSetFrameCoords}
                  heatmapLoading={editFileLoading.heatmap}
                />
              </Grid>
            </Grid>
          </Grid>
        </HotKeys>
      </DialogContent>
    </Dialog>
  );
};

export default HeatmapModal;
