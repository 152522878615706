import { Box, BoxProps, styled, Theme } from '@mui/material';
import React, { memo } from 'react';

type FileCategory = 'setup' | 'data' | 'dxf' | 'pdf' | 'image' | 'txt' | 'other';
type CustomBoxProps = {
  type: FileCategory;
};

const Dot = styled((props: BoxProps & CustomBoxProps) => <Box {...props} />)(({ theme, type }) => ({
  width: '10px',
  height: '10px',
  borderRadius: '50%',
  backgroundColor: findColor(theme, type),
}));

const findColor = (theme: Theme, type: FileCategory) => {
  switch (type) {
    case 'data':
      return theme.palette.primary.main;
    case 'image':
      return theme.palette.warning.light;
    case 'pdf':
      return '#e75480';
    case 'setup':
      return theme.palette.success.main;
    case 'dxf':
      return '#f8bbd0';
    case 'txt':
      return '#6db2ff';
    case 'other':
      return theme.palette.info.main;
  }
  return theme.palette.primary.main;
};

export default memo(Dot);
