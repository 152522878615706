import { plotLinesInitialState } from '../initialStates/pmsInputStates';
import { PlotLine } from '../../models/inputTypes/PmsFields';

export const plotLineArrayPreparation = (plotLineValues: PlotLine[], newValue: number) => {
  const tempArray = plotLineValues;
  const tempLength = plotLineValues.length;
  if (tempLength !== newValue) {
    if (newValue > tempLength) {
      for (let i = tempLength; i < newValue; i++) {
        tempArray.push({
          ...plotLinesInitialState,
          name_h: plotLinesInitialState.name_h + `_${i + 1}`,
          name_z: plotLinesInitialState.name_z + `_${i + 1}`,
          name_c: plotLinesInitialState.name_c + `_${i + 1}`,
          name_dir: plotLinesInitialState.name_dir + `_${i + 1}`,
        });
      }
    } else {
      for (let i = 0; i < tempLength - newValue; i++) {
        tempArray.pop();
      }
    }
  }
  return tempArray;
};
