import React, { FC, memo, useMemo, useState } from 'react';
import { Box, FormControlLabel, RadioGroup, SelectChangeEvent, Typography } from '@mui/material';
import CustomSelect from '../../custom/CustomSelect';
import CustomMenuItem from '../../custom/CustomMenuItem';
import LabelWrapper from '../../common/LabelWrapper';
import { HydBoundaryConditions } from '../../../models/inputTypes/HydFields';
import CustomInputWithLabel from '../../common/CustomInputWithLabel';
import CustomRadio from '../../custom/CustomRadio';
import CustomInput from '../../custom/CustomInput';
import CustomTabs from '../../custom/CustomTabs';
import CustomTab from '../../custom/CustomTab';
import { boundaryArrayPreparation } from '../../../utils/functions/boundaryArrayPreparation';
import { hydBoundaryObjectInitialState } from '../../../utils/initialStates/hydInputState';
import BoxComponent from '../../custom/BoxComponent';

type InputsProps = {
  inputState: HydBoundaryConditions;
  setInputState: (value: HydBoundaryConditions) => void;
};

const styles = {
  flexGap: { display: 'flex', gap: '16px 24px', flexWrap: 'wrap' },
} as const;

const HydBoundaryConditionsInputsGroup: FC<InputsProps> = ({ inputState, setInputState }) => {
  const [selectedTab, setSelectedTab] = useState(0);

  const onTabChange = (event: any, newValue: any) => {
    setSelectedTab(newValue);
  };

  const onSelectChange = (event: SelectChangeEvent<any>) => {
    const length = parseInt(event.target.value);
    const arrayBoundary = boundaryArrayPreparation(inputState.boundaries.slice(), length);
    if ((selectedTab >= length && length > 0) || (length > 0 && selectedTab == -1)) setSelectedTab(0);
    if (length == 0) setSelectedTab(-1);
    setInputState({ ...inputState, boundariesLength: event.target.value, boundaries: arrayBoundary });
  };

  const onDynamicInputChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const newArray = inputState.boundaries.slice();
    if (event.target.name === 'condition_type') {
      newArray[selectedTab] = {
        ...newArray[selectedTab],
        condition_type: +event.target.value,
        u: 0,
        v: 0,
        z: 0,
        tidal_per: hydBoundaryObjectInitialState.tidal_per,
        tidal_amp: hydBoundaryObjectInitialState.tidal_amp,
      };
    } else {
      newArray[selectedTab] = { ...newArray[selectedTab], [event.target.name]: event.target.value };
    }
    setInputState({ ...inputState, boundaries: newArray });
  };

  const boundingTabs = useMemo(() => {
    const newTabs: any[] = [];
    const length = inputState.boundariesLength;
    for (let i = 0; i < length; i++) {
      newTabs.push(
        <CustomTab
          id={(i + 1).toString()}
          value={i}
          label={<Typography variant={'subtitle1'}>Boundary {i + 1}</Typography>}
        />,
      );
    }
    return newTabs;
  }, [inputState.boundariesLength]);

  const prepareSelectItems = useMemo(
    () =>
      [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item, i) => (
        <CustomMenuItem key={i} value={item}>
          <Typography variant={'subtitle2'}>{item}</Typography>
        </CustomMenuItem>
      )),
    [],
  );
  const prepareBoundingPoints = () => {
    if (!inputState.boundariesLength)
      return (
        <BoxComponent disabled>
          <Typography my={1} variant={'subtitle1'}>
            Bounding Points
          </Typography>
          <Box sx={{ display: 'flex', gap: '16px 24px', flexWrap: 'wrap' }}>
            <Box>
              <LabelWrapper label={'First Point'}>
                <Box sx={{ display: 'flex', gap: '16px 8px' }}>
                  <CustomInputWithLabel label={'X-Axis'} disabled value={hydBoundaryObjectInitialState.fp_x} />
                  <CustomInputWithLabel label={'Y-Axis'} disabled value={hydBoundaryObjectInitialState.fp_y} />
                </Box>
              </LabelWrapper>
            </Box>
            <Box>
              <LabelWrapper label={'Last Point'}>
                <Box sx={{ display: 'flex', gap: '16px 8px' }}>
                  <CustomInputWithLabel label={'X-Axis'} disabled value={hydBoundaryObjectInitialState.lp_x} />
                  <CustomInputWithLabel label={'Y-Axis'} disabled value={hydBoundaryObjectInitialState.lp_y} />
                </Box>
              </LabelWrapper>
            </Box>
          </Box>
          <BoxComponent mt={2}>
            <RadioGroup value={hydBoundaryObjectInitialState.condition_type}>
              <FormControlLabel
                disabled
                labelPlacement={'end'}
                value="1"
                control={<CustomRadio />}
                label={<Typography variant={'subtitle2'}>Tide</Typography>}
              />
            </RadioGroup>
            <BoxComponent my={2} sx={styles.flexGap}>
              <Box>
                <LabelWrapper label={'A (m)'}>
                  <CustomInput
                    min={0}
                    required
                    step={'any'}
                    errorText={'Enter a number'}
                    onChange={onDynamicInputChange}
                    value={0}
                    type={'number'}
                    name={'tidal_amp'}
                    maxWidth={'70px'}
                  />
                </LabelWrapper>
              </Box>
              <Box>
                <LabelWrapper label={'T (hrs)'}>
                  <CustomInput
                    required
                    min={0}
                    step={'any'}
                    errorText={'Enter a number'}
                    onChange={onDynamicInputChange}
                    value={0}
                    type={'number'}
                    name={'tidal_per'}
                    maxWidth={'70px'}
                  />
                </LabelWrapper>
              </Box>
            </BoxComponent>
            <RadioGroup>
              <FormControlLabel
                disabled
                labelPlacement={'end'}
                value="2"
                control={<CustomRadio />}
                label={<Typography variant={'subtitle2'}>Specify Conditions</Typography>}
              />
            </RadioGroup>
          </BoxComponent>
          <Box mt={2} sx={{ display: 'flex', gap: '16px 24px', flexWrap: 'wrap' }}>
            <Box>
              <LabelWrapper label={'U (m/s)'}>
                <CustomInput disabled value={hydBoundaryObjectInitialState.u} maxWidth={'70px'} />
              </LabelWrapper>
            </Box>
            <Box>
              <LabelWrapper label={'V (m/s)'}>
                <CustomInput disabled value={hydBoundaryObjectInitialState.v} maxWidth={'70px'} />
              </LabelWrapper>
            </Box>
            <Box>
              <LabelWrapper label={'Z (m)'}>
                <CustomInput disabled value={hydBoundaryObjectInitialState.z} maxWidth={'70px'} />
              </LabelWrapper>
            </Box>
          </Box>
        </BoxComponent>
      );

    return (
      <>
        <Typography my={1} variant={'subtitle1'}>
          Bounding Points
        </Typography>
        <Box sx={{ display: 'flex', gap: '16px 24px', flexWrap: 'wrap' }}>
          <Box>
            <LabelWrapper label={'First Point'}>
              <Box sx={{ display: 'flex', gap: '16px 8px' }}>
                <CustomInputWithLabel
                  required
                  min={1}
                  step={1}
                  errorText={'Enter positive integer'}
                  onChange={onDynamicInputChange}
                  value={inputState.boundaries[selectedTab].fp_x}
                  type={'number'}
                  name={'fp_x'}
                  label={'X-Axis'}
                />
                <CustomInputWithLabel
                  required
                  min={1}
                  step={1}
                  errorText={'Enter positive integer'}
                  onChange={onDynamicInputChange}
                  value={inputState.boundaries[selectedTab].fp_y}
                  type={'number'}
                  name={'fp_y'}
                  label={'Y-Axis'}
                />
              </Box>
            </LabelWrapper>
          </Box>
          <Box>
            <LabelWrapper label={'Last Point'}>
              <Box sx={{ display: 'flex', gap: '16px 8px' }}>
                <CustomInputWithLabel
                  required
                  min={1}
                  step={1}
                  errorText={'Enter positive integer'}
                  onChange={onDynamicInputChange}
                  value={inputState.boundaries[selectedTab].lp_x}
                  type={'number'}
                  name={'lp_x'}
                  label={'X-Axis'}
                />
                <CustomInputWithLabel
                  required
                  min={1}
                  step={1}
                  errorText={'Enter positive integer'}
                  onChange={onDynamicInputChange}
                  value={inputState.boundaries[selectedTab].lp_y}
                  type={'number'}
                  name={'lp_y'}
                  label={'Y-Axis'}
                />
              </Box>
            </LabelWrapper>
          </Box>
        </Box>
        <Box mt={2}>
          <RadioGroup
            aria-label="calcMode"
            name="condition_type"
            value={inputState.boundaries[selectedTab].condition_type}
            onChange={onDynamicInputChange}
          >
            <FormControlLabel
              labelPlacement={'end'}
              value="1"
              control={<CustomRadio />}
              label={<Typography variant={'subtitle2'}>Tide</Typography>}
            />
          </RadioGroup>
          <BoxComponent my={2} disabled={inputState.boundaries[selectedTab].condition_type == 2} sx={styles.flexGap}>
            <Box>
              <LabelWrapper label={'A (m)'}>
                <CustomInput
                  min={0}
                  required
                  step={'any'}
                  errorText={'Enter an integer'}
                  disabled={inputState.boundaries[selectedTab].condition_type == 2}
                  onChange={onDynamicInputChange}
                  value={inputState.boundaries[selectedTab].tidal_amp}
                  type={'number'}
                  name={'tidal_amp'}
                  maxWidth={'70px'}
                />
              </LabelWrapper>
            </Box>
            <Box>
              <LabelWrapper label={'T (hrs)'}>
                <CustomInput
                  required
                  min={0}
                  step={'any'}
                  errorText={'Enter an integer'}
                  disabled={inputState.boundaries[selectedTab].condition_type == 2}
                  onChange={onDynamicInputChange}
                  value={inputState.boundaries[selectedTab].tidal_per}
                  type={'number'}
                  name={'tidal_per'}
                  maxWidth={'70px'}
                />
              </LabelWrapper>
            </Box>
          </BoxComponent>
          <RadioGroup
            aria-label="calcMode"
            name="condition_type"
            value={inputState.boundaries[selectedTab].condition_type}
            onChange={onDynamicInputChange}
          >
            <FormControlLabel
              labelPlacement={'end'}
              value="2"
              control={<CustomRadio />}
              label={<Typography variant={'subtitle2'}>Specify Conditions</Typography>}
            />
          </RadioGroup>
        </Box>
        <BoxComponent disabled={inputState.boundaries[selectedTab].condition_type == 1} mt={2} sx={styles.flexGap}>
          <Box>
            <LabelWrapper label={'U (m/s)'}>
              <CustomInput
                required
                step={'any'}
                errorText={'Enter a number'}
                disabled={inputState.boundaries[selectedTab].condition_type == 1}
                onChange={onDynamicInputChange}
                value={inputState.boundaries[selectedTab].u}
                type={'number'}
                name={'u'}
                maxWidth={'70px'}
              />
            </LabelWrapper>
          </Box>
          <Box>
            <LabelWrapper label={'V (m/s)'}>
              <CustomInput
                required
                step={'any'}
                errorText={'Enter a number'}
                disabled={inputState.boundaries[selectedTab].condition_type == 1}
                onChange={onDynamicInputChange}
                value={inputState.boundaries[selectedTab].v}
                type={'number'}
                name={'v'}
                maxWidth={'70px'}
              />
            </LabelWrapper>
          </Box>
          <Box>
            <LabelWrapper label={'Z (m)'}>
              <CustomInput
                required
                errorText={'Enter a number'}
                disabled={inputState.boundaries[selectedTab].condition_type == 1}
                onChange={onDynamicInputChange}
                value={inputState.boundaries[selectedTab].z}
                type={'number'}
                name={'z'}
                maxWidth={'70px'}
              />
            </LabelWrapper>
          </Box>
        </BoxComponent>
      </>
    );
  };

  return (
    <Box my={1}>
      <LabelWrapper label={'Number of boundaries'}>
        <CustomSelect
          onChange={onSelectChange}
          value={inputState.boundariesLength}
          name={'boundariesLength'}
          minWidth={'140px'}
          displayEmpty
        >
          {prepareSelectItems}
        </CustomSelect>
      </LabelWrapper>
      <Box mt={2}>
        <CustomTabs value={selectedTab} onChange={onTabChange} variant={'scrollable'} scrollButtons={'auto'}>
          {boundingTabs}
        </CustomTabs>
      </Box>
      {prepareBoundingPoints()}
    </Box>
  );
};

export default memo(HydBoundaryConditionsInputsGroup);
