import React, { FC } from 'react';
import { Typography, TypographyProps, Box } from '@mui/material';
import ComputerIcon from '@mui/icons-material/Computer';
import ChecklistRtlIcon from '@mui/icons-material/ChecklistRtl';
import CustomSpaceProgress from '../custom/CustomSpaceProgress';

type StorageComponentProps = {
  label?: string;
  usedCount?: number;
  totalCount?: number;
  progress: number;
};

const StorageComponent: FC<StorageComponentProps & TypographyProps> = ({
  label = 'Simulation Jobs',
  usedCount = 0,
  totalCount = 0,
  ...props
}) => {
  if (totalCount != 0) {
    return (
      <Box pt={'8px'}>
        <Box sx={{ display: 'flex', padding: '0px 0px 11px 0px', gap: '11px', alignItems: 'center' }}>
          <ChecklistRtlIcon />
          <Typography variant={'subtitle1'}>Simulation Jobs</Typography>
        </Box>
        <CustomSpaceProgress value={100 - props.progress} sx={{ width: '228px' }} />
        <Typography variant={'caption'}>
          {totalCount - usedCount} / {totalCount} remaining
        </Typography>
      </Box>
    );
  } else {
    return null;
  }
};

export default StorageComponent;
