import React, { FC, memo, useMemo } from 'react';
import { Box, SelectChangeEvent, Typography } from '@mui/material';
import CustomMenuItem from '../../custom/CustomMenuItem';
import CustomSelect from '../../custom/CustomSelect';
import CustomInputWithLabel from '../../common/CustomInputWithLabel';
import CustomInput from '../../custom/CustomInput';
import LabelWrapper from '../../common/LabelWrapper';
import { FileType } from '../../../models/inputTypes/FileType';
import { PmsNumericDomain } from '../../../models/inputTypes/PmsFields';
import { useGetProjectsQuery } from '../../../redux/RTK/queries/projectQuery';
import { PmsSimulationPreparationResponse } from '../../../utils/simulationFileToState/pmsSimulationPreparation';
import { HmsSimulationPreparationResponse } from '../../../utils/simulationFileToState/hmsSimulationPreparation';
import { useHistory, useParams } from 'react-router';
import BathymetricFileContainer from '../../common/fileComponents/BathymetricFileContainer';
import SimulationFileContainer, {
  SimulationContainerSetter,
} from '../../common/fileComponents/SimulationFileContainer';
import { ModelEnum } from '../../../models/types/ModelEnum';

const styles = {
  spaceBetweenContainer: {
    flexWrap: 'wrap',
    display: 'flex',
    maxWidth: '730px',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& >:nth-child(n)': {
      my: 1,
    },
  },
  flexStartBox: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  coordinates: {
    '& >:nth-child(2)': {
      ml: 1,
    },
  },
} as const;

type PmsNumericalDomainInputsProps = {
  inputState: PmsNumericDomain;
  setInputState: (value: PmsNumericDomain) => void;
  simulationChangeFile?: (
    value: PmsSimulationPreparationResponse | HmsSimulationPreparationResponse,
    file?: FileType,
  ) => void;
  modelType?: ModelEnum.PMS | ModelEnum.HMS;
};

const PmsNumericalDomainInputsGroup: FC<PmsNumericalDomainInputsProps> = ({
  modelType = ModelEnum.PMS,
  simulationChangeFile,
  inputState,
  setInputState,
}) => {
  const { name, fileId } = useParams<{ id?: string; name?: string; fileId?: string }>();
  const history = useHistory();

  const { data, isFetching } = useGetProjectsQuery({});

  const projectItems = useMemo(() => {
    if (data?.length) {
      return data.map((item, i) => (
        <CustomMenuItem key={i} value={item.id}>
          <Typography variant={'subtitle2'}>{item.name} </Typography>
        </CustomMenuItem>
      ));
    }
  }, [data]);

  const onSimulationFileChange = ({ loadedSimulation, file }: SimulationContainerSetter) => {
    if (simulationChangeFile && loadedSimulation && file) {
      return simulationChangeFile(loadedSimulation, file);
    }
    setInputState({ ...inputState, simulationSetup: undefined });
  };

  const onNumberChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setInputState({ ...inputState, [event.target.name]: event.target.value });
  };

  const onInputChange = (
    event: SelectChangeEvent<unknown> | React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setInputState({
      ...inputState,
      [event.target.name]: event.target.value,
      simulationSetup: undefined,
    });

    if (name && fileId) {
      history.push(`/numerical-models/maris-${modelType}`);
    }
  };

  const onBathymetricFileChange = ({ file, cellX, cellY }: { file: FileType; cellX?: number; cellY?: number }) => {
    if (!cellX || !cellY) return setInputState({ ...inputState, bathymetricData: file });
    setInputState({ ...inputState, bathymetricData: file, cellX: cellX, cellY: cellY });
  };

  const getType = () => {
    if (modelType && modelType.toUpperCase() === 'PMS') {
      return 'PMS';
    } else {
      return 'HMS';
    }
  };

  return (
    <>
      <Box sx={{ ...styles.spaceBetweenContainer }}>
        <Box>
          <LabelWrapper label={'Select project'}>
            <CustomSelect
              required
              value={inputState.project}
              onChange={onInputChange}
              name={'project'}
              minWidth={'180px'}
              isLoading={isFetching}
              defaultValue={''}
              displayEmpty
            >
              <CustomMenuItem value={''}>
                <Typography variant={'subtitle2'}>select project</Typography>
              </CustomMenuItem>
              {projectItems}
            </CustomSelect>
          </LabelWrapper>
        </Box>
        <Box>
          <LabelWrapper label={'Insert simulation setup'}>
            <SimulationFileContainer
              typePage={getType()}
              modelType={modelType}
              redirectLocation={`/numerical-models/maris-${modelType}`}
              project={inputState.project}
              file={inputState.simulationSetup}
              setSimulationInputs={onSimulationFileChange}
            />
          </LabelWrapper>
        </Box>
      </Box>
      <Box>
        <Box mt={2} sx={{ ...styles.spaceBetweenContainer }}>
          <Box>
            <Box sx={{ ...styles.flexStartBox }}>
              <Box>
                <LabelWrapper label={'Number of cells'}>
                  <Box sx={{ ...styles.flexStartBox, ...styles.coordinates }}>
                    <CustomInputWithLabel
                      required
                      min={1}
                      step={1}
                      errorText={'Enter positive integer'}
                      type={'number'}
                      value={inputState.cellX}
                      onChange={onNumberChange}
                      name={'cellX'}
                      label={'X'}
                    />
                    <CustomInputWithLabel
                      required
                      min={1}
                      step={1}
                      errorText={'Enter positive integer'}
                      type={'number'}
                      value={inputState.cellY}
                      onChange={onNumberChange}
                      name={'cellY'}
                      label={'Y'}
                    />
                  </Box>
                </LabelWrapper>
              </Box>
              <Box ml={3}>
                <LabelWrapper label={'Cell size (m)'}>
                  <Box sx={{ ...styles.flexStartBox, ...styles.coordinates }}>
                    <CustomInputWithLabel
                      required
                      min={0.0000000001}
                      step={'any'}
                      errorText={'Enter positive number'}
                      type={'number'}
                      value={inputState.cellSizeX}
                      onChange={onNumberChange}
                      name={'cellSizeX'}
                      label={'DX'}
                    />
                    <CustomInputWithLabel
                      required
                      min={0.0000000001}
                      step={'any'}
                      errorText={'Enter positive number'}
                      type={'number'}
                      value={inputState.cellSizeY}
                      onChange={onNumberChange}
                      name={'cellSizeY'}
                      label={'DY'}
                    />
                  </Box>
                </LabelWrapper>
              </Box>
            </Box>
          </Box>
          <Box>
            <LabelWrapper label={'Insert bathymetry file'}>
              <BathymetricFileContainer
                requestDims={!inputState.simulationSetup}
                typePage={'PMS'}
                setBathymetricInputs={onBathymetricFileChange}
                project={inputState.project}
                file={inputState.bathymetricData}
              />
            </LabelWrapper>
          </Box>
        </Box>
        <Box mt={2}>
          <LabelWrapper label={'Still water level shift (m)'}>
            <CustomInput
              required
              step={'any'}
              maxWidth={'150px'}
              errorText={'Enter number'}
              type={'number'}
              value={inputState.waterLevel}
              onChange={onNumberChange}
              name={'waterLevel'}
            />
          </LabelWrapper>
        </Box>
      </Box>
    </>
  );
};

export default memo(PmsNumericalDomainInputsGroup);
