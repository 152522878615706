import React, { FC, memo } from 'react';
import { Box, Fade } from '@mui/material';
import CustomInput from '../../custom/CustomInput';
import { SrfSponge } from '../../../models/inputTypes/SrfFields';
import LabelWrapper from '../../common/LabelWrapper';

const styles = {
  spacingContainer: {
    flexWrap: 'wrap',
    display: 'flex',
    alignItems: 'flex-end',
  },
  childMarginBig: {
    '& >:nth-child(n)': {
      width: '180px',
      my: 1,
      mr: { xs: 2, lg: 4 },
    },
  },
  childMarginSmall: {
    '& >:not(:first-child)': {
      marginLeft: 1,
    },
    h6: {
      margin: 'auto',
    },
  },
} as const;

type SrfSpongeInputsProps = {
  inputState: SrfSponge;
  setInputState: (value: SrfSponge) => void;
};
const SrfSpongeInputsGroup: FC<SrfSpongeInputsProps> = ({ inputState, setInputState }) => {
  const onInputChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setInputState({ ...inputState, [event.target.name]: event.target.value });
  };

  const onNumberChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setInputState({ ...inputState, [event.target.name]: event.target.value });
  };

  return (
    <Fade in={true}>
      <Box>
        <Box sx={{ ...styles.childMarginBig, ...styles.spacingContainer }}>
          <Box>
            <LabelWrapper label={'Name Sponge Layer Map'}>
              <CustomInput
                pattern="[a-zA-Z0-9_-]*"
                errorText={'Please enter valid text (letters only)'}
                required
                maxLength={30}
                type={'text'}
                value={inputState.nameSpongeLayer}
                name={'nameSpongeLayer'}
                onChange={onInputChange}
              />
            </LabelWrapper>
          </Box>
          <Box>
            <LabelWrapper label={'Background value'}>
              <CustomInput
                required
                step={'any'}
                errorText={'Enter positive number'}
                type={'number'}
                value={inputState.backgroundValue}
                name={'backgroundValue'}
                onChange={onInputChange}
              />
            </LabelWrapper>
          </Box>
          <Box>
            <LabelWrapper label={'Number of layers'}>
              <CustomInput
                required
                min={1}
                step={1}
                errorText={'Enter positive integer'}
                type={'number'}
                value={inputState.layerNumber}
                name={'layerNumber'}
                onChange={onNumberChange}
              />
            </LabelWrapper>
          </Box>
        </Box>
        <Box mt={1} sx={{ ...styles.childMarginBig, ...styles.spacingContainer }}>
          <Box>
            <LabelWrapper label={'Add values along code value'}>
              <CustomInput
                required
                min={0}
                step={'any'}
                errorText={'Enter positive number'}
                type={'number'}
                value={inputState.addValues}
                name={'addValues'}
                onChange={onInputChange}
              />
            </LabelWrapper>
          </Box>
          <Box>
            <LabelWrapper label={'Base value'}>
              <CustomInput
                required
                min={0.000000000001}
                step={'any'}
                errorText={'Enter positive number'}
                type={'number'}
                value={inputState.baseValue}
                name={'baseValue'}
                onChange={onInputChange}
              />
            </LabelWrapper>
          </Box>
          <Box>
            <LabelWrapper label={'Power value'}>
              <CustomInput
                required
                min={0.000000000001}
                step={'any'}
                errorText={'Enter positive number'}
                type={'number'}
                value={inputState.powerValue}
                name={'powerValue'}
                onChange={onInputChange}
              />
            </LabelWrapper>
          </Box>
        </Box>
      </Box>
    </Fade>
  );
};

export default memo(SrfSpongeInputsGroup);
