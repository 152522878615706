import { PaletteColorOptions } from '@mui/material';

const primary: PaletteColorOptions = {
  main: '#007BFF',
  light: '#6394DF',
  dark: '#194383',
};

const secondary: PaletteColorOptions = {
  light: '#C0B0FF',
  main: '#866DE8',
  dark: '#674DCD',
};

const error: PaletteColorOptions = {
  light: '#F78584',
  main: '#E73635',
  dark: '#BE2120',
};

const warning: PaletteColorOptions = {
  light: '#F4B483',
  main: '#DD7526',
  dark: '#B95000',
};

const success: PaletteColorOptions = {
  light: '#95F2AB',
  main: '#0AC096',
  dark: '#287C3C',
};

const info: PaletteColorOptions = {
  light: '#B6B6B6',
  main: '#727272',
  dark: '#212121',
  contrastText: '#FAFAFA',
};

export { primary, secondary, error, success, info, warning };
