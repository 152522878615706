import React, { FC, memo } from 'react';
import { Box, Typography, Theme, InputAdornment, IconButton } from '@mui/material';
import { ReactComponent as SelectArrow } from '../../../assets/images/ChevronDown.svg';
import CustomMenuItem from '../../custom/CustomMenuItem';
import CustomButton from '../../custom/CustomButton';
import CustomSelect from '../../custom/CustomSelect';
import BoxInputContainer from '../../custom/BoxInputContainer';
import maskValues from '../../../dummy/maskValues';
import { colorTheme } from '../../../dummy/colorTheme';
import { StyledInput } from '../../common/LoginInput';
import { ReactComponent as Close } from '../../../assets/images/close.svg';

const styles = {
  inputGrid: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '96px 2fr',
    gap: '12px 24px',
  },
  boxInputs: {
    padding: (theme: Theme) => theme.spacing(1.5, 4, 1.5, 2),
    background: '#DFE1E6',
  },
  smallInputsGrid: { display: 'grid', alignItems: 'center', gridTemplateColumns: '1.5fr 1fr', gap: '12px 24px' },

  buttonPosition: {
    display: 'flex',
    margin: 'auto',
    marginTop: 3,
    background: (theme: Theme) => theme.palette.primary.dark,
  },
} as const;

type HeatMapInputProps = {
  onPlot: () => void;
  min: number | undefined;
  setMin: (min: number | undefined) => void;
  max: number | undefined;
  setMax: (max: number | undefined) => void;
  levels: number | undefined;
  setLevels: (levels: number | undefined) => void;
  color: string;
  setColor: (color: string) => void;
  mask: any | undefined;
  setMask: (mask: any | undefined) => void;

  disabled?: boolean;
};

const FileInputsCard: FC<HeatMapInputProps> = ({
  disabled,
  onPlot,
  min,
  setMin,
  max,
  setMax,
  levels,
  setLevels,
  color,
  setColor,
  mask,
  setMask,
  ...rest
}) => {
  return (
    <BoxInputContainer sx={{ ...styles.boxInputs }} borderRadius={'6px'} disabled={disabled}>
      <Box sx={styles.inputGrid}>
        <Typography variant={'subtitle1'} align={'right'}>
          Min
        </Typography>
        <StyledInput
          type="number"
          value={min ?? ''}
          onChange={(event) => setMin(event.target.value ? Number(event.target.value) : undefined)}
          endAdornment={
            min !== undefined ? (
              <InputAdornment position="end">
                <IconButton size="small" onClick={() => setMin(undefined)}>
                  <Close />
                </IconButton>
              </InputAdornment>
            ) : undefined
          }
        />
        <Typography variant={'subtitle1'} align={'right'}>
          Max
        </Typography>
        <StyledInput
          value={max ?? ''}
          onChange={(event) => setMax(event.target.value ? Number(event.target.value) : undefined)}
          endAdornment={
            max !== undefined ? (
              <InputAdornment position="end">
                <IconButton size="small" onClick={() => setMax(undefined)}>
                  <Close />
                </IconButton>
              </InputAdornment>
            ) : undefined
          }
          fullWidth
          type={'number'}
        />
        <Typography variant={'subtitle1'} align={'right'}>
          No of Levels
        </Typography>
        <StyledInput
          value={levels ?? ''}
          onChange={(event) => setLevels(event.target.value ? Number(event.target.value) : undefined)}
          fullWidth
          type={'number'}
          endAdornment={
            levels !== undefined ? (
              <InputAdornment position="end">
                <IconButton size="small" onClick={() => setLevels(undefined)}>
                  <Close />
                </IconButton>
              </InputAdornment>
            ) : undefined
          }
        />
        <Typography variant={'subtitle1'} align={'right'}>
          Color theme
        </Typography>
        <CustomSelect
          value={color}
          onChange={(event) => setColor(event.target.value as string)}
          fullWidth
          IconComponent={SelectArrow}
        >
          <CustomMenuItem value={''}>
            <Typography variant={'subtitle2'}>none</Typography>
          </CustomMenuItem>
          {colorTheme.map((item, i) => (
            <CustomMenuItem key={i} value={item.value}>
              <Typography variant={'subtitle2'}>{item.title}</Typography>
            </CustomMenuItem>
          ))}
        </CustomSelect>
        <Typography variant={'subtitle1'} align={'right'}>
          Mask Values
        </Typography>

        <Box sx={styles.smallInputsGrid}>
          <CustomSelect
            value={mask && Object.keys(mask)?.[0] !== undefined ? Object.keys(mask)?.[0] : ''}
            onChange={(event) => setMask(event.target.value ? { [event.target.value as any]: undefined } : undefined)}
            fullWidth
            IconComponent={SelectArrow}
          >
            <CustomMenuItem value={''}>
              <Typography variant={'subtitle2'}>none</Typography>
            </CustomMenuItem>
            {Object.entries(maskValues).map(([key, value]) => (
              <CustomMenuItem key={key} value={value}>
                {value}
              </CustomMenuItem>
            ))}
          </CustomSelect>
          <StyledInput
            disabled={!mask || Object.keys(mask)?.[0] === undefined}
            value={mask && Object.values(mask)?.[0] !== undefined ? Object.values(mask)?.[0] : ''}
            onChange={(event) =>
              setMask(
                mask && Object.keys(mask)?.[0] !== undefined
                  ? { [Object.keys(mask)?.[0]]: event.target.value ? Number(event.target.value) : undefined }
                  : undefined,
              )
            }
            fullWidth
            type={'number'}
            endAdornment={
              mask && Object.values(mask)?.[0] !== undefined ? (
                <InputAdornment position="end">
                  <IconButton
                    size="small"
                    onClick={() =>
                      setMask(
                        mask && Object.keys(mask)?.[0] !== undefined
                          ? { [Object.keys(mask)?.[0]]: undefined }
                          : undefined,
                      )
                    }
                  >
                    <Close />
                  </IconButton>
                </InputAdornment>
              ) : undefined
            }
          />
        </Box>
      </Box>
      <CustomButton
        minWidth={'120px'}
        sx={styles.buttonPosition}
        size={'small'}
        onClick={onPlot}
        variant={'contained'}
        color={'primary'}
      >
        Plot
      </CustomButton>
    </BoxInputContainer>
  );
};

export default memo(FileInputsCard);
