import React, { FC, memo, useMemo, useState } from 'react';
import { CircularProgress, TableCell, TableRow, Typography, styled, Tooltip, Box } from '@mui/material';
import CustomTable from '../../custom/CustomTable';
import { useHistory } from 'react-router-dom';
import { Projects } from '../../../models/types/Projects';
import { IconButton } from '@mui/material';
import { ReactComponent as DeleteIcon } from '../../../assets/images/delete.svg';
import { ReactComponent as DownloadIcon } from '../../../assets/images/download.svg';
import { ReactComponent as ViewIcon } from '../../../assets/images/view.svg';
import { useGetProjectsQuery, useLazyGetProjectZipQuery } from '../../../redux/RTK/queries/projectQuery';
import moment from 'moment-timezone';
import { sortProjects } from '../../../utils/functions/sortProjects';
import { useStorageQuery } from '../../../redux/RTK/queries/storageQuery';
import { useRenameProjectMutation } from '../../../redux/RTK/mutations/projectMutations';
import EdiText from 'react-editext';

type LatestProjectProps = {
  setDeleteProject: (value: Projects) => void;
};

const TableHead = [
  { title: 'Name', align: 'flex-start', sort: true },
  { title: 'Date Created', align: 'center', sort: true },
  { title: '', align: 'center' },
];

const LatestProjectGroup: FC<LatestProjectProps> = ({ setDeleteProject }) => {
  const history = useHistory();
  const { data, isLoading } = useGetProjectsQuery({});
  const [fetchZip] = useLazyGetProjectZipQuery();
  const { data: storage } = useStorageQuery({});
  const [sortBy, setSortBy] = useState({ title: '', ascending: false });
  const [project, response] = useRenameProjectMutation();

  const StyledEdiText = styled(EdiText)`
    button {
      &:hover {
        background: none;
        color: none;
      }
      background: none;
      border: none;
      outline: none;
    }
    div[editext='button-container'] {
      background: none;
      color: none;
      border: none;
      outline: none;
    }
    input {
      width: 150px;
    }
  `;

  const handleEditClick = (id: number) => {
    history.push(`/projects/${id}`);
  };

  const handleDeleteClicked = (item: Projects) => {
    setDeleteProject(item);
  };

  const onDownloadClick = (id: number, name: string) => {
    fetchZip({ id, name: name });
  };

  const renameProject = (val: string, id: number) => {
    project({ id: id, name: val });
  };

  const dataUtc = useMemo(() => {
    if (storage && storage.profile) {
      const utc = storage.profile.timezone;
      if (utc) {
        return utc.slice(utc.indexOf('C') + 1, utc.lastIndexOf(')'));
      } else return '';
    } else return '';
  }, [storage]);

  const tableRows = useMemo(() => {
    if (isLoading) {
      return (
        <TableRow>
          <TableCell colSpan={5} align={'center'}>
            <CircularProgress />
          </TableCell>
        </TableRow>
      );
    }
    if (!data?.length) {
      return (
        <TableRow>
          <TableCell colSpan={5} align={'center'}>
            <Typography my={1} variant={'subtitle1'}>
              No available projects
            </Typography>
          </TableCell>
        </TableRow>
      );
    }
    if (data?.length) {
      const sortedProjects = sortProjects(data.slice(), sortBy.title, sortBy.ascending);
      return sortedProjects.map((item, i) => (
        <TableRow key={i}>
          <TableCell style={{ width: '300px', fontSize: 13 }}>
            <Tooltip title="Click to rename" placement="bottom-start">
              <Box component="div" sx={{ width: 0 }} whiteSpace="nowrap">
                <StyledEdiText
                  value={item.name}
                  type="text"
                  onSave={(value) => renameProject(value, item.id)}
                  submitOnUnfocus
                  hideIcons
                  editOnViewClick
                  submitOnEnter
                  cancelOnEscape
                />
              </Box>
            </Tooltip>
          </TableCell>
          <TableCell align={'center'}>
            <Typography variant={'subtitle2'}>
              {moment(item.created_at).zone(dataUtc).format('DD/MM/yyyy HH:mm')}
            </Typography>
          </TableCell>
          <TableCell align={'right'}>
            <IconButton onClick={() => handleEditClick(item.id)}>
              <ViewIcon />
            </IconButton>
            <IconButton onClick={() => onDownloadClick(item.id, item.name)}>
              <DownloadIcon />
            </IconButton>
            <IconButton onClick={() => handleDeleteClicked(item)}>
              <DeleteIcon />
            </IconButton>
          </TableCell>
        </TableRow>
      ));
    }
  }, [data, sortBy, isLoading, storage, response]);

  return (
    <>
      <CustomTable sortBy={sortBy} onSortClick={(val) => setSortBy(val)} header={TableHead}>
        {tableRows}
      </CustomTable>
    </>
  );
};

export default memo(LatestProjectGroup);
