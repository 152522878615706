import { HydSourcePoints } from '../../models/inputTypes/HydFields';
import { hydSourcePointsObjectInitialState } from '../initialStates/hydInputState';

export const sourcePointsArrayPreparation = (boundaries: HydSourcePoints[], newValue: number) => {
  const tempArray = boundaries;
  const tempLength = boundaries.length;
  if (tempLength !== newValue) {
    if (newValue > tempLength) {
      for (let i = tempLength; i < newValue; i++) {
        tempArray.push(hydSourcePointsObjectInitialState);
      }
    } else {
      for (let i = 0; i < tempLength - newValue; i++) {
        tempArray.pop();
      }
    }
  }
  return tempArray;
};
