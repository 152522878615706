import React, { FC, memo, useMemo } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';

import CustomInput from '../../custom/CustomInput';

import { FileType } from '../../../models/inputTypes/FileType';
import ButtonFileDialog from '../../common/ButtonFileDialog';
import { EviNumerical } from '../../../models/inputTypes/EviFields';
import { bedLevelInitialObject } from '../../../utils/initialStates/eviInitialStates';
import LabelWrapper from '../../common/LabelWrapper';
import { ReactComponent as Add } from '../../../assets/images/add.svg';

type InputsGroupProps = {
  projectId: string;
  inputState: EviNumerical;
  setInputState: React.Dispatch<React.SetStateAction<EviNumerical>>;
};

const EviBedLevelInputs: FC<InputsGroupProps> = ({ inputState, setInputState }) => {
  const isAddDisabled = useMemo(() => {
    return inputState.bedLevelList.length > 14;
  }, [inputState.bedLevelList]);

  const onDynamicFileChange = (file: FileType | undefined, fieldName: string, index: number) => {
    const newCodeArray = inputState.bedLevelList.slice();
    newCodeArray[index] = { ...newCodeArray[index], [fieldName]: file };
    setInputState({ ...inputState, bedLevelList: newCodeArray });
  };

  const onDynamicInputChange = (index: number, e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const newCodeArray = inputState.bedLevelList.slice();
    let sum = 1;
    newCodeArray[index] = { ...newCodeArray[index], [e.target.name]: e.target.value };
    newCodeArray.forEach((item) => (sum = sum - +item.weight));
    setInputState({ ...inputState, bedLevelList: newCodeArray, calmPeriod: +sum.toFixed(4) });
  };

  const handleAddFile = () => {
    const tempQtList = inputState.bedLevelList.slice();
    tempQtList.push(bedLevelInitialObject);
    setInputState({ ...inputState, bedLevelList: tempQtList });
  };
  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputState({
      ...inputState,
      [event.target.name]: event.target.value,
    });
  };
  const eviInputs = () => {
    return inputState.bedLevelList.map((item, i) => (
      <>
        <Box display={'flex'} alignItems={'center'}>
          <Typography variant={'subtitle1'} mr={2}>
            {i + 1}
          </Typography>
          <ButtonFileDialog
            typePage={'EVI'}
            required
            dataFilesOnly
            disabled={!inputState.project}
            projectId={inputState.project}
            file={item.file}
            setFile={(file) => onDynamicFileChange(file, 'file', i)}
          />
        </Box>
        <Box>
          <CustomInput
            maxWidth={'90px'}
            errorText={item.weight < 0 ? 'Enter positive number' : 'Enter number (0-1)'}
            type={'number'}
            min={0}
            max={1}
            step={'any'}
            value={item.weight}
            name={'weight'}
            onChange={(e) => onDynamicInputChange(i, e)}
          />
        </Box>
      </>
    ));
  };

  return (
    <>
      <Box display={'grid'} mt={4} rowGap={2} columnGap={3} gridTemplateColumns={'1fr 1fr'} maxWidth={'700px'}>
        <Typography ml={3} variant={'subtitle2'}>
          Rate of Bed Level Change
        </Typography>
        <Typography variant={'subtitle2'}>Weights</Typography>
        {eviInputs()}
      </Box>
      <Box ml={3} my={2} display={'flex'} maxWidth={'435px'} alignItems={'center'} justifyContent={'space-between'}>
        <Box>
          <Button
            disabled={isAddDisabled}
            variant={'contained'}
            color={'success'}
            startIcon={<Add />}
            onClick={handleAddFile}
          >
            <Typography color={'info.contrastText'} variant={'subtitle2'}>
              Add file
            </Typography>
          </Button>
        </Box>
        <Box>
          <LabelWrapper label={'Calm period'}>
            <CustomInput
              maxWidth={'101px'}
              errorText={'Total weights should be 0≤ total ≤1'}
              type={'number'}
              step={'any'}
              min={0}
              max={1}
              value={inputState.calmPeriod}
              name={'calmPeriod'}
              onChange={onInputChange}
            />
          </LabelWrapper>
        </Box>
      </Box>
      <Stack>
        <LabelWrapper label={'Enter Output file name'}>
          <CustomInput
            type={'text'}
            required
            maxWidth={'200px'}
            placeholder={'Enter name'}
            value={inputState.outputFileName}
            name={'outputFileName'}
            onChange={onInputChange}
          />
        </LabelWrapper>
      </Stack>
    </>
  );
};

export default memo(EviBedLevelInputs);
