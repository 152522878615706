import { Projects } from '../../../models/types/Projects';
import { customApi } from '../Api';
import { openErrorToast, setProgress, setDownloading, setZipping } from '../../slices/appSlice';
import axios from 'axios';
import { useAppSelector } from '../../store';

const extendedApi = customApi.injectEndpoints({
  endpoints: (builder) => ({
    getProjects: builder.query<Projects[], any>({
      query: () => ({
        url: '/api/projects/',
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (err) {
          dispatch(openErrorToast('Error fetching data. Try again!'));
        }
      },
      providesTags: ['projects'],
    }),
    getProjectZip: builder.query<{ file: string }, { id: number; name?: string }>({
      query: ({ id }) => ({
        url: `/api/projects/${id}/zip`,
        method: 'POST',
      }),
      async onQueryStarted(id, { getState, dispatch, queryFulfilled }) {
        try {
          const store: any = getState();
          dispatch(setZipping(true));
          const res = await queryFulfilled;
          dispatch(setDownloading(true));
          const val = await axios.get(res.data.file, {
            method: 'GET',
            responseType: 'blob',
            headers: {
              Authorization: 'Token ' + store.authentication.data.accessToken,
            },
            onDownloadProgress: (progressEvent) => {
              const total = progressEvent.total;
              const loaded = progressEvent.loaded;
              const percentCompleted = Math.floor((loaded / total) * 100);
              console.log('progress: ', percentCompleted);
              dispatch(setProgress(percentCompleted));
            },
          });
          const hiddenElement = document.createElement('a');
          hiddenElement.href = URL.createObjectURL(val.data);
          hiddenElement.target = '_blank';
          hiddenElement.download = `${id.name}.zip`;
          hiddenElement.click();
          hiddenElement.remove();
          dispatch(setProgress(0));
          dispatch(setZipping(false));
          dispatch(setDownloading(false));
        } catch (err: any) {
          dispatch(openErrorToast(err.error.data.error));
          dispatch(setZipping(false));
          dispatch(setDownloading(false));
        }
      },
    }),
  }),
  overrideExisting: false,
});

export const { useGetProjectsQuery, useLazyGetProjectZipQuery } = extendedApi;
