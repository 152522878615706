import { customApi } from '../Api';
import { openSuccessToast, openErrorToast } from '../../slices/appSlice';
import { useHistory } from 'react-router-dom';

const extendedApi = customApi.injectEndpoints({
  endpoints: (builder) => ({
    resetPassword: builder.mutation({
      query: (email) => ({
        url: '/dj-rest-auth/password/reset/',
        method: 'POST',
        body: { email },
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (err: any) {
          const errorMsg = err?.error?.data?.email[0] || 'Something went wrong. Please try again!';
          dispatch(openErrorToast(errorMsg));
        }
      },
    }),
    resetPasswordConfirm: builder.mutation({
      query: ({ uid, token, new_password1, new_password2 }) => ({
        url: '/dj-rest-auth/password/reset/confirm/',
        method: 'POST',
        body: { uid, token, new_password1, new_password2 },
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (err: any) {
          const errorMsg = err?.error?.data?.new_password2[0] || 'Something went wrong. Please try again!';
          dispatch(openErrorToast(errorMsg));
        }
      },
    }),
  }),
  overrideExisting: false,
});

export const { useResetPasswordMutation, useResetPasswordConfirmMutation } = extendedApi;
