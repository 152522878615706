import { createSlice } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../store';
import {
  getFrame,
  postHeatmap,
  putFile,
  postHeatmapHms,
  getAlignedPoints,
  getAdjustedPoints,
  postHeatmapSrf,
  postPoints,
  putReplaceFile,
  postDxf,
  putFileDxf,
} from '../../axios/endpoints/editFile';
import { openErrorToast, openSuccessToast, openWarningToast } from './appSlice';

interface EditFileState {
  heatmapData: any;
  frameData: any;
  savedFile: any;
  heatmapHmsData: any;
  alignedPoints: any;
  adjustedPoints: any;
  heatmapSrfData: any;
  clicks: { x: number; y: number }[] | undefined;
  replacedFile: any;
  dxfData: any;
  loading: {
    heatmap: boolean;
    frame: boolean;
    save: boolean;
    heatmapHms: boolean;
    align: boolean;
    adjust: boolean;
    heatmapSrf: boolean;
    select: boolean;
    replace: boolean;
    dxf: boolean;
    create: boolean;
  };
  error: {
    heatmap: string | undefined;
    frame: string | undefined;
    save: string | undefined;
    heatmapHms: string | undefined;
    align: string | undefined;
    adjust: string | undefined;
    heatmapSrf: string | undefined;
    select: string | undefined;
    replace: string | undefined;
    dxf: string | undefined;
    create: string | undefined;
  };
}

const initialState: EditFileState = {
  heatmapData: undefined,
  frameData: undefined,
  savedFile: undefined,
  heatmapHmsData: undefined,
  alignedPoints: undefined,
  adjustedPoints: undefined,
  heatmapSrfData: undefined,
  clicks: undefined,
  replacedFile: undefined,
  dxfData: undefined,
  loading: {
    heatmap: false,
    frame: false,
    save: false,
    heatmapHms: false,
    align: false,
    adjust: false,
    heatmapSrf: false,
    select: false,
    replace: false,
    dxf: false,
    create: false,
  },
  error: {
    heatmap: undefined,
    frame: undefined,
    save: undefined,
    heatmapHms: undefined,
    align: undefined,
    adjust: undefined,
    heatmapSrf: undefined,
    select: undefined,
    replace: undefined,
    dxf: undefined,
    create: undefined,
  },
};

const editFileSlice = createSlice({
  name: 'editFile',
  initialState,
  reducers: {
    setHeatmapData: (state, action) => {
      state.heatmapData = action.payload;
    },
    setFrameData: (state, action) => {
      state.frameData = action.payload;
    },
    setSavedFile: (state, action) => {
      state.savedFile = action.payload;
    },
    setHeatmapHmsData: (state, action) => {
      state.heatmapHmsData = action.payload;
    },
    setAlignedPoints: (state, action) => {
      state.alignedPoints = action.payload;
    },
    setAdjustedPoints: (state, action) => {
      state.adjustedPoints = action.payload;
    },
    setHeatmapSrfData: (state, action) => {
      state.heatmapSrfData = action.payload;
    },
    setReplacedFile: (state, action) => {
      state.replacedFile = action.payload;
    },
    setClicks: (state, action) => {
      state.clicks = action.payload;
    },
    setDxfData: (state, action) => {
      state.dxfData = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = { ...state.loading, [action.payload.key]: action.payload.value };
    },
    setError: (state, action) => {
      state.error = { ...state.error, [action.payload.key]: action.payload.value };
    },
    reset: () => initialState,
  },
});

export const selectEditFileHeatmap = (state: RootState) => state.editFile.heatmapData;
export const selectEditFileFrame = (state: RootState) => state.editFile.frameData;
export const selectEditFileLoading = (state: RootState) => state.editFile.loading;
export const selectEditFileError = (state: RootState) => state.editFile.error;
export const selectEditFileSaved = (state: RootState) => state.editFile.savedFile;
export const selectEditFileHeatmapHms = (state: RootState) => state.editFile.heatmapHmsData;
export const selectAlignedPoints = (state: RootState) => state.editFile.alignedPoints;
export const selectAdjustedPoints = (state: RootState) => state.editFile.adjustedPoints;
export const selectEditFileClicks = (state: RootState) => state.editFile.clicks;
export const selectEditFileHeatmapSrf = (state: RootState) => state.editFile.heatmapSrfData;
export const selectEditFileReplaced = (state: RootState) => state.editFile.replacedFile;
export const selectEditFileDxf = (state: RootState) => state.editFile.dxfData;

export const performGetHeatmap =
  (
    projectId: string,
    fileName: string,
    max: number | undefined = undefined,
    min: number | undefined = undefined,
    levels: number | undefined = undefined,
    color: string | undefined = undefined,
    mask: any | undefined = undefined,
    replace_operation: any | undefined = undefined,
    timeframe: number | undefined = undefined,
    data: number[][] | undefined = undefined,
    x: number | undefined = undefined,
    y: number | undefined = undefined,
  ): AppThunk =>
  async (dispatch) => {
    dispatch(setLoadingEditFile({ key: 'heatmap', value: true }));

    try {
      const res: any = await postHeatmap(
        projectId,
        fileName,
        max,
        min,
        levels,
        color,
        mask,
        replace_operation,
        timeframe,
        data,
        x,
        y,
      );
      if (res?.data?.data) {
        dispatch(setHeatmapData(res.data.data));
      }
      dispatch(setErrorEditFile({ key: 'heatmap', value: undefined }));
      dispatch(setLoadingEditFile({ key: 'heatmap', value: false }));
    } catch (e: any) {
      dispatch(setErrorEditFile({ key: 'heatmap', value: e.message }));
      dispatch(setLoadingEditFile({ key: 'heatmap', value: false }));
      dispatch(openErrorToast(e.response.data.error));
    }
  };

export const performGetFrame =
  (
    projectId: string,
    fileName: string,
    timeframe: number | undefined = undefined,
    x: number | undefined = undefined,
    y: number | undefined = undefined,
  ): AppThunk =>
  async (dispatch) => {
    dispatch(setLoadingEditFile({ key: 'frame', value: true }));
    try {
      const res: any = await getFrame(projectId, fileName, timeframe, x, y);
      if (res?.data) {
        dispatch(setFrameData(res.data));
      }
      dispatch(setErrorEditFile({ key: 'frame', value: undefined }));
      dispatch(setLoadingEditFile({ key: 'frame', value: false }));
    } catch (e: any) {
      dispatch(setErrorEditFile({ key: 'frame', value: e.message }));
      dispatch(setLoadingEditFile({ key: 'frame', value: false }));
      dispatch(openErrorToast(e.response.data.error));
    }
  };

export const performPutFile =
  (
    projectId: string,
    fileName: string,
    destinationFile: string | undefined,
    timeframe: number | undefined = undefined,
    data: number[][] | undefined = undefined,
    x: number | undefined = undefined,
    y: number | undefined = undefined,
    replace_operation: any | undefined = undefined,
  ): AppThunk =>
  async (dispatch) => {
    dispatch(setLoadingEditFile({ key: 'save', value: true }));
    try {
      const res: any = await putFile(projectId, fileName, destinationFile, timeframe, data, x, y, replace_operation);
      if (destinationFile) {
        dispatch(setSavedFile({ projectId: projectId, destinationFile: destinationFile }));
      } else {
        dispatch(setSavedFile({ projectId: projectId, fileName: fileName }));
      }
      dispatch(setErrorEditFile({ key: 'save', value: undefined }));
      dispatch(setLoadingEditFile({ key: 'save', value: false }));
    } catch (e: any) {
      dispatch(setErrorEditFile({ key: 'save', value: e.message }));
      dispatch(setLoadingEditFile({ key: 'save', value: false }));
      dispatch(openErrorToast(e.message));
    }
  };

export const performGetHmsHeatmap =
  (
    projectId: string,
    fileName: string,
    timeframe: number | undefined = undefined,
    north: number | undefined,
    south: number | undefined,
    west: number | undefined,
    east: number | undefined,
    sponge_file: string | undefined,
  ): AppThunk =>
  async (dispatch) => {
    dispatch(setLoadingEditFile({ key: 'heatmapHms', value: true }));

    try {
      const res: any = await postHeatmapHms(projectId, fileName, timeframe, north, south, west, east, sponge_file);
      if (res?.data?.data) {
        dispatch(setHeatmapHmsData(res.data.data));
      }
      dispatch(setErrorEditFile({ key: 'heatmapHms', value: undefined }));
      dispatch(setLoadingEditFile({ key: 'heatmapHms', value: false }));
    } catch (e: any) {
      dispatch(setErrorEditFile({ key: 'heatmapHms', value: e.message }));
      dispatch(setLoadingEditFile({ key: 'heatmapHms', value: false }));
      dispatch(openErrorToast(e.response.data.error));
    }
  };

export const performGetAlignedPoints =
  (fpx: number, fpy: number, lpx: number, lpy: number, Ni: number, Nj: number): AppThunk =>
  async (dispatch) => {
    dispatch(setLoadingEditFile({ key: 'align', value: true }));
    try {
      const res: any = await getAlignedPoints(fpx, fpy, lpx, lpy, Ni, Nj);
      if (res?.data) {
        dispatch(setAlignedPoints(res.data));
      }
      dispatch(setErrorEditFile({ key: 'align', value: undefined }));
      dispatch(setLoadingEditFile({ key: 'align', value: false }));
    } catch (e: any) {
      dispatch(setErrorEditFile({ key: 'align', value: e.message }));
      dispatch(setLoadingEditFile({ key: 'align', value: false }));
      dispatch(openErrorToast(e.response.data.error));
    }
  };

export const performGetAdjustedPoints =
  (points: { fpx: number; fpy: number; lpx: number; lpy: number; sector: string }[]): AppThunk =>
  async (dispatch) => {
    dispatch(setLoadingEditFile({ key: 'adjust', value: true }));
    try {
      const res: any = await getAdjustedPoints(points);
      if (res?.data) {
        dispatch(setAdjustedPoints(res.data));
      }
      dispatch(setErrorEditFile({ key: 'adjust', value: undefined }));
      dispatch(setLoadingEditFile({ key: 'adjust', value: false }));
    } catch (e: any) {
      dispatch(setErrorEditFile({ key: 'adjust', value: e.message }));
      dispatch(setLoadingEditFile({ key: 'adjust', value: false }));
      dispatch(openErrorToast(e.response.data.error));
    }
  };

export const performGetSrfHeatmap =
  (projectId: string, fileName: string, timeframe: number | undefined = undefined): AppThunk =>
  async (dispatch) => {
    dispatch(setLoadingEditFile({ key: 'heatmapSrf', value: true }));

    try {
      const res: any = await postHeatmapSrf(projectId, fileName, timeframe);
      if (res?.data?.data) {
        dispatch(setHeatmapSrfData(res.data.data));
      }
      dispatch(setErrorEditFile({ key: 'heatmapSrf', value: undefined }));
      dispatch(setLoadingEditFile({ key: 'heatmapSrf', value: false }));
    } catch (e: any) {
      dispatch(setErrorEditFile({ key: 'heatmapSrf', value: e.message }));
      dispatch(setLoadingEditFile({ key: 'heatmapSrf', value: false }));
      dispatch(openErrorToast(e.response.data.error));
    }
  };

export const performGetPoints =
  (
    projectId: string,
    fileName: string,
    timeframe: number,
    points: { x: number; y: number }[],
    value: number | undefined,
  ): AppThunk =>
  async (dispatch) => {
    dispatch(setLoadingEditFile({ key: 'select', value: true }));
    try {
      const res: any = await postPoints(projectId, fileName, timeframe, points, value);
      if (res?.data) {
        dispatch(setClicks(res.data.points));
      }
      dispatch(setErrorEditFile({ key: 'select', value: undefined }));
      dispatch(setLoadingEditFile({ key: 'select', value: false }));
    } catch (e: any) {
      dispatch(setErrorEditFile({ key: 'select', value: e.message }));
      dispatch(setLoadingEditFile({ key: 'select', value: false }));
      dispatch(openErrorToast(e.response.data.error));
    }
  };

export const performReplaceFile =
  (
    projectId: string,
    fileName: string,
    destinationFile: string | undefined,
    timeframe: number | undefined = undefined,
    points: { x: number; y: number }[] | undefined = undefined,
    value: number | undefined = undefined,
    data: number[][] | undefined = undefined,
    x: number | undefined = undefined,
    y: number | undefined = undefined,
  ): AppThunk =>
  async (dispatch) => {
    dispatch(setLoadingEditFile({ key: 'replace', value: true }));
    try {
      const res: any = await putReplaceFile(projectId, fileName, destinationFile, timeframe, points, value, data, x, y);
      if (destinationFile) {
        dispatch(setReplacedFile({ projectId: projectId, destinationFile: destinationFile }));
      } else {
        dispatch(setReplacedFile({ projectId: projectId, fileName: fileName }));
      }
      dispatch(setErrorEditFile({ key: 'replace', value: undefined }));
      dispatch(setLoadingEditFile({ key: 'replace', value: false }));
    } catch (e: any) {
      dispatch(setErrorEditFile({ key: 'replace', value: e.message }));
      dispatch(setLoadingEditFile({ key: 'replace', value: false }));
      dispatch(openErrorToast(e.message));
    }
  };

export const performGetDxf =
  (
    projectId: string,
    fileName: string,
    epsg: number | undefined,
    coastline: number | undefined = undefined,
    groynes: number | undefined = undefined,
    revet: number | undefined = undefined,
  ): AppThunk =>
  async (dispatch) => {
    dispatch(setLoadingEditFile({ key: 'dxf', value: true }));

    try {
      const res: any = await postDxf(projectId, fileName, epsg, coastline, groynes, revet);
      if (res?.data?.data) {
        dispatch(setDxfData(res.data.data));
      }
      if (res?.data?.warning) {
        dispatch(openWarningToast(res.data.warning));
      }
      dispatch(setErrorEditFile({ key: 'dxf', value: undefined }));
      dispatch(setLoadingEditFile({ key: 'dxf', value: false }));
    } catch (e: any) {
      dispatch(setErrorEditFile({ key: 'dxf', value: e.message }));
      dispatch(setLoadingEditFile({ key: 'dxf', value: false }));
      dispatch(openErrorToast(e.response.data.error));
    }
  };

export const performPutFileDxf =
  (
    projectId: string,
    fileName: string,
    epsg: number | undefined,
    coastFilename: string | undefined = undefined,
    groynesFilename: string | undefined = undefined,
    revetFilename: string | undefined = undefined,
  ): AppThunk =>
  async (dispatch) => {
    dispatch(setLoadingEditFile({ key: 'create', value: true }));
    try {
      const res: any = await putFileDxf(projectId, fileName, epsg, coastFilename, groynesFilename, revetFilename);
      dispatch(setErrorEditFile({ key: 'create', value: undefined }));
      dispatch(setLoadingEditFile({ key: 'create', value: false }));
      dispatch(openSuccessToast('Files created successfully!'));
    } catch (e: any) {
      dispatch(setErrorEditFile({ key: 'create', value: e.message }));
      dispatch(setLoadingEditFile({ key: 'create', value: false }));
      dispatch(openErrorToast(e.response.data.error));
    }
  };

export const {
  setHeatmapData,
  setFrameData,
  setSavedFile,
  setHeatmapHmsData,
  setAlignedPoints,
  setAdjustedPoints,
  setClicks,
  setHeatmapSrfData,
  setReplacedFile,
  setDxfData,
  setLoading: setLoadingEditFile,
  setError: setErrorEditFile,
  reset: resetEditFile,
} = editFileSlice.actions;
export default editFileSlice.reducer;
