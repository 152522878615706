import { customApi } from '../Api';
import { openErrorToast } from '../../slices/appSlice';
import { Storage } from '../../../models/types/storage';

const extendedApi = customApi.injectEndpoints({
  endpoints: (builder) => ({
    storage: builder.query<Storage, unknown>({
      query: () => ({
        url: '/api/users/detail',
        method: 'GET',
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (err: any) {
          dispatch(openErrorToast(err.error.data.error));
          // dispatch(clearUser());
        }
      },
      providesTags: ['jobs', 'projectFiles'],
    }),
  }),
  overrideExisting: false,
});

export const { useStorageQuery } = extendedApi;
