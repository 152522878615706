import React, { FC, memo } from 'react';
import { Box, FormControlLabel, RadioGroup, Typography } from '@mui/material';
import CustomInput from '../../custom/CustomInput';
import LabelWrapper from '../../common/LabelWrapper';
import CustomSwitch from '../../custom/CustomSwitch';
import CustomRadio from '../../custom/CustomRadio';
import { HydModelDefinition } from '../../../models/inputTypes/HydFields';
import BoxComponent from '../../custom/BoxComponent';
import { hydModelDefinitionInitialState } from '../../../utils/initialStates/hydInputState';

const styles = {
  spacingJustifyContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  flexStartBox: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
  },
  childSpacing: {
    '& >:nth-child(n)': {
      my: 1,
      mr: { xs: 2, md: 3, xl: 4 },
    },
  },
  childSpacingX: {
    '& >:nth-child(n)': {
      ml: 1,
      mr: { xs: 3, lg: 3, xl: 5 },
    },
  },
  childSpacingAll: {
    '& >:nth-child(n)': {
      my: 1,
      mr: 2,
    },
  },
} as const;

type InputsProps = {
  inputState: HydModelDefinition;
  setInputState: (value: HydModelDefinition) => void;
};

const HydModelDefinitionInputsGroup: FC<InputsProps> = ({ inputState, setInputState }) => {
  const onNumberChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setInputState({ ...inputState, [event.target.name]: event.target.value });
  };

  const onChangeSwitch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputState({ ...inputState, [e.target.name]: e.target.checked });
  };

  const onChangeRadio = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === 'calculationType') {
      setInputState({
        ...inputState,
        calculationType: e.target.value as any,
        criterion: hydModelDefinitionInitialState.criterion,
      });
      return;
    }
    setInputState({ ...inputState, [e.target.name]: e.target.value });
  };

  return (
    <Box sx={{ ...styles.flexStartBox, ...styles.childSpacingAll }}>
      <Box>
        <Typography mb={1} variant={'subtitle1'}>
          Calculation Mode
        </Typography>
        <Box sx={{ ...styles.flexStartBox, ...styles.childSpacingX }}>
          <Box>
            <RadioGroup
              aria-label="calcMode"
              name="calculationType"
              value={inputState.calculationType}
              onChange={onChangeRadio}
            >
              <FormControlLabel
                labelPlacement={'end'}
                value="quansi"
                control={<CustomRadio />}
                label={<Typography variant={'subtitle2'}>Quasi-stationary</Typography>}
              />
              <FormControlLabel
                labelPlacement={'end'}
                value="instationary"
                control={<CustomRadio />}
                label={<Typography variant={'subtitle2'}>Instationary</Typography>}
              />
            </RadioGroup>
          </Box>
          <BoxComponent disabled={inputState.calculationType === 'instationary'}>
            <LabelWrapper label={'Convergence criterion (%)'}>
              <CustomInput
                disabled={inputState.calculationType === 'instationary'}
                min={0.00000001}
                required
                step={'any'}
                errorText={'Enter positive number'}
                value={inputState.criterion}
                type={'number'}
                onChange={onNumberChange}
                name={'criterion'}
              />
            </LabelWrapper>
          </BoxComponent>
        </Box>
      </Box>
      <Box>
        <Typography mb={1} variant={'subtitle1'}>
          Solution Scheme & Filtering
        </Typography>
        <Box sx={{ ...styles.flexStartBox, ...styles.childSpacingX }}>
          <Box>
            <RadioGroup
              aria-label="btmFriction"
              name="filteringType"
              value={inputState.filteringType}
              onChange={onChangeRadio}
            >
              <FormControlLabel
                labelPlacement={'end'}
                value="euler"
                control={<CustomRadio />}
                label={<Typography variant={'subtitle2'}>Euler</Typography>}
              />
              <FormControlLabel
                labelPlacement={'end'}
                value="2ndOrder"
                control={<CustomRadio />}
                label={<Typography variant={'subtitle2'}>Runge-Kutta 2nd order</Typography>}
              />
              <FormControlLabel
                labelPlacement={'end'}
                value="3rdOrder"
                control={<CustomRadio />}
                label={<Typography variant={'subtitle2'}>Runge-Kutta 3rd order (SSP)</Typography>}
              />
            </RadioGroup>
          </Box>
          <Box>
            <LabelWrapper label={'Include filtering'}>
              <Box sx={{ ...styles.spacingJustifyContainer }}>
                <CustomSwitch checked={inputState.filtering} name={'filtering'} onChange={onChangeSwitch} />
              </Box>
            </LabelWrapper>
          </Box>
        </Box>
      </Box>
      <Box>
        <Typography mb={1} variant={'subtitle1'}>
          Simulation Time
        </Typography>
        <Box sx={{ ...styles.flexStartBox, ...styles.childSpacing }}>
          <Box>
            <LabelWrapper label={'Simulation time(s)'}>
              <CustomInput
                min={0.00000001}
                required
                step={'any'}
                errorText={'Enter positive number'}
                value={inputState.simulationTime}
                type={'number'}
                onChange={onNumberChange}
                name={'simulationTime'}
              />
            </LabelWrapper>
          </Box>
          <Box>
            <LabelWrapper label={'Time step (s)'}>
              <CustomInput
                min={0.00000001}
                required
                step={'any'}
                errorText={'Enter positive number'}
                value={inputState.timeSteps}
                type={'number'}
                onChange={onNumberChange}
                name={'timeSteps'}
              />
            </LabelWrapper>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default memo(HydModelDefinitionInputsGroup);
