import { OutputFile } from '../../../models/jobModels/ModelsFileTypes';
import { HydPlotArea, HydPlotLine } from '../../../models/inputTypes/HydFields';
import {
  hydPlotAreasAliases,
  hydPlotAreasInitialState,
  hydPlotLinesAliases,
  hydPlotLinesInitialState,
} from '../../initialStates/hydInputState';

export const hydOutputAreaFilePreparation = (plotAreas: HydPlotArea[], projectId: string) => {
  const areaOutput: OutputFile[] = [];
  plotAreas.forEach((area, i) => {
    if (area.u) {
      areaOutput.push({
        alias: hydPlotAreasAliases.name_u + `_${i + 1}.csv`,
        project: projectId.toString(),
        name: (area.name_u || hydPlotAreasInitialState.name_u + `_${i + 1}`) + '.csv',
      });
    }
    if (area.v) {
      areaOutput.push({
        alias: hydPlotAreasAliases.name_v + `_${i + 1}.csv`,
        project: projectId.toString(),
        name: (area.name_v || hydPlotAreasInitialState.name_v + `_${i + 1}`) + '.csv',
      });
    }
    if (area.z) {
      areaOutput.push({
        alias: hydPlotAreasAliases.name_z + `_${i + 1}.csv`,
        project: projectId.toString(),
        name: (area.name_z || hydPlotAreasInitialState.name_z + `_${i + 1}`) + '.csv',
      });
    }
    if (area.speed) {
      areaOutput.push({
        alias: hydPlotAreasAliases.name_speed + `_${i + 1}.csv`,
        project: projectId.toString(),
        name: (area.name_speed || hydPlotAreasInitialState.name_speed + `_${i + 1}`) + '.csv',
      });
    }
    if (area.direction) {
      areaOutput.push({
        alias: hydPlotAreasAliases.name_direction + `_${i + 1}.csv`,
        project: projectId.toString(),
        name: (area.name_direction || hydPlotAreasInitialState.name_direction + `_${i + 1}`) + '.csv',
      });
    }
    if (area.depth) {
      areaOutput.push({
        alias: hydPlotAreasAliases.name_depth + `_${i + 1}.csv`,
        project: projectId.toString(),
        name: (area.name_depth || hydPlotAreasInitialState.name_depth + `_${i + 1}`) + '.csv',
      });
    }
  });
  return areaOutput;
};

export const hydOutputLineFilePreparation = (plotLines: HydPlotLine[], projectId: string) => {
  const lineOutput: OutputFile[] = [];
  plotLines.forEach((line, i) => {
    if (line.u) {
      lineOutput.push({
        alias: hydPlotLinesAliases.name_u + `_${i + 1}.csv`,
        project: projectId.toString(),
        name: (line.name_u || hydPlotLinesInitialState.name_u + `_${i + 1}`) + '.csv',
      });
    }
    if (line.v) {
      lineOutput.push({
        alias: hydPlotLinesAliases.name_v + `_${i + 1}.csv`,
        project: projectId.toString(),
        name: (line.name_v || hydPlotLinesInitialState.name_v + `_${i + 1}`) + '.csv',
      });
    }
    if (line.z) {
      lineOutput.push({
        alias: hydPlotLinesAliases.name_z + `_${i + 1}.csv`,
        project: projectId.toString(),
        name: (line.name_z || hydPlotLinesInitialState.name_z + `_${i + 1}`) + '.csv',
      });
    }
    if (line.speed) {
      lineOutput.push({
        alias: hydPlotLinesAliases.name_speed + `_${i + 1}.csv`,
        project: projectId.toString(),
        name: (line.name_speed || hydPlotLinesInitialState.name_speed + `_${i + 1}`) + '.csv',
      });
    }
    if (line.direction) {
      lineOutput.push({
        alias: hydPlotLinesAliases.name_direction + `_${i + 1}.csv`,
        project: projectId.toString(),
        name: (line.name_direction || hydPlotLinesInitialState.name_direction + `_${i + 1}`) + '.csv',
      });
    }
    if (line.depth) {
      lineOutput.push({
        alias: hydPlotLinesAliases.name_depth + `_${i + 1}.csv`,
        project: projectId.toString(),
        name: (line.name_depth || hydPlotLinesInitialState.name_depth + `_${i + 1}`) + '.csv',
      });
    }
  });
  return lineOutput;
};
