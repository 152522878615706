import { customApi } from '../Api';
import { openErrorToast, openSuccessToast, setGlobalLoading } from '../../slices/appSlice';

const extendedApi = customApi.injectEndpoints({
  endpoints: (builder) => ({
    deleteJobs: builder.mutation({
      query: (id) => ({
        url: `/api/jobs/${id}`,
        method: 'DELETE',
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          dispatch(setGlobalLoading(true));
          await queryFulfilled;
          dispatch(openSuccessToast('Job deleted successfully!'));
          dispatch(setGlobalLoading(false));
        } catch (err) {
          dispatch(openErrorToast('Error deleting job. Please try again!'));
          dispatch(setGlobalLoading(false));
        }
      },
      invalidatesTags: ['jobs'],
    }),
    deleteAllJobs: builder.mutation({
      query: () => ({
        url: `/api/jobs/`,
        method: 'DELETE',
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          dispatch(setGlobalLoading(true));
          await queryFulfilled;
          dispatch(openSuccessToast('Jobs deleted successfully!'));
        } catch (err) {
          dispatch(openErrorToast('Error deleting jobs. Please try again!'));
        }
        dispatch(setGlobalLoading(false));
      },
      invalidatesTags: ['jobs'],
    }),
    createJobs: builder.mutation<any, any>({
      query: (job) => ({
        url: `/api/jobs/`,
        method: 'POST',
        body: job,
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          dispatch(setGlobalLoading(true));
          await queryFulfilled;
          dispatch(openSuccessToast('Job submitted successfully!'));
        } catch (err: any) {
          dispatch(openErrorToast(err.error.data.error));
        }
        dispatch(setGlobalLoading(false));
      },
      invalidatesTags: ['jobs', 'projectFiles'],
    }),
    createEviJob: builder.mutation<any, any>({
      query: (job) => ({
        url: `/api/evi/`,
        method: 'POST',
        body: job,
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          dispatch(setGlobalLoading(true));
          await queryFulfilled;
          dispatch(openSuccessToast('Job submitted successfully!'));
        } catch (err: any) {
          dispatch(openErrorToast(err.error.data.error));
        }
        dispatch(setGlobalLoading(false));
      },
      invalidatesTags: ['jobs', 'projectFiles'],
    }),
  }),
  overrideExisting: false,
});

export const { useDeleteJobsMutation, useDeleteAllJobsMutation, useCreateEviJobMutation, useCreateJobsMutation } =
  extendedApi;
