import { SrfSubmission } from '../../models/inputTypes/SrfFields';
import { srfNumericalDomainInitialState, srfSubmissionInitialState } from '../initialStates/SrfInputState';
import { PmsSubmission } from '../../models/inputTypes/PmsFields';
import { OutputFile } from '../../models/jobModels/ModelsFileTypes';
import { prepareInputFileSimulation } from '../functions/prepareInputFileSimulation';
import { findOutputFileName } from '../functions/findOutputFileName';
import { SdtType } from '../../models/jobModels/sdtType';
import {
  SdtInputForcing,
  SdtModelDefinition,
  SdtModelFeatures,
  SdtNumericalDomain,
  SdtOutput,
  SdtPlotArea,
  SdtPlotLine,
} from '../../models/inputTypes/SdtFields';
import {
  sdtAreaAliases,
  sdtLineAliases,
  sdtNumericalDomainInitialState,
  sdtPlotAreaInitialState,
  sdtPlotLineInitialState,
} from '../initialStates/sdtInputState';

export type SdtSimulationPreparationResponse = {
  numerical: SdtNumericalDomain;
  modelDefinition: SdtModelDefinition;
  modelFeatures: SdtModelFeatures;
  inputForcing: SdtInputForcing;
  output: SdtOutput;
  submission: PmsSubmission;
};

export const sdtSimulationPreparation = (simulationFile: SdtType): SdtSimulationPreparationResponse | undefined => {
  const I_Depths = prepareInputFileSimulation(simulationFile.parameters.input_files, 'I_Depths.csv');
  const I_Roughness = prepareInputFileSimulation(simulationFile.parameters.input_files, 'I_Roughness.csv');
  const I_D50 = prepareInputFileSimulation(simulationFile.parameters.input_files, 'I_D50.csv');
  const I_Hard_Bottom = prepareInputFileSimulation(simulationFile.parameters.input_files, 'I_Hard_Bottom.csv');
  const I_U_Velocity = prepareInputFileSimulation(simulationFile.parameters.input_files, 'I_U_Velocity.csv');
  const I_V_Velocity = prepareInputFileSimulation(simulationFile.parameters.input_files, 'I_V_Velocity.csv');
  const I_Water_Depths = prepareInputFileSimulation(simulationFile.parameters.input_files, 'I_Water_Depths.csv');
  const I_MWD = prepareInputFileSimulation(simulationFile.parameters.input_files, 'I_MWD.csv');
  const I_Period = prepareInputFileSimulation(simulationFile.parameters.input_files, 'I_Period.csv');
  const I_Heights = prepareInputFileSimulation(simulationFile.parameters.input_files, 'I_Heights.csv');
  const I_Sources = prepareInputFileSimulation(simulationFile.parameters.input_files, 'I_Sources.csv');

  const numerical: SdtNumericalDomain = {
    project: simulationFile.project ? simulationFile.project.toString() : srfNumericalDomainInitialState.project,
    cellX: simulationFile.parameters.im,
    cellY: simulationFile.parameters.jm,
    cellSizeX: simulationFile.parameters.dx,
    cellSizeY: simulationFile.parameters.dy,
    bathymetryFile: I_Depths,
    neX: simulationFile.parameters?.ne_x ?? sdtNumericalDomainInitialState.neX,
    neY: simulationFile.parameters?.ne_y ?? sdtNumericalDomainInitialState.neY,
    swX: simulationFile.parameters?.sw_x ?? sdtNumericalDomainInitialState.swX,
    swY: simulationFile.parameters?.sw_y ?? sdtNumericalDomainInitialState.swY,
  };

  const modelDefinition: SdtModelDefinition = {
    calculationType: simulationFile.parameters.time_varying == 1 ? 'varying' : 'stationary',
    stationaryHyd: simulationFile.parameters.hyd_sframe,
    stationaryWave: simulationFile.parameters.wave_sframe,
    varyingHyd: simulationFile.parameters.hyd_nframes,
    varyingWave: simulationFile.parameters.wave_nframes,
    timeStep: simulationFile.parameters.time_step,
    simulationTime: simulationFile.parameters.sim_max_duration,
  };

  const modelFeatures: SdtModelFeatures = {
    density: simulationFile.parameters.density,
    porosity: simulationFile.parameters.porosity,
    diameterType: simulationFile.parameters.diameter_flag == 1 ? 'varying' : 'constant',
    d50: simulationFile.parameters.mean_grain_diameter,
    d50File: I_D50,
    roughness: simulationFile.parameters.bed_roughness_type.toString(),
    roughnessType: simulationFile.parameters.bed_roughness_varying == 1 ? 'varying' : 'constant',
    roughnessCoefficient: simulationFile.parameters.constant_bed_roughness,
    roughnessFile: I_Roughness,
    hardBottom: simulationFile.parameters.hard_bottom == 1,
    hardBottomFile: I_Hard_Bottom,
    sediment: simulationFile.parameters.sediment_transport_formula.toString(),
    bedLoad: simulationFile.parameters.calibration_coefficient,
    suspendedLoad: simulationFile.parameters.suspension_coefficient,
    maxBedRate: simulationFile.parameters.bed_level_change,
    startTimeStep: simulationFile.parameters.morphology_stime,
    morphologicalAcc: simulationFile.parameters.morfac,
    bedUpdating: simulationFile.parameters.bed_update_scheme.toString(),
  };

  const inputForcing: SdtInputForcing = {
    I_Sources: I_Sources,
    uVelocityFile: I_U_Velocity,
    vVelocityFile: I_V_Velocity,
    waterDepthFile: I_Water_Depths,
    waveHeightFile: I_Heights,
    waveFieldType: simulationFile.parameters.wave_period == 1 ? 'varying' : 'constant',
    wavePeriod: simulationFile.parameters.wave_constant,
    wavePeriodFile: I_Period,
    waveVelocity: simulationFile.parameters.wave_velocity.toString(),
    waveDirectionFile: I_MWD,
    fluxSources: simulationFile.parameters.nsources,
    startExternalSources: simulationFile.parameters.stssi,
    endExternalSources: simulationFile.parameters.etssi,
  };

  const output: SdtOutput = {
    plotAreas: +simulationFile.parameters.plot_areas_length,
    plotLines: +simulationFile.parameters.plot_lines_length,
    plotAreaValues: prepareSdtPlotAreas(simulationFile.parameters.plot_areas, simulationFile.parameters.output_files),
    plotLineValues: prepareSdtPlotLines(simulationFile.parameters.plot_lines, simulationFile.parameters.output_files),
  };

  const submission: SrfSubmission = {
    vCpus: simulationFile.cores ? simulationFile.cores : srfSubmissionInitialState.vCpus,
    simulationFile: srfSubmissionInitialState.simulationFile,
  };

  return { numerical, modelDefinition, modelFeatures, inputForcing, output, submission };
};

const prepareSdtPlotAreas = (areas: Partial<SdtPlotArea>[], outputFiles: OutputFile[]) => {
  const preparedAreas: SdtPlotArea[] = [];
  areas.map((area, i) => {
    preparedAreas.push({
      sw_x: area.sw_x ?? sdtPlotAreaInitialState.sw_x,
      sw_y: area.sw_y ?? sdtPlotAreaInitialState.sw_y,
      ne_x: area.ne_x ?? sdtPlotAreaInitialState.ne_x,
      ne_y: area.ne_y ?? sdtPlotAreaInitialState.ne_y,
      bl: area.bl ?? 0,
      dhdt: area.dhdt ?? 0,
      qtx: area.qtx ?? 0,
      qty: area.qty ?? 0,
      qtmag: area.qtmag ?? 0,
      qtdir: area.qtdir ?? 0,
      init_depths: area.init_depths ?? 0,
      name_bl: findOutputFileName(
        outputFiles,
        sdtAreaAliases.name_bl,
        i + 1,
        sdtPlotAreaInitialState.name_bl,
        !area.bl,
      ),
      name_dhdt: findOutputFileName(
        outputFiles,
        sdtAreaAliases.name_dhdt,
        i + 1,
        sdtPlotAreaInitialState.name_dhdt,
        !area.dhdt,
      ),
      name_qtx: findOutputFileName(
        outputFiles,
        sdtAreaAliases.name_qtx,
        i + 1,
        sdtPlotAreaInitialState.name_qtx,
        !area.qtx,
      ),
      name_qty: findOutputFileName(
        outputFiles,
        sdtAreaAliases.name_qty,
        i + 1,
        sdtPlotAreaInitialState.name_qty,
        !area.qty,
      ),
      name_qtmag: findOutputFileName(
        outputFiles,
        sdtAreaAliases.name_qtmag,
        i + 1,
        sdtPlotAreaInitialState.name_qtmag,
        !area.qtmag,
      ),
      name_qtdir: findOutputFileName(
        outputFiles,
        sdtAreaAliases.name_qtdir,
        i + 1,
        sdtPlotAreaInitialState.name_qtdir,
        !area.qtdir,
      ),
      name_init_depths: findOutputFileName(
        outputFiles,
        sdtAreaAliases.name_init_depths,
        i + 1,
        sdtPlotAreaInitialState.name_init_depths,
        !area.init_depths,
      ),
      start_step: area.start_step ?? sdtPlotAreaInitialState.start_step,
      end_step: area.end_step ?? sdtPlotAreaInitialState.end_step,
      interval: area.interval ?? sdtPlotAreaInitialState.interval,
    });
  });
  return preparedAreas;
};

const prepareSdtPlotLines = (lines: Partial<SdtPlotLine>[], outputFiles: OutputFile[]) => {
  const preparedLines: SdtPlotLine[] = [];
  lines.map((line, i) => {
    preparedLines.push({
      fp_x: line.fp_x ?? 0,
      fp_y: line.fp_y ?? 0,
      lp_x: line.lp_x ?? 0,
      lp_y: line.lp_y ?? 0,
      bl: line.bl ?? 0,
      dhdt: line.dhdt ?? 0,
      name_bl: findOutputFileName(
        outputFiles,
        sdtLineAliases.name_bl,
        i + 1,
        sdtPlotLineInitialState.name_bl,
        !line.bl,
      ),
      name_dhdt: findOutputFileName(
        outputFiles,
        sdtLineAliases.name_dhdt,
        i + 1,
        sdtPlotLineInitialState.name_dhdt,
        !line.dhdt,
      ),
      start_step: line.start_step ?? 12,
      end_step: line.end_step ?? sdtPlotLineInitialState.end_step,
      interval: line.interval ?? sdtPlotLineInitialState.interval,
    });
  });
  return preparedLines;
};
