import { srfType } from '../../models/jobModels/srfType';
import {
  SrfNumericalDomain,
  SrfReflection,
  SrfSponge,
  SrfSubmission,
  SrfSwitches,
} from '../../models/inputTypes/SrfFields';
import {
  srfNumericalDomainInitialState,
  srfReflectionInitialState,
  srfSpongeInitialState,
  srfSubmissionInitialState,
} from '../initialStates/SrfInputState';
import { prepareInputFileSimulation } from '../functions/prepareInputFileSimulation';

export type srfSimulationPreparationResponse = {
  numerical: SrfNumericalDomain;
  switches: SrfSwitches;
  sponge: SrfSponge;
  ref: SrfReflection;
  submission: SrfSubmission;
};

export const srfSimulationPreparation = (simulationFile: srfType): srfSimulationPreparationResponse | undefined => {
  const O_Sponge = simulationFile.parameters.output_files.find((data) => data.alias == 'O_Sponge.csv');
  const O_Eddy = simulationFile.parameters.output_files.find((data) => data.alias == 'O_Eddy.csv');

  const preparedDepthFile = prepareInputFileSimulation(simulationFile.parameters.input_files, 'I_Depths.csv');

  const numerical: SrfNumericalDomain = {
    project: simulationFile.project ? simulationFile.project.toString() : srfNumericalDomainInitialState.project,
    cellX: simulationFile.parameters.im,
    cellY: simulationFile.parameters.jm,
    cellSizeX: simulationFile.parameters.dx,
    cellSizeY: simulationFile.parameters.dy,
    bathymetryFile: preparedDepthFile,
    swX: simulationFile.parameters.sw_x,
    swY: simulationFile.parameters.sw_y,
    neX: simulationFile.parameters.ne_x,
    neY: simulationFile.parameters.ne_y,
  };

  const switches: SrfSwitches = {
    sponge: !!simulationFile.parameters.sponge_map,
    ref: !!simulationFile.parameters.layer_map,
  };

  const sponge: SrfSponge = {
    nameSpongeLayer: O_Sponge?.name ? O_Sponge.name.split('.csv')[0] : srfSpongeInitialState.nameSpongeLayer,
    addValues: simulationFile.parameters.sponge_back_add,
    backgroundValue: simulationFile.parameters.sponge_back_val,
    baseValue: simulationFile.parameters.sponge_base,
    layerNumber: simulationFile.parameters.sponge_back_layers,
    powerValue: simulationFile.parameters.sponge_power,
  };

  const ref: SrfReflection = {
    reflectionMap: O_Eddy?.name ? O_Eddy.name.split('.csv')[0] : srfReflectionInitialState.reflectionMap,
    backgroundValue: simulationFile.parameters.eddy,
    landCodeValues: simulationFile.parameters.codes_length,
    codes_list: simulationFile.parameters.codes_list,
  };

  const submission: SrfSubmission = {
    vCpus: simulationFile.cores ? simulationFile.cores : srfSubmissionInitialState.vCpus,
    simulationFile: srfSubmissionInitialState.simulationFile,
  };
  return { numerical, switches, sponge, ref, submission };
};
