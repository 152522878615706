import {
  SdtInputForcing,
  SdtModelDefinition,
  SdtModelFeatures,
  SdtNumericalDomain,
  SdtOutput,
  SdtPlotArea,
  SdtPlotLine,
} from '../../models/inputTypes/SdtFields';

export const sdtNumericalDomainInitialState: SdtNumericalDomain = {
  project: '',
  simulationSetup: undefined,
  cellX: 1,
  cellY: 1,
  cellSizeX: 2.5,
  cellSizeY: 2.5,
  bathymetryFile: undefined,
  swX: 1,
  swY: 1,
  neX: 1,
  neY: 1,
};

export const sdtModelDefinitionInitialState: SdtModelDefinition = {
  calculationType: 'stationary',
  stationaryHyd: 1,
  stationaryWave: 1,
  varyingHyd: 2,
  varyingWave: 2,
  timeStep: 1,
  simulationTime: 3600,
};

export const sdtModelFeaturesInitialState: SdtModelFeatures = {
  density: 2650,
  porosity: 0.4,
  diameterType: 'constant',
  d50: 1.99,
  d50File: undefined,
  roughness: '1',
  roughnessType: 'constant',
  roughnessCoefficient: 0.02,
  roughnessFile: undefined,
  hardBottom: false,
  hardBottomFile: undefined,
  sediment: '3',
  bedLoad: 1,
  suspendedLoad: 1,
  maxBedRate: 5,
  startTimeStep: 1,
  morphologicalAcc: 1,
  bedUpdating: '1',
};

export const sdtInputForcingInitialState: SdtInputForcing = {
  uVelocityFile: undefined,
  vVelocityFile: undefined,
  waterDepthFile: undefined,
  waveHeightFile: undefined,
  I_Sources: undefined,
  waveFieldType: 'constant',
  wavePeriod: 10,
  wavePeriodFile: undefined,
  waveVelocity: '1',
  waveDirectionFile: undefined,
  fluxSources: 0,
  startExternalSources: 5,
  endExternalSources: 10,
};

export const sdtOutputInitialState: SdtOutput = {
  plotAreas: 0,
  plotLines: 0,
  plotAreaValues: [],
  plotLineValues: [],
};

export const sdtPlotAreaInitialState: SdtPlotArea = {
  sw_x: 1,
  sw_y: 1,
  ne_x: 1,
  ne_y: 1,
  bl: 0,
  dhdt: 0,
  qtx: 0,
  qty: 0,
  qtmag: 0,
  qtdir: 0,
  init_depths: 0,

  name_bl: 'BL_Area',
  name_dhdt: 'DZDT_Area',
  name_qtx: 'Qtx_Area',
  name_qty: 'Qty_Area',
  name_qtmag: 'Qtmag_Area',
  name_qtdir: 'Qtdir_Area',
  name_init_depths: 'In_Depths_Area',

  start_step: 1,
  end_step: 16000,
  interval: 1000,
};

export const sdtPlotLineInitialState: SdtPlotLine = {
  fp_x: 1,
  fp_y: 1,
  lp_x: 1,
  lp_y: 1,
  bl: 0,
  dhdt: 0,
  name_bl: 'BL_Line',
  name_dhdt: 'DZDT_Line',
  start_step: 1,
  end_step: 16000,
  interval: 1000,
};

export const sdtLineAliases = {
  name_bl: 'O_BL_Line',
  name_dhdt: 'O_DZDT_Line',
};

export const sdtAreaAliases = {
  name_bl: 'O_BL_Area',
  name_dhdt: 'O_DZDT_Area',
  name_qtx: 'O_Qtx_Area',
  name_qty: 'O_Qty_Area',
  name_qtmag: 'O_Qtmag_Area',
  name_qtdir: 'O_Qtdir_Area',
  name_init_depths: 'O_In_Depths_Area',
};
