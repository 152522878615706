import React from 'react';
import { MenuItem, MenuItemProps, styled } from '@mui/material';

type customSelectProps = {
  title?: string;
};

const CustomMenuItem = styled((props: MenuItemProps & customSelectProps) => <MenuItem {...props} />)(({}) => ({
  padding: '4px 4px',
  borderRadius: 4,
  margin: 3,
  '&.Mui-selected': {
    background: '#E0EFF5',
    '&:hover': {
      background: '#E0EFF5',
    },
  },
}));

export default CustomMenuItem;
