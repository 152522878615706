import React, { FC, useCallback, useEffect, useRef } from 'react';
import Decimal from 'decimal.js';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';

const styles = {
  gradient: {
    width: '1rem',
    outline: 'thin solid #000',
    border: 'thin solid #000',
  },
  gradientNumbers: {
    marginTop: '-0.5225rem',
    marginBottom: '-0.5225rem',
    marginLeft: 4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    zIndex: 2,
  },
  gradientNumbersText: {
    textAlign: 'left',
    fontSize: '0.65rem',
  },
} as const;

const FileHeatmapGradient: FC<{ levels: number; gradientColors: number[][] | undefined; values: any }> = ({
  levels,
  gradientColors,
  values,
  ...rest
}) => {
  const gradientRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const gradient: HTMLDivElement | null = gradientRef.current;
    if (gradientColors && gradient) {
      const colorSteps = gradientColors
        .slice()
        .reverse()
        .map((color) => `rgba(${color.join(',')})`)
        .join(',');
      gradient.style.backgroundImage = `linear-gradient(${colorSteps})`;
    }
  }, [gradientColors, gradientRef.current]);

  const gradientNumbers = useCallback(() => {
    const gradientValues: number[] = [];
    if (values) {
      const loopLevels = levels < 21 ? levels - 1 : 19;
      const step = new Decimal(values.max).minus(values.min).div(loopLevels);
      for (let st = 0; st <= loopLevels; st++) {
        const sub = new Decimal(step).mul(st);
        const val = new Decimal(values.max).sub(sub).toDP(4);
        gradientValues.push(val.toNumber());
      }
    }
    return gradientValues;
  }, [values]);

  return (
    <Box display={'flex'}>
      <div ref={gradientRef} style={styles.gradient} />
      <div style={styles.gradientNumbers}>
        {gradientNumbers().map((value, index) => (
          <Typography sx={styles.gradientNumbersText} key={index} variant={'caption'}>
            {value}
          </Typography>
        ))}
      </div>
    </Box>
  );
};

export default FileHeatmapGradient;
