import React, { FC, useEffect, useState } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { Box, CircularProgress, Grid, styled, Typography } from '@mui/material';
import loginIcon from '../../assets/images/login.png';
import { ReactComponent as ScientiaLogo } from '../../assets/images/scientiaMaris.svg';
import LoginInput from '../../components/common/LoginInput';
import LabelWrapper from '../../components/common/LabelWrapper';
import CustomButton from '../../components/custom/CustomButton';
import { useResetPasswordConfirmMutation } from '../../redux/RTK/mutations/resetPasswordMutations';

const BoxBackground = styled(Box)(() => ({
  overflow: 'auto',
  background: `url(${loginIcon})`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  height: '100vh',
  width: '100vw',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const Card = styled(Box)(({ theme }) => ({
  background: '#FAFAFA',
  width: '280px',
  height: '345px',
  borderRadius: 4,
  boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.24)',
  padding: '38px 32px',
  '& >:first-child': {
    display: 'flex',
    margin: theme.spacing(0, 'auto', 4.5, 'auto'),
  },

  form: {
    '& div': {
      marginBottom: theme.spacing(2),
    },
  },
}));

const ResetPassword: FC = () => {
  const [reset, { isSuccess, isLoading }] = useResetPasswordConfirmMutation();
  const { uid, token }: { uid: string; token: string } = useParams();
  const [inputs, setInputs] = useState<{
    uid: string;
    token: string;
    new_password1: string;
    new_password2: string;
  }>({
    uid: uid,
    token: token,
    new_password1: '',
    new_password2: '',
  });
  const [passwordError, setPasswordError] = useState({ new_password1: '', new_password2: '' });

  useEffect(() => {
    document.title = 'Reset your password - Scientia Maris';
  }, []);

  const onFormSubmit = (e: any) => {
    e.preventDefault();
    if (inputs.new_password1 && inputs.new_password2) {
      reset(inputs);
    }
  };

  const onTypeChange = (e: any) => {
    if (e.key === 'Enter') {
      const form = e.target.form;
      const new_password1 = form.elements[0];
      const new_password2 = form.elements[1];
      if (!new_password1.value) {
        new_password1.focus();
        return;
      }
      if (!new_password2.value) {
        new_password2.focus();
        return;
      }
    }
  };

  const isPassEmptyNonEqual =
    inputs.new_password1 !== inputs.new_password2 || !inputs.new_password1 || !inputs.new_password2;

  const handlePasswordChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setInputs({ ...inputs, [event.target.name]: event.target.value });
  };

  return (
    <BoxBackground>
      <Card>
        <ScientiaLogo />
        <Box>
          <form onKeyUp={onTypeChange} onSubmit={onFormSubmit}>
            <LabelWrapper label={'Enter new password'}>
              <LoginInput
                fullWidth
                error={!!passwordError.new_password2 || inputs.new_password2 !== inputs.new_password1}
                errorText={passwordError.new_password2 ? passwordError.new_password2 : 'Passwords dont match!'}
                isPasswordType
                value={inputs.new_password1}
                name={'new_password1'}
                onChange={handlePasswordChange}
              />
            </LabelWrapper>
            <LabelWrapper label={'Re-enter new password'}>
              <LoginInput
                fullWidth
                error={!!passwordError.new_password2 || inputs.new_password2 !== inputs.new_password1}
                errorText={passwordError.new_password2 ? passwordError.new_password2 : 'Passwords dont match!'}
                isPasswordType
                value={inputs.new_password2}
                name={'new_password2'}
                onChange={handlePasswordChange}
              />
            </LabelWrapper>
            <CustomButton
              type={'submit'}
              variant={'contained'}
              disabled={isPassEmptyNonEqual || isLoading}
              fullWidth
              color={'primary'}
            >
              {isLoading ? (
                <CircularProgress size={18} sx={{ color: 'white' }} />
              ) : (
                <Typography variant={'subtitle2'}>Reset Password</Typography>
              )}
            </CustomButton>
            {isSuccess === true && <Redirect to={'/password/reset'} />}
          </form>
        </Box>
      </Card>
    </BoxBackground>
  );
};

export default ResetPassword;
