import {
  SdtInputForcing,
  SdtModelDefinition,
  SdtModelFeatures,
  SdtNumericalDomain,
  SdtOutput,
} from '../../models/inputTypes/SdtFields';
import { PmsSubmission } from '../../models/inputTypes/PmsFields';
import { SdtType } from '../../models/jobModels/sdtType';
import { dynamicInputFilePreparation } from '../functions/dynamicInputFilePreparation';
import { sdtOutputAreaFilePreparation, sdtOutputLineFilePreparation } from './filePreparation/sdtFilePreparation';
import { sdtPlotAreaInitialState, sdtPlotLineInitialState } from '../initialStates/sdtInputState';

export const sdtPreparation = (
  numericalState: SdtNumericalDomain,
  modelDefinitionState: SdtModelDefinition,
  modelFeaturesState: SdtModelFeatures,
  inputForcingState: SdtInputForcing,
  outputState: SdtOutput,
  submissionState: PmsSubmission,
): SdtType | unknown => {
  const inputFiles = dynamicInputFilePreparation({
    I_Depths: numericalState.bathymetryFile,
    I_Roughness: modelFeaturesState.roughnessFile,
    I_D50: modelFeaturesState.d50File,
    I_Hard_Bottom: modelFeaturesState.hardBottomFile,
    I_U_Velocity: inputForcingState.uVelocityFile,
    I_V_Velocity: inputForcingState.vVelocityFile,
    I_Water_Depths: inputForcingState.waterDepthFile,
    I_MWD: inputForcingState.waveDirectionFile,
    I_Period: inputForcingState.wavePeriodFile,
    I_Heights: inputForcingState.waveHeightFile,
    I_Sources: inputForcingState.I_Sources,
  });

  const outputArea = sdtOutputAreaFilePreparation(outputState.plotAreaValues, numericalState.project);
  const outputLine = sdtOutputLineFilePreparation(outputState.plotLineValues, numericalState.project);

  const preparedValues: SdtType = {
    simulation: 'sdt',
    project: numericalState.project.toString(),
    cores: +submissionState.vCpus,
    name: submissionState.simulationFile,
    parameters: {
      input_files: inputFiles,
      output_files: [...outputArea, ...outputLine],
      im: +numericalState.cellX,
      jm: +numericalState.cellY,
      dx: +numericalState.cellSizeX,
      dy: +numericalState.cellSizeY,

      sw_x: +numericalState.swX,
      sw_y: +numericalState.swY,
      ne_x: +numericalState.neX,
      ne_y: +numericalState.neY,

      time_varying: modelDefinitionState.calculationType === 'varying' ? 1 : 0,
      wave_sframe: +modelDefinitionState.stationaryWave,
      hyd_sframe: +modelDefinitionState.stationaryHyd,
      time_step: +modelDefinitionState.timeStep,
      sim_max_duration: +modelDefinitionState.simulationTime,
      hyd_nframes: +modelDefinitionState.varyingHyd,
      wave_nframes: +modelDefinitionState.varyingWave,

      density: +modelFeaturesState.density,
      porosity: +modelFeaturesState.porosity,
      diameter_flag: modelFeaturesState.diameterType === 'varying' ? 1 : 0,
      mean_grain_diameter: +modelFeaturesState.d50, // is this value correct???
      bed_roughness_type: +modelFeaturesState.roughness,
      bed_roughness_varying: modelFeaturesState.roughnessType === 'varying' ? 1 : 0,
      constant_bed_roughness: +modelFeaturesState.roughnessCoefficient,
      hard_bottom: modelFeaturesState.hardBottom ? 1 : 0,
      sediment_transport_formula: +modelFeaturesState.sediment,
      calibration_coefficient: +modelFeaturesState.bedLoad,
      suspension_coefficient: +modelFeaturesState.suspendedLoad,

      bed_level_change: +modelFeaturesState.maxBedRate,
      morphology_stime: +modelFeaturesState.startTimeStep,
      morfac: +modelFeaturesState.morphologicalAcc,
      bed_update_scheme: +modelFeaturesState.bedUpdating,

      wave_period: inputForcingState.waveFieldType === 'varying' ? 1 : 0,
      wave_constant: +inputForcingState.wavePeriod,
      wave_velocity: +inputForcingState.waveVelocity,
      nsources: +inputForcingState.fluxSources,
      stssi: +inputForcingState.startExternalSources,
      etssi: +inputForcingState.endExternalSources,
      plot_areas_length: +outputState.plotAreas,
      plot_lines_length: +outputState.plotLines,
      plot_areas: outputState.plotAreaValues.map((area) => ({
        sw_x: +area.sw_x,
        sw_y: +area.sw_y,
        ne_x: +area.ne_x,
        ne_y: +area.ne_y,
        bl: +area.bl,
        dhdt: +area.dhdt,
        qtx: +area.qtx,
        qty: +area.qty,
        qtmag: +area.qtmag,
        qtdir: +area.qtdir,
        init_depths: +area.init_depths,

        start_step:
          modelDefinitionState.calculationType !== 'varying' ? sdtPlotAreaInitialState.start_step : +area.start_step,
        end_step:
          modelDefinitionState.calculationType !== 'varying' ? sdtPlotAreaInitialState.end_step : +area.end_step,
        interval:
          modelDefinitionState.calculationType !== 'varying' ? sdtPlotAreaInitialState.interval : +area.interval,
      })),
      plot_lines: outputState.plotLineValues.map((line) => ({
        fp_x: +line.fp_x,
        fp_y: +line.fp_y,
        lp_x: +line.lp_x,
        lp_y: +line.lp_y,
        bl: +line.bl,
        dhdt: +line.dhdt,
        start_step:
          modelDefinitionState.calculationType !== 'varying' ? sdtPlotLineInitialState.start_step : +line.start_step,
        end_step:
          modelDefinitionState.calculationType !== 'varying' ? sdtPlotLineInitialState.end_step : +line.end_step,
        interval:
          modelDefinitionState.calculationType !== 'varying' ? sdtPlotLineInitialState.interval : +line.interval,
      })),
    },
  };
  return preparedValues;
};
