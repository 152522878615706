import React, { FC } from 'react';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import { ReactComponent as SettingsIcon } from '../../assets/images/settings.svg';
import { ReactComponent as Logout } from '../../assets/images/logout.svg';

const profileStyles = {
  circle: {
    borderRadius: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '62px',
    minHeight: '62px',
    maxWidth: '65px',
    maxHeight: '65px',
    bgcolor: 'primary.main',
  },

  profileButtonsStyle: {
    paddingLeft: '4px',
  },
  rootStyle: {
    padding: '12px 4px 12px 12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  profileInfoStyle: {
    display: 'flex',
    alignItems: 'center',
  },
  userInfoStyle: {
    display: 'flex',
    justifyContent: 'center',
    'flex-direction': 'column',
    paddingLeft: 1,
  },
};

type ProfileContainerProps = {
  firstName: string;
  lastName: string;
  tag: string;
  onLogoutClick: () => void;
  onSettingsClick: () => void;
};

const ProfileContainer: FC<ProfileContainerProps> = ({ firstName, lastName, tag, onLogoutClick, onSettingsClick }) => {
  return (
    <Box sx={profileStyles.rootStyle}>
      <Box sx={profileStyles.profileInfoStyle}>
        <Box sx={profileStyles.circle}>
          <Typography align={'center'} color={'info.contrastText'} variant={'h1'}>
            {firstName[0]}
            {lastName[0]}
          </Typography>
        </Box>
        <Box sx={profileStyles.userInfoStyle}>
          <Typography variant={'h5'}>
            {firstName}
            <br />
            {lastName}
          </Typography>
          <Typography variant={'caption'} color={'info.main'}>
            {tag}
          </Typography>
        </Box>
      </Box>
      <Box sx={profileStyles.profileButtonsStyle}>
        <Grid direction={'column'} container>
          <Grid item>
            <IconButton onClick={onSettingsClick}>
              <SettingsIcon />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton onClick={onLogoutClick}>
              <Logout />
            </IconButton>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default ProfileContainer;
