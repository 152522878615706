import { FileForm } from '../../models/inputTypes/FileType';
import { findFileType } from './findFileType';

export const sortProjects = (data: any[], title: string, ascending: boolean) => {
  switch (title) {
    case 'Name':
      return data.sort((item1, item2) =>
        ascending ? item1.name.localeCompare(item2.name) : item2.name.localeCompare(item1.name),
      );
    case 'Date Created':
      return data.sort((item1, item2) => {
        const key1 = new Date(item1.created_at);
        const key2 = new Date(item2.created_at);
        if (key1 < key2) return ascending ? -1 : 1;
        if (key1 > key2) return ascending ? 1 : -1;
        return 0;
      });
  }
  return data;
};
