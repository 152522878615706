import React, { FC, memo, useMemo } from 'react';
import { Box, FormControlLabel, RadioGroup, SelectChangeEvent, Typography } from '@mui/material';
import CustomInput from '../../custom/CustomInput';
import LabelWrapper from '../../common/LabelWrapper';
import BoxComponent from '../../custom/BoxComponent';
import CustomRadio from '../../custom/CustomRadio';
import CustomMenuItem from '../../custom/CustomMenuItem';
import CustomSelect from '../../custom/CustomSelect';
import CustomSwitch from '../../custom/CustomSwitch';
import { SdtModelFeatures } from '../../../models/inputTypes/SdtFields';
import { FileType } from '../../../models/inputTypes/FileType';
import ButtonFileDialog from '../../common/ButtonFileDialog';
import { sdtModelFeaturesInitialState } from '../../../utils/initialStates/sdtInputState';

const styles = {
  childSpacing: {
    '& >:nth-child(n)': {
      my: 1,
      mr: { xs: 2, md: 3, xl: 4 },
    },
  },
  wrap: {
    flexWrap: 'wrap',
  },
  flexCenterBox: {
    display: 'flex',
    justifyContent: 'center',
  },
  spaceBetweenBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    maxWidth: '900px',
  },
  flexStartBox: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
  },
} as const;
type InputsProps = {
  calculationDisabled?: boolean;
  projectId: string;
  inputState: SdtModelFeatures;
  setInputState: (value: SdtModelFeatures) => void;
};
const SdtModelFeaturesInputsGroup: FC<InputsProps> = ({
  calculationDisabled = false,
  projectId,
  inputState,
  setInputState,
}) => {
  const d50Warning = useMemo(
    () => (inputState.d50 <= 0.1 || inputState.d50 >= 2 ? 'should be 0.1 < d50 < 2' : undefined),
    [inputState.d50],
  );

  const onInputChange = (
    event: SelectChangeEvent<unknown> | React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    if (event.target.name === 'sediment' && event.target.value === '4') {
      setInputState({
        ...inputState,
        sediment: event.target.value,
        suspendedLoad: sdtModelFeaturesInitialState.suspendedLoad,
      });
      return;
    }

    setInputState({ ...inputState, [event.target.name]: event.target.value });
  };

  const onNumberChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setInputState({ ...inputState, [event.target.name]: event.target.value });
  };

  const onFileChange = (file: FileType | undefined, fieldName: string) => {
    setInputState({ ...inputState, [fieldName]: file });
  };

  const onChangeSwitch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputState({ ...inputState, [e.target.name]: e.target.checked });
  };

  const onChangeRadio = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value as any;
    if (e.target.name === 'diameterType') {
      setInputState({
        ...inputState,
        diameterType: newValue,
        d50: sdtModelFeaturesInitialState.d50,
        d50File: undefined,
      });
      return;
    }
    if (e.target.name === 'roughnessType') {
      setInputState({
        ...inputState,
        roughnessType: newValue,
        roughnessCoefficient: sdtModelFeaturesInitialState.roughnessCoefficient,
        roughnessFile: undefined,
      });
      return;
    }
  };

  const onBedRoughnessChange = (
    event: SelectChangeEvent<any> | React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    const coValue = event.target.value == '1' ? 0.02 : event.target.value == '2' ? 32 : 0.002;
    setInputState({
      ...inputState,
      roughness: event.target.value,
      roughnessCoefficient: coValue,
    });
  };

  return (
    <Box>
      <Typography mb={1} variant={'subtitle1'}>
        Sediment Properties
      </Typography>
      <Box sx={{ ...styles.flexStartBox, ...styles.childSpacing }}>
        <Box>
          <LabelWrapper label={'Sediment density (kg/m^3)'}>
            <CustomInput
              min={0.00000000001}
              required
              step={'any'}
              errorText={'Enter positive number'}
              type={'number'}
              onChange={onNumberChange}
              value={inputState.density}
              name={'density'}
            />
          </LabelWrapper>
        </Box>
        <Box>
          <LabelWrapper label={'Sediment porosity (-)'}>
            <CustomInput
              min={0.00000000001}
              required
              step={'any'}
              errorText={'Enter positive number'}
              type={'number'}
              onChange={onNumberChange}
              value={inputState.porosity}
              name={'porosity'}
            />
          </LabelWrapper>
        </Box>
      </Box>

      <Typography mt={2} mb={1} variant={'subtitle1'}>
        Mean Grain Diameter
      </Typography>
      <Box sx={{ ...styles.spaceBetweenBox, ...styles.wrap }}>
        <Box sx={{ ...styles.flexStartBox, ...styles.childSpacing }}>
          <Box>
            <RadioGroup
              value={inputState.diameterType}
              onChange={onChangeRadio}
              aria-label="diameterType"
              name="diameterType"
            >
              <FormControlLabel
                labelPlacement={'end'}
                value="constant"
                control={<CustomRadio />}
                label={<Typography variant={'subtitle2'}>Constant</Typography>}
              />
              <FormControlLabel
                labelPlacement={'end'}
                value="varying"
                control={<CustomRadio />}
                label={<Typography variant={'subtitle2'}>Varying</Typography>}
              />
            </RadioGroup>
          </Box>
          <BoxComponent disabled={inputState.diameterType !== 'constant'}>
            <LabelWrapper label={'d50 (mm)'}>
              <CustomInput
                disabled={inputState.diameterType !== 'constant'}
                min={0.00000000001}
                required
                step={'any'}
                errorText={'Enter positive number'}
                type={'number'}
                onChange={onNumberChange}
                warningText={d50Warning}
                value={inputState.d50}
                name={'d50'}
              />
            </LabelWrapper>
          </BoxComponent>
        </Box>
        <BoxComponent disabled={inputState.diameterType !== 'varying'} mr={1} my={1}>
          <LabelWrapper label={'Insert d50 file'}>
            <ButtonFileDialog
              typePage={'SDT'}
              dataFilesOnly
              required
              projectId={projectId}
              disabled={!projectId || inputState.diameterType !== 'varying'}
              file={inputState.d50File}
              setFile={(file) => onFileChange(file, 'd50File')}
            />
          </LabelWrapper>
        </BoxComponent>
      </Box>

      <Typography mt={2} mb={1} variant={'subtitle1'}>
        Bed Roughness
      </Typography>
      <Box sx={{ ...styles.spaceBetweenBox, ...styles.wrap }}>
        <Box sx={{ ...styles.flexStartBox, ...styles.childSpacing }}>
          <BoxComponent>
            <LabelWrapper label={'Roughness type'}>
              <CustomSelect
                required
                onChange={onBedRoughnessChange}
                value={inputState.roughness}
                name={'roughness'}
                minWidth={'140px'}
                defaultValue={''}
                displayEmpty
              >
                <CustomMenuItem value={'1'}>
                  <Typography variant={'subtitle2'}>Manning</Typography>
                </CustomMenuItem>
                <CustomMenuItem value={'2'}>
                  <Typography variant={'subtitle2'}>Chezy</Typography>
                </CustomMenuItem>
                <CustomMenuItem value={'3'}>
                  <Typography variant={'subtitle2'}>Nikuradse</Typography>
                </CustomMenuItem>
                <CustomMenuItem value={'4'}>
                  <Typography variant={'subtitle2'}>Equivalent Roughness Length</Typography>
                </CustomMenuItem>
              </CustomSelect>
            </LabelWrapper>
          </BoxComponent>
          <Box>
            <RadioGroup
              value={inputState.roughnessType}
              onChange={onChangeRadio}
              aria-label="roughnessType"
              name="roughnessType"
            >
              <FormControlLabel
                labelPlacement={'end'}
                value="constant"
                control={<CustomRadio />}
                label={<Typography variant={'subtitle2'}>Constant</Typography>}
              />
              <FormControlLabel
                labelPlacement={'end'}
                value="varying"
                control={<CustomRadio />}
                label={<Typography variant={'subtitle2'}>Varying</Typography>}
              />
            </RadioGroup>
          </Box>
          <BoxComponent disabled={inputState.roughnessType !== 'constant'}>
            <LabelWrapper label={'Roughness coefficient'}>
              <CustomInput
                required
                step={'any'}
                disabled={inputState.roughnessType !== 'constant'}
                min={0.00000000001}
                errorText={'Enter positive number'}
                type={'number'}
                onChange={onNumberChange}
                value={inputState.roughnessCoefficient}
                name={'roughnessCoefficient'}
              />
            </LabelWrapper>
          </BoxComponent>
        </Box>
        <BoxComponent disabled={inputState.roughnessType !== 'varying'} mr={1} my={1}>
          <LabelWrapper label={'Insert roughness file'}>
            <ButtonFileDialog
              typePage={'SDT'}
              dataFilesOnly
              required
              projectId={projectId}
              disabled={!projectId || inputState.roughnessType !== 'varying'}
              file={inputState.roughnessFile}
              setFile={(file) => onFileChange(file, 'roughnessFile')}
            />
          </LabelWrapper>
        </BoxComponent>
      </Box>

      <Typography mt={2} mb={1} variant={'subtitle1'}>
        Hard Bottom
      </Typography>
      <Box sx={{ ...styles.spaceBetweenBox, ...styles.wrap }}>
        <Box>
          <LabelWrapper label={'Consider hard bottom'}>
            <Box sx={{ ...styles.flexCenterBox }}>
              <CustomSwitch checked={inputState.hardBottom} name={'hardBottom'} onChange={onChangeSwitch} />
            </Box>
          </LabelWrapper>
        </Box>
        <BoxComponent mr={1} mb={1} disabled={!inputState.hardBottom}>
          <LabelWrapper label={'Insert hard bottom file'}>
            <ButtonFileDialog
              typePage={'SDT'}
              dataFilesOnly
              required
              projectId={projectId}
              file={inputState.hardBottomFile}
              disabled={!projectId || !inputState.hardBottom}
              setFile={(file) => onFileChange(file, 'hardBottomFile')}
            />
          </LabelWrapper>
        </BoxComponent>
      </Box>

      <Typography mt={2} mb={1} variant={'subtitle1'}>
        Sediment Transport
      </Typography>
      <Box sx={{ ...styles.flexStartBox, ...styles.childSpacing }}>
        <Box>
          <LabelWrapper label={'Sediment transport formula'}>
            <CustomSelect
              onChange={onInputChange}
              value={inputState.sediment}
              name={'sediment'}
              minWidth={'200px'}
              defaultValue={''}
              displayEmpty
            >
              <CustomMenuItem value={'1'}>
                <Typography variant={'subtitle2'}>Soulsby & Van Rijn (1997)</Typography>
              </CustomMenuItem>
              <CustomMenuItem value={'2'}>
                <Typography variant={'subtitle2'}>Van Thiel & Van Rijn (2009)</Typography>
              </CustomMenuItem>
              <CustomMenuItem value={'3'}>
                <Typography variant={'subtitle2'}>Van Rijn (2007)</Typography>
              </CustomMenuItem>
              <CustomMenuItem value={'4'}>
                <Typography variant={'subtitle2'}>Soulsby (1997)</Typography>
              </CustomMenuItem>
            </CustomSelect>
          </LabelWrapper>
        </Box>
        <Box>
          <LabelWrapper maxWidth={'200px'} label={'Calibration coefficient for bed load'}>
            <CustomInput
              min={0}
              required
              step={'any'}
              errorText={'Enter positive number'}
              fullWidth
              type={'number'}
              onChange={onNumberChange}
              value={inputState.bedLoad}
              name={'bedLoad'}
            />
          </LabelWrapper>
        </Box>
        <BoxComponent disabled={inputState.sediment === '4'}>
          <LabelWrapper maxWidth={'200px'} label={'Calibration coefficient for suspended load'}>
            <CustomInput
              min={0}
              required
              step={'any'}
              errorText={'Enter positive number'}
              fullWidth
              disabled={inputState.sediment === '4'}
              type={'number'}
              onChange={onNumberChange}
              value={inputState.suspendedLoad}
              name={'suspendedLoad'}
            />
          </LabelWrapper>
        </BoxComponent>
      </Box>

      <Typography mt={2} mb={1} variant={'subtitle1'}>
        Morphology
      </Typography>
      <Box sx={{ ...styles.flexStartBox, ...styles.childSpacing, ...styles.wrap }}>
        <Box maxWidth={'200px'}>
          <LabelWrapper label={'Max rate of bed level change (m/d)'}>
            <CustomInput
              min={0.0000000001}
              required
              step={'any'}
              errorText={'Enter positive number'}
              fullWidth
              type={'number'}
              onChange={onNumberChange}
              value={inputState.maxBedRate}
              name={'maxBedRate'}
            />
          </LabelWrapper>
        </Box>
        <BoxComponent disabled={calculationDisabled} maxWidth={'200px'}>
          <LabelWrapper label={'Starting time step for morphology calculations'}>
            <CustomInput
              min={0}
              required
              step={1}
              errorText={'Enter positive integer'}
              fullWidth
              type={'number'}
              onChange={onNumberChange}
              disabled={calculationDisabled}
              value={inputState.startTimeStep}
              name={'startTimeStep'}
            />
          </LabelWrapper>
        </BoxComponent>
        <Box maxWidth={'200px'}>
          <LabelWrapper label={'Morphological acceleration factor'}>
            <CustomInput
              min={0.0000000001}
              required
              step={'any'}
              errorText={'Enter positive number'}
              fullWidth
              type={'number'}
              onChange={onNumberChange}
              value={inputState.morphologicalAcc}
              name={'morphologicalAcc'}
            />
          </LabelWrapper>
        </Box>
        <Box>
          <LabelWrapper label={'Bed updating solution scheme'}>
            <CustomSelect
              onChange={onInputChange}
              value={inputState.bedUpdating}
              name={'bedUpdating'}
              minWidth={'200px'}
              defaultValue={''}
              displayEmpty
            >
              <CustomMenuItem value={'1'}>
                <Typography variant={'subtitle2'}>UPWIND (FTBS)</Typography>
              </CustomMenuItem>
              <CustomMenuItem value={'2'}>
                <Typography variant={'subtitle2'}>Lax-Wendroff</Typography>
              </CustomMenuItem>
              <CustomMenuItem value={'3'}>
                <Typography variant={'subtitle2'}>Lax Wendroff (Richtmyer)</Typography>
              </CustomMenuItem>
              <CustomMenuItem value={'4'}>
                <Typography variant={'subtitle2'}>WENO</Typography>
              </CustomMenuItem>
            </CustomSelect>
          </LabelWrapper>
        </Box>
      </Box>
    </Box>
  );
};

export default memo(SdtModelFeaturesInputsGroup);
