import { SdtPlotArea } from '../../models/inputTypes/SdtFields';
import { sdtPlotAreaInitialState } from '../initialStates/sdtInputState';

export const sdtPlotAreaArrayPreparation = (
  plotAreaValues: SdtPlotArea[],
  newValue: number,
  customInitialState?: SdtPlotArea,
) => {
  const plotAreaStartState = customInitialState ?? sdtPlotAreaInitialState;
  const tempArray = plotAreaValues;
  const tempLength = plotAreaValues.length;
  if (tempLength !== newValue) {
    if (newValue > tempLength) {
      for (let i = tempLength; i < newValue; i++) {
        tempArray.push({
          ...plotAreaStartState,
          name_bl: plotAreaStartState.name_bl + `_${i + 1}`,
          name_dhdt: plotAreaStartState.name_dhdt + `_${i + 1}`,
          name_qtx: plotAreaStartState.name_qtx + `_${i + 1}`,
          name_qty: plotAreaStartState.name_qty + `_${i + 1}`,
          name_qtmag: plotAreaStartState.name_qtmag + `_${i + 1}`,
          name_qtdir: plotAreaStartState.name_qtdir + `_${i + 1}`,
          name_init_depths: plotAreaStartState.name_init_depths + `_${i + 1}`,
        });
      }
    } else {
      for (let i = 0; i < tempLength - newValue; i++) {
        tempArray.pop();
      }
    }
  }
  return tempArray;
};
