import React, { FC, memo, useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import CustomInput from '../../custom/CustomInput';
import CustomButton from '../../custom/CustomButton';
import { ReactComponent as SaveIcon } from '../../../assets/images/save.svg';
import SquaredIconButton from '../../custom/SquaredIconButton';
import CpuInputLabel from '../../common/CpuInputLabel';
import { PmsSubmission } from '../../../models/inputTypes/PmsFields';
import LabelWrapper from '../../common/LabelWrapper';
import { useStorageQuery } from '../../../redux/RTK/queries/storageQuery';

const styles = {
  spacingContainer: {
    flexWrap: 'wrap',
    display: 'flex',
    alignItems: 'flex-end',
  },
  childMarginBig: {
    '& >:nth-child(n)': {
      my: 1,
      mr: { xs: 3, lg: 4, xl: 4 },
    },
  },
} as const;

type PmsSubmissionInputsProps = {
  inputState: PmsSubmission;
  setInputState: (value: PmsSubmission) => void;
  onSaveClick?: () => void;
  disable: boolean | undefined;
};

const PmsSubmissionInputsGroup: FC<PmsSubmissionInputsProps> = ({
  onSaveClick,
  inputState,
  setInputState,
  disable,
}) => {
  const { data: storage } = useStorageQuery({});

  const cores = useMemo(() => {
    if (storage?.profile) {
      const coresUsed = storage.profile?.cores_used ?? 0;
      const coresQuota = storage.profile?.cores_quota ?? 0;
      const coresLeft = coresQuota - coresUsed;
      return {
        left: coresLeft,
        total: coresQuota,
        errorMsg:
          coresLeft > 0 ? `enter core number ${coresLeft > 1 ? `(1-${coresLeft})` : '(1)'}` : 'No available cores',
      };
    }
    return { left: 0, total: 0, errorMsg: 'No available cores' };
  }, [storage]);

  const coreWarning = useMemo(() => (inputState.vCpus == 1 ? 'Recommended vCPUs ≥ 2' : undefined), [inputState.vCpus]);

  const onInputChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setInputState({ ...inputState, [event.target.name]: event.target.value });
  };
  const onNumberChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setInputState({ ...inputState, [event.target.name]: event.target.value });
  };

  return (
    <>
      <Box sx={{ ...styles.childMarginBig, ...styles.spacingContainer }}>
        <Box sx={{ ...styles.spacingContainer }}>
          <Box mr={2}>
            <LabelWrapper label={'Create Simulation File'}>
              <CustomInput
                disabled={disable}
                errorText={'Please enter valid text'}
                required
                maxLength={30}
                onChange={onInputChange}
                value={inputState.simulationFile}
                name={'simulationFile'}
                placeholder={'Enter name'}
              />
            </LabelWrapper>
          </Box>
          <Box sx={{ ...styles.spacingContainer }}>
            <SquaredIconButton onClick={onSaveClick} variant={'contained'} color={'success'} disabled={disable}>
              <SaveIcon />
            </SquaredIconButton>
          </Box>
        </Box>
        <Box>
          <CpuInputLabel mb={1} leftCount={cores.left} totalCount={cores.total} />
          <CustomInput
            disabled={disable}
            required
            min={1}
            max={cores.left}
            warningText={coreWarning}
            step={1}
            errorText={cores.errorMsg}
            type={'number'}
            onChange={onNumberChange}
            value={inputState.vCpus}
            name={'vCpus'}
          />
        </Box>
        <CustomButton type={'submit'} variant={'contained'} color={'success'} disabled={disable}>
          <Typography color={'info.contrastText'} variant={'subtitle2'}>
            Submit
          </Typography>
        </CustomButton>
      </Box>
    </>
  );
};

export default memo(PmsSubmissionInputsGroup);
