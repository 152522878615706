import React, { FC, memo } from 'react';
import { Box, Typography, Theme, InputAdornment, IconButton, FormControlLabel, Checkbox } from '@mui/material';
import CustomButton from '../../../custom/CustomButton';
import BoxInputContainer from '../../../custom/BoxInputContainer';
import { StyledInput } from '../../../common/LoginInput';
import { ReactComponent as Close } from '../../../../assets/images/close.svg';
import CustomInput from '../../../custom/CustomInput';

const styles = {
  inputGrid: { display: 'grid', alignItems: 'center', gridTemplateColumns: '80px 2fr', gap: '12px 24px' },
  boxInputs: {
    padding: (theme: Theme) => theme.spacing(1.5, 4, 1.5, 2),
    background: '#DFE1E6',
  },
  buttonPosition: {
    display: 'flex',
    margin: 'auto',
    marginTop: 2,
    height: '40px',
    background: (theme: Theme) => theme.palette.primary.dark,
  },
  valuesPosition: {
    display: 'flex',
    marginTop: 1,
    marginBottom: 3,
    gridTemplateColumns: '1.5fr 1fr',
    gap: '12px 12px',
    alignItems: 'center',
  },
} as const;

type HeatMapInputProps = {
  onSave: () => void;
  saveName: string | undefined;
  setSaveName: (name: string) => void;
  saveLoading: boolean | undefined;
  autoSave: boolean;
  setAutoSave: (autoSave: boolean) => void;
  selectLoading: boolean | undefined;
  value: number | undefined;
  setValue: (value: any | undefined) => void;
  checkValue: number | undefined;
  setCheckValue: (checkValue: any | undefined) => void;
  timeframe: number;
  draw: boolean;
  onDraw: () => void;
};
const FileSaveCard: FC<HeatMapInputProps> = ({
  timeframe,
  value,
  setValue,
  checkValue,
  setCheckValue,
  onSave,
  saveName,
  setSaveName,
  saveLoading,
  autoSave,
  setAutoSave,
  selectLoading,
  draw,
  onDraw,
  ...rest
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSaveName(event.target.value?.trim());
  };

  const handleAutoSaveChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAutoSave(event.target.checked);
  };

  return (
    <BoxInputContainer sx={{ ...styles.boxInputs }} borderRadius={'6px'}>
      <Typography variant={'subtitle1'}>Define Bounding Line</Typography>
      <Box mt={3} sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <CustomButton
          minWidth={'120px'}
          size={'small'}
          variant={'contained'}
          color={draw ? 'info' : 'primary'}
          onClick={onDraw}
        >
          Draw Line
        </CustomButton>
      </Box>
      <Box mt={2} sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box>
          <Typography variant={'subtitle1'}>Select points based on value</Typography>
          <StyledInput
            disabled={timeframe > 1}
            value={checkValue}
            defaultValue={10}
            onChange={(event) => setCheckValue(event.target.value ? Number(event.target.value) : undefined)}
            maxWidth={'200px'}
            type={'number'}
            endAdornment={
              checkValue !== undefined ? (
                <InputAdornment position="end">
                  <IconButton size="small" onClick={() => setCheckValue('')}>
                    <Close />
                  </IconButton>
                </InputAdornment>
              ) : undefined
            }
          />
        </Box>
        <Box>
          <Typography variant={'subtitle1'}>Replace selected values with</Typography>
          <StyledInput
            disabled={timeframe > 1}
            value={value}
            onChange={(event) => setValue(event.target.value ? Number(event.target.value) : undefined)}
            maxWidth={'200px'}
            type={'number'}
            endAdornment={
              value !== undefined ? (
                <InputAdornment position="end">
                  <IconButton size="small" onClick={() => setValue('')}>
                    <Close />
                  </IconButton>
                </InputAdornment>
              ) : undefined
            }
          />
        </Box>
      </Box>
      <Box mt={6} sx={{ ...styles.inputGrid, gridTemplateColumns: '60px 1fr' }}>
        <Typography variant={'subtitle1'} align={'right'}>
          Save as:
        </Typography>
        <CustomInput value={saveName} onChange={handleChange} fullWidth />
      </Box>

      <CustomButton
        loading={saveLoading}
        disabled={!saveName || saveLoading}
        minWidth={'120px'}
        sx={styles.buttonPosition}
        size={'small'}
        variant={'contained'}
        color={'primary'}
        onClick={onSave}
      >
        Save
      </CustomButton>
      <FormControlLabel
        control={<Checkbox color={'primary'} checked={autoSave} onChange={handleAutoSaveChange} name="autoSave" />}
        label={<Typography variant={'body2'}>Auto Save</Typography>}
      />
    </BoxInputContainer>
  );
};

export default memo(FileSaveCard);
