import React, { FC, memo } from 'react';
import { Box, SelectChangeEvent, Typography } from '@mui/material';
import CustomMenuItem from '../../custom/CustomMenuItem';
import CustomSelect from '../../custom/CustomSelect';
import LabelWrapper from '../../common/LabelWrapper';
import CustomInput from '../../custom/CustomInput';
import CustomRadioButton from '../../custom/CustomRadioButton';
import BoxComponent from '../../custom/BoxComponent';
import { MarisPlotLabelPdf } from '../../../models/inputTypes/MarisPlotFields';
import ButtonFileDialog from '../../common/ButtonFileDialog';
import { FileType } from '../../../models/inputTypes/FileType';
import { marisPlotLabelPdfInitialState } from '../../../utils/initialStates/marisPlotState';
import CustomTextArea from '../../custom/CustomTextArea';

const styles = {
  itemSpacing: {
    '& >:nth-child(n)': {
      my: 1,
      mr: 4,
    },
  },
  wrap: {
    flexWrap: 'wrap',
  },
  line: {
    bgcolor: 'info.light',
    height: 2,
  },
  flexStartBox: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
} as const;

type InputsProps = {
  projectId: string;
  inputState: MarisPlotLabelPdf;
  setInputState: (value: MarisPlotLabelPdf) => void;
};

const MarisPlotLabelInputsGroup: FC<InputsProps> = ({ projectId, inputState, setInputState }) => {
  const onInputChange = (
    event: SelectChangeEvent<unknown> | React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setInputState({ ...inputState, [event.target.name]: event.target.value });
  };

  const onRadioClick = (value: any) => {
    setInputState({
      ...inputState,
      userIdType: value,
      username: marisPlotLabelPdfInitialState.username,
      logoFile: undefined,
    });
  };

  const onFileChange = (file: FileType | undefined, fieldName: string) => {
    setInputState({ ...inputState, [fieldName]: file });
  };

  return (
    <Box>
      <Typography my={2} variant={'subtitle1'}>
        User #ID
      </Typography>
      <Box sx={{ ...styles.flexStartBox }}>
        <Box mr={10}>
          <CustomRadioButton
            checked={inputState.userIdType === 'username'}
            onButtonClick={onRadioClick}
            value={'username'}
            textLabel={'Username'}
          />
        </Box>
        <BoxComponent disabled={inputState.userIdType != 'username'}>
          <LabelWrapper label={'Enter username'}>
            <CustomInput
              errorText={'Please enter text'}
              required
              disabled={inputState.userIdType != 'username'}
              value={inputState.username}
              name={'username'}
              onChange={onInputChange}
            />
          </LabelWrapper>
        </BoxComponent>
      </Box>
      <Box mt={2} sx={{ ...styles.flexStartBox }}>
        <Box mr={14}>
          <CustomRadioButton
            checked={inputState.userIdType === 'logo'}
            onButtonClick={onRadioClick}
            value={'logo'}
            textLabel={'Logo'}
          />
        </Box>
        <BoxComponent disabled={inputState.userIdType !== 'logo'}>
          <LabelWrapper label={'Insert logo file'}>
            <ButtonFileDialog
              typePage={'PLOT'}
              imageFileOnly
              disabled={!projectId || inputState.userIdType !== 'logo'}
              projectId={projectId}
              required
              file={inputState.logoFile}
              setFile={(file) => onFileChange(file, 'logoFile')}
            />
          </LabelWrapper>
        </BoxComponent>
      </Box>

      <Box mx={5} my={2} sx={styles.line} />

      <Box sx={{ ...styles.flexStartBox, ...styles.itemSpacing, ...styles.wrap }}>
        <Box>
          <LabelWrapper label={'Model Applied'}>
            <CustomSelect
              required
              value={inputState.modelApplied}
              name={'modelApplied'}
              onChange={onInputChange}
              minWidth={'140px'}
              defaultValue={''}
              displayEmpty
            >
              <CustomMenuItem value={'1'}>
                <Typography variant={'subtitle2'}>Maris BTG</Typography>
              </CustomMenuItem>
              <CustomMenuItem value={'2'}>
                <Typography variant={'subtitle2'}>Maris PMS</Typography>
              </CustomMenuItem>
              <CustomMenuItem value={'3'}>
                <Typography variant={'subtitle2'}>Maris HMS</Typography>
              </CustomMenuItem>
              <CustomMenuItem value={'4'}>
                <Typography variant={'subtitle2'}>Maris HYD</Typography>
              </CustomMenuItem>
              <CustomMenuItem value={'5'}>
                <Typography variant={'subtitle2'}>Maris SDT</Typography>
              </CustomMenuItem>
            </CustomSelect>
          </LabelWrapper>
        </Box>
        <Box>
          <LabelWrapper label={'Figure number'}>
            <CustomInput
              required
              placeholder={'-'}
              errorText={'Please enter text'}
              value={inputState.figureNumber}
              name={'figureNumber'}
              onChange={onInputChange}
            />
          </LabelWrapper>
        </Box>
        <Box>
          <LabelWrapper label={'Figure title'}>
            <CustomInput
              required
              errorText={'Please enter text'}
              placeholder={'-'}
              value={inputState.figureTitle}
              name={'figureTitle'}
              onChange={onInputChange}
            />
          </LabelWrapper>
        </Box>
        <Box>
          <LabelWrapper label={'Date'}>
            <CustomInput
              errorText={'Please enter text'}
              required
              placeholder={'-'}
              value={inputState.date}
              name={'date'}
              onChange={onInputChange}
            />
          </LabelWrapper>
        </Box>
        <Box>
          <LabelWrapper label={'Client'}>
            <CustomInput
              minWidth={'380px'}
              required
              errorText={'Please enter text'}
              placeholder={'-'}
              value={inputState.client}
              name={'client'}
              onChange={onInputChange}
            />
          </LabelWrapper>
        </Box>
        <Box>
          <LabelWrapper label={'Project title'}>
            <CustomInput
              required
              minWidth={'380px'}
              errorText={'Please enter text'}
              placeholder={'-'}
              value={inputState.projectTitle}
              name={'projectTitle'}
              onChange={onInputChange}
            />
          </LabelWrapper>
        </Box>
        <Box>
          <LabelWrapper label={'Figure description'}>
            <CustomTextArea
              rows={7}
              minWidth={'380px'}
              required
              errorText={'Please enter text'}
              placeholder={'-'}
              value={inputState.figureDescription}
              name={'figureDescription'}
              onChange={onInputChange}
            />
          </LabelWrapper>
        </Box>
      </Box>
    </Box>
  );
};

export default memo(MarisPlotLabelInputsGroup);
