import React from 'react';
import { Button, ButtonProps, CircularProgress, styled } from '@mui/material';
import { ReactComponent as AddIcon } from '../../assets/images/add.svg';
type extraProps = {
  loading?: boolean;
};
const CustomImageButton = styled((props: ButtonProps & extraProps) => (
  <Button startIcon={props?.loading ? <CircularProgress size={24} color={'info'} /> : <AddIcon />} {...props} />
))`
  padding: 6px 20px;
  text-transform: none;
  box-shadow: none;
`;

export default CustomImageButton;
