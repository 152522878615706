import React, { FC } from 'react';
import { Box, RadioProps, styled, Typography } from '@mui/material';
import CustomRadio from './CustomRadio';

type CustomRadioProps = {
  textLabel?: string;
  onButtonClick?: (val?: unknown) => void;
};

const BoxButton = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
}));

const CustomRadioButton: FC<RadioProps & CustomRadioProps> = ({ onButtonClick, textLabel, ...props }) => {
  const onClick = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (onButtonClick) onButtonClick(props?.value);
  };
  return (
    <BoxButton onClick={onClick}>
      <CustomRadio {...props} />
      <Typography variant={'subtitle2'}>{textLabel}</Typography>
    </BoxButton>
  );
};

export default CustomRadioButton;
