import React, { FC, useEffect, useState } from 'react';
import { Box, CircularProgress, Grid, styled, Typography } from '@mui/material';
import loginIcon from '../assets/images/login.png';
import { ReactComponent as ScientiaLogo } from '../assets/images/scientiaMaris.svg';
import LoginInput from '../components/common/LoginInput';
import LabelWrapper from '../components/common/LabelWrapper';
import CustomButton from '../components/custom/CustomButton';
import { useLoginMutation } from '../redux/RTK/mutations/loginMutations';
import { Link } from 'react-router-dom';

const BoxBackground = styled(Box)(() => ({
  overflow: 'auto',
  background: `url(${loginIcon})`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  height: '100vh',
  width: '100vw',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const LoginCard = styled(Box)(({ theme }) => ({
  background: '#FAFAFA',
  minWidth: '280px',
  height: '345px',
  borderRadius: 4,
  boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.24)',
  padding: '38px 32px',
  '& >:first-child': {
    display: 'flex',
    margin: theme.spacing(0, 'auto', 4.5, 'auto'),
  },

  form: {
    '& div': {
      marginBottom: theme.spacing(2),
    },
  },
}));

const Login: FC = () => {
  const [login, { isLoading }] = useLoginMutation();
  const [inputs, setInputs] = useState<{ username: string; password: string }>({ username: '', password: '' });

  useEffect(() => {
    document.title = 'Login - Scientia Maris';
  }, []);

  const onFormSubmit = (e: any) => {
    e.preventDefault();
    if (inputs.username && inputs.password) {
      login({ username: inputs.username, password: inputs.password });
    }
  };

  const onInputChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };
  const onTypeChange = (e: any) => {
    if (e.key === 'Enter') {
      const form = e.target.form;
      const username = form.elements[0];
      const password = form.elements[1];
      if (!username.value) {
        username.focus();
        return;
      }
      if (!password.value) {
        password.focus();
        return;
      }
    }
  };

  return (
    <BoxBackground>
      <LoginCard>
        <ScientiaLogo />
        <Box>
          <form onKeyUp={onTypeChange} onSubmit={onFormSubmit}>
            <LabelWrapper label={'Username'}>
              <LoginInput
                placeholder={'Enter username'}
                name={'username'}
                value={inputs.username}
                onChange={onInputChange}
                fullWidth
              />
            </LabelWrapper>
            <LabelWrapper label={'Password'}>
              <LoginInput
                placeholder={'Enter password'}
                name={'password'}
                value={inputs.password}
                onChange={onInputChange}
                isPasswordType
                fullWidth
              />
            </LabelWrapper>
            <CustomButton
              type={'submit'}
              variant={'contained'}
              disabled={!inputs.username || !inputs.password || isLoading}
              fullWidth
              color={'primary'}
            >
              {isLoading ? (
                <CircularProgress size={18} sx={{ color: 'white' }} />
              ) : (
                <Typography variant={'subtitle2'}>Sign in</Typography>
              )}
            </CustomButton>
          </form>
          <Link to="/password/forgot" className={'text-dangers'}>
            <Typography variant={'subtitle2'} align={'center'} padding={'25px'}>
              Forgot Password?
            </Typography>
          </Link>
        </Box>
      </LoginCard>
    </BoxBackground>
  );
};

export default Login;
