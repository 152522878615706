import { User } from '../../../models/types/User';
import { clearUser, setUser } from '../../slices/authenticationSlice';
import { openErrorToast } from '../../slices/appSlice';

import { customApi } from '../Api';

const extendedApi = customApi.injectEndpoints({
  endpoints: (builder) => ({
    user: builder.query<User, unknown>({
      query: () => ({
        url: '/dj-rest-auth/user/',
        method: 'GET',
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setUser(data));
        } catch (err: any) {
          dispatch(openErrorToast(err.error.data.error));
          dispatch(clearUser());
        }
      },
      providesTags: ['user'],
    }),
  }),
  overrideExisting: false,
});

export const { useUserQuery } = extendedApi;
