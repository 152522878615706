import { HmsPlotArea, HmsPlotLine } from '../../../models/inputTypes/HmsFields';
import { OutputFile } from '../../../models/jobModels/ModelsFileTypes';
import {
  hmsPlotAreasAliases,
  hmsPlotAreasInitialState,
  hmsPlotLinesAliases,
  hmsPlotLinesInitialState,
} from '../../initialStates/hmsInputState';

export const hmsOutputAreaFilePreparation = (plotAreas: HmsPlotArea[], projectId: string) => {
  const areaOutput: OutputFile[] = [];
  plotAreas.forEach((area, i) => {
    if (area.h) {
      areaOutput.push({
        alias: hmsPlotAreasAliases.name_h + `_${i + 1}.csv`,
        project: projectId.toString(),
        name: (area.name_h || hmsPlotAreasInitialState.name_h + `_${i + 1}`) + '.csv',
      });
    }
    if (area.z) {
      areaOutput.push({
        alias: hmsPlotAreasAliases.name_z + `_${i + 1}.csv`,
        project: projectId.toString(),
        name: (area.name_z || hmsPlotAreasInitialState.name_z + `_${i + 1}`) + '.csv',
      });
    }
    if (area.rad) {
      areaOutput.push({
        alias: hmsPlotAreasAliases.name_rad_xx + `_${i + 1}.csv`,
        project: projectId.toString(),
        name: (area.name_rad_xx || hmsPlotAreasInitialState.name_rad_xx + `_${i + 1}`) + '.csv',
      });
      areaOutput.push({
        alias: hmsPlotAreasAliases.name_rad_xy + `_${i + 1}.csv`,
        project: projectId.toString(),
        name: (area.name_rad_xy || hmsPlotAreasInitialState.name_rad_xy + `_${i + 1}`) + '.csv',
      });
      areaOutput.push({
        alias: hmsPlotAreasAliases.name_rad_yy + `_${i + 1}.csv`,
        project: projectId.toString(),
        name: (area.name_rad_yy || hmsPlotAreasInitialState.name_rad_yy + `_${i + 1}`) + '.csv',
      });
    }
    if (area.init_depths) {
      areaOutput.push({
        alias: hmsPlotAreasAliases.name_init_depths + `_${i + 1}.csv`,
        project: projectId.toString(),
        name: (area.name_init_depths || hmsPlotAreasInitialState.name_init_depths + `_${i + 1}`) + '.csv',
      });
    }
  });
  return areaOutput;
};

export const hmsOutputLineFilePreparation = (plotLines: HmsPlotLine[], projectId: string) => {
  const lineOutput: OutputFile[] = [];
  plotLines.forEach((line, i) => {
    if (line.h)
      lineOutput.push({
        alias: hmsPlotLinesAliases.name_h + `_${i + 1}.csv`,
        project: projectId.toString(),
        name: (line.name_h || hmsPlotLinesInitialState.name_h + `_${i + 1}`) + '.csv',
      });
    if (line.z)
      lineOutput.push({
        alias: hmsPlotLinesAliases.name_z + `_${i + 1}.csv`,
        project: projectId.toString(),
        name: (line.name_z || hmsPlotLinesInitialState.name_z + `_${i + 1}`) + '.csv',
      });
  });
  return lineOutput;
};
