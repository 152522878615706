import React from 'react';
import { Slider, SliderProps, styled } from '@mui/material';

const CustomSlider = styled((props: SliderProps) => <Slider {...props} />)(({ theme, value }) => ({
  color: theme.palette.primary.main,
  height: 6,
  '&.Mui-disabled': {
    color: theme.palette.primary.main,
    opacity: 0.5,
  },
  '& .MuiSlider-rail': {
    color: theme.palette.info.main,
  },
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-thumb': {
    height: 16,
    width: 16,
    backgroundColor: theme.palette.primary.light,
    border: `2px solid ${theme.palette.primary.main}`,
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&:before': {
      display: 'none',
    },
  },
  '&:after': {
    ...theme.typography.caption,
    color: theme.palette.info.light,
    content: `"${value ? (value as number) / 100 : 0}"`,
    position: 'absolute',
    bottom: -4,

    left: '50%',
    transform: 'translateX(-50%)',
  },
}));

export default CustomSlider;
