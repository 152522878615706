import React, { FC, useEffect } from 'react';
import PageContainer from '../components/structural/PageContainer';
import { Collapse, Grid, Typography } from '@mui/material';
import CreateProject from '../components/groups/projects/CreateProject';
import { useState } from 'react';
import BoxInputContainer from '../components/custom/BoxInputContainer';
import ProjectsFilesGroup from '../components/groups/projects/ProjectsFilesGroup';
import { useParams } from 'react-router';
import UploadFilesProject from '../components/groups/projects/UploadFilesProject';
import FilesPerProject from '../components/groups/projects/FilesPerProject';

const styles = {
  title: {
    marginTop: 3,
    marginBottom: 3,
  },
};

const Projects: FC = () => {
  const { id } = useParams<{ id: string }>();

  const [checked, setChecked] = useState(false);
  const [filePerProject, setFilePerProject] = useState('');
  const [search, setSearch] = useState<string>('');

  useEffect(() => {
    if (id) setFilePerProject(id);
    else setFilePerProject('');
  }, [id]);

  return (
    <PageContainer subTitle={'Projects'}>
      <Grid container>
        <Grid item sx={styles.title}>
          <Typography variant="h1">Projects</Typography>
        </Grid>
        <Grid item xs={12}>
          <BoxInputContainer borderRadius={'8px'}>
            <Typography variant="subtitle1">Create a new project</Typography>
            <CreateProject />

            <Typography mt={2} variant="subtitle1">
              Upload files
            </Typography>
            <UploadFilesProject />

            <Typography mt={2} variant="subtitle1">
              Files per project
            </Typography>
            <FilesPerProject
              setDebounce={setSearch}
              checked={checked}
              setChecked={setChecked}
              filePerProject={filePerProject}
              setFilePerProject={setFilePerProject}
            />
          </BoxInputContainer>
        </Grid>
        <Grid item xs={12} sx={{ marginTop: '16px' }}>
          <BoxInputContainer>
            <Collapse in={checked}>
              {checked && <ProjectsFilesGroup debouncedSearch={search} filePerProject={filePerProject} />}
            </Collapse>
          </BoxInputContainer>
        </Grid>
      </Grid>
    </PageContainer>
  );
};

export default Projects;
