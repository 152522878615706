import React, { ChangeEvent, FC } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import CustomButton from './CustomButton';
import { ReactComponent as UploadIcon } from '../../assets/images/uploadImage.svg';
import { FileType } from '../../models/inputTypes/FileType';

const styles = {
  root: {
    padding: '30px 30px',
    border: '2px dashed #B6B6B6',
    bgcolor: 'info.contrastText',
    position: 'relative',
    overflow: 'hidden',
    borderRadius: '4px',
  },

  fileContainer: {
    '&>:nth-child(n)': {
      marginBottom: '10px',
    },
  },
} as const;
type extraProps = {
  multipleFiles?: boolean;
  selectedFileName?: string;
  setMultipleFiles: (val: File[]) => void;
};
type InputType = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

const CustomDropzoneInput: FC<InputType & extraProps> = ({
  setMultipleFiles,
  multipleFiles,
  selectedFileName,
  ...props
}) => {
  const onFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e?.target?.files?.length) {
      const fileArray: File[] = [];
      for (let i = 0; i < e.target.files.length; i++) {
        fileArray.push(e.target.files[i]);
      }
      setMultipleFiles(fileArray);
    }
    return;
  };

  return (
    <Box sx={{ ...styles.root }}>
      <Grid container sx={{ ...styles.fileContainer }} justifyContent={'center'}>
        <Grid item container justifyContent={'center'} xs={12}>
          <UploadIcon />
        </Grid>
        <Grid item container justifyContent={'center'} xs={12}>
          <Typography color={'info.light'} variant={'body2'}>
            Drag & drop files here
          </Typography>
        </Grid>
        <Grid item container justifyContent={'center'} xs={12}>
          <Typography color={'info.light'} variant={'subtitle1'}>
            or
          </Typography>
        </Grid>
        <Grid item container justifyContent={'center'} xs={12}>
          <CustomButton variant={'outlined'} minWidth={'130px'} borderRadius={4} color={'secondary'}>
            <Typography variant={'subtitle1'}>Browse Files</Typography>
          </CustomButton>
        </Grid>
        {selectedFileName && (
          <Box sx={{ position: 'absolute', bottom: 0 }}>
            <Typography variant={'caption'}>{selectedFileName}</Typography>
          </Box>
        )}
      </Grid>
      <input
        onChange={onFileChange}
        multiple={multipleFiles}
        value={''}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          background: 'red',
          opacity: 0,
          cursor: 'pointer',
        }}
        type="file"
        {...props}
      />
    </Box>
  );
};

export default CustomDropzoneInput;
