import { customApi } from '../Api';
import { openErrorToast, openSuccessToast } from '../../slices/appSlice';

const extendedApi = customApi.injectEndpoints({
  endpoints: (builder) => ({
    updateTimezone: builder.mutation<Storage, unknown>({
      query: ({ username, timezone }) => ({
        url: '/api/users/detail',
        method: 'PUT',
        body: { username, profile: { timezone } },
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(openSuccessToast('Timezone created successfully!'));
        } catch (err) {
          dispatch(openErrorToast('Error. Please try again!'));
        }
      },
      invalidatesTags: ['jobs', 'projectFiles'],
    }),
  }),
  overrideExisting: false,
});

export const { useUpdateTimezoneMutation } = extendedApi;
