import homeIcon from '../assets/images/home.svg';
import projectsIcon from '../assets/images/projects.svg';
import jobsIcon from '../assets/images/jobs.svg';

export const adminLinks = [
  { to: '/', title: 'Home', image: homeIcon, exact: true },
  { to: '/projects', title: 'Projects', image: projectsIcon },
  { to: '/jobs', title: 'Jobs', image: jobsIcon, exact: true },
];

export const numericalToolsLinks = [
  { to: '/designTools/maris-btg', title: 'Maris BTG' },
  { to: '/designTools/maris-srf', title: 'Maris SRF' },
  { to: '/designTools/maris-evi', title: 'Maris EVI' },
];

export const numericalModelsLinks = [
  { to: '/numerical-models/maris-pms', title: 'Maris PMS' },
  { to: '/numerical-models/maris-hms', title: 'Maris HMS' },
  { to: '/numerical-models/maris-hyd', title: 'Maris HYD' },
  { to: '/numerical-models/maris-sdt', title: 'Maris SDT' },
  { to: '/numerical-models/shorelineS-cloud', title: 'ShorelineS cloud' },
];

export const plotAnimationLinks = [{ to: '/plots-animation/maris-plot', title: 'Maris PLT' }];
