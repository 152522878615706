import React, { FC, memo } from 'react';
import { Box, Grid, SelectChangeEvent, Typography } from '@mui/material';
import CustomInput from '../../custom/CustomInput';
import CustomMenuItem from '../../custom/CustomMenuItem';
import CustomSelect from '../../custom/CustomSelect';
import LabelWrapper from '../../common/LabelWrapper';
import { HmsModelFeatures } from '../../../models/inputTypes/HmsFields';
import BoxComponent from '../../custom/BoxComponent';
import { hmsModelFeaturesInitialState } from '../../../utils/initialStates/hmsInputState';
import CustomSwitch from '../../custom/CustomSwitch';

const styles = {
  flexBetweenBox: {
    display: 'flex',
    alignItems: 'flex-end',
    flexWrap: 'wrap',
    maxWidth: '1100px',
    justifyContent: 'space-between',
  },
  childSpacing: {
    '& >:nth-child(n)': {
      my: 1,
      mr: { xs: 2, md: 3, lg: 4 },
    },
  },
  flexStartBox: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
} as const;

type InputsProps = {
  projectId: string;

  inputState: HmsModelFeatures;
  setInputState: (value: HmsModelFeatures) => void;
};

const HmsModelFeaturesInputsGroup: FC<InputsProps> = ({ inputState, setInputState }) => {
  const onInputChange = (
    event: SelectChangeEvent<unknown> | React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    if (event.target.name === 'dispersion') {
      setInputState({
        ...inputState,
        dispersion: event.target.value as string,
        startingTime: hmsModelFeaturesInitialState.startingTime,
        relation: hmsModelFeaturesInitialState.relation,
        celerityFile: undefined,
      });
      return;
    }
    setInputState({ ...inputState, [event.target.name]: event.target.value });
  };

  const onNumberChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setInputState({ ...inputState, [event.target.name]: event.target.value });
  };

  const switchChange = (e: any) => {
    setInputState({
      ...inputState,
      relation: hmsModelFeaturesInitialState.relation,
      [e.target.name]: e.target.checked ? '1' : '0',
    });
  };

  return (
    <Box>
      <Typography mb={1} variant={'subtitle1'}>
        Nonlinearity
      </Typography>
      <Box sx={{ ...styles.flexBetweenBox }}>
        <Box sx={{ ...styles.flexStartBox, ...styles.childSpacing }}>
          <Box>
            <LabelWrapper label={'Include nonlinear amplitude dispersion'}>
              <Grid container justifyContent={'center'}>
                <CustomSwitch name={'dispersion'} checked={inputState.dispersion == '1'} onChange={switchChange} />
              </Grid>
            </LabelWrapper>
          </Box>
          <BoxComponent disabled={inputState.dispersion == '0' || inputState.dispersion == '2'}>
            <LabelWrapper label={'Starting time(s)'}>
              <CustomInput
                min={0}
                step={'any'}
                required
                disabled={inputState.dispersion == '0' || inputState.dispersion == '2'}
                errorText={'Enter positive number'}
                type={'number'}
                onChange={onNumberChange}
                value={inputState.startingTime}
                name={'startingTime'}
              />
            </LabelWrapper>
          </BoxComponent>
          <BoxComponent disabled={inputState.dispersion == '0' || inputState.dispersion == '2'}>
            <LabelWrapper label={'Selection of relation'}>
              <CustomSelect
                disabled={inputState.dispersion == '0' || inputState.dispersion == '2'}
                onChange={onInputChange}
                value={inputState.relation}
                name={'relation'}
                minWidth={'140px'}
                defaultValue={''}
                displayEmpty
              >
                <CustomMenuItem value={'1'}>
                  <Typography variant={'subtitle2'}>Zhao & Anastasiou (1993)</Typography>
                </CustomMenuItem>
                <CustomMenuItem value={'2'}>
                  <Typography variant={'subtitle2'}>Kirby & Dalrymple (1986)</Typography>
                </CustomMenuItem>
              </CustomSelect>
            </LabelWrapper>
          </BoxComponent>
        </Box>
      </Box>
    </Box>
  );
};

export default memo(HmsModelFeaturesInputsGroup);
