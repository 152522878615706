import React, { FC, memo, useState } from 'react';
import { Box, Typography } from '@mui/material';
import CustomInputWithLabel from '../../common/CustomInputWithLabel';
import LabelWrapper from '../../common/LabelWrapper';
import CustomInput from '../../custom/CustomInput';
import BoxComponent from '../../custom/BoxComponent';
import CustomCheckbox from '../../custom/CustomCheckbox';
import { SdtPlotLine } from '../../../models/inputTypes/SdtFields';

const styles = {
  spacingContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  alignEndContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    flexWrap: 'wrap',
  },
  largeChildSpacing: {
    '& >:nth-child(n)': {
      my: 1,
      mr: { xs: 4, md: 6, lg: 8 },
    },
  },
  smallChildSpacing: {
    '& >:nth-child(n)': {
      my: 3,
      mr: 4,
    },
  },

  smallVariableSpacing: {
    '& >:nth-child(n)': {
      my: 1,
      mr: 4,
    },
    '& >:first-child': {
      my: 1,
      mr: 2,
    },
  },
  coordinates: {
    '& >:nth-child(2)': {
      ml: 1,
    },
  },
} as const;

type SdtPlotLinesInputsGroupProps = {
  removeTimeParams?: boolean;
  inputState: SdtPlotLine;
  setInputState: (value: SdtPlotLine) => void;
};

const SdtPlotLinesInputsGroup: FC<SdtPlotLinesInputsGroupProps> = ({
  removeTimeParams = false,
  inputState,
  setInputState,
}) => {
  const [valueX, setValueX] = useState(1);
  const [valueY, setValueY] = useState(1);
  const onCheckboxClick = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    const currentValue: 'bl' | 'dhdt' = e.target.id;
    const newValue = inputState[currentValue] ? 0 : 1;
    setInputState({ ...inputState, [currentValue]: newValue });
  };

  const onInputChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setInputState({ ...inputState, [event.target.name]: event.target.value });
  };

  const onNumberChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setInputState({ ...inputState, [event.target.name]: event.target.value });
  };

  const onInputChangeX = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setValueX(+event.target.value);
    setInputState({ ...inputState, [event.target.name]: event.target.value });
  };
  const onInputChangeY = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setValueY(+event.target.value);
    setInputState({ ...inputState, [event.target.name]: event.target.value });
  };

  return (
    <Box>
      <Typography mb={1} variant={'subtitle1'}>
        Bounding Points & Grid Cell Node Numbers
      </Typography>
      <Box mb={2} sx={{ ...styles.spacingContainer, ...styles.largeChildSpacing }}>
        <Box>
          <LabelWrapper label={'First point'}>
            <Box sx={{ display: 'flex', ...styles.coordinates }}>
              <CustomInputWithLabel
                min={1}
                step={1}
                required
                errorText={'Enter positive integer'}
                type={'number'}
                onChange={onNumberChange}
                value={inputState.fp_x}
                name={'fp_x'}
                label={'X'}
              />
              <CustomInputWithLabel
                min={1}
                step={1}
                required
                errorText={'Enter positive integer'}
                type={'number'}
                onChange={onNumberChange}
                value={inputState.fp_y}
                name={'fp_y'}
                label={'Y'}
              />
            </Box>
          </LabelWrapper>
        </Box>
        <Box>
          <LabelWrapper label={'Last point'}>
            <Box sx={{ display: 'flex', ...styles.coordinates }}>
              <CustomInputWithLabel
                min={inputState.fp_x}
                step={1}
                required
                errorText={
                  valueX < inputState.fp_x && valueX > 0
                    ? 'Number should be >= than first point'
                    : 'Enter positive integer'
                }
                type={'number'}
                onChange={onInputChangeX}
                value={inputState.lp_x}
                name={'lp_x'}
                label={'X'}
              />
              <CustomInputWithLabel
                min={inputState.fp_y}
                step={1}
                required
                errorText={
                  valueY < inputState.fp_y && valueY > 0
                    ? 'Number should be >= than first point'
                    : 'Enter positive integer'
                }
                type={'number'}
                onChange={onInputChangeY}
                value={inputState.lp_y}
                name={'lp_y'}
                label={'Y'}
              />
            </Box>
          </LabelWrapper>
        </Box>
      </Box>
      <Typography mx={1} variant={'subtitle1'}>
        Variables
      </Typography>
      <Box sx={{ ...styles.smallChildSpacing }}>
        <Box sx={{ ...styles.alignEndContainer }}>
          <Box mr={2}>
            <CustomCheckbox checked={!!inputState.bl} id={'bl'} onClick={onCheckboxClick} />
          </Box>
          <BoxComponent disabled={!inputState.bl}>
            <LabelWrapper label={'Bed Level'}>
              <CustomInput
                disabled={!inputState.bl}
                required
                minWidth={'160px'}
                errorText={'Please enter file name'}
                maxLength={30}
                onChange={onInputChange}
                value={inputState.name_bl}
                name={'name_bl'}
              />
            </LabelWrapper>
          </BoxComponent>
        </Box>
        <Box sx={{ ...styles.alignEndContainer }}>
          <Box mr={2}>
            <CustomCheckbox checked={!!inputState.dhdt} id={'dhdt'} onClick={onCheckboxClick} />
          </Box>
          <BoxComponent disabled={!inputState.dhdt}>
            <LabelWrapper label={'Rate of Bed Level Changes'}>
              <CustomInput
                disabled={!inputState.dhdt}
                required
                minWidth={'160px'}
                errorText={'Please enter file name'}
                type={'text'}
                maxLength={30}
                onChange={onInputChange}
                value={inputState.name_dhdt}
                name={'name_dhdt'}
              />
            </LabelWrapper>
          </BoxComponent>
        </Box>
      </Box>
      {!removeTimeParams && (
        <>
          <Box mt={2} mb={1}>
            <Typography variant={'subtitle1'}>Time Parameters</Typography>
          </Box>
          <Box sx={{ ...styles.spacingContainer, ...styles.smallChildSpacing }}>
            <Box>
              <LabelWrapper label={'Start time step'}>
                <CustomInput
                  min={1}
                  step={1}
                  type={'number'}
                  errorText={'Enter positive integer'}
                  required
                  onChange={onNumberChange}
                  value={inputState.start_step}
                  name={'start_step'}
                />
              </LabelWrapper>
            </Box>
            <Box>
              <LabelWrapper label={'End time step'}>
                <CustomInput
                  min={1}
                  step={1}
                  type={'number'}
                  errorText={'Enter positive integer'}
                  required
                  onChange={onNumberChange}
                  value={inputState.end_step}
                  name={'end_step'}
                />
              </LabelWrapper>
            </Box>
            <Box>
              <LabelWrapper label={'Time step interval'}>
                <CustomInput
                  min={1}
                  step={1}
                  required
                  errorText={'Enter positive integer'}
                  type={'number'}
                  onChange={onNumberChange}
                  value={inputState.interval}
                  name={'interval'}
                />
              </LabelWrapper>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

export default memo(SdtPlotLinesInputsGroup);
