import { customApi } from '../Api';
import { clearUser, setAccessToken } from '../../slices/authenticationSlice';
import { openErrorToast, setGlobalLoading } from '../../slices/appSlice';

const extendedApi = customApi.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<{ key: string }, { username: string; password: string }>({
      query: ({ username, password }) => ({
        url: '/dj-rest-auth/login/',
        method: 'POST',
        body: { username, password },
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setAccessToken(data.key));
        } catch (err) {
          dispatch(openErrorToast('Error logging in. Please check your credentials!'));
        }
      },
    }),
    logout: builder.mutation({
      query: () => ({
        url: '/dj-rest-auth/logout/',
        method: 'POST',
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        dispatch(setGlobalLoading(true));
        try {
          await queryFulfilled;
          dispatch(setGlobalLoading(false));
          dispatch(clearUser());
          dispatch(customApi.util.resetApiState());
        } catch (err) {
          dispatch(setGlobalLoading(false));
          dispatch(clearUser());
          dispatch(customApi.util.resetApiState());
        }
      },
    }),
  }),
  overrideExisting: false,
});

export const { useLogoutMutation, useLoginMutation } = extendedApi;
