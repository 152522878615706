import { customApi } from '../Api';
import { openSuccessToast } from '../../slices/appSlice';

const extendedApi = customApi.injectEndpoints({
  endpoints: (builder) => ({
    changePassword: builder.mutation<unknown, { new_password1: string; new_password2: string; old_password: string }>({
      query: ({ new_password1, new_password2, old_password }) => ({
        url: '/dj-rest-auth/password/change/',
        method: 'POST',
        body: {
          new_password1,
          new_password2,
          old_password,
          LOGOUT_ON_PASSWORD_CHANGE: false,
          OLD_PASSWORD_FIELD_ENABLED: true,
        },
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(openSuccessToast('User data updated successfully!'));
        } catch (err) {}
      },
    }),
  }),
  overrideExisting: false,
});

export const { useChangePasswordMutation } = extendedApi;
