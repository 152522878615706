import { Box, Grid, SelectChangeEvent, Typography } from '@mui/material';
import LabelWrapper from '../../common/LabelWrapper';
import CustomSelect from '../../custom/CustomSelect';
import CustomMenuItem from '../../custom/CustomMenuItem';
import CustomInput from '../../custom/CustomInput';
import React, { useState } from 'react';
import CustomButton from '../../custom/CustomButton';
import { SrfRefEddyCalculator } from '../../../models/inputTypes/SrfFields';
import { srfRefEddyCalculatorInitialState } from '../../../utils/initialStates/SrfInputState';
import { useLazyCalculateRefQuery } from '../../../redux/RTK/queries/calculateRefQuery';

const styles = {
  spacingContainer: {
    flexWrap: 'wrap',
    display: 'flex',
    alignItems: 'flex-end',
  },
  reflectionSecMargin: {
    paddingTop: 1.5,
    justifyContent: 'space-between',
    '& >:nth-child(n)': {
      paddingBottom: 1,
      mr: { xs: 1, lg: 1 },
    },
  },
  reflectionMargin: {
    justifyContent: 'space-between',
    '& >:first-child': {
      width: '132px',
    },
    '& >:nth-child(n)': {
      paddingBottom: 1,
      mr: { xs: 2, lg: 1 },
    },
  },
} as const;

const RefEddyCalculator = () => {
  const [postCalculate] = useLazyCalculateRefQuery();
  const [refEddyCalculatorState, setRefEddyCalculatorState] = useState<SrfRefEddyCalculator>(
    srfRefEddyCalculatorInitialState,
  );
  const [image, setImage] = useState<string>('');

  const onInputChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | SelectChangeEvent<unknown>,
  ) => {
    setRefEddyCalculatorState({ ...refEddyCalculatorState, [event.target.name]: event.target.value });
  };

  const calculate = async () => {
    if (
      refEddyCalculatorState.sea_state &&
      refEddyCalculatorState.Tp &&
      refEddyCalculatorState.Hs &&
      refEddyCalculatorState.depth &&
      refEddyCalculatorState.width
    ) {
      const res = await postCalculate(refEddyCalculatorState);
      if (res) {
        const newImage = new Image();
        newImage.src = 'data:image/png;base64,' + res.data.image;
        setImage(newImage.src);
      }
    }
  };

  return (
    <Grid item xs={12} md={7} lg={5}>
      <Typography mb={1} variant={'subtitle1'}>
        Reflection - Eddy Calculator
      </Typography>
      <Box style={{ background: '#F3F3F3', padding: 16, maxWidth: 358 }}>
        <Box sx={{ ...styles.reflectionMargin, ...styles.spacingContainer }}>
          <Box>
            <LabelWrapper label={'Sea state'}>
              <CustomSelect
                required
                fullWidth
                displayEmpty
                onChange={onInputChange}
                name={'sea_state'}
                value={refEddyCalculatorState.sea_state}
              >
                <CustomMenuItem value={'1'}>
                  <Typography variant={'subtitle2'}>Regular</Typography>
                </CustomMenuItem>
                <CustomMenuItem value={'2'}>
                  <Typography variant={'subtitle2'}>Irregular</Typography>
                </CustomMenuItem>
              </CustomSelect>
            </LabelWrapper>
          </Box>
          <Box>
            <LabelWrapper label={'Hs (m)'}>
              <CustomInput
                maxWidth={'54px'}
                required
                errorText={'Enter positive number'}
                type={'number'}
                min={0.000001}
                step={'any'}
                onChange={onInputChange}
                value={refEddyCalculatorState.Hs}
                name={'Hs'}
              />
            </LabelWrapper>
          </Box>
          <Box>
            <LabelWrapper label={'Tp (s)'}>
              <CustomInput
                maxWidth={'54px'}
                required
                errorText={'Enter positive number'}
                min={0.000001}
                step={'any'}
                type={'number'}
                onChange={onInputChange}
                value={refEddyCalculatorState.Tp}
                name={'Tp'}
              />
            </LabelWrapper>
          </Box>
        </Box>
        <Box sx={{ ...styles.reflectionSecMargin, ...styles.spacingContainer }}>
          <Box>
            <LabelWrapper label={'Depth (m)'}>
              <CustomInput
                maxWidth={'54px'}
                required
                errorText={'Enter positive number'}
                min={0.000001}
                step={'any'}
                type={'number'}
                onChange={onInputChange}
                value={refEddyCalculatorState.depth}
                name={'depth'}
              />
            </LabelWrapper>
          </Box>
          <Box>
            <LabelWrapper label={'Width of layer (m)'}>
              <CustomInput
                maxWidth={'60px'}
                required
                errorText={'Enter positive number'}
                min={0.000001}
                step={'any'}
                type={'number'}
                onChange={onInputChange}
                value={refEddyCalculatorState.width}
                name={'width'}
              />
            </LabelWrapper>
          </Box>
          <Box>
            <CustomButton variant={'contained'} color={'secondary'} onClick={calculate}>
              <Typography color={'info.contrastText'} variant={'subtitle1'}>
                Calculate
              </Typography>
            </CustomButton>
          </Box>
        </Box>
        {image && (
          <Box style={{ paddingTop: 14, textAlign: 'center' }}>
            <img width={'350px'} src={image} alt={'graph'} />
          </Box>
        )}
      </Box>
    </Grid>
  );
};

export default RefEddyCalculator;
