import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Dialog, DialogContent, DialogProps, Grid, Theme, ButtonBase } from '@mui/material';
import PageBreadCrumbs from '../common/PageBreadCrumbs';
import { FileForm } from '../../models/inputTypes/FileType';
import {
  performGetAdjustedPoints,
  performGetAlignedPoints,
  performGetFrame,
  performGetHmsHeatmap,
  resetEditFile,
  selectAdjustedPoints,
  selectAlignedPoints,
  selectEditFileError,
  selectEditFileFrame,
  selectEditFileHeatmapHms,
  selectEditFileLoading,
} from '../../redux/slices/editFileSlice';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { HotKeys } from 'react-hotkeys';
import FileNameCard from '../groups/heatMapModal/FileNameCard';
import FileHeatmapCard from '../groups/hms/heatmapModal/FileHeatmapCard';
import FileTimeframeCard from '../groups/heatMapModal/FileTimeFrameCard';
import { useGetProjectsQuery } from '../../redux/RTK/queries/projectQuery';
import { ReactComponent as Close } from '../../assets/images/close.svg';
import FileDetailCard from '../groups/hms/heatmapModal/FileDetailCard';
import { HmsBoundaryConditions, HmsEnergyDissipation } from '../../models/inputTypes/HmsFields';
import { openWarningToast } from '../../redux/slices/appSlice';

const styles = {
  dialog: {
    overflowY: 'visible',
    height: '100%',
    maxHeight: 'calc(100% - 16px)',
  },
  closeButton: {
    position: 'absolute',
    right: -10,
    top: -5,
    boxShadow: '0px 0px 5px 1px gray',
    zIndex: '200000 !important',
    borderRadius: '50%',
    width: '35px',
    padding: 0,
    minWidth: 0,
    color: (theme: Theme) => theme.palette.info.dark,
    background: 'white',
    height: '35px',
  },
  gridContainer: {
    minHeight: '100%',
  },
} as const;

interface CustomHeatmapModalProps {
  handleClose: () => void;
  file?: FileForm;
  open: boolean;
  energyState: HmsEnergyDissipation;
  inputState: HmsBoundaryConditions;
  setInputState: (value: HmsBoundaryConditions) => void;
}

const HeatmapHmsModal: FC<CustomHeatmapModalProps & Exclude<DialogProps, 'open'>> = ({
  open,
  file,
  handleClose,
  energyState,
  inputState,
  setInputState,
  ...props
}) => {
  const dispatch = useAppDispatch();
  const fileName = useMemo(() => file?.name || '', [file]);
  const projectId = useMemo(() => file?.project.toString() || '', [file]);
  const { data: projects } = useGetProjectsQuery({});
  const [frameCoords, setFrameCoords] = useState<{ x: number; y: number }>({ x: -1, y: -1 });
  const [timeframe, setTimeframe] = useState<number>(1);
  const [grid, setGrid] = useState<any[][]>();
  const [gridEditHistory, setGridEditHistory] = useState<any[][][]>([]);
  const [points, setPoints] = useState<{ fpx: number; fpy: number; lpx: number; lpy: number; sector: string }[]>([]);
  const [index, setIndex] = useState(-1);
  const [drawStates, setDrawStates] = useState<boolean[]>(
    new Array(inputState.wave_generators_line_coordinates.length).fill(false),
  );
  const north = energyState.north;
  const south = energyState.south;
  const east = energyState.east;
  const west = energyState.west;
  const spongeFile = energyState.spongeFile;

  const project = useMemo(() => projects?.find((item) => item.id === +projectId), [projects]);
  const [boundaryState, setBoundaryState] = useState<HmsBoundaryConditions>(inputState);

  const heatmap = useAppSelector(selectEditFileHeatmapHms);
  const frame = useAppSelector(selectEditFileFrame);
  const editFileError = useAppSelector(selectEditFileError);
  const editFileLoading = useAppSelector(selectEditFileLoading);
  const alignedPoints = useAppSelector(selectAlignedPoints);
  const adjustedPoints = useAppSelector(selectAdjustedPoints);

  const beforeUnload = useCallback(
    (event) => {
      if (gridEditHistory.length > 0) {
        event.preventDefault();
        const confirmationMessage = 'Changes that you made may not be saved.';
        event.returnValue = confirmationMessage;
        return confirmationMessage;
      }
    },
    [gridEditHistory],
  );

  const onValidate = (idx: number) => {
    setIndex(idx);
    const point = boundaryState.wave_generators_line_coordinates[idx];
    dispatch(
      performGetAlignedPoints(
        point.wg_fpx,
        point.wg_fpy,
        point.wg_lpx,
        point.wg_lpy,
        heatmap?.dimensions[1],
        heatmap?.dimensions[0],
      ),
    );
  };

  useEffect(() => {
    if (alignedPoints && heatmap && index == 0) {
      if (heatmap.sponge_points.length && heatmap.sponge_points.length > 0) {
        for (const point of heatmap.sponge_points) {
          if (
            (point[1] === alignedPoints.fpx - 1 && point[0] === heatmap.dimensions[0] - alignedPoints.fpy) ||
            (point[1] === alignedPoints.lpx - 1 && point[0] === heatmap.dimensions[0] - alignedPoints.lpy)
          ) {
            dispatch(openWarningToast('The wave generation line is located inside the sponge layers.'));
          }
        }
      } else {
        if (
          (alignedPoints.fpx >= heatmap.dimensions[1] - east && east != 0) ||
          (alignedPoints.fpx <= west && west != 0)
        ) {
          dispatch(openWarningToast('The wave generation line is located inside the sponge layers.'));
        }
        if (
          (alignedPoints.fpy >= heatmap.dimensions[0] - north && north != 0) ||
          (alignedPoints.fpy <= south && south != 0)
        ) {
          dispatch(openWarningToast('The wave generation line is located inside the sponge layers.'));
        }
        if (
          (alignedPoints.lpx >= heatmap.dimensions[1] - east && east != 0) ||
          (alignedPoints.lpx <= west && west != 0)
        ) {
          dispatch(openWarningToast('The wave generation line is located inside the sponge layers.'));
        }
        if (
          (alignedPoints.lpy >= heatmap.dimensions[0] - north && north != 0) ||
          (alignedPoints.lpy <= south && south != 0)
        ) {
          dispatch(openWarningToast('The wave generation line is located inside the sponge layers.'));
        }
      }
    }
    if (adjustedPoints && adjustedPoints.length > 0 && heatmap && index != 0) {
      const finalPoints = adjustedPoints[index];
      if (finalPoints && heatmap.sponge_points.length && heatmap.sponge_points.length > 0) {
        for (const point of heatmap.sponge_points) {
          if (
            (point[1] === finalPoints.fpx - 1 && point[0] === heatmap.dimensions[0] - finalPoints.fpy) ||
            (point[1] === finalPoints.lpx - 1 && point[0] === heatmap.dimensions[0] - finalPoints.lpy)
          ) {
            dispatch(openWarningToast('The wave generation line is located inside the sponge layers.'));
          }
        }
      } else {
        if (
          (finalPoints && finalPoints.fpx >= heatmap.dimensions[1] - east && east != 0) ||
          (finalPoints && finalPoints.fpx <= west && west != 0)
        ) {
          dispatch(openWarningToast('The wave generation line is located inside the sponge layers.'));
        }
        if (
          (finalPoints && finalPoints.fpy >= heatmap.dimensions[0] - north && north != 0) ||
          (finalPoints && finalPoints.fpy <= south && south != 0)
        ) {
          dispatch(openWarningToast('The wave generation line is located inside the sponge layers.'));
        }
        if (
          (finalPoints && finalPoints.lpx >= heatmap.dimensions[1] - east && east != 0) ||
          (finalPoints && finalPoints.lpx <= west && west != 0)
        ) {
          dispatch(openWarningToast('The wave generation line is located inside the sponge layers.'));
        }
        if (
          (finalPoints && finalPoints.lpy >= heatmap.dimensions[0] - north && north != 0) ||
          (finalPoints && finalPoints.lpy <= south && south != 0)
        ) {
          dispatch(openWarningToast('The wave generation line is located inside the sponge layers.'));
        }
      }
    }
  }, [alignedPoints, adjustedPoints, heatmap?.sponge_points]);

  useEffect(() => {
    if (alignedPoints) {
      const pointsArray = points.slice();
      pointsArray[index] = {
        fpx: alignedPoints.fpx,
        fpy: alignedPoints.fpy,
        lpx: alignedPoints.lpx,
        lpy: alignedPoints.lpy,
        sector: alignedPoints.sector,
      };
      setPoints(pointsArray);
    }
  }, [alignedPoints]);

  useEffect(() => {
    if (points) {
      if (index != 0) {
        dispatch(performGetAdjustedPoints(points));
      } else {
        const generatorsArray = boundaryState.wave_generators_line_coordinates.slice();
        generatorsArray[index] = {
          wg_fpx: points[index].fpx,
          wg_fpy: points[index].fpy,
          wg_lpx: points[index].lpx,
          wg_lpy: points[index].lpy,
        };
        setBoundaryState({ ...boundaryState, wave_generators_line_coordinates: generatorsArray });
      }
    }
  }, [points]);

  useEffect(() => {
    const generatorsArray = boundaryState.wave_generators_line_coordinates.slice();
    if (adjustedPoints) {
      adjustedPoints.forEach(
        (element: { fpx: number; fpy: number; lpx: number; lpy: number; sector: string }, i: number) => {
          generatorsArray[i] = {
            wg_fpx: element.fpx,
            wg_fpy: element.fpy,
            wg_lpx: element.lpx,
            wg_lpy: element.lpy,
          };
        },
      );
      setBoundaryState({ ...boundaryState, wave_generators_line_coordinates: generatorsArray });
    }
  }, [adjustedPoints]);

  const undoShortcut = useCallback(() => {
    const gridHistory = gridEditHistory.slice();
    if (gridHistory.length > 0) {
      gridHistory.pop();
      setGridEditHistory(gridHistory);
      if (gridHistory.length > 0) {
        setGrid(gridHistory[gridHistory.length - 1]);
      } else {
        setGrid(frame.points);
      }
    }
  }, [gridEditHistory, frame]);

  useEffect(() => {
    window.addEventListener('beforeunload', beforeUnload);

    return () => {
      window.removeEventListener('beforeunload', beforeUnload);
    };
  }, [beforeUnload, gridEditHistory]);

  useEffect(() => {
    return () => {
      dispatch(resetEditFile());
    };
  }, []);

  useEffect(() => {
    if (heatmap) {
      if (frameCoords.x == -1 || frameCoords.y === -1) {
        setFrameCoords({ x: 0, y: heatmap.dimensions?.[0] });
        dispatch(performGetFrame(projectId, fileName, timeframe, 0, heatmap.dimensions?.[0]));
      }
    }
  }, [heatmap]);

  useEffect(() => {
    setFrameCoords({ x: -1, y: -1 });
    setGrid(undefined);
    dispatch(performGetHmsHeatmap(projectId, fileName, timeframe, north, south, west, east, spongeFile?.fileName));
  }, [timeframe]);

  useEffect(() => {
    if (frameCoords.x >= 0 && frameCoords.y >= 0) {
      dispatch(performGetFrame(projectId, fileName, timeframe, frameCoords?.x, frameCoords?.y));
      if (gridEditHistory.length > 0) {
        dispatch(performGetHmsHeatmap(projectId, fileName, timeframe, north, south, west, east, spongeFile?.fileName));
      }
    }
  }, [frameCoords]);

  useEffect(() => {
    if (frame) {
      setGridEditHistory([]);
      setGrid(frame.points);
    }
  }, [frame]);

  const onSave = () => {
    setInputState(boundaryState);
    handleClose();
  };

  const handleEditGrid = (values: any[][]) => {
    const gridHistory = gridEditHistory.slice();
    gridHistory.push(values);
    setGridEditHistory(gridHistory);
    setGrid(values);
  };

  const handleSetTimeframe = (value: number) => {
    setTimeframe(value);
  };

  const handleSetFrameCoords = (values: { x: number; y: number }) => {
    if (values.x >= 0 && values.y >= 0 && (frameCoords.x !== values?.x || frameCoords.y !== values?.y)) {
      setFrameCoords(values);
    }
  };

  const keyMap = {
    UNDO: ['command+z', 'ctrl+z'],
  };

  const handlers = {
    UNDO: undoShortcut,
  };

  return (
    <Dialog
      maxWidth={false}
      PaperProps={{ style: styles.dialog }}
      fullWidth
      onClose={handleClose}
      open={open}
      {...props}
    >
      <ButtonBase sx={styles.closeButton} onClick={handleClose}>
        <Close />
      </ButtonBase>
      <DialogContent>
        <HotKeys keyMap={keyMap} handlers={handlers} allowChanges>
          <Grid sx={styles.gridContainer} container spacing={2} justifyContent={'space-between'}>
            <Grid item xs={4} container spacing={2} alignContent={'flex-start'}>
              <Grid item container xs={12} spacing={2}>
                <Grid item xs={12}>
                  <PageBreadCrumbs
                    path={[
                      'Projects',
                      project?.name?.toString() ?? file?.project.toString() ?? 'unavailable',
                      file?.name ?? 'unavailable',
                    ]}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FileNameCard
                    dimensions={heatmap?.dimensions}
                    projectName={project?.name?.toString() ?? file?.project.toString() ?? 'unavailable'}
                    fileName={fileName}
                  />
                </Grid>
              </Grid>
              <Grid item container xs={12} spacing={2}>
                <Grid item xs={12}>
                  <FileDetailCard
                    timeframe={heatmap?.totalTimeFrames}
                    drawStates={drawStates}
                    setDrawStates={setDrawStates}
                    onClose={onSave}
                    inputState={boundaryState}
                    setInputState={setBoundaryState}
                    onValidate={onValidate}
                    dimensions={heatmap?.dimensions}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={8} container spacing={2} alignContent={'space-between'}>
              <Grid item xs={12}>
                <FileTimeframeCard
                  timeframe={timeframe}
                  setTimeframe={handleSetTimeframe}
                  totalTimeFrames={heatmap?.totalTimeFrames}
                />
              </Grid>
              <Grid item xs={12} container>
                <FileHeatmapCard
                  drawStates={drawStates}
                  setDrawStates={setDrawStates}
                  inputState={boundaryState}
                  index={index}
                  setIndex={setIndex}
                  setInputState={setBoundaryState}
                  errorHeat={!!editFileError.heatmapHms}
                  errorGrid={!!editFileError.frame}
                  grid={grid}
                  setGrid={handleEditGrid}
                  gridLoading={editFileLoading.frame}
                  heatmap={heatmap}
                  frameCoords={frameCoords}
                  setFrameCoords={handleSetFrameCoords}
                  heatmapLoading={editFileLoading.heatmapHms}
                />
              </Grid>
            </Grid>
          </Grid>
        </HotKeys>
      </DialogContent>
    </Dialog>
  );
};

export default HeatmapHmsModal;
