import { SrfSubmission } from '../../models/inputTypes/SrfFields';
import { srfSubmissionInitialState } from '../initialStates/SrfInputState';
import { PmsSubmission } from '../../models/inputTypes/PmsFields';
import { OutputFile } from '../../models/jobModels/ModelsFileTypes';
import { prepareInputFileSimulation } from '../functions/prepareInputFileSimulation';
import { findOutputFileName } from '../functions/findOutputFileName';
import {
  HydBoundaryConditions,
  HydInitialConditions,
  HydModelDefinition,
  HydModelFeatures,
  HydNumericalDomain,
  HydOutput,
  HydPlotArea,
  HydPlotLine,
  HydSourceLinks,
} from '../../models/inputTypes/HydFields';
import { HydType } from '../../models/jobModels/hydType';
import {
  hydModelFeaturesInitialState,
  hydPlotAreasAliases,
  hydPlotAreasInitialState,
  hydPlotLinesAliases,
  hydPlotLinesInitialState,
} from '../initialStates/hydInputState';

export type HydSimulationPreparationResponse = {
  numerical: HydNumericalDomain;
  modelDefinition: HydModelDefinition;
  initialState: HydInitialConditions;
  boundaries: HydBoundaryConditions;
  sourceLinks: HydSourceLinks;
  modelFeatures: HydModelFeatures;
  output: HydOutput;
  submission: PmsSubmission;
};

export const hydSimulationPreparation = (simulationFile: HydType): HydSimulationPreparationResponse | undefined => {
  const I_Depths = prepareInputFileSimulation(simulationFile.parameters.input_files, 'I_Depths.csv');
  const I_U_Hot = prepareInputFileSimulation(simulationFile.parameters.input_files, 'I_U_Hot.csv');
  const I_V_Hot = prepareInputFileSimulation(simulationFile.parameters.input_files, 'I_V_Hot.csv');
  const I_Z_Hot = prepareInputFileSimulation(simulationFile.parameters.input_files, 'I_Z_Hot.csv');
  const I_Eddy_Map = prepareInputFileSimulation(simulationFile.parameters.input_files, 'I_Eddy_Map.csv');
  const I_Friction_Map = prepareInputFileSimulation(simulationFile.parameters.input_files, 'I_Friction_Map.csv');
  const I_Sxx = prepareInputFileSimulation(simulationFile.parameters.input_files, 'I_Sxx.csv');
  const I_Sxy = prepareInputFileSimulation(simulationFile.parameters.input_files, 'I_Sxy.csv');
  const I_Syy = prepareInputFileSimulation(simulationFile.parameters.input_files, 'I_Syy.csv');
  const I_WindVelDir = prepareInputFileSimulation(simulationFile.parameters.input_files, 'I_WindVelDir.csv');

  const numerical: HydNumericalDomain = {
    project: simulationFile.project.toString(),
    cellX: +simulationFile.parameters.im,
    cellY: +simulationFile.parameters.jm,
    cellSizeX: +simulationFile.parameters.dx,
    cellSizeY: +simulationFile.parameters.dy,
    bathymetryFile: I_Depths,
    swX: +simulationFile.parameters.isba1,
    swY: +simulationFile.parameters.jsba1,
    neX: +simulationFile.parameters.isba2,
    neY: +simulationFile.parameters.jsba2,
    waterLevel: +simulationFile.parameters.m,
  };

  const modelDefinition: HydModelDefinition = {
    calculationType: simulationFile.parameters.convergence == 1 ? 'instationary' : 'quansi',
    criterion: +simulationFile.parameters.convergence_accuracy,
    filteringType:
      simulationFile.parameters.solution_scheme == 1
        ? 'euler'
        : simulationFile.parameters.solution_scheme == 2
        ? '3rdOrder'
        : '2ndOrder',
    filtering: simulationFile.parameters.filter == 1,
    timeSteps: +simulationFile.parameters.dt,
    simulationTime: +simulationFile.parameters.tend,
  };

  const initialState: HydInitialConditions = {
    coldStart: simulationFile.parameters.icold == 0,
    uVelocity: +simulationFile.parameters.u,
    vVelocity: +simulationFile.parameters.v,
    zVelocity: +simulationFile.parameters.z,

    hotStart: simulationFile.parameters.icold !== 0,
    uFile: I_U_Hot,
    vFile: I_V_Hot,
    zFile: I_Z_Hot,
  };

  const boundaries: HydBoundaryConditions = {
    boundariesLength: +simulationFile.parameters.boundaries_length,
    boundaries: simulationFile.parameters.boundaries,
  };

  const sourceLinks: HydSourceLinks = {
    pointsLength: +simulationFile.parameters.source_points_length,
    source_points: simulationFile.parameters.source_points,
  };

  const modelFeatures: HydModelFeatures = {
    coriolis: simulationFile.parameters.icoriol == 1,
    avgLatitute: +simulationFile.parameters.average_latitude,
    wettingDrying: simulationFile.parameters.iwetdry == 1,
    wettingDepth: +simulationFile.parameters.wetting_depths,
    dryingDepth: +simulationFile.parameters.drying_depths,

    eddyViscosity: simulationFile.parameters.eddy !== 0,
    eddyType: simulationFile.parameters.eddy == 2 ? 'varying' : 'constant',
    eddyCoefficient: simulationFile.parameters.eddy_coefficient.toString(),
    eddyFile: I_Eddy_Map,

    bottomFriction: simulationFile.parameters.friction !== 0,
    bottomFrictionType: simulationFile.parameters.friction == 2 ? 'varying' : 'constant',
    bottomFrictionCoefficient: simulationFile.parameters.friction_coefficient.toString(),
    frictionFile: I_Friction_Map,

    waveRadiation: simulationFile.parameters.iradiation_stresses == 1,
    sxxFile: I_Sxx,
    sxyFile: I_Sxy,
    syyFile: I_Syy,
    windForcing: simulationFile.parameters.iwind !== 0,
    windForcingType: simulationFile.parameters.iwind == 2 ? 'varying' : 'constant',
    uWind: +simulationFile.parameters.wind_velocity,
    direction: +simulationFile.parameters.wind_direction,
    windFrictionFile: I_WindVelDir,

    wind_friction_flag:
      simulationFile.parameters?.wind_friction_flag?.toString() ?? hydModelFeaturesInitialState.wind_friction_flag,
    constant_wind_friction_factor:
      simulationFile.parameters?.constant_wind_friction_factor ??
      hydModelFeaturesInitialState.constant_wind_friction_factor,
  };

  const output: HydOutput = {
    plotAreas: +simulationFile.parameters.plot_areas_length,
    plotLines: +simulationFile.parameters.plot_lines_length,
    plotAreaValues: prepareHydPlotAreas(simulationFile.parameters.plot_areas, simulationFile.parameters.output_files),
    plotLineValues: prepareHydPlotLines(simulationFile.parameters.plot_lines, simulationFile.parameters.output_files),
  };

  const submission: SrfSubmission = {
    vCpus: simulationFile.cores ? simulationFile.cores : srfSubmissionInitialState.vCpus,
    simulationFile: srfSubmissionInitialState.simulationFile,
  };

  return { numerical, modelDefinition, initialState, boundaries, sourceLinks, modelFeatures, output, submission };
};

const prepareHydPlotAreas = (areas: Partial<HydPlotArea>[], outputFiles: OutputFile[]) => {
  const preparedAreas: HydPlotArea[] = [];
  areas.map((area, i) => {
    preparedAreas.push({
      sw_x: area.sw_x ?? hydPlotAreasInitialState.sw_x,
      sw_y: area.sw_y ?? hydPlotAreasInitialState.sw_y,
      ne_x: area.ne_x ?? hydPlotAreasInitialState.ne_x,
      ne_y: area.ne_y ?? hydPlotAreasInitialState.ne_y,
      z: area.z ?? hydPlotAreasInitialState.z,
      u: area.u ?? hydPlotAreasInitialState.u,
      v: area.v ?? hydPlotAreasInitialState.v,
      speed: area.speed ?? hydPlotAreasInitialState.speed,
      direction: area.direction ?? hydPlotAreasInitialState.direction,
      depth: area.depth ?? hydPlotAreasInitialState.depth,
      name_z: findOutputFileName(
        outputFiles,
        hydPlotAreasAliases.name_z,
        i + 1,
        hydPlotAreasInitialState.name_z,
        !area.z,
      ),
      name_u: findOutputFileName(
        outputFiles,
        hydPlotAreasAliases.name_u,
        i + 1,
        hydPlotAreasInitialState.name_u,
        !area.u,
      ),
      name_v: findOutputFileName(
        outputFiles,
        hydPlotAreasAliases.name_v,
        i + 1,
        hydPlotAreasInitialState.name_v,
        !area.v,
      ),
      name_speed: findOutputFileName(
        outputFiles,
        hydPlotAreasAliases.name_speed,
        i + 1,
        hydPlotAreasInitialState.name_speed,
        !area.speed,
      ),
      name_direction: findOutputFileName(
        outputFiles,
        hydPlotAreasAliases.name_direction,
        i + 1,
        hydPlotAreasInitialState.name_direction,
        !area.direction,
      ),
      name_depth: findOutputFileName(
        outputFiles,
        hydPlotAreasAliases.name_depth,
        i + 1,
        hydPlotAreasInitialState.name_depth,
        !area.depth,
      ),

      start_step: area?.start_step ?? hydPlotAreasInitialState.start_step,
      end_step: area?.end_step ?? hydPlotAreasInitialState.end_step,
      interval: area?.interval ?? hydPlotAreasInitialState.interval,
    });
  });
  return preparedAreas;
};

const prepareHydPlotLines = (lines: Partial<HydPlotLine>[], outputFiles: OutputFile[]) => {
  const preparedLines: HydPlotLine[] = [];
  lines.map((line, i) => {
    preparedLines.push({
      fp_x: line.fp_x ?? hydPlotLinesInitialState.fp_x,
      fp_y: line.fp_y ?? hydPlotLinesInitialState.fp_y,
      lp_x: line.lp_x ?? hydPlotLinesInitialState.lp_x,
      lp_y: line.lp_y ?? hydPlotLinesInitialState.lp_y,
      z: line.z ?? hydPlotLinesInitialState.z,
      u: line.u ?? hydPlotLinesInitialState.u,
      v: line.v ?? hydPlotLinesInitialState.v,
      speed: line.speed ?? hydPlotLinesInitialState.speed,
      direction: line.direction ?? hydPlotLinesInitialState.direction,
      depth: line.depth ?? hydPlotLinesInitialState.depth,
      name_z: findOutputFileName(
        outputFiles,
        hydPlotLinesAliases.name_z,
        i + 1,
        hydPlotLinesInitialState.name_z,
        !line.z,
      ),
      name_u: findOutputFileName(
        outputFiles,
        hydPlotLinesAliases.name_u,
        i + 1,
        hydPlotLinesInitialState.name_u,
        !line.u,
      ),
      name_v: findOutputFileName(
        outputFiles,
        hydPlotLinesAliases.name_v,
        i + 1,
        hydPlotLinesInitialState.name_v,
        !line.v,
      ),
      name_speed: findOutputFileName(
        outputFiles,
        hydPlotLinesAliases.name_speed,
        i + 1,
        hydPlotLinesInitialState.name_speed,
        !line.speed,
      ),
      name_direction: findOutputFileName(
        outputFiles,
        hydPlotLinesAliases.name_direction,
        i + 1,
        hydPlotLinesInitialState.name_direction,
        !line.direction,
      ),
      name_depth: findOutputFileName(
        outputFiles,
        hydPlotLinesAliases.name_depth,
        i + 1,
        hydPlotLinesInitialState.name_depth,
        !line.depth,
      ),

      start_step: line?.start_step ?? hydPlotLinesInitialState.start_step,
      end_step: line?.end_step ?? hydPlotLinesInitialState.end_step,
      interval: line?.interval ?? hydPlotLinesInitialState.interval,
    });
  });
  return preparedLines;
};
