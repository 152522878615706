import React from 'react';
import { ButtonProps, styled } from '@mui/material';
import { LoadingButton } from '@mui/lab';

type extraButtonProps = {
  small?: boolean;
  borderRadius?: number;
  minWidth?: string;
  loading?: any;
};
const CustomButton = styled((props: ButtonProps & extraButtonProps) => <LoadingButton {...props} />)`
  border-radius: ${(props: extraButtonProps) => props.borderRadius}px;
  padding: ${(props: extraButtonProps) => (props.small ? '6px 10px' : '8px 10px')};
  min-width: ${(props: extraButtonProps) => props.minWidth};
  text-transform: none;
  box-shadow: none;
`;

export default CustomButton;
