import React, { FC, memo, useState } from 'react';
import { Box, Typography } from '@mui/material';
import CustomInputWithLabel from '../../common/CustomInputWithLabel';
import LabelWrapper from '../../common/LabelWrapper';
import CustomInput from '../../custom/CustomInput';
import BoxComponent from '../../custom/BoxComponent';
import CustomCheckbox from '../../custom/CustomCheckbox';
import { HydPlotLine } from '../../../models/inputTypes/HydFields';

const styles = {
  spacingContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  alignEndContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    flexWrap: 'wrap',
  },
  largeChildSpacing: {
    '& >:nth-child(n)': {
      my: 1,
      mr: { xs: 4, md: 6, lg: 8 },
    },
  },
  smallChildSpacing: {
    '& >:nth-child(n)': {
      my: 3,
      mr: 4,
    },
  },

  smallVariableSpacing: {
    '& >:nth-child(n)': {
      my: 1,
      mr: 4,
    },
    '& >:first-child': {
      my: 1,
      mr: 2,
    },
  },
  coordinates: {
    '& >:nth-child(2)': {
      ml: 1,
    },
  },
} as const;

type HydPlotLinesInputsGroupProps = {
  inputState: HydPlotLine;
  setInputState: (value: HydPlotLine) => void;
};

const HydPlotLinesInputsGroup: FC<HydPlotLinesInputsGroupProps> = ({ inputState, setInputState }) => {
  const [valueX, setValueX] = useState(1);
  const [valueY, setValueY] = useState(1);
  const onCheckboxClick = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    const currentValue: 'u' | 'v' | 'z' | 'speed' | 'direction' | 'depth' = e.target.id;
    const newValue = inputState[currentValue] ? 0 : 1;
    setInputState({ ...inputState, [currentValue]: newValue });
  };

  const onInputChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setInputState({ ...inputState, [event.target.name]: event.target.value });
  };

  const onNumberChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setInputState({ ...inputState, [event.target.name]: event.target.value });
  };

  const onInputChangeX = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setValueX(+event.target.value);
    setInputState({ ...inputState, [event.target.name]: event.target.value });
  };
  const onInputChangeY = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setValueY(+event.target.value);
    setInputState({ ...inputState, [event.target.name]: event.target.value });
  };

  return (
    <Box>
      <Typography mb={1} variant={'subtitle1'}>
        Bounding Points & Grid Cell Node Numbers
      </Typography>
      <Box mb={2} sx={{ ...styles.spacingContainer, ...styles.largeChildSpacing }}>
        <Box>
          <LabelWrapper label={'First point'}>
            <Box sx={{ display: 'flex', ...styles.coordinates }}>
              <CustomInputWithLabel
                min={1}
                step={1}
                required
                errorText={'Enter positive integer'}
                type={'number'}
                onChange={onNumberChange}
                value={inputState.fp_x}
                name={'fp_x'}
                label={'X'}
              />
              <CustomInputWithLabel
                min={1}
                step={1}
                required
                errorText={'Enter positive integer'}
                type={'number'}
                onChange={onNumberChange}
                value={inputState.fp_y}
                name={'fp_y'}
                label={'Y'}
              />
            </Box>
          </LabelWrapper>
        </Box>
        <Box>
          <LabelWrapper label={'Last point'}>
            <Box sx={{ display: 'flex', ...styles.coordinates }}>
              <CustomInputWithLabel
                min={inputState.fp_x}
                step={1}
                required
                errorText={
                  valueX < inputState.fp_x && valueX > 0
                    ? 'Number should be >= than first point'
                    : 'Enter positive integer'
                }
                type={'number'}
                onChange={onInputChangeX}
                value={inputState.lp_x}
                name={'lp_x'}
                label={'X'}
              />
              <CustomInputWithLabel
                min={inputState.fp_y}
                step={1}
                required
                errorText={
                  valueY < inputState.fp_y && valueY > 0
                    ? 'Number should be >= than first point'
                    : 'Enter positive integer'
                }
                type={'number'}
                onChange={onInputChangeY}
                value={inputState.lp_y}
                name={'lp_y'}
                label={'Y'}
              />
            </Box>
          </LabelWrapper>
        </Box>
      </Box>
      <Typography mx={1} variant={'subtitle1'}>
        Variables
      </Typography>
      <Box sx={{ ...styles.smallChildSpacing }}>
        <Box sx={{ ...styles.alignEndContainer }}>
          <Box mr={2}>
            <CustomCheckbox checked={!!inputState.u} id={'u'} onClick={onCheckboxClick} />
          </Box>
          <BoxComponent disabled={!inputState.u}>
            <LabelWrapper label={'U Velocity'}>
              <CustomInput
                disabled={!inputState.u}
                required
                minWidth={'160px'}
                errorText={'Please enter file name'}
                maxLength={30}
                onChange={onInputChange}
                value={inputState.name_u}
                name={'name_u'}
              />
            </LabelWrapper>
          </BoxComponent>
        </Box>
        <Box sx={{ ...styles.alignEndContainer }}>
          <Box mr={2}>
            <CustomCheckbox checked={!!inputState.v} id={'v'} onClick={onCheckboxClick} />
          </Box>
          <BoxComponent disabled={!inputState.v}>
            <LabelWrapper label={'V Velocity'}>
              <CustomInput
                disabled={!inputState.v}
                required
                minWidth={'160px'}
                errorText={'Please enter file name'}
                type={'text'}
                maxLength={30}
                onChange={onInputChange}
                value={inputState.name_v}
                name={'name_v'}
              />
            </LabelWrapper>
          </BoxComponent>
        </Box>
        <Box sx={{ ...styles.alignEndContainer }}>
          <Box mr={2}>
            <CustomCheckbox checked={!!inputState.z} id={'z'} onClick={onCheckboxClick} />
          </Box>
          <BoxComponent disabled={!inputState.z}>
            <LabelWrapper label={'Z Surface elevation'}>
              <CustomInput
                disabled={!inputState.z}
                required
                minWidth={'160px'}
                errorText={'Please enter file name'}
                type={'text'}
                maxLength={30}
                onChange={onInputChange}
                value={inputState.name_z}
                name={'name_z'}
              />
            </LabelWrapper>
          </BoxComponent>
        </Box>
        <Box sx={{ ...styles.alignEndContainer }}>
          <Box mr={2}>
            <CustomCheckbox checked={!!inputState.speed} id={'speed'} onClick={onCheckboxClick} />
          </Box>
          <BoxComponent disabled={!inputState.speed}>
            <LabelWrapper label={'Current Speed'}>
              <CustomInput
                disabled={!inputState.speed}
                required
                minWidth={'160px'}
                errorText={'Please enter file name'}
                type={'text'}
                maxLength={30}
                onChange={onInputChange}
                value={inputState.name_speed}
                name={'name_speed'}
              />
            </LabelWrapper>
          </BoxComponent>
        </Box>
        <Box sx={{ ...styles.alignEndContainer, ...styles.smallVariableSpacing }}>
          <Box mr={2}>
            <CustomCheckbox checked={!!inputState.direction} id={'direction'} onClick={onCheckboxClick} />
          </Box>
          <BoxComponent disabled={!inputState.direction}>
            <LabelWrapper label={'Current Direction'}>
              <CustomInput
                disabled={!inputState.direction}
                required
                type={'text'}
                minWidth={'160px'}
                errorText={'Please enter file name'}
                maxLength={30}
                onChange={onInputChange}
                value={inputState.name_direction}
                name={'name_direction'}
              />
            </LabelWrapper>
          </BoxComponent>
        </Box>
        <Box sx={{ ...styles.alignEndContainer }}>
          <Box mb={1} mr={2}>
            <CustomCheckbox checked={!!inputState.depth} id={'depth'} onClick={onCheckboxClick} />
          </Box>
          <BoxComponent disabled={!inputState.depth}>
            <LabelWrapper label={'Total Depth'}>
              <CustomInput
                required
                disabled={!inputState.depth}
                type={'text'}
                minWidth={'160px'}
                errorText={'Please enter file name'}
                maxLength={30}
                onChange={onInputChange}
                value={inputState.name_depth}
                name={'name_depth'}
              />
            </LabelWrapper>
          </BoxComponent>
        </Box>
      </Box>
      <>
        <Box mt={2} mb={1}>
          <Typography variant={'subtitle1'}>Time Parameters</Typography>
        </Box>
        <Box sx={{ ...styles.spacingContainer, ...styles.smallChildSpacing }}>
          <Box>
            <LabelWrapper label={'Start time step'}>
              <CustomInput
                min={1}
                step={1}
                type={'number'}
                errorText={'Enter positive integer'}
                required
                onChange={onNumberChange}
                value={inputState.start_step}
                name={'start_step'}
              />
            </LabelWrapper>
          </Box>
          <Box>
            <LabelWrapper label={'End time step'}>
              <CustomInput
                min={1}
                step={1}
                type={'number'}
                errorText={'Enter positive integer'}
                required
                onChange={onNumberChange}
                value={inputState.end_step}
                name={'end_step'}
              />
            </LabelWrapper>
          </Box>
          <Box>
            <LabelWrapper label={'Time step interval'}>
              <CustomInput
                min={1}
                step={1}
                required
                errorText={'Enter positive integer'}
                type={'number'}
                onChange={onNumberChange}
                value={inputState.interval}
                name={'interval'}
              />
            </LabelWrapper>
          </Box>
        </Box>
      </>
    </Box>
  );
};

export default memo(HydPlotLinesInputsGroup);
