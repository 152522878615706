import React, { FC, FormEvent, useEffect, useMemo, useState } from 'react';
import PageContainer from '../../components/structural/PageContainer';
import { Box, Collapse, Grid, Typography } from '@mui/material';
import BoxInputContainer from '../../components/custom/BoxInputContainer';
import SrfNumericalDomainInputsGroup from '../../components/groups/srf/SrfNumericalDomainInputsGroup';
import CustomSwitch from '../../components/custom/CustomSwitch';
import SrfSubmissionInputsGroup from '../../components/groups/srf/SrfSubmissionInputsGroup';
import SrfSpongeInputsGroup from '../../components/groups/srf/SrfSpongeInputsGroup';
import SrfRefInputsGroup from '../../components/groups/srf/SrfRefInputsGroup';
import CustomPrompt from '../../components/custom/CustomPrompt';
import {
  SrfNumericalDomain,
  SrfReflection,
  SrfSponge,
  SrfSubmission,
  SrfSwitches,
} from '../../models/inputTypes/SrfFields';
import {
  srfNumericalDomainInitialState,
  srfReflectionInitialState,
  srfSpongeInitialState,
  srfSpongeSwitchesInitialState,
  srfSubmissionInitialState,
} from '../../utils/initialStates/SrfInputState';
import { useGetProjectsQuery } from '../../redux/RTK/queries/projectQuery';
import { srfPreparation } from '../../utils/modelPreparation/srfPreparation';
import { FileType } from '../../models/inputTypes/FileType';
import { srfSimulationPreparationResponse } from '../../utils/simulationFileToState/srfSimulationPreparation';
import { openWarningToast } from '../../redux/slices/appSlice';
import { useAddProjectFileMutation } from '../../redux/RTK/mutations/projectFileMutations';
import { useCreateJobsMutation } from '../../redux/RTK/mutations/jobsMutations';
import { SdtSimulationPreparationResponse } from '../../utils/simulationFileToState/sdtSimulationPreparation';
import ConfirmationModal from '../../components/dialogs/ConfirmationModal';
import { useAppDispatch } from '../../redux/store';
import useSimulationPreparation from '../../utils/hooks/jsonParamHook';
import { ModelEnum } from '../../models/types/ModelEnum';

const MarisSrfPage: FC = () => {
  const { data } = useGetProjectsQuery({});
  const [createJob] = useCreateJobsMutation();
  const [uploadFile] = useAddProjectFileMutation();
  const dispatch = useAppDispatch();

  const [numericalState, setNumericalState] = useState<SrfNumericalDomain>(srfNumericalDomainInitialState);
  const [switchBoxes, setSwitchBoxes] = useState<SrfSwitches>(srfSpongeSwitchesInitialState);
  const [spongeState, setSpongeState] = useState<SrfSponge>(srfSpongeInitialState);
  const [reflectionState, setReflectionState] = useState<SrfReflection>(srfReflectionInitialState);
  const [submissionState, setSubmissionState] = useState<SrfSubmission>(srfSubmissionInitialState);
  const [saveNoOutput, setSaveNoOutput] = useState(false);
  const [stateHasChanged, setStateHasChanged] = useState(false);
  const { preparedData, error, currentFile } = useSimulationPreparation(ModelEnum.SRF);

  useEffect(() => {
    if (preparedData && !error && currentFile) {
      onSimulationFileChange(preparedData, currentFile);
    }
  }, [preparedData, error, currentFile]);

  useEffect(() => {
    if (!stateHasChanged) {
      const hasNumericalChanged = numericalState != srfNumericalDomainInitialState;
      const hasSpongeChanged = spongeState != srfSpongeInitialState;
      const hasReflectionChanged = reflectionState != srfReflectionInitialState;
      const hasSubChanged = submissionState != srfSubmissionInitialState;
      setStateHasChanged(hasReflectionChanged || hasNumericalChanged || hasSpongeChanged || hasSubChanged);
    }
  }, [numericalState, spongeState, reflectionState, submissionState]);

  const selectedProjectName = useMemo(() => {
    if (data?.length) {
      const found = data.find((item) => item.id.toString() === numericalState.project.toString());
      return found?.name;
    }
  }, [numericalState.project]);

  const onSwitchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSwitchBoxes({ ...switchBoxes, [e.target.id]: e.target.checked });
    if (e.target.id === 'sponge') {
      setSpongeState(srfSpongeInitialState);
    }
    if (e.target.id === 'ref') {
      setReflectionState(srfReflectionInitialState);
    }
  };

  const onNoOutputAccept = () => {
    const preparedValue = srfPreparation(numericalState, spongeState, switchBoxes, reflectionState, submissionState);
    if (preparedValue) createJob(preparedValue);
    setSaveNoOutput(false);
  };

  const onSubmissionClick = (event: FormEvent<any>) => {
    event.preventDefault();
    if (!switchBoxes.ref && !switchBoxes.sponge) {
      setSaveNoOutput(true);
      return;
    }
    const preparedValue = srfPreparation(numericalState, spongeState, switchBoxes, reflectionState, submissionState);
    if (preparedValue) createJob(preparedValue);
  };

  const onSimulationSave = () => {
    const preparedValue = srfPreparation(numericalState, spongeState, switchBoxes, reflectionState, submissionState);
    if (preparedValue && numericalState.project && submissionState.simulationFile) {
      uploadFile({
        id: numericalState.project,
        name: `${submissionState?.simulationFile}.json`,
        file: preparedValue,
      });
    } else {
      dispatch(
        openWarningToast(
          !numericalState.project ? 'You have to select project first!' : 'simulation file name required!',
        ),
      );
    }
  };

  const onSimulationFileChange = (
    sim: srfSimulationPreparationResponse | SdtSimulationPreparationResponse,
    file?: FileType,
  ) => {
    const value = sim as srfSimulationPreparationResponse;
    setNumericalState({ ...value.numerical, simulationSetup: file });
    setSwitchBoxes(value.switches);
    setSpongeState(value.sponge);
    setReflectionState(value.ref);
    setSubmissionState({ ...value.submission, simulationFile: file?.fileName?.split('.json')[0] ?? '' });
  };

  return (
    <PageContainer title={'NUMERICAL TOOLS'} subTitle={'Maris SRF'} projectName={selectedProjectName}>
      <CustomPrompt
        when={stateHasChanged}
        message={(params) =>
          params.pathname == '/designTools/maris-srf' ? true : 'Changes that you made may not be saved.'
        }
      />

      <Typography variant={'h4'}>Numerical Domain</Typography>
      <form onSubmit={onSubmissionClick}>
        <BoxInputContainer borderRadius={'4px'} mt={2}>
          <SrfNumericalDomainInputsGroup
            simulationChangeFile={onSimulationFileChange}
            inputState={numericalState}
            setInputState={setNumericalState}
          />
        </BoxInputContainer>

        <Grid mt={2} container alignItems={'center'}>
          <Typography variant={'h4'}>Sponge</Typography>
          <Box ml={27}>
            <CustomSwitch checked={switchBoxes.sponge} id={'sponge'} onChange={onSwitchChange} />
          </Box>
        </Grid>
        <Collapse in={switchBoxes.sponge} collapsedSize={40}>
          <BoxInputContainer borderRadius={'4px'} mt={2}>
            <SrfSpongeInputsGroup inputState={spongeState} setInputState={setSpongeState} />
          </BoxInputContainer>
        </Collapse>

        <Grid mt={2} container alignItems={'center'}>
          <Typography variant={'h4'}>Eddy/FrictionMap</Typography>
          <Box ml={16}>
            <CustomSwitch checked={switchBoxes.ref} id={'ref'} onChange={onSwitchChange} />
          </Box>
        </Grid>

        <Collapse in={switchBoxes.ref} collapsedSize={40}>
          <BoxInputContainer borderRadius={'4px'} mt={2}>
            <SrfRefInputsGroup inputState={reflectionState} setInputState={setReflectionState} />
          </BoxInputContainer>
        </Collapse>

        <Typography mt={2} variant={'h4'}>
          Submission
        </Typography>
        <BoxInputContainer borderRadius={'4px'} mt={2}>
          <SrfSubmissionInputsGroup
            onSaveClick={onSimulationSave}
            inputState={submissionState}
            setInputState={setSubmissionState}
          />
        </BoxInputContainer>
      </form>
      <ConfirmationModal
        open={saveNoOutput}
        handleClose={() => setSaveNoOutput(false)}
        message={
          'Sponge and Reflection section is disabled! You are about to make a job without output! Are you sure you want to do that?'
        }
        handleAccept={onNoOutputAccept}
      />
    </PageContainer>
  );
};

export default MarisSrfPage;
