import React, { FC, useState } from 'react';
import { Box } from '@mui/material';
import { Switch, Route, Redirect } from 'react-router-dom';
import Login from '../pages/Login';
import LayoutRoute from './LayoutRoute';
import RoutesWithLayout from './RoutesWithLayout';
import SnackBarContainer from '../components/structural/SnackbarContainer';
import GlobalLoading from '../components/structural/GlobalLoading';
import { useAppSelector } from '../redux/store';
import ForgotPassword from '../pages/resetPassword/ForgotPassword';
import ResetPassword from '../pages/resetPassword/ResetPassword';
import SentEmail from '../pages/resetPassword/SentEmail';
import ResetPasswordLogin from '../pages/resetPassword/ResetPasswordLogin';

const CustomRouter: FC = () => {
  const accessToken = useAppSelector((state) => state.authentication.data.accessToken);

  return (
    <Box>
      <Switch>
        <Route
          path={'/password/forgot'}
          render={() => {
            return <ForgotPassword />;
          }}
        />
        <Route
          path={'/password/sent-email'}
          render={() => {
            return <SentEmail />;
          }}
        />
        <Route
          path={'/password/reset/confirm/:uid/:token'}
          render={() => {
            return <ResetPassword />;
          }}
        />
        <Route
          path={'/password/reset'}
          render={() => {
            return <ResetPasswordLogin />;
          }}
        />
        <Route
          path={'/login'}
          render={() => {
            if (accessToken) return <Redirect to={'/'} />;
            return <Login />;
          }}
        />
        <Route
          path={'/'}
          render={() => {
            if (!accessToken) return <Redirect to={'/login'} />;
            return (
              <LayoutRoute path={'/'}>
                <RoutesWithLayout />
              </LayoutRoute>
            );
          }}
        />
      </Switch>
      <SnackBarContainer />
      <GlobalLoading />
    </Box>
  );
};

export default CustomRouter;
