import { EviType } from '../../models/jobModels/eviType';
import { EviNumerical } from '../../models/inputTypes/EviFields';
import { InputFile, OutputFile } from '../../models/jobModels/ModelsFileTypes';
import {
  prepareBedLevelFiles,
  prepareEviOutputFiles,
  prepareSedimentFiles,
  prepareWeights,
} from '../functions/eviFilePreparations';
import { PmsSubmission } from '../../models/inputTypes/PmsFields';

export const eviPreparation = (numerical: EviNumerical, submission: PmsSubmission): EviType => {
  const isBedLevel = numerical.fileType == 1;
  const preparedInputFiles: Partial<InputFile>[] = isBedLevel
    ? prepareBedLevelFiles(numerical.bedLevelList, numerical.project)
    : prepareSedimentFiles(numerical.sedimentList, numerical.project);

  const preparedWeights: number[] = prepareWeights(isBedLevel ? numerical.bedLevelList : numerical.sedimentList);
  const preparedOutputFiles: Partial<OutputFile>[] = prepareEviOutputFiles(numerical, isBedLevel);

  return {
    simulation: 'evi',
    project: numerical.project,
    name: submission.simulationFile,
    cores: +submission.vCpus,
    parameters: {
      filetype: +numerical.fileType,
      frame: +numerical.frame,
      calm_period: +numerical.calmPeriod,
      input_file: preparedInputFiles,
      weights: preparedWeights,
      output_file: preparedOutputFiles,
    },
  };
};
