import React, { FC, memo } from 'react';
import { Box, Typography, Theme, InputAdornment, IconButton, Grid } from '@mui/material';
import BoxInputContainer from '../../custom/BoxInputContainer';
import { StyledInput } from '../../common/LoginInput';
import { ReactComponent as Close } from '../../../assets/images/close.svg';
import LabelWrapper from '../../common/LabelWrapper';
import CustomButton from '../../custom/CustomButton';
import CustomSwitch from '../../custom/CustomSwitch';

const styles = {
  inputGrid: {
    display: 'grid',
    alignItems: 'center',
    // gridTemplateColumns: '96px 2fr',
    gap: '12px 24px',
  },
  boxInputs: {
    padding: (theme: Theme) => theme.spacing(1.5, 4, 1.5, 2),
    background: '#DFE1E6',
  },
  smallInputsGrid: { display: 'grid', alignItems: 'center', gridTemplateColumns: '1.5fr 1fr', gap: '12px 24px' },

  buttonPosition: {
    display: 'flex',
    margin: 'auto',
    marginTop: 3,
    background: (theme: Theme) => theme.palette.primary.dark,
  },
} as const;

type HeatMapInputProps = {
  disabled: boolean;
  coastline: number | undefined;
  setCoastline: (coastline: number | undefined) => void;
  groynesBrkw: number | undefined;
  setGroynesBrkw: (groynesBrkw: number | undefined) => void;
  revet: number | undefined;
  setRevet: (revet: number | undefined) => void;
  epsg: number | undefined;
  setEpsg: (epsg: number | undefined) => void;
  enableMap: boolean;
  setEnableMap: (enableMap: boolean) => void;
  onPlot: () => void;
};

const FileInputsCard: FC<HeatMapInputProps> = ({
  disabled,
  coastline,
  setCoastline,
  groynesBrkw,
  setGroynesBrkw,
  revet,
  setRevet,
  epsg,
  setEpsg,
  enableMap,
  setEnableMap,
  onPlot,
  ...rest
}) => {
  return (
    <BoxInputContainer sx={{ ...styles.boxInputs }} borderRadius={'6px'}>
      <Typography mb={3} variant={'subtitle1'}>
        Background options
      </Typography>
      <Box sx={styles.inputGrid}>
        <Box>
          <LabelWrapper label={'Insert epsg code'}>
            <Grid mb={2} container>
              <CustomSwitch checked={enableMap} onChange={(e) => setEnableMap(e.target.checked)} />
            </Grid>
            <StyledInput
              disabled={!enableMap}
              value={epsg ?? ''}
              onChange={(event) => setEpsg(event.target.value ? Number(event.target.value) : undefined)}
              maxWidth={'200px'}
              type={'number'}
              endAdornment={
                epsg !== undefined ? (
                  <InputAdornment position="end">
                    <IconButton size="small" onClick={() => setEpsg(undefined)}>
                      <Close />
                    </IconButton>
                  </InputAdornment>
                ) : undefined
              }
            />
          </LabelWrapper>
        </Box>
      </Box>
      <Typography my={3} variant={'subtitle1'}>
        Specify code values
      </Typography>
      <Box sx={styles.inputGrid}>
        <Box>
          <LabelWrapper label={'Coastline'}>
            <StyledInput
              value={coastline ?? ''}
              onChange={(event) => setCoastline(event.target.value ? Number(event.target.value) : undefined)}
              endAdornment={
                coastline !== undefined ? (
                  <InputAdornment position="end">
                    <IconButton size="small" onClick={() => setCoastline(undefined)}>
                      <Close />
                    </IconButton>
                  </InputAdornment>
                ) : undefined
              }
              maxWidth={'200px'}
              type={'number'}
            />
          </LabelWrapper>
        </Box>
        <Box>
          <LabelWrapper label={'Groynes/Breakwater'}>
            <StyledInput
              value={groynesBrkw ?? ''}
              onChange={(event) => setGroynesBrkw(event.target.value ? Number(event.target.value) : undefined)}
              maxWidth={'200px'}
              type={'number'}
              endAdornment={
                groynesBrkw !== undefined ? (
                  <InputAdornment position="end">
                    <IconButton size="small" onClick={() => setGroynesBrkw(undefined)}>
                      <Close />
                    </IconButton>
                  </InputAdornment>
                ) : undefined
              }
            />
          </LabelWrapper>
        </Box>
        <Box>
          <LabelWrapper label={'Revetments'}>
            <StyledInput
              value={revet ?? ''}
              onChange={(event) => setRevet(event.target.value ? Number(event.target.value) : undefined)}
              maxWidth={'200px'}
              type={'number'}
              endAdornment={
                revet !== undefined ? (
                  <InputAdornment position="end">
                    <IconButton size="small" onClick={() => setRevet(undefined)}>
                      <Close />
                    </IconButton>
                  </InputAdornment>
                ) : undefined
              }
            />
          </LabelWrapper>
        </Box>
      </Box>
      <CustomButton
        // loading={saveLoading}
        // disabled={disabled}
        minWidth={'120px'}
        sx={styles.buttonPosition}
        size={'small'}
        variant={'contained'}
        color={'primary'}
        onClick={onPlot}
      >
        Plot
      </CustomButton>
    </BoxInputContainer>
  );
};

export default memo(FileInputsCard);
