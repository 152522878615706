import React from 'react';
import { Button, ButtonProps, styled } from '@mui/material';

const SquaredIconButton = styled((props: ButtonProps) => <Button {...props} />)(({ theme }) => ({
  padding: theme.spacing(0.5),
  minWidth: 0,
  minHeight: 0,
}));

export default SquaredIconButton;
