import React from 'react';
import { LinearProgress, linearProgressClasses, LinearProgressProps, styled } from '@mui/material';

type customProgressProps = {
  title?: string;
};

const CustomProgress = styled((props: LinearProgressProps & customProgressProps) => (
  <LinearProgress variant="determinate" {...props} />
))(({ theme }) => ({
  height: '8px',
  borderRadius: '99px',
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.info.light,
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: '0px 99px 99px 0px',
    backgroundColor: '#007BFF',
  },
}));

export default CustomProgress;
