import { Box, BoxProps, styled } from '@mui/material';
import React from 'react';
import theme from '../../theme';

type CustomBoxProps = {
  disabled?: boolean;
};

const BoxInputContainer = styled((props: BoxProps & CustomBoxProps) => <Box {...props} />)`
  opacity: ${(props: CustomBoxProps) => (props.disabled ? 0.5 : 1)};
  pointer-events: ${(props: CustomBoxProps) => (props.disabled ? 'none' : 'initial')};
  background: ${theme.palette.info.contrastText};
  box-shadow: 0 0 0 1px #f2f2f2;
  padding: ${theme.spacing(3, 2)};
`;

export default BoxInputContainer;
