import { createSlice } from '@reduxjs/toolkit';

type appSliceType = {
  toast: {
    isOpen: boolean;
    message: string;
    severity: 'success' | 'info' | 'warning' | 'error';
  };
  globalLoading: boolean;
  downloading: boolean;
  progress: number;
  zipping: boolean;
};

const initialState: appSliceType = {
  toast: {
    isOpen: false,
    message: '',
    severity: 'success',
  },
  globalLoading: false,
  downloading: false,
  progress: 0,
  zipping: false,
};

const appSlice = createSlice({
  name: 'app',
  initialState: initialState,
  reducers: {
    openSuccessToast(state, action) {
      state.toast.isOpen = true;
      state.toast.message = action.payload;
      state.toast.severity = 'success';
    },
    openErrorToast(state, action) {
      state.toast.isOpen = true;
      state.toast.message = action.payload;
      state.toast.severity = 'error';
    },
    openWarningToast(state, action) {
      state.toast.isOpen = true;
      state.toast.message = action.payload;
      state.toast.severity = 'warning';
    },
    openInfoToast(state, action) {
      state.toast.isOpen = true;
      state.toast.message = action.payload;
      state.toast.severity = 'info';
    },
    closeToast(state) {
      state.toast.isOpen = false;
      state.toast.message = '';
      state.toast.severity = 'success';
    },
    setGlobalLoading(state, action) {
      state.globalLoading = action.payload;
    },
    setDownloading(state, action) {
      state.downloading = action.payload;
    },
    setProgress(state, action) {
      state.progress = action.payload;
    },
    setZipping(state, action) {
      state.zipping = action.payload;
    },
  },
});

export const {
  closeToast,
  setGlobalLoading,
  openInfoToast,
  openWarningToast,
  openErrorToast,
  openSuccessToast,
  setDownloading,
  setProgress,
  setZipping,
} = appSlice.actions;
export default appSlice.reducer;
