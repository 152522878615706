import {
  SrfNumericalDomain,
  SrfRefEddyCalculator,
  SrfReflection,
  SrfSponge,
  SrfSubmission,
  SrfSwitches,
} from '../../models/inputTypes/SrfFields';

export const srfNumericalDomainInitialState: SrfNumericalDomain = {
  project: '',
  simulationSetup: undefined,
  cellX: 1,
  cellY: 1,
  cellSizeX: 2.5,
  cellSizeY: 2.5,
  bathymetryFile: undefined,
  swX: 1,
  swY: 1,
  neX: 1,
  neY: 1,
};

export const srfSpongeSwitchesInitialState: SrfSwitches = {
  sponge: false,
  ref: false,
};

export const srfSpongeInitialState: SrfSponge = {
  nameSpongeLayer: 'Sponge_Map',
  addValues: 10,
  backgroundValue: 1,
  baseValue: 7,
  layerNumber: 20,
  powerValue: 0.7,
};

export const srfReflectionInitialState: SrfReflection = {
  reflectionMap: 'Eddy_Map',
  backgroundValue: 0,
  landCodeValues: 1,
  codes_list: [{ code: 10, base: 1, layers: 4 }],
};

export const srfRefEddyCalculatorInitialState: SrfRefEddyCalculator = {
  sea_state: '1',
  Hs: 1.5,
  Tp: 5.0,
  depth: 4,
  width: 10,
};

export const srfSubmissionInitialState: SrfSubmission = {
  // outputFileName: '',
  simulationFile: '',
  vCpus: 0,
};
