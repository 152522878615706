import React, { FC, memo } from 'react';
import { Box, Grid, SelectChangeEvent, Typography } from '@mui/material';
import CustomMenuItem from '../../custom/CustomMenuItem';
import CustomSelect from '../../custom/CustomSelect';
import LabelWrapper from '../../common/LabelWrapper';
import CustomSwitch from '../../custom/CustomSwitch';
import CustomRadioButton from '../../custom/CustomRadioButton';
import BoxComponent from '../../custom/BoxComponent';
import { MarisPlotPrintSetup } from '../../../models/inputTypes/MarisPlotFields';
import { marisPlotPrintSetupInitialState } from '../../../utils/initialStates/marisPlotState';
import CustomInput from '../../custom/CustomInput';

const styles = {
  flexStartBox: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  gapLarge: {
    gap: '32px',
  },
  gapMedium: {
    gap: '16px',
  },
} as const;

type InputsProps = {
  inputState: MarisPlotPrintSetup;
  setInputState: (value: MarisPlotPrintSetup) => void;
};

const MarisPlotPrintSetupInputsGroup: FC<InputsProps> = ({ inputState, setInputState }) => {
  const onInputChange = (
    event: SelectChangeEvent<unknown> | React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setInputState({ ...inputState, [event.target.name]: event.target.value });
  };

  const onRadioClick = (value: any) => {
    setInputState({
      ...inputState,
      subSeriesType: value,
      pageOrientation: marisPlotPrintSetupInitialState.pageOrientation,
      imageDPI: marisPlotPrintSetupInitialState.imageDPI,
      label: false,
    });
  };

  const onChangeSwitch = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name == 'north_arrow') {
      setInputState({
        ...inputState,
        [e.target.name]: e.target.checked,
        north_arrow_pos: marisPlotPrintSetupInitialState.north_arrow_pos,
        north_arrow_colour: marisPlotPrintSetupInitialState.north_arrow_colour,
        north_arrow_rot: marisPlotPrintSetupInitialState.north_arrow_rot,
      });
      return;
    }

    setInputState({ ...inputState, [e.target.name]: e.target.checked });
  };

  return (
    <Grid container sx={styles.gapLarge}>
      <Box>
        <Typography my={2} variant={'subtitle1'}>
          File Type
        </Typography>
        <Box sx={{ ...styles.flexStartBox }}>
          <Box mr={10}>
            <CustomRadioButton
              checked={inputState.subSeriesType === 'pdf'}
              onButtonClick={onRadioClick}
              value={'pdf'}
              textLabel={'.PDF'}
            />
          </Box>
          <BoxComponent mr={10} disabled={inputState.subSeriesType !== 'pdf'}>
            <LabelWrapper label={'A4 Page Orientation'}>
              <CustomSelect
                disabled={inputState.subSeriesType !== 'pdf'}
                required
                value={inputState.pageOrientation}
                name={'pageOrientation'}
                onChange={onInputChange}
                minWidth={'140px'}
                defaultValue={''}
                displayEmpty
              >
                <CustomMenuItem value={'1'}>
                  <Typography variant={'subtitle2'}>Portrait</Typography>
                </CustomMenuItem>
                <CustomMenuItem value={'2'}>
                  <Typography variant={'subtitle2'}>Landscape</Typography>
                </CustomMenuItem>
              </CustomSelect>
            </LabelWrapper>
          </BoxComponent>
          <BoxComponent>
            <LabelWrapper label={'Label'}>
              <CustomSwitch
                disabled={inputState.subSeriesType !== 'pdf'}
                checked={inputState.label}
                name={'label'}
                onChange={onChangeSwitch}
              />
            </LabelWrapper>
          </BoxComponent>
        </Box>
        <Box mt={2} sx={{ ...styles.flexStartBox }}>
          <Box mr={10}>
            <CustomRadioButton
              checked={inputState.subSeriesType === 'png'}
              onButtonClick={onRadioClick}
              value={'png'}
              textLabel={'.PNG'}
            />
          </Box>
          <BoxComponent disabled={inputState.subSeriesType !== 'png'}>
            <LabelWrapper label={'Image DPI'}>
              <CustomSelect
                required
                disabled={inputState.subSeriesType !== 'png'}
                value={inputState.imageDPI}
                name={'imageDPI'}
                onChange={onInputChange}
                minWidth={'140px'}
                defaultValue={''}
                displayEmpty
              >
                <CustomMenuItem value={'1'}>
                  <Typography variant={'subtitle2'}>100</Typography>
                </CustomMenuItem>
                <CustomMenuItem value={'2'}>
                  <Typography variant={'subtitle2'}>300</Typography>
                </CustomMenuItem>
                <CustomMenuItem value={'3'}>
                  <Typography variant={'subtitle2'}>600</Typography>
                </CustomMenuItem>
                <CustomMenuItem value={'4'}>
                  <Typography variant={'subtitle2'}>800</Typography>
                </CustomMenuItem>
              </CustomSelect>
            </LabelWrapper>
          </BoxComponent>
        </Box>
      </Box>

      <Box>
        <Typography my={2} variant={'subtitle1'}>
          Compass
        </Typography>
        <Grid container sx={styles.gapLarge}>
          <Box>
            <LabelWrapper label={'Enable Compass'}>
              <CustomSwitch checked={inputState.north_arrow} name={'north_arrow'} onChange={onChangeSwitch} />
            </LabelWrapper>
          </Box>
          <BoxComponent disabled={!inputState.north_arrow}>
            <LabelWrapper label={'Position'}>
              <CustomSelect
                required
                disabled={!inputState.north_arrow}
                value={inputState.north_arrow_pos}
                name={'north_arrow_pos'}
                onChange={onInputChange}
                minWidth={'140px'}
                defaultValue={''}
                displayEmpty
              >
                <CustomMenuItem value={'1'}>
                  <Typography variant={'subtitle2'}>Top Right</Typography>
                </CustomMenuItem>
                <CustomMenuItem value={'2'}>
                  <Typography variant={'subtitle2'}>Bottom Right</Typography>
                </CustomMenuItem>
                <CustomMenuItem value={'3'}>
                  <Typography variant={'subtitle2'}>Bottom Left</Typography>
                </CustomMenuItem>
                <CustomMenuItem value={'4'}>
                  <Typography variant={'subtitle2'}>Top Left</Typography>
                </CustomMenuItem>
              </CustomSelect>
            </LabelWrapper>
          </BoxComponent>
          <BoxComponent disabled={!inputState.north_arrow}>
            <LabelWrapper label={'Color'}>
              <CustomSelect
                required
                disabled={!inputState.north_arrow}
                value={inputState.north_arrow_colour}
                name={'north_arrow_colour'}
                onChange={onInputChange}
                minWidth={'140px'}
                defaultValue={''}
                displayEmpty
              >
                <CustomMenuItem value={'1'}>
                  <Typography variant={'subtitle2'}>Black</Typography>
                </CustomMenuItem>
                <CustomMenuItem value={'2'}>
                  <Typography variant={'subtitle2'}>White</Typography>
                </CustomMenuItem>
              </CustomSelect>
            </LabelWrapper>
          </BoxComponent>
          <BoxComponent disabled={!inputState.north_arrow}>
            <LabelWrapper label={'Rotation (deg)'}>
              <CustomInput
                required
                disabled={!inputState.north_arrow}
                name={'north_arrow_rot'}
                min={0}
                step={'any'}
                type={'number'}
                max={360}
                value={inputState.north_arrow_rot}
                onChange={onInputChange}
                errorText={'Number must be 0 - 360'}
              />
            </LabelWrapper>
          </BoxComponent>
        </Grid>
      </Box>
    </Grid>
  );
};

export default memo(MarisPlotPrintSetupInputsGroup);
