import React, { FC, FormEvent, useEffect, useMemo, useState } from 'react';
import PageContainer from '../../components/structural/PageContainer';
import { Typography } from '@mui/material';
import BoxInputContainer from '../../components/custom/BoxInputContainer';
import MarisPlotDataSelectionInputsGroup from '../../components/groups/marisPlot/MarisPlotDataSelectionInputsGroup';
import MarisPlotPrintSetupInputsGroup from '../../components/groups/marisPlot/MarisPlotPrintSetupInputsGroup';
import MarisPlotLabelInputsGroup from '../../components/groups/marisPlot/MarisPlotLabelInputsGroup';
import SubmissionInputsGroup from '../../components/groups/btg/SubmissionInputsGroup';
import MarisPlotFigurePropertiesInputsGroup from '../../components/groups/marisPlot/MarisPlotFigurePropertiesInputsGroup';
import CustomPrompt from '../../components/custom/CustomPrompt';
import { BtgSubmission } from '../../models/inputTypes/BtgFields';
import { btgSubmissionInitialState } from '../../utils/initialStates/btgInputState';
import {
  MarisPlotDataSelection,
  MarisPlotFigureProperties,
  MarisPlotLabelPdf,
  MarisPlotPrintSetup,
} from '../../models/inputTypes/MarisPlotFields';
import {
  marisPlotDataSelectionInitialState,
  marisPlotFigurePropertiesInitialState,
  marisPlotLabelPdfInitialState,
  marisPlotPrintSetupInitialState,
} from '../../utils/initialStates/marisPlotState';
import { useGetProjectsQuery } from '../../redux/RTK/queries/projectQuery';
import { plotPreparation } from '../../utils/modelPreparation/plotPreparation';
import { FileType } from '../../models/inputTypes/FileType';
import { MarisSimulationPreparationResponse } from '../../utils/simulationFileToState/plotSimulationPreparation';
import { openWarningToast } from '../../redux/slices/appSlice';
import { useAddProjectFileMutation } from '../../redux/RTK/mutations/projectFileMutations';
import { useCreatePlotsMutation } from '../../redux/RTK/mutations/plotMutations';
import { useAppDispatch } from '../../redux/store';
import useSimulationPreparation from '../../utils/hooks/jsonParamHook';
import { ModelEnum } from '../../models/types/ModelEnum';

const MarisPlotPage: FC = () => {
  const [createJob] = useCreatePlotsMutation();
  const { data } = useGetProjectsQuery({});
  const [uploadFile] = useAddProjectFileMutation();
  const dispatch = useAppDispatch();

  const [dataSelectionState, setDataSelectionState] = useState<MarisPlotDataSelection>(
    marisPlotDataSelectionInitialState,
  );
  const [figurePropertiesState, setFigurePropertiesState] = useState<MarisPlotFigureProperties>(
    marisPlotFigurePropertiesInitialState,
  );
  const [printSetupState, setPrintSetupState] = useState<MarisPlotPrintSetup>(marisPlotPrintSetupInitialState);
  const [labelPdfState, setLabelPdfState] = useState<MarisPlotLabelPdf>(marisPlotLabelPdfInitialState);
  const [submissionState, setSubmissionState] = useState<BtgSubmission>(btgSubmissionInitialState);
  const [stateHasChanged, setStateHasChanged] = useState(false);
  const { preparedData, error, currentFile } = useSimulationPreparation(ModelEnum.PLOT);

  useEffect(() => {
    if (preparedData && !error && currentFile) {
      onSimulationFileChange(preparedData, currentFile);
    }
  }, [preparedData, error, currentFile]);
  useEffect(() => {
    if (!stateHasChanged) {
      const dataSelectionChanged = dataSelectionState != marisPlotDataSelectionInitialState;
      const figurePropertiesChanged = figurePropertiesState != marisPlotFigurePropertiesInitialState;
      const printSetupChanged = printSetupState != marisPlotPrintSetupInitialState;
      const labelPdfChanged = labelPdfState != marisPlotLabelPdfInitialState;
      const submissionChanged = submissionState != btgSubmissionInitialState;
      setStateHasChanged(
        dataSelectionChanged || figurePropertiesChanged || printSetupChanged || submissionChanged || labelPdfChanged,
      );
    }
  }, [dataSelectionState, figurePropertiesState, printSetupState, labelPdfState, submissionState]);

  const selectedProjectName = useMemo(() => {
    if (data?.length) {
      const found = data.find((item) => item.id.toString() === dataSelectionState.project.toString());
      return found?.name;
    }
  }, [dataSelectionState.project]);

  const onSubmitClick = (e: FormEvent<any>) => {
    e.preventDefault();
    const preparedValue = plotPreparation(
      dataSelectionState,
      figurePropertiesState,
      printSetupState,
      labelPdfState,
      submissionState,
    );
    if (preparedValue) createJob(preparedValue);
  };

  const onSimulationSave = () => {
    const preparedValue = plotPreparation(
      dataSelectionState,
      figurePropertiesState,
      printSetupState,
      labelPdfState,
      submissionState,
    );
    if (preparedValue && dataSelectionState.project && submissionState?.simulationFile) {
      uploadFile({
        id: dataSelectionState.project,
        name: `${submissionState?.simulationFile}.json`,
        file: preparedValue,
      });
    } else {
      dispatch(
        openWarningToast(
          !dataSelectionState.project ? 'You have to select project first!' : 'simulation file name required!',
        ),
      );
    }
  };

  const onSimulationFileChange = (value: MarisSimulationPreparationResponse, file?: FileType) => {
    setDataSelectionState({ ...value.dataSelection, plotSetupFile: file });
    setFigurePropertiesState(value.figureProperties);
    setPrintSetupState(value.printSetup);
    setLabelPdfState(value.pdfLabel);
    setSubmissionState({ ...value.submission, simulationFile: file?.fileName?.split('.json')[0] ?? '' });
  };

  return (
    <PageContainer title={'PLOT'} subTitle={'Maris PLT'} projectName={selectedProjectName}>
      <CustomPrompt
        when={stateHasChanged}
        message={(params) =>
          params.pathname == '/plots-animation/maris-plot' ? true : 'Changes that you made may not be saved.'
        }
      />

      <Typography variant={'h4'}>Data Selection</Typography>
      <form onSubmit={onSubmitClick}>
        <BoxInputContainer borderRadius={'4px'} mt={2}>
          <MarisPlotDataSelectionInputsGroup
            simulationChangeFile={onSimulationFileChange}
            inputState={dataSelectionState}
            setInputState={setDataSelectionState}
          />
        </BoxInputContainer>

        <Typography my={2} variant={'h4'}>
          Figure Properties
        </Typography>
        <BoxInputContainer borderRadius={'4px'}>
          <MarisPlotFigurePropertiesInputsGroup
            projectId={dataSelectionState.project}
            hasVectors={dataSelectionState.plotVector}
            inputState={figurePropertiesState}
            setInputState={setFigurePropertiesState}
          />
        </BoxInputContainer>

        <Typography my={2} variant={'h4'}>
          Print setup
        </Typography>
        <BoxInputContainer borderRadius={'4px'}>
          <MarisPlotPrintSetupInputsGroup inputState={printSetupState} setInputState={setPrintSetupState} />
        </BoxInputContainer>

        {printSetupState.label ? (
          <>
            <Typography my={2} variant={'h4'}>
              Label (.PDF only)
            </Typography>
            <BoxInputContainer borderRadius={'4px'}>
              <MarisPlotLabelInputsGroup
                projectId={dataSelectionState.project}
                inputState={labelPdfState}
                setInputState={setLabelPdfState}
              />
            </BoxInputContainer>
          </>
        ) : undefined}

        <Typography my={2} variant={'h4'}>
          Submission
        </Typography>
        <BoxInputContainer borderRadius={'4px'}>
          <SubmissionInputsGroup
            onSaveClick={onSimulationSave}
            inputState={submissionState}
            setInputState={setSubmissionState}
          />
        </BoxInputContainer>
      </form>
    </PageContainer>
  );
};
export default MarisPlotPage;
