import { BedLevelObject, EviNumerical, SedimentObject } from '../../models/inputTypes/EviFields';
import { InputFile, OutputFile } from '../../models/jobModels/ModelsFileTypes';

export const prepareBedLevelFiles = (list: BedLevelObject[], project?: string) => {
  return list.map((item) => ({
    project: item.file?.currentFile.project?.toString() ?? project,
    fileid: item.file?.currentFile?.name ?? '',
  }));
};

export const prepareSedimentFiles = (list: SedimentObject[], project?: string) => {
  const sedimentXArray: Partial<InputFile>[] = [];
  const sedimentYArray: Partial<InputFile>[] = [];

  list.forEach((item) => {
    sedimentXArray.push({
      project: item.qtx?.currentFile.project?.toString() ?? project,
      fileid: item.qtx?.currentFile?.name ?? '',
    });
    sedimentYArray.push({
      project: item.qty?.currentFile.project?.toString() ?? project,
      fileid: item.qty?.currentFile?.name ?? '',
    });
  });
  return [...sedimentXArray, ...sedimentYArray];
};

export const prepareWeights = (list: SedimentObject[] | BedLevelObject[]) => {
  return list.map((item) => +item.weight);
};

export const prepareEviOutputFiles = (numerical: EviNumerical, isBedLevel: boolean) => {
  const files: Partial<OutputFile>[] = [];
  if (isBedLevel) {
    files.push({
      name: numerical.outputFileName ? `${numerical.outputFileName}.csv` : '',
      project: numerical.project.toString(),
    });
    return files;
  }

  files.push({
    name: numerical.magnitude ? `${numerical.magnitude}.csv` : '',
    project: numerical.project.toString(),
  });
  files.push({
    name: numerical.direction ? `${numerical.direction}.csv` : '',
    project: numerical.project.toString(),
  });
  return files;
};
