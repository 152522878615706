import { HmsType } from '../../models/jobModels/hmsType';
import { PmsNumericDomain, PmsSubmission } from '../../models/inputTypes/PmsFields';

import {
  HmsBoundaryConditions,
  HmsEnergyDissipation,
  HmsModelFeatures,
  HmsOutput,
  HmsSimulationTime,
} from '../../models/inputTypes/HmsFields';
import { generatorPreparation } from '../functions/generatorPreparation';
import { hmsOutputAreaFilePreparation, hmsOutputLineFilePreparation } from './filePreparation/hmsFilePreparation';
import { dynamicInputFilePreparation } from '../functions/dynamicInputFilePreparation';
import { hmsPlotAreasInitialState, hmsPlotLinesInitialState } from '../initialStates/hmsInputState';

export const HmsPreparation = (
  numericalState: PmsNumericDomain,
  boundaryState: HmsBoundaryConditions,
  simulationState: HmsSimulationTime,
  modelState: HmsModelFeatures,
  energyState: HmsEnergyDissipation,
  outputState: HmsOutput,
  submissionState: PmsSubmission,
): HmsType => {
  const inputFiles = dynamicInputFilePreparation({
    I_Depths: numericalState.bathymetricData,
    I_Friction_Map: energyState.frictionFile,
    I_Eddy_Map: energyState.eddyFile,
    I_Sponge_Map: energyState.spongeFile,
    I_Cel_Map: modelState.celerityFile,
  });
  const outputArea = hmsOutputAreaFilePreparation(outputState.plotAreaValues, numericalState.project);
  const outputLine = hmsOutputLineFilePreparation(outputState.plotLineValues, numericalState.project);

  const preparedValues: HmsType = {
    simulation: 'hms',
    project: numericalState.project.toString(),
    cores: +submissionState.vCpus,
    name: submissionState.simulationFile,
    parameters: {
      input_files: inputFiles,
      output_files: [...outputArea, ...outputLine],
      im: +numericalState.cellX,
      jm: +numericalState.cellY,
      dx: +numericalState.cellSizeX,
      dy: +numericalState.cellSizeY,
      m: +numericalState.waterLevel,

      sea_state: +boundaryState.seaState,
      h: +boundaryState.hm,
      t: +boundaryState.ts,
      mwd: +boundaryState.direction,
      period: +boundaryState.numberOfPeriods,
      dirs: +boundaryState.numberOfDirections, // numb of directions missing from design inputs
      spectrum: +boundaryState.spectrum,
      depth_wavemaker: +boundaryState.depthWavemaker,
      min_period: +boundaryState.minPeriod,
      max_period: +boundaryState.maxPeriod,
      dir_deviation: +boundaryState.mwdDeviation, //deviation from MWD deg missing from design inputs
      gamma: +boundaryState.peakFactorY,
      dir_spreading: +boundaryState.directionalSpreading, //directional spreading missing from design inputs
      number_wave_generators: boundaryState.generatorNumber,
      wave_generators_line_coordinates: generatorPreparation(boundaryState.wave_generators_line_coordinates.slice(), 4),

      simtime: +simulationState.simulationTime,
      sleeptime: +simulationState.timeStep, //????
      steadystate: simulationState.convergence ? 1 : 0,
      convergence: +simulationState.criterion, //example simulationState.convergence === '1' ? simulationState.criterion : '0.02'  ??????

      nonlinearity: +modelState.dispersion,
      nonlinear_equation: +modelState.relation,
      nonlinear_starting_time: +modelState.startingTime, //modelState.dispersion==='0' || modelState.dispersion==='2' ?'400':modelState.startingTime ???

      breaking: energyState.waveBreaking ? 1 : 0,
      brk_coef_a: +energyState.a,
      brk_coef_g1: +energyState.y1,
      brk_coef_g2: +energyState.y2, //????//??where does y2 go ?? //

      friction: energyState.bottomFriction ? (energyState.bottomFrictionType === 'varying' ? 2 : 1) : 0,
      friction_coefficient: +energyState.coefficient, //!energyState.bottomFriction || energyState.bottomFrictionType === 'varying'? 0.02 : energyState.coefficient

      sponge_map: energyState.spongeLayer ? (energyState.spongeLayerType === 'varying' ? 2 : 1) : 0,
      sp_layer_west: +energyState.west, //!energyState.spongeLayer || energyState.spongeLayerType === 'varying' ? '50' : energyState.west,
      sp_layer_north: +energyState.north, //!energyState.spongeLayer || energyState.spongeLayerType === 'varying' ? '50' : energyState.north,
      sp_layer_east: +energyState.east, //!energyState.spongeLayer || energyState.spongeLayerType === 'varying' ? '50' : energyState.east,
      sp_layer_south: +energyState.south, //!energyState.spongeLayer || energyState.spongeLayerType === 'varying' ? '50' : energyState.south,

      eddy: energyState.eddyLayer ? (energyState.eddyType === 'varying' ? 2 : 1) : 0,
      eddy_coefficient: +energyState.eddyCoefficient, //!energyState.eddyLayer || energyState.eddyType === 'varying' ? '0.1' : energyState.south,

      plot_areas_length: outputState.plotAreas,
      plot_lines_length: outputState.plotLines,

      plot_areas: outputState.plotAreaValues.map((area) => ({
        ne_x: +area.ne_x,
        ne_y: +area.ne_y,
        sw_x: +area.sw_x,
        sw_y: +area.sw_y,
        h: +area.h,
        z: +area.z,
        rad: +area.rad,
        init_depths: +area.init_depths,
        start_step: +area.start_step ?? hmsPlotAreasInitialState.start_step,
        end_step: +area.end_step ?? hmsPlotAreasInitialState.end_step,
        interval: +area.interval ?? hmsPlotAreasInitialState.interval,
      })),

      plot_lines: outputState.plotLineValues.map((line) => ({
        fp_x: +line.fp_x,
        fp_y: +line.fp_y,
        lp_x: +line.lp_x,
        lp_y: +line.lp_y,
        h: +line.h,
        z: +line.z,
        start_step: +line.start_step ?? hmsPlotLinesInitialState.start_step,
        end_step: +line.end_step ?? hmsPlotLinesInitialState.end_step,
        interval: +line.interval ?? hmsPlotLinesInitialState.interval,
      })),
    },
  };
  return preparedValues;
};
