import React, { FC, memo, useMemo } from 'react';
import { Box, FormControlLabel, Grid, RadioGroup, SelectChangeEvent, Typography } from '@mui/material';
import CustomInput from '../../custom/CustomInput';
import LabelWrapper from '../../common/LabelWrapper';
import CustomMenuItem from '../../custom/CustomMenuItem';
import CustomSelect from '../../custom/CustomSelect';
import CustomSwitch from '../../custom/CustomSwitch';
import CustomSlider from '../../custom/CustomSlider';
import CustomInputWithLabel from '../../common/CustomInputWithLabel';
import CustomRadio from '../../custom/CustomRadio';
import BoxComponent from '../../custom/BoxComponent';
import { FileType } from '../../../models/inputTypes/FileType';
import { MarisPlotFigureProperties } from '../../../models/inputTypes/MarisPlotFields';
import ButtonFileDialog from '../../common/ButtonFileDialog';
import { colorOptions } from '../../../dummy/colorOptions';
import { marisPlotFigurePropertiesInitialState } from '../../../utils/initialStates/marisPlotState';

const styles = {
  flexStartContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
  },
  spaceBetweenBox: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  childMargin: {
    '& >:nth-child(n)': {
      my: 1,
      mr: 4,
    },
  },
  childMarginY: {
    '& >:nth-child(n)': {
      my: 1,
    },
  },
  coordinates: {
    '& >:nth-child(2)': {
      ml: 1,
    },
  },
} as const;

type InputsProps = {
  projectId: string;
  hasVectors?: boolean;
  inputState: MarisPlotFigureProperties;
  setInputState: (value: MarisPlotFigureProperties) => void;
};

const MarisPlotFigurePropertiesInputsGroup: FC<InputsProps> = ({
  projectId,
  hasVectors = false,
  inputState,
  setInputState,
}) => {
  const onInputChange = (
    event: SelectChangeEvent<unknown> | React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setInputState({ ...inputState, [event.target.name]: event.target.value });
  };

  const onNumberChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setInputState({ ...inputState, [event.target.name]: event.target.value });
  };

  const onSliderChange = (event: Event, value: number | number[], _activeThumb: number) => {
    setInputState({ ...inputState, transparency: (value as number) / 100 });
  };

  const onFileChange = (file: FileType | undefined, fieldName: string) => {
    setInputState({ ...inputState, [fieldName]: file });
  };

  const onChangeSwitch = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === 'minMaxValues') {
      setInputState({
        ...inputState,
        [e.target.name]: e.target.checked,
        minValue: marisPlotFigurePropertiesInitialState.minValue,
        maxValue: marisPlotFigurePropertiesInitialState.maxValue,
      });
      return;
    }
    if (e.target.name === 'isoLines') {
      setInputState({
        ...inputState,
        [e.target.name]: e.target.checked,
        transparency: marisPlotFigurePropertiesInitialState.transparency,
        contoursNumberOfLevels: marisPlotFigurePropertiesInitialState.contoursNumberOfLevels,
        contoursFontSize: marisPlotFigurePropertiesInitialState.contoursFontSize,
      });
      return;
    }
    if (e.target.name === 'plotLand') {
      setInputState({
        ...inputState,
        [e.target.name]: e.target.checked,
        plotLandFile: marisPlotFigurePropertiesInitialState.plotLandFile,
        landColor: marisPlotFigurePropertiesInitialState.landColor,
      });
      return;
    }
    if (e.target.name === 'plotBasemap') {
      setInputState({
        ...inputState,
        [e.target.name]: e.target.checked,
        basemapCodeValue: marisPlotFigurePropertiesInitialState.basemapCodeValue,
        inpEPSG: marisPlotFigurePropertiesInitialState.inpEPSG,
        originX: marisPlotFigurePropertiesInitialState.originX,
        originY: marisPlotFigurePropertiesInitialState.originY,
        mapProvider: marisPlotFigurePropertiesInitialState.mapProvider,
      });
      return;
    }
    setInputState({ ...inputState, [e.target.name]: e.target.checked });
  };

  const onChangeRadio = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputState({ ...inputState, [e.target.name]: e.target.value });
  };

  const colorItems = useMemo(
    () =>
      colorOptions.map((item) => (
        <CustomMenuItem key={item.value} value={item.value}>
          <Typography variant={'subtitle2'}>{item.title}</Typography>
        </CustomMenuItem>
      )),
    [],
  );
  return (
    <Box>
      <Typography variant={'subtitle1'}>Palette</Typography>
      <Box sx={{ ...styles.flexStartContainer }}>
        <Box sx={{ width: '500px', ...styles.childMargin, ...styles.flexStartContainer }}>
          <Box>
            <LabelWrapper label={'Title'}>
              <CustomInput
                required
                placeholder={'-'}
                errorText={'Please enter text'}
                type={'text'}
                value={inputState.title}
                name={'title'}
                onChange={onInputChange}
              />
            </LabelWrapper>
          </Box>
          <Box>
            <LabelWrapper label={'Colors'}>
              <CustomSelect
                required
                value={inputState.paletteColors}
                name={'paletteColors'}
                onChange={onInputChange}
                minWidth={'140px'}
                defaultValue={''}
                displayEmpty
              >
                {colorItems}
              </CustomSelect>
            </LabelWrapper>
          </Box>
          <Box maxWidth={'100px'}>
            <LabelWrapper label={'Enable minmax values'}>
              <Grid container justifyContent={'center'}>
                <CustomSwitch checked={inputState.minMaxValues} name={'minMaxValues'} onChange={onChangeSwitch} />
              </Grid>
            </LabelWrapper>
          </Box>
        </Box>
        <Box sx={{ ...styles.childMargin, ...styles.flexStartContainer }}>
          <BoxComponent disabled={!inputState.minMaxValues}>
            <LabelWrapper label={'Min value'}>
              <CustomInput
                required
                disabled={!inputState.minMaxValues}
                errorText={'Enter number'}
                type={'number'}
                value={inputState.minValue}
                name={'minValue'}
                onChange={onNumberChange}
              />
            </LabelWrapper>
          </BoxComponent>
          <BoxComponent disabled={!inputState.minMaxValues}>
            <LabelWrapper label={'Max value'}>
              <CustomInput
                required
                disabled={!inputState.minMaxValues}
                errorText={'Enter number'}
                type={'number'}
                value={inputState.maxValue}
                name={'maxValue'}
                onChange={onNumberChange}
              />
            </LabelWrapper>
          </BoxComponent>

          <Box>
            <LabelWrapper label={'Number of levels'}>
              <CustomInput
                min={0}
                step={1}
                errorText={'Enter positive integer'}
                required
                type={'number'}
                value={inputState.paletteNumberOfLevels}
                name={'paletteNumberOfLevels'}
                onChange={onNumberChange}
              />
            </LabelWrapper>
          </Box>
          <Box>
            <LabelWrapper label={'Font size'}>
              <CustomInput
                required
                min={0}
                step={1}
                errorText={'Enter positive integer'}
                type={'number'}
                value={inputState.paletteFontSize}
                name={'paletteFontSize'}
                onChange={onInputChange}
              />
            </LabelWrapper>
          </Box>
        </Box>
      </Box>

      <Typography my={2} variant={'subtitle1'}>
        Contours
      </Typography>
      <Box sx={{ ...styles.flexStartContainer }}>
        <Box sx={{ width: '500px', ...styles.spaceBetweenBox }}>
          <Box>
            <LabelWrapper label={'Isolines'}>
              <Grid container justifyContent={'center'}>
                <CustomSwitch checked={inputState.isoLines} name={'isoLines'} onChange={onChangeSwitch} />
              </Grid>
            </LabelWrapper>
          </Box>
          <BoxComponent sx={{ width: 120 }} disabled={!inputState.isoLines}>
            <LabelWrapper label={'Transparency'}>
              <CustomSlider
                min={0}
                max={100}
                step={5}
                onChange={onSliderChange}
                value={inputState.transparency * 100}
              />
            </LabelWrapper>
          </BoxComponent>
          <Box width={'100px'} />
        </Box>
        <BoxComponent disabled={!inputState.isoLines} sx={{ ...styles.childMargin, ...styles.flexStartContainer }}>
          <Box>
            <LabelWrapper label={'Number of levels'}>
              <CustomInput
                required
                min={0}
                step={1}
                errorText={'Enter positive integer'}
                disabled={!inputState.isoLines}
                type={'number'}
                value={inputState.contoursNumberOfLevels}
                name={'contoursNumberOfLevels'}
                onChange={onNumberChange}
              />
            </LabelWrapper>
          </Box>
          <Box>
            <LabelWrapper label={'Font size'}>
              <CustomInput
                required
                min={0}
                step={1}
                errorText={'Enter positive integer'}
                disabled={!inputState.isoLines}
                type={'number'}
                value={inputState.contoursFontSize}
                name={'contoursFontSize'}
                onChange={onNumberChange}
              />
            </LabelWrapper>
          </Box>
        </BoxComponent>
      </Box>

      <Typography my={2} variant={'subtitle1'}>
        Vectors
      </Typography>
      <BoxComponent disabled={!hasVectors} sx={{ ...styles.flexStartContainer }}>
        <Box sx={{ width: '500px', ...styles.childMarginY, ...styles.spaceBetweenBox }}>
          <Box>
            <LabelWrapper label={'Colors'}>
              <CustomSelect
                required
                disabled={!hasVectors}
                value={inputState.vectorColors}
                name={'vectorColors'}
                onChange={onInputChange}
                minWidth={'140px'}
                defaultValue={''}
                displayEmpty
              >
                <CustomMenuItem key={'color-1'} value={'1'}>
                  <Typography variant={'subtitle2'}>black</Typography>
                </CustomMenuItem>
                <CustomMenuItem key={'color-2'} value={'2'}>
                  <Typography variant={'subtitle2'}>white</Typography>
                </CustomMenuItem>
              </CustomSelect>
            </LabelWrapper>
          </Box>
          <Box>
            <LabelWrapper label={'Plot reference vector'}>
              <Grid container justifyContent={'center'}>
                <CustomSwitch checked={inputState.plotVector} name={'plotVector'} onChange={onChangeSwitch} />
              </Grid>
            </LabelWrapper>
          </Box>
          <Box width={'150px'} />
        </Box>
        <Box sx={{ ...styles.childMargin, ...styles.flexStartContainer }}>
          <Box>
            <LabelWrapper label={'Interval between vectors'}>
              <Box sx={{ ...styles.flexStartContainer, ...styles.coordinates }}>
                <CustomInputWithLabel
                  min={1}
                  step={1}
                  required
                  disabled={!hasVectors}
                  errorText={'Enter positive integer'}
                  type="number"
                  value={inputState.vectorIntervalX}
                  name={'vectorIntervalX'}
                  onChange={onNumberChange}
                  label={'X'}
                />
                <CustomInputWithLabel
                  min={1}
                  step={1}
                  required
                  disabled={!hasVectors}
                  errorText={'Enter positive integer'}
                  type="number"
                  value={inputState.vectorIntervalY}
                  name={'vectorIntervalY'}
                  onChange={onNumberChange}
                  label={'Y'}
                />
              </Box>
            </LabelWrapper>
          </Box>
          <Box>
            <LabelWrapper label={'Vector scaling'}>
              <CustomInput
                required
                disabled={!hasVectors}
                min={0}
                step={'any'}
                errorText={'Enter positive number'}
                type="number"
                value={inputState.vectorScaling}
                name={'vectorScaling'}
                onChange={onNumberChange}
              />
            </LabelWrapper>
          </Box>
        </Box>
      </BoxComponent>

      <Typography my={2} variant={'subtitle1'}>
        Land options
      </Typography>
      <Box sx={{ maxWidth: '830px', ...styles.childMarginY, ...styles.spaceBetweenBox }}>
        <Box>
          <LabelWrapper label={'Plot land'}>
            <Grid container justifyContent={'center'}>
              <CustomSwitch checked={inputState.plotLand} name={'plotLand'} onChange={onChangeSwitch} />
            </Grid>
          </LabelWrapper>
        </Box>
        <Box>
          <LabelWrapper label={'Insert plot land file'}>
            <ButtonFileDialog
              typePage={'PLOT'}
              dataFilesOnly
              projectId={projectId}
              disabled={!projectId || !inputState.plotLand}
              required
              file={inputState.plotLandFile}
              setFile={(file) => onFileChange(file, 'plotLandFile')}
            />
          </LabelWrapper>
        </Box>
        <Box>
          <LabelWrapper label={'Land color'}>
            <CustomSelect
              disabled={!inputState.plotLand}
              required
              value={inputState.landColor}
              name={'landColor'}
              onChange={onInputChange}
              minWidth={'140px'}
              defaultValue={''}
              displayEmpty
            >
              <CustomMenuItem key={'color-1'} value={'1'}>
                <Typography variant={'subtitle2'}>Light Grey</Typography>
              </CustomMenuItem>
              <CustomMenuItem key={'color-2'} value={'2'}>
                <Typography variant={'subtitle2'}>White</Typography>
              </CustomMenuItem>
              <CustomMenuItem key={'color-3'} value={'3'}>
                <Typography variant={'subtitle2'}>Yellow</Typography>
              </CustomMenuItem>
            </CustomSelect>
          </LabelWrapper>
        </Box>
      </Box>

      <Typography my={2} variant={'subtitle1'}>
        Axes Options
      </Typography>
      <Box sx={{ ...styles.flexStartContainer }}>
        <Box sx={{ width: '360px', ...styles.childMarginY, ...styles.flexStartContainer }}>
          <Box mr={18}>
            <RadioGroup name="axesType" value={inputState.axesType} onChange={onChangeRadio}>
              <FormControlLabel
                labelPlacement={'end'}
                value="meter"
                control={<CustomRadio />}
                label={<Typography variant={'subtitle2'}>Meter</Typography>}
              />
              <FormControlLabel
                labelPlacement={'end'}
                value="grid"
                control={<CustomRadio />}
                label={<Typography variant={'subtitle2'}>Grid</Typography>}
              />
            </RadioGroup>
          </Box>
          <Box>
            <LabelWrapper label={'Plot Major Grid Lines'}>
              <Grid container justifyContent={'center'}>
                <CustomSwitch checked={inputState.majorGridLines} name={'majorGridLines'} onChange={onChangeSwitch} />
              </Grid>
            </LabelWrapper>
          </Box>
        </Box>
        <Box sx={{ ...styles.childMargin, ...styles.flexStartContainer }}>
          <Box>
            <LabelWrapper label={'X-Axis title'}>
              <CustomInput
                errorText={'Please enter text'}
                required
                value={inputState.xAxisTitle}
                name={'xAxisTitle'}
                onChange={onInputChange}
              />
            </LabelWrapper>
          </Box>
          <Box>
            <LabelWrapper label={'Y-Axis title'}>
              <CustomInput
                required
                errorText={'Please enter text'}
                value={inputState.yAxisTitle}
                name={'yAxisTitle'}
                onChange={onInputChange}
              />
            </LabelWrapper>
          </Box>
          <Box>
            <LabelWrapper label={'Font size'}>
              <CustomInput
                required
                min={0}
                errorText={'Enter positive number'}
                type={'number'}
                value={inputState.axeFontSize}
                name={'axeFontSize'}
                onChange={onNumberChange}
              />
            </LabelWrapper>
          </Box>
        </Box>
      </Box>
      <Typography my={2} variant={'subtitle1'}>
        Basemap
      </Typography>
      <Box sx={{ ...styles.childMargin, ...styles.flexStartContainer }}>
        <Box>
          <LabelWrapper label={'Plot Basemap'}>
            <Grid container justifyContent={'center'}>
              <CustomSwitch checked={inputState.plotBasemap} name={'plotBasemap'} onChange={onChangeSwitch} />
            </Grid>
          </LabelWrapper>
        </Box>
        <Box>
          <LabelWrapper label={'Show Basemap on land value'}>
            <CustomInput
              required
              min={0}
              step={1}
              disabled={!inputState.plotBasemap}
              errorText={'Enter positive integer'}
              type={'number'}
              value={inputState.basemapCodeValue}
              name={'basemapCodeValue'}
              onChange={onNumberChange}
            />
          </LabelWrapper>
        </Box>
        <Box>
          <LabelWrapper label={'CRS EPSG code'}>
            <CustomInput
              required
              placeholder={'-'}
              min={1024}
              max={32767}
              step={1}
              maxWidth={'85px'}
              disabled={!inputState.plotBasemap}
              errorText={'Enter number 1024-32767'}
              type={'number'}
              value={inputState.inpEPSG}
              name={'inpEPSG'}
              onChange={onNumberChange}
            />
          </LabelWrapper>
        </Box>
        <Box>
          <Typography mb={1} variant={'subtitle2'}>
            Origin Coordinates (m)
          </Typography>
          <Box sx={{ ...styles.flexStartContainer, ...styles.coordinates }}>
            <CustomInputWithLabel
              required
              placeholder={'-'}
              maxWidth={'100px'}
              disabled={!inputState.plotBasemap}
              errorText={'Enter number'}
              type={'number'}
              onChange={onNumberChange}
              value={inputState.originX}
              name={'originX'}
              label={'X'}
            />
            <CustomInputWithLabel
              required
              placeholder={'-'}
              maxWidth={'100px'}
              disabled={!inputState.plotBasemap}
              errorText={'Enter number'}
              type={'number'}
              onChange={onNumberChange}
              value={inputState.originY}
              name={'originY'}
              label={'Y'}
            />
          </Box>
        </Box>
        <Box>
          <LabelWrapper label={'Map Layer'}>
            <CustomSelect
              required
              placeholder={'-'}
              disabled={!inputState.plotBasemap}
              value={inputState.mapProvider}
              name={'mapProvider'}
              onChange={onInputChange}
              minWidth={'140px'}
              defaultValue={''}
              displayEmpty
            >
              <CustomMenuItem key={'map-1'} value={1}>
                <Typography variant={'subtitle2'}>World Satellite (ESRI)</Typography>
              </CustomMenuItem>
              <CustomMenuItem key={'map-2'} value={2}>
                <Typography variant={'subtitle2'}>World Street Map (ESRI)</Typography>
              </CustomMenuItem>
              <CustomMenuItem key={'map-3'} value={3}>
                <Typography variant={'subtitle2'}>World Topo Map (ESRI)</Typography>
              </CustomMenuItem>
            </CustomSelect>
          </LabelWrapper>
        </Box>
      </Box>
    </Box>
  );
};

export default memo(MarisPlotFigurePropertiesInputsGroup);
