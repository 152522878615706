import React, { FC } from 'react';
import { Checkbox, CheckboxProps } from '@mui/material';
import { ReactComponent as CheckedIcon } from '../../assets/images/checkboxChecked.svg';
import { ReactComponent as UncheckedIcon } from '../../assets/images/checkbox.svg';

const CustomCheckbox: FC<CheckboxProps> = ({ ...props }) => {
  return <Checkbox disableRipple color="default" checkedIcon={<CheckedIcon />} icon={<UncheckedIcon />} {...props} />;
};

export default CustomCheckbox;
