import React, { FC, useEffect, useState } from 'react';
import { Box, CircularProgress, Grid, styled, Typography } from '@mui/material';
import loginIcon from '../../assets/images/login.png';
import { useHistory, Redirect } from 'react-router-dom';
import { ReactComponent as ScientiaLogo } from '../../assets/images/scientiaMaris.svg';
import LoginInput from '../../components/common/LoginInput';
import LabelWrapper from '../../components/common/LabelWrapper';
import CustomButton from '../../components/custom/CustomButton';
import { useResetPasswordMutation } from '../../redux/RTK/mutations/resetPasswordMutations';

const BoxBackground = styled(Box)(() => ({
  overflow: 'auto',
  background: `url(${loginIcon})`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  height: '100vh',
  width: '100vw',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const Card = styled(Box)(({ theme }) => ({
  background: '#FAFAFA',
  width: '280px',
  height: '345px',
  borderRadius: 4,
  boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.24)',
  padding: '38px 32px',
  '& >:first-child': {
    display: 'flex',
    margin: theme.spacing(0, 'auto', 4.5, 'auto'),
  },

  form: {
    '& div': {
      marginBottom: theme.spacing(2),
    },
  },
}));

const ForgotPassword: FC = () => {
  const [sent_mail, { isSuccess, isLoading }] = useResetPasswordMutation();
  const [email, setEmail] = useState('');

  useEffect(() => {
    document.title = 'Reset your password - Scientia Maris';
  }, []);

  const onFormSubmit = (e: any) => {
    e.preventDefault();
    if (email) {
      sent_mail(email);
    }
  };

  const onInputChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const onTypeChange = (e: any) => {
    if (e.key === 'Enter') {
      const form = e.target.form;
      const email = form.elements[0];
      if (!email.value) {
        email.focus();
        return;
      }
    }
  };

  return (
    <BoxBackground>
      <Card>
        <ScientiaLogo />
        <Box>
          <Typography variant={'h5'} align={'center'}>
            Forgot your password?
          </Typography>
          <Typography fontSize={13} align={'center'} my={'20px'}>
            Enter your e-mail address and we will sent you instructions on how to reset your password.
          </Typography>
          <form onKeyUp={onTypeChange} onSubmit={onFormSubmit}>
            <LabelWrapper label={'Email'}>
              <LoginInput placeholder={'Enter email'} value={email} onChange={onInputChange} fullWidth />
            </LabelWrapper>
            <CustomButton
              type={'submit'}
              variant={'contained'}
              disabled={!email || isLoading}
              fullWidth
              color={'primary'}
            >
              {isLoading ? (
                <CircularProgress size={18} sx={{ color: 'white' }} />
              ) : (
                <Typography variant={'subtitle2'}>Send email</Typography>
              )}
            </CustomButton>
          </form>
          {isSuccess === true && <Redirect to={'/password/sent-email'} />}
        </Box>
      </Card>
    </BoxBackground>
  );
};

export default ForgotPassword;
