import { get } from '../../axios';
import { openErrorToast, setGlobalLoading } from '../../redux/slices/appSlice';
import store from '../../redux/store';

export const downloadAxiosFile = async () => {
  const storeDispatch = store.dispatch;
  storeDispatch(setGlobalLoading(true));
  try {
    get(`/api/profile/userguides`, {
      responseType: 'blob',
    }).then((res) => {
      const hiddenElement = document.createElement('a');
      hiddenElement.href = URL.createObjectURL(res.data);
      hiddenElement.target = '_blank';
      hiddenElement.download = 'ScientiaMaris_User_Guides.zip';
      hiddenElement.click();
      hiddenElement.remove();
      storeDispatch(setGlobalLoading(false));
    });
  } catch (e) {
    storeDispatch(openErrorToast('Error downloading File!'));
  }
};
