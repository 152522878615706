import { createSlice } from '@reduxjs/toolkit';
import { Authentication } from '../types/Authentication';
import Cookies from 'js-cookie';

const prepareInitialState = () => {
  const accessToken = Cookies.get('maris-accessToken');
  const refreshToken = Cookies.get('maris-refreshToken');
  return {
    data: {
      accessToken: accessToken ? accessToken : '',
      refreshToken: refreshToken ? refreshToken : '',
      user: undefined,
    },
    meta: {
      loading: false,
      error: '',
      success: '',
    },
  };
};

const initialState: Authentication = prepareInitialState();

const authenticationSlice = createSlice({
  name: 'authentication',
  initialState: initialState,
  reducers: {
    setUser: (state, action) => {
      state.data.user = action.payload;
    },
    setAccessToken: (state, action) => {
      state.data.accessToken = action.payload;
      Cookies.set('maris-accessToken', action.payload);
    },
    setRefreshToken: (state, action) => {
      state.data.refreshToken = action.payload;
      Cookies.set('maris-refreshToken', action.payload);
    },
    clearUser: (state) => {
      state.data.accessToken = '';
      state.data.user = undefined;
      state.data.refreshToken = '';
      Cookies.remove('maris-accessToken');
      Cookies.remove('maris-refreshToken');
    },
  },
});

export const { setAccessToken, setUser, setRefreshToken, clearUser } = authenticationSlice.actions;
export default authenticationSlice.reducer;
