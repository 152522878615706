import { BtgBathymetric, BtgInterpolation, BtgNumericDomain, BtgSubmission } from '../../models/inputTypes/BtgFields';

export const btgBathymetricInitialState: BtgBathymetric = {
  project: '',
  simulationSetup: undefined,
  coordX: 0,
  coordY: 0,
  closedPolygons: 0,
  polygonsData: undefined,
  points: 1,
  dataPoints: undefined,
};

export const btgNumericDomainInitialState: BtgNumericDomain = {
  nodeX: 1,
  nodeY: 1,
  sizeDX: 2.5,
  sizeDY: 2.5,
  landValue: 0.0,
};

export const btgInterpolationInitialState: BtgInterpolation = {
  interpolation: '1',
  searchRadius: 100,
};

export const btgSubmissionInitialState: BtgSubmission = {
  outputFileName: '',
  simulationFile: '',
};
