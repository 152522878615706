import {
  PmsBoundaryConditions,
  PmsDissipation,
  PmsModelFeatures,
  PmsNumericDomain,
  PmsOutput,
  PmsSubmission,
} from '../../models/inputTypes/PmsFields';
import { PmsType } from '../../models/jobModels/pmsType';
import { pmsOutputAreaFilePreparation, pmsOutputLineFilePreparation } from './filePreparation/pmsFilePreparation';
import { dynamicInputFilePreparation } from '../functions/dynamicInputFilePreparation';

export const pmsPreparation = (
  numericalState: PmsNumericDomain,
  boundaryState: PmsBoundaryConditions,
  modelState: PmsModelFeatures,
  dissipationState: PmsDissipation,
  outputState: PmsOutput,
  submissionState: PmsSubmission,
): PmsType => {
  const inputFiles = dynamicInputFilePreparation({
    I_Depths: numericalState.bathymetricData,
    I_Friction_Map: dissipationState.frictionFile,
  });
  const outputArea = pmsOutputAreaFilePreparation(outputState.plotAreaValues, numericalState.project);
  const outputLine = pmsOutputLineFilePreparation(outputState.plotLineValues, numericalState.project);

  const preparedValues: PmsType = {
    simulation: 'pms',
    project: numericalState.project.toString(),
    cores: +submissionState.vCpus,
    name: submissionState.simulationFile,
    parameters: {
      input_files: inputFiles,
      output_files: [...outputArea, ...outputLine],
      im: +numericalState.cellX,
      jm: +numericalState.cellY,
      dx: +numericalState.cellSizeX,
      dy: +numericalState.cellSizeY,
      m: +numericalState.waterLevel,
      wgbl: +boundaryState.boundaryType,
      lbt1: +boundaryState.coord1,
      lbt2: +boundaryState.coord2,
      ss: +boundaryState.seaState,
      Ho: +boundaryState.hm,
      per: +boundaryState.ts,
      thita: +boundaryState.direction,
      period: +boundaryState.numberOfPeriods, //boundaryState.seaState==='0'?pmsBoundaryConditionsInitialState.numberOfPeriods:boundaryState.numberOfPeriods
      spectrum: +boundaryState.spectrum, //boundaryState.seaState==='0'?pmsBoundaryConditionsInitialState.spectrum:boundaryState.spectrum
      min_period: +boundaryState.minPeriod, //boundaryState.seaState==='0'?pmsBoundaryConditionsInitialState.minPeriod:boundaryState.minPeriod

      max_period: +boundaryState.maxPeriod, //boundaryState.seaState==='0'?pmsBoundaryConditionsInitialState.maxPeriod:boundaryState.maxPeriod
      gamma: +boundaryState.peakFactorY, //boundaryState.seaState==='0'?pmsBoundaryConditionsInitialState.peakFactorY:boundaryState.peakFactorY
      depth_wavemaker: +boundaryState.depthWavemaker, //boundaryState.seaState==='0'?pmsBoundaryConditionsInitialState.depthWavemaker:boundaryState.depthWavemaker

      lnr_f: modelState.nonlinearDispersion ? 2 : 1,
      nlnr_iter: +modelState.iterations, //modelState.nonlinearDispersion==='1'?pmsModelFeaturesInitialState.iterations:modelState.iterations
      nlnr_eq: +modelState.relation, //modelState.nonlinearDispersion==='1'?pmsModelFeaturesInitialState.relation:modelState.relation
      coef: +modelState.approximitation,
      coef_mm: +modelState.degrees, //modelState.approximitation!=='3'?pmsModelFeaturesInitialState.degrees:modelState.degrees

      brk_f: dissipationState.waveBreaking ? 1 : 0,
      brk_coef_a: +dissipationState.a, //dissipationState.waveBreaking!=='0'?pmsDissipationInitialState.a:dissipationState.a
      brk_coef_g1: +dissipationState.y1, //dissipationState.waveBreaking!=='0'?pmsDissipationInitialState.y1:dissipationState.y1
      brk_coef_g2: +dissipationState.y2, //dissipationState.waveBreaking!=='0'?pmsDissipationInitialState.y2:dissipationState.y2
      frctn: dissipationState.bottomFriction ? (dissipationState.waveBreakingType === 'varying' ? 2 : 1) : 0,
      frctn_coef: +dissipationState.nikuradse, //!dissipationState.bottomFriction || dissipationState.waveBreakingType === 'varying' ? pmsDissipationInitialState.nikuradse:dissipationState.nikuradse
      plot_areas_length: +outputState.plotAreas,
      plot_lines_length: +outputState.plotLines,

      plot_areas: outputState.plotAreaValues.map((area) => ({
        sw_x: +area.sw_x,
        sw_y: +area.sw_y,
        ne_x: +area.ne_x,
        ne_y: +area.ne_y,
        h: +area.h,
        c: +area.c,
        z: +area.z,
        dir: +area.dir,
        rad: +area.rad,
        init_depths: +area.init_depths,
      })),

      plot_lines: outputState.plotLineValues.map((line) => ({
        fp_x: +line.fp_x,
        fp_y: +line.fp_y,
        lp_x: +line.lp_x,
        lp_y: +line.lp_y,
        h: +line.h,
        c: +line.c,
        z: +line.z,
        dir: +line.dir,
      })),
    },
  };
  return preparedValues;
};
