import { customApi } from '../Api';
import { Projects } from '../../../models/types/Projects';
import { openErrorToast, openSuccessToast, setGlobalLoading } from '../../slices/appSlice';

const extendedApi = customApi.injectEndpoints({
  endpoints: (builder) => ({
    project: builder.mutation<unknown, Pick<Projects, 'name'>>({
      query: ({ name }) => ({
        url: '/api/projects/',
        method: 'POST',
        body: { name },
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(openSuccessToast('Project created successfully!'));
        } catch (err: any) {
          const errorMsg =
            err?.error?.data?.name || err?.error?.data?.non_field_errors || 'Error creating project. Please try again!';
          dispatch(openErrorToast(errorMsg));
        }
      },
      invalidatesTags: ['projects'],
    }),
    renameProject: builder.mutation<unknown, { id: number; name: string }>({
      query: ({ id, name }) => ({
        url: `/api/projects/${id}`,
        method: 'PUT',
        body: { name },
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          data != 'Do nothing' && dispatch(openSuccessToast('Project renamed successfully!'));
        } catch (err: any) {
          const errorMsg = err?.error?.data?.name || 'Error renaming project. Please try again!';
          dispatch(openErrorToast(errorMsg));
        }
      },
      invalidatesTags: ['projects'],
    }),
    deleteProject: builder.mutation<unknown, number>({
      query: (id) => ({
        url: `/api/projects/${id}`,
        method: 'DELETE',
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          dispatch(setGlobalLoading(true));
          await queryFulfilled;
          dispatch(setGlobalLoading(false));
          dispatch(openSuccessToast('Project deleted successfully!'));
        } catch (err: any) {
          dispatch(setGlobalLoading(false));
          const errorMsg = err?.error?.data?.detail || 'Error deleting project. Please try again!';
          dispatch(openErrorToast(errorMsg));
        }
      },
      invalidatesTags: ['projects'],
    }),
  }),
  overrideExisting: false,
});

export const { useDeleteProjectMutation, useProjectMutation, useRenameProjectMutation } = extendedApi;
