import React, { FC } from 'react';
import { Backdrop, CircularProgress, Grid, Paper, Theme, Typography } from '@mui/material';
import FileDataSheetSrf from '../../../common/FileDataSheet/FileDataSheetSrf';
import HeatmapSrf from '../../../common/Heatmap/HeatmapSrf';

const classes = {
  paper: {
    backgroundColor: (theme: Theme) => theme.palette.grey[100],
    padding: 2,
  },
  zoomOut: {
    marginLeft: (theme: Theme) => theme.spacing(1),
  },
  backdropContainer: {
    position: 'relative',
  },
  backdrop: {
    position: 'absolute',
    zIndex: (theme: Theme) => theme.zIndex.drawer + 1,
    color: (theme: Theme) => theme.palette.common.white,
    borderRadius: 4,
    width: '100%',
    height: '100%',
    marginTop: 1,
    marginLeft: 1,
  },
} as const;

const FileHeatmapCard: FC<{
  draw: boolean;
  clicks: { x: number; y: number }[];
  setClicks: (clicks: { x: number; y: number }[]) => void;
  points: { x: number; y: number }[];
  changedPoints: { x: number; y: number }[] | undefined;
  setChangedPoints: (changedPoints: { x: number; y: number }[] | undefined) => void;
  allPoints: { x: number; y: number }[] | undefined;
  errorHeat?: boolean;
  errorGrid?: boolean;
  heatmap: any;
  grid: any[][] | undefined;
  setGrid: (grid: any[][]) => void;
  frameCoords: { x: number; y: number };
  setFrameCoords: (frameCoords: { x: number; y: number }) => void;
  gridLoading: boolean;
  heatmapLoading: boolean;
  setSelect: (selected: boolean) => void;
}> = ({
  draw,
  clicks,
  setClicks,
  points,
  changedPoints,
  setChangedPoints,
  allPoints,
  errorHeat = false,
  errorGrid = false,
  heatmap,
  grid,
  setGrid,
  frameCoords,
  setFrameCoords,
  gridLoading,
  heatmapLoading,
  setSelect,
  ...rest
}) => {
  return (
    <Paper sx={classes.paper} elevation={0} {...rest}>
      <Grid container spacing={2} alignItems={'flex-start'}>
        <Grid item xs={12} sx={classes.backdropContainer}>
          <HeatmapSrf
            draw={draw}
            clicks={clicks}
            setClicks={setClicks}
            points={points}
            allPoints={allPoints}
            imageBase64={heatmap?.image}
            dimensions={heatmap?.dimensions}
            frameCoords={frameCoords}
            setFrameCoords={setFrameCoords}
            values={heatmap?.values}
            gradientColors={heatmap?.rgbaValues}
            setSelect={setSelect}
          />
          <Backdrop sx={classes.backdrop} open={heatmapLoading || !heatmap || (!heatmap && errorHeat)}>
            {!heatmapLoading && !heatmap && errorHeat ? (
              <Typography variant={'body2'}>Error loading Heatmap</Typography>
            ) : (
              <CircularProgress color={'inherit'} />
            )}
          </Backdrop>
        </Grid>
        <Grid item xs={12} sx={classes.backdropContainer}>
          <FileDataSheetSrf
            grid={grid}
            setGrid={setGrid}
            coordinates={frameCoords}
            dimensions={heatmap?.dimensions}
            setCoordinates={setFrameCoords}
            disabled={gridLoading || !heatmap}
            changedpoints={allPoints}
            points={points}
            modifiedPoints={changedPoints}
            setChangedPoints={setChangedPoints}
          />
          <Backdrop sx={classes.backdrop} open={gridLoading || !grid || (!grid && errorGrid)}>
            {errorGrid ? (
              <Typography variant={'body2'}>Error loading data sheet</Typography>
            ) : (
              <CircularProgress color={'inherit'} />
            )}
          </Backdrop>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default FileHeatmapCard;
