import {
  HydBoundaries,
  HydBoundaryConditions,
  HydInitialConditions,
  HydModelDefinition,
  HydModelFeatures,
  HydNumericalDomain,
  HydPlotArea,
  HydPlotLine,
  HydSourceLinks,
  HydSourcePoints,
} from '../../models/inputTypes/HydFields';

export const hydNumericalDomainInitialState: HydNumericalDomain = {
  project: '',
  simulationSetup: undefined,
  cellX: 1,
  cellY: 1,
  cellSizeX: 2.5,
  cellSizeY: 2.5,
  bathymetryFile: undefined,
  swX: 1,
  swY: 1,
  neX: 1,
  neY: 1,
  waterLevel: 0,
};
export const hydModelDefinitionInitialState: HydModelDefinition = {
  calculationType: 'instationary',
  criterion: 0.01,
  filteringType: 'euler',
  filtering: false,
  timeSteps: 0.05,
  simulationTime: 600,
};

export const hydInitialConditionsInitialState: HydInitialConditions = {
  coldStart: true,
  uVelocity: 0,
  vVelocity: 0,
  zVelocity: 0,

  hotStart: false,
  uFile: undefined,
  vFile: undefined,
  zFile: undefined,
};
export const hydBoundaryObjectInitialState: HydBoundaries = {
  fp_x: 1,
  fp_y: 1,
  lp_x: 1,
  lp_y: 1,
  condition_type: 1,
  u: 0,
  v: 0,
  z: 0,
  tidal_amp: 0,
  tidal_per: 0,
};

export const hydSourcePointsObjectInitialState: HydSourcePoints = {
  point_type: 0,
  parameter_type: 1,
  x: 1,
  y: 1,
  param1: 0,
  param2: 0,
};

export const hydBoundaryConditionsInitialState: HydBoundaryConditions = {
  boundariesLength: 0,
  boundaries: [],
};

export const hydSourceLinksInitialState: HydSourceLinks = {
  pointsLength: 0,
  source_points: [],
};

export const hydModelFeaturesInitialState: HydModelFeatures = {
  coriolis: false,
  avgLatitute: 40,
  wettingDrying: false,
  wettingDepth: 0.2,
  dryingDepth: 0.3,

  eddyViscosity: true,
  eddyType: 'constant',
  eddyCoefficient: '2',
  eddyFile: undefined,

  bottomFriction: true,
  bottomFrictionType: 'constant',
  bottomFrictionCoefficient: '0.03',
  frictionFile: undefined,

  waveRadiation: false,
  sxxFile: undefined,
  sxyFile: undefined,
  syyFile: undefined,
  windForcing: false,
  windForcingType: 'constant',
  uWind: 28,
  direction: 270,
  windFrictionFile: undefined,

  wind_friction_flag: '1',
  constant_wind_friction_factor: 0.0026,
};

export const hydOutputInitialState = {
  plotAreas: 0,
  plotLines: 0,
  plotAreaValues: [],
  plotLineValues: [],
};

export const hydPlotLinesInitialState: HydPlotLine = {
  fp_x: 1,
  fp_y: 1,
  lp_x: 1,
  lp_y: 1,
  z: 0,
  u: 0,
  v: 0,
  speed: 0,
  direction: 0,
  depth: 0,
  name_z: 'Z_Line',
  name_u: 'U_Line',
  name_v: 'V_Line',
  name_speed: 'C_Line',
  name_direction: 'D_Line',
  name_depth: 'T_Line',

  start_step: 1,
  end_step: 16000,
  interval: 1000,
};

export const hydPlotAreasInitialState: HydPlotArea = {
  sw_x: 1,
  sw_y: 1,
  ne_x: 1,
  ne_y: 1,

  z: 0,
  u: 0,
  v: 0,
  speed: 0,
  direction: 0,
  depth: 0,
  name_z: 'Z_Area',
  name_u: 'U_Area',
  name_v: 'V_Area',
  name_speed: 'C_Area',
  name_direction: 'D_Area',
  name_depth: 'T_Area',

  start_step: 1,
  end_step: 16000,
  interval: 1000,
};

export const hydPlotLinesAliases = {
  name_z: 'O_Z_Line',
  name_u: 'O_U_Line',
  name_v: 'O_V_Line',
  name_speed: 'O_C_Line',
  name_direction: 'O_D_Line',
  name_depth: 'O_T_Line',
};

export const hydPlotAreasAliases = {
  name_z: 'O_Z_Area',
  name_u: 'O_U_Area',
  name_v: 'O_V_Area',
  name_speed: 'O_C_Area',
  name_direction: 'O_D_Area',
  name_depth: 'O_T_Area',
};
