import React, { FC } from 'react';
import { Box, BoxProps, styled, Typography } from '@mui/material';

type LabelWrapperProps = {
  label: string;
};

const DisplayBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
}));

const RadioLabel: FC<LabelWrapperProps & BoxProps> = ({ label, children, ...props }) => {
  return (
    <DisplayBox {...props}>
      {children}
      <Typography ml={1} variant={'subtitle2'}>
        {label}
      </Typography>
    </DisplayBox>
  );
};

export default RadioLabel;
