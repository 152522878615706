import React, { FC } from 'react';
import { Dialog, DialogContent, DialogProps, Grid, Typography } from '@mui/material';
import CustomButton from '../custom/CustomButton';
import theme from '../../theme';

const styles = {
  root: {
    padding: theme.spacing(6, 5, 4),
  },
  message: {
    marginBottom: 4,
  },
  buttons: {
    marginTop: 3,
  },
} as const;

interface CustomConfirmationModalProps {
  severe?: string;
  open: boolean;
  handleClose: () => void;
  message?: string;
  closeLabel?: string;
  acceptLabel?: string;
  handleAccept?: () => void;
}
const ConfirmationModal: FC<CustomConfirmationModalProps & DialogProps> = ({
  severe,
  message = 'Message',
  closeLabel = 'No',
  acceptLabel = 'Yes',
  open,
  handleAccept,
  handleClose,
  ...props
}) => {
  return (
    <Dialog open={open} onClose={handleClose} {...props}>
      <DialogContent sx={styles.root}>
        <Grid container>
          {severe ? (
            <Grid item mb={2} xs={12}>
              <Typography variant={'h3'} color={'error'} align={'center'}>
                <strong>{severe}</strong>
              </Typography>
            </Grid>
          ) : undefined}
          <Grid item xs={12} sx={styles.message}>
            <Typography variant={'body2'} align={'center'}>
              {message}
            </Typography>
          </Grid>
          <Grid container sx={styles.buttons} justifyContent={'space-around'}>
            <CustomButton
              minWidth={'120px'}
              color={severe ? 'info' : 'warning'}
              variant={'contained'}
              onClick={handleClose}
            >
              <Typography variant={'body2'}>{closeLabel}</Typography>
            </CustomButton>
            <CustomButton
              minWidth={'120px'}
              color={severe ? 'error' : 'primary'}
              variant={'contained'}
              onClick={handleAccept}
            >
              <Typography variant={'body2'}>{acceptLabel}</Typography>
            </CustomButton>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ConfirmationModal;
