import React, { FC, useEffect, useMemo, useState } from 'react';
import { Dialog, DialogContent, DialogProps, Grid, Theme, ButtonBase } from '@mui/material';
import PageBreadCrumbs from '../common/PageBreadCrumbs';
import { FileForm } from '../../models/inputTypes/FileType';
import { ReactComponent as Close } from '../../assets/images/close.svg';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { HotKeys } from 'react-hotkeys';
import FileSaveCard from '../groups/dxfModal/FileSaveCard';
import FileInputsCard from '../groups/dxfModal/FileInputsCard';
import FileDxfMapCard from '../groups/dxfModal/FileDxfMapCard';
import { useGetProjectsQuery } from '../../redux/RTK/queries/projectQuery';
import {
  selectEditFileDxf,
  resetEditFile,
  performGetDxf,
  performPutFileDxf,
  selectEditFileLoading,
  selectEditFileError,
} from '../../redux/slices/editFileSlice';
import { deleteRequest } from '../../axios/index';

const styles = {
  dialog: {
    overflowY: 'visible',
    height: '100%',
    maxHeight: 'calc(100% - 16px)',
  },
  closeButton: {
    position: 'absolute',
    right: -10,
    top: -5,
    boxShadow: '0px 0px 5px 1px gray',
    zIndex: '200000 !important',
    borderRadius: '50%',
    width: '35px',
    padding: 0,
    minWidth: 0,
    color: (theme: Theme) => theme.palette.info.dark,
    background: 'white',
    height: '35px',
  },
  gridContainer: {
    maxHeight: '100%',
  },
} as const;

interface CustomDxfModalProps {
  handleClose: () => void;
  file?: FileForm;
  open: boolean;
}

const DxfModal: FC<CustomDxfModalProps & Exclude<DialogProps, 'open'>> = ({ open, file, handleClose, ...props }) => {
  const dispatch = useAppDispatch();
  const fileName = useMemo(() => file?.name || '', [file]);
  const projectId = useMemo(() => file?.project.toString() || '', [file]);
  const { data: projects } = useGetProjectsQuery({});
  const project = useMemo(() => projects?.find((item) => item.id === +projectId), [projects]);

  const [coastline, setCoastline] = useState<number>();
  const [groynesBrkw, setGroynesBrkw] = useState<number>();
  const [revet, setRevet] = useState<number>();
  const [coastFilename, setCoastFilename] = useState<string | undefined>('coastline.txt');
  const [groynesFilename, setGroynesFilename] = useState<string | undefined>('structures.txt');
  const [revetFilename, setRevetFilename] = useState<string | undefined>('revetments.txt');
  const [epsg, setEpsg] = useState<number | undefined>(undefined);
  const [enableMap, setEnableMap] = useState<boolean>(false);

  const dxfMap = useAppSelector(selectEditFileDxf);
  const editFileLoading = useAppSelector(selectEditFileLoading);
  const editFileError = useAppSelector(selectEditFileError);

  const keyMap = {
    UNDO: ['command+z', 'ctrl+z'],
  };

  const handleSave = async () => {
    dispatch(performPutFileDxf(projectId, fileName, epsg, coastFilename, groynesFilename, revetFilename));
  };

  useEffect(() => {
    return () => {
      dispatch(resetEditFile());
    };
  }, []);

  useEffect(() => {
    dispatch(performGetDxf(projectId, fileName, epsg, coastline, groynesBrkw, revet));
  }, [projectId]);

  const updatePlot = async () => {
    if (enableMap && epsg) {
      dispatch(performGetDxf(projectId, fileName, epsg, coastline, groynesBrkw, revet));
    } else {
      dispatch(performGetDxf(projectId, fileName, undefined, coastline, groynesBrkw, revet));
    }
  };

  const handleClosed = async () => {
    const response = await deleteRequest(`dxf/file/${projectId}/${fileName}`);
    handleClose();
  };

  return (
    <Dialog
      maxWidth={false}
      PaperProps={{ style: styles.dialog }}
      fullWidth
      onClose={handleClosed}
      open={open}
      {...props}
    >
      <ButtonBase sx={styles.closeButton} onClick={handleClosed}>
        <Close />
      </ButtonBase>
      <DialogContent>
        <HotKeys keyMap={keyMap} allowChanges>
          <Grid sx={styles.gridContainer} container spacing={2} justifyContent={'space-between'}>
            <Grid item xs={4} container spacing={2} alignContent={'flex-start'}>
              <Grid item container xs={12} spacing={2}>
                <Grid item xs={12}>
                  <PageBreadCrumbs
                    path={[
                      'Projects',
                      project?.name?.toString() ?? file?.project.toString() ?? 'unavailable',
                      file?.name ?? 'unavailable',
                    ]}
                  />
                </Grid>
              </Grid>
              <Grid item container xs={12} spacing={2}>
                <Grid item xs={12}>
                  <FileInputsCard
                    disabled={!epsg}
                    coastline={coastline}
                    setCoastline={setCoastline}
                    groynesBrkw={groynesBrkw}
                    setGroynesBrkw={setGroynesBrkw}
                    revet={revet}
                    setRevet={setRevet}
                    epsg={epsg}
                    setEpsg={setEpsg}
                    enableMap={enableMap}
                    setEnableMap={setEnableMap}
                    onPlot={updatePlot}
                  />
                </Grid>
              </Grid>
              <Grid item container xs={12} spacing={2}>
                <Grid item xs={12}>
                  <FileSaveCard
                    coastFilename={coastFilename}
                    setCoastFilename={setCoastFilename}
                    groynesFilename={groynesFilename}
                    setGroynesFilename={setGroynesFilename}
                    revetFilename={revetFilename}
                    setRevetFilename={setRevetFilename}
                    onSave={handleSave}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={8} container spacing={2}>
              <Grid item container xs={12} spacing={2}>
                <Grid item xs={12}>
                  <FileDxfMapCard
                    errorDxf={!!editFileError.dxf}
                    dxfMap={dxfMap}
                    // epsg={epsg}
                    // setEpsg={setEpsg}
                    // setEpsgCode={setEpsgCode}
                    dxfLoading={editFileLoading.dxf}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </HotKeys>
      </DialogContent>
    </Dialog>
  );
};

export default DxfModal;
