import React, { FC, useEffect, useMemo, useRef } from 'react';
import { Box, Container, ContainerProps } from '@mui/material';
import PageHeader from './PageHeader';

type PageContainerProps = {
  title?: string;
  subTitle?: string;
  projectName?: string;
};

const styles = {
  container: { paddingBottom: 4 },
};

const PageContainer: FC<PageContainerProps & ContainerProps> = ({
  title,
  subTitle,
  projectName,
  children,
  ...props
}) => {
  const pageHeader = useMemo(
    () => (title ? <PageHeader title={title} subTitle={subTitle} projectName={projectName} /> : undefined),
    [title, subTitle, projectName],
  );

  const pageRef = useRef<any>(null);

  useEffect(() => {
    if (pageRef.current) {
      document.title = subTitle ? subTitle + ' - Scientia Maris' : 'Scientia Maris';
      pageRef.current?.scrollIntoView(true);
    }
  }, [pageRef]);

  return (
    <Box ref={pageRef}>
      {pageHeader}
      <Container sx={styles.container} maxWidth={'xl'} {...props}>
        {children}
      </Container>
    </Box>
  );
};

export default PageContainer;
