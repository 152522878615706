import React, { FC, memo, useCallback, useMemo, useState } from 'react';
import { IconButton, Input, InputProps, styled } from '@mui/material';
import { CustomInputProps } from '../custom/CustomInput';

import { ReactComponent as EyeClosedIcon } from '../../assets/images/eyeClosed.svg';
import { ReactComponent as EyeIcon } from '../../assets/images/eye.svg';
import theme from '../../theme';

const styles = {
  input: { boxShadow: `0 0 0 1px ${theme.palette.secondary.main}` },
};

type LoginInputProps = {
  isPasswordType?: boolean;
};

export const StyledInput = styled((props: InputProps & CustomInputProps) => <Input disableUnderline {...props} />)(
  ({ theme, maxWidth, error, errorText, disabled }) => ({
    position: 'relative',
    background: 'white',
    opacity: disabled ? 0.4 : 1,
    maxWidth: maxWidth ? maxWidth : 'auto',
    boxShadow: `0 0 0 1px ${error ? theme.palette.error.main : theme.palette.info.light}`,
    borderRadius: 4,
    fontSize: theme.typography.subtitle2.fontSize,
    '& .MuiInputBase-input': {
      padding: '11px 4px 9px 8px',
    },
    input: {
      '&[type=number]': {
        '-moz-appearance': 'textfield',
      },
      '&::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
      '&::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
    },
    '&::before': {
      content: errorText && error ? `'${errorText}'` : '""',
      position: 'absolute',
      color: theme.palette.error.main,
      ...theme.typography.caption,
      fontWeight: 400,
      minHeight: 40,
      overflowWrap: 'break-word',
      bottom: -42,
      minWidth: 54,
      left: 0,
      right: 0,
      textAlign: 'center',
    },
  }),
);

const LoginInput: FC<InputProps & CustomInputProps & LoginInputProps> = ({ isPasswordType, ...props }) => {
  const [isVisible, setIsVisible] = useState(false);

  const onToggle = useCallback(() => {
    setIsVisible(!isVisible);
  }, [isVisible]);

  const endAdornmentIcon = useMemo(() => {
    if (!isPasswordType) return;
    return (
      <IconButton disabled={props.disabled} size={'small'} onClick={onToggle}>
        {isVisible ? <EyeIcon /> : <EyeClosedIcon />}
      </IconButton>
    );
  }, [isVisible, isPasswordType, onToggle, props.disabled]);

  return (
    <StyledInput
      sx={props.value && !props.error ? styles.input : undefined}
      type={isVisible || !isPasswordType ? 'text' : 'password'}
      endAdornment={endAdornmentIcon}
      {...props}
    />
  );
};

export default memo(LoginInput);
