import React, { FC, memo, useMemo } from 'react';
import { Box, SelectChangeEvent, Typography } from '@mui/material';
import CustomInput from '../../custom/CustomInput';
import CustomMenuItem from '../../custom/CustomMenuItem';
import CustomSelect from '../../custom/CustomSelect';
import LabelWrapper from '../../common/LabelWrapper';
import CustomSwitch from '../../custom/CustomSwitch';
import BoxComponent from '../../custom/BoxComponent';
import { PmsModelFeatures } from '../../../models/inputTypes/PmsFields';
import { pmsModelFeaturesInitialState } from '../../../utils/initialStates/pmsInputStates';

const styles = {
  spacingJustifyContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  spaceAlignStartContainer: {
    flexWrap: 'wrap',
    display: 'flex',
    alignItems: 'flex-start',
  },
  spaceAlignEndContainer: {
    flexWrap: 'wrap',
    display: 'flex',
    alignItems: 'flex-end',
  },
  flexStartBox: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
  },
  childSpacing: {
    '& >:nth-child(n)': {
      my: 1,
      mr: { xs: 2, md: 3, xl: 4 },
    },
  },
} as const;

type PmsModelFeaturesInputsProps = {
  inputState: PmsModelFeatures;
  setInputState: (value: PmsModelFeatures) => void;
};

const PmsModelFeaturesInputsGroup: FC<PmsModelFeaturesInputsProps> = ({ inputState, setInputState }) => {
  const iterationWarning: string | undefined = useMemo(
    () => (inputState.nonlinearDispersion && inputState.iterations != 3 ? 'Warning:recommended value is 3' : undefined),
    [inputState.nonlinearDispersion, inputState.iterations],
  );

  const onInputChange = (
    event: SelectChangeEvent<unknown> | React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    if (event.target.name === 'approximitation' && event.target.value !== '3') {
      setInputState({
        ...inputState,
        [event.target.name as string]: event.target.value,
        degrees: pmsModelFeaturesInitialState.degrees,
      });
      return;
    }
    setInputState({ ...inputState, [event.target.name]: event.target.value });
  };

  const onNumberChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setInputState({ ...inputState, [event.target.name]: event.target.value });
  };

  const onSwitchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputState({
      ...inputState,
      [e.target.name]: e.target.checked,
      iterations: pmsModelFeaturesInitialState.iterations,
      relation: pmsModelFeaturesInitialState.relation,
    });
  };

  return (
    <Box sx={{ ...styles.flexStartBox }}>
      <Box mr={4}>
        <Typography mb={2} variant={'subtitle1'}>
          Nonlinearity
        </Typography>
        <Box sx={{ ...styles.spaceAlignStartContainer, ...styles.childSpacing }}>
          <Box>
            <LabelWrapper label={'Include nonlinear amplitude dispersion'}>
              <Box sx={{ ...styles.spacingJustifyContainer }}>
                <CustomSwitch
                  checked={inputState.nonlinearDispersion}
                  name={'nonlinearDispersion'}
                  onChange={onSwitchChange}
                />
              </Box>
            </LabelWrapper>
          </Box>
          <BoxComponent disabled={!inputState.nonlinearDispersion} sx={{ ...styles.flexStartBox }}>
            <Box mr={4}>
              <LabelWrapper label={'Number of iterations'}>
                <CustomInput
                  disabled={!inputState.nonlinearDispersion}
                  min={2}
                  max={5000}
                  required
                  step={1}
                  warningText={iterationWarning}
                  errorText={'Enter integer from 1-5000'}
                  type={'number'}
                  value={inputState.iterations}
                  onChange={onNumberChange}
                  name={'iterations'}
                />
              </LabelWrapper>
            </Box>
            <Box>
              <LabelWrapper label={'Selection of relation'}>
                <CustomSelect
                  required
                  value={inputState.relation}
                  onChange={onInputChange}
                  name={'relation'}
                  minWidth={'140px'}
                  defaultValue={''}
                  displayEmpty
                >
                  <CustomMenuItem value={'1'}>
                    <Typography variant={'subtitle2'}>Zhao & Anastasiou (1993)</Typography>
                  </CustomMenuItem>
                  <CustomMenuItem value={'2'}>
                    <Typography variant={'subtitle2'}>Kirby & Dalrymple (1986)</Typography>
                  </CustomMenuItem>
                </CustomSelect>
              </LabelWrapper>
            </Box>
          </BoxComponent>
        </Box>
      </Box>
      <Box>
        <Typography mb={2} variant={'subtitle1'}>
          Coefficients
        </Typography>
        <Box sx={{ ...styles.spaceAlignEndContainer, ...styles.childSpacing }}>
          <Box mr={4}>
            <Box>
              <CustomSelect
                value={inputState.approximitation}
                onChange={onInputChange}
                name={'approximitation'}
                minWidth={'140px'}
                defaultValue={''}
                displayEmpty
              >
                <CustomMenuItem value={'1'}>
                  <Typography variant={'subtitle2'}>Simple Approximation</Typography>
                </CustomMenuItem>
                <CustomMenuItem value={'2'}>
                  <Typography variant={'subtitle2'}>Pade’s Approximation</Typography>
                </CustomMenuItem>
                <CustomMenuItem value={'3'}>
                  <Typography variant={'subtitle2'}>Minimax Approximation</Typography>
                </CustomMenuItem>
              </CustomSelect>
            </Box>
          </Box>
          <BoxComponent disabled={inputState.approximitation !== '3'}>
            <LabelWrapper label={'Degrees'}>
              <CustomInput
                disabled={inputState.approximitation !== '3'}
                min={10}
                max={60}
                required
                step={10}
                errorText={'Enter number 10-70 (in tens)'}
                type={'number'}
                minWidth={'65px'}
                value={inputState.degrees}
                onChange={onNumberChange}
                name={'degrees'}
              />
            </LabelWrapper>
          </BoxComponent>
        </Box>
      </Box>
    </Box>
  );
};

export default memo(PmsModelFeaturesInputsGroup);
