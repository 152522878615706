export const fileToByteArray = (file: any) => {
  return new Promise((resolve, reject) => {
    try {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onloadend = (evt: any) => {
        resolve(evt.target.result);
      };
    } catch (e) {
      reject(e);
    }
  });
};
