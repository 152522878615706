import { createTheme } from '@mui/material';
import { error, info, primary, secondary, success, warning } from './palette';

const font = "'Poppins', sans-serif";

const theme = createTheme({
  palette: {
    primary: primary,
    secondary: secondary,
    error: error,
    warning: warning,
    info: info,
    success: success,
  },
  typography: {
    fontFamily: font,
    h1: {
      fontSize: 24,
      fontWeight: 800,
    },
    h2: {
      fontSize: 18,
      fontWeight: 800,
    },
    h3: {
      fontSize: 22,
      fontWeight: 600,
    },
    h4: {
      fontSize: 18,
      fontWeight: 600,
    },
    h5: {
      fontSize: 16,
      fontWeight: 600,
    },
    h6: {
      fontSize: 22,
      fontWeight: 'normal',
    },
    body1: {
      fontSize: 18,
      fontWeight: 'normal',
    },
    body2: {
      fontSize: 16,
      fontWeight: 'normal',
    },
    subtitle1: {
      fontSize: 12,
      fontWeight: 'bold',
    },
    subtitle2: {
      fontSize: 12,
      fontWeight: 'normal',
    },
    caption: {
      fontSize: 8,
      fontWeight: 600,
    },
  },
});
export default theme;
