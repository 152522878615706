export const colorOptions = [
  { title: 'Rainbow', value: '1' },
  { title: 'Jet', value: '2' },
  { title: 'Coolwarm', value: '3' },
  { title: 'PurpleRed', value: '4' },
  { title: 'PurleOrange', value: '5' },
  { title: 'Blues', value: '6' },
  { title: 'BlueBrown', value: '7' },
  { title: 'GreenLightBlue', value: '8' },
  { title: 'BlueWhiteRed', value: '9' },
  { title: 'GreenYellowBrown', value: '10' },
  { title: 'RedYellowBlue', value: '11' },
  { title: 'BlueViolet', value: '12' },
  { title: 'LightBlue', value: '13' },
];
