import { styled, Tab } from '@mui/material';

const CustomTab = styled(Tab)(({ theme }) => ({
  marginRight: 8,
  textTransform: 'none',
  background: '#F1F7FF',
  color: theme.palette.primary.light,
  borderRadius: 4,
  padding: 2,
  minHeight: 32,
  transition: '0.2s',
  '&.Mui-selected': {
    background: theme.palette.primary.dark,
    color: theme.palette.info.contrastText,
  },
}));

export default CustomTab;
