import React, { FC, FormEvent, useEffect, useMemo, useState } from 'react';
import PageContainer from '../../components/structural/PageContainer';
import { Collapse, Typography } from '@mui/material';
import BoxInputContainer from '../../components/custom/BoxInputContainer';
import PmsNumericalDomainInputsGroup from '../../components/groups/pms/PmsNumericalDomainInputsGroup';
import CustomTabs from '../../components/custom/CustomTabs';
import PmsSubmissionInputsGroup from '../../components/groups/pms/PmsSubmissionInputsGroup';
import HmsBoundaryConditionsInputsGroup from '../../components/groups/hms/HmsBoundaryConditionsInputsGroup';
import HmsSimulationInputsGroup from '../../components/groups/hms/HmsSimulationInputsGroup';
import HmsModelFeaturesInputsGroup from '../../components/groups/hms/HmsModelFeaturesInputsGroup';
import HmsEnergyDissipationInputsGroup from '../../components/groups/hms/HmsEnergyDissipationInputsGroup';
import CustomPrompt from '../../components/custom/CustomPrompt';
import { PmsNumericDomain, PmsSubmission } from '../../models/inputTypes/PmsFields';
import { pmsNumericDomainInitialState, pmsSubmissionInitialState } from '../../utils/initialStates/pmsInputStates';
import { plotTabPreparation } from '../../utils/functions/PlotTabPreparation';
import {
  HmsBoundaryConditions,
  HmsEnergyDissipation,
  HmsModelFeatures,
  HmsOutput,
  HmsPlotArea,
  HmsPlotLine,
  HmsSimulationTime,
} from '../../models/inputTypes/HmsFields';
import {
  hmsBoundaryConditionsInitialState,
  hmsEnergyDissipationInitialState,
  hmsModelFeaturesInitialState,
  hmsOutputInitialState,
  hmsPlotAreasInitialState,
  hmsPlotLinesInitialState,
  hmsSimulationTimeInitialState,
} from '../../utils/initialStates/hmsInputState';
import { useGetProjectsQuery } from '../../redux/RTK/queries/projectQuery';
import { HmsPreparation } from '../../utils/modelPreparation/HmsPreparation';
import HmsOutputInputsGroup from '../../components/groups/hms/HmsOutputInputsGroup';
import HmsPlotAreasInputsGroup from '../../components/groups/hms/HmsPlotAreasInputsGroup';
import HmsPlotLineInputsGroup from '../../components/groups/hms/HmsPlotLinesInputsGroup';
import { openWarningToast } from '../../redux/slices/appSlice';
import { useAddProjectFileMutation } from '../../redux/RTK/mutations/projectFileMutations';
import { PmsSimulationPreparationResponse } from '../../utils/simulationFileToState/pmsSimulationPreparation';
import { HmsSimulationPreparationResponse } from '../../utils/simulationFileToState/hmsSimulationPreparation';
import { FileType } from '../../models/inputTypes/FileType';
import { useCreateJobsMutation } from '../../redux/RTK/mutations/jobsMutations';
import ConfirmationModal from '../../components/dialogs/ConfirmationModal';
import { useAppDispatch } from '../../redux/store';
import useSimulationPreparation from '../../utils/hooks/jsonParamHook';
import { ModelEnum } from '../../models/types/ModelEnum';
import { useStorageQuery } from '../../redux/RTK/queries/storageQuery';

const MarisHmsPage: FC = () => {
  const { data } = useGetProjectsQuery({});
  const { data: profile } = useStorageQuery({});
  const [createJob] = useCreateJobsMutation();
  const [uploadFile] = useAddProjectFileMutation();
  const dispatch = useAppDispatch();

  const [selectedPlots, setSelectedPlots] = useState<{ line: number; area: number }>({ line: 0, area: 0 });
  const [numericalState, setNumericalState] = useState<PmsNumericDomain>(pmsNumericDomainInitialState);
  const [boundaryState, setBoundaryState] = useState<HmsBoundaryConditions>(hmsBoundaryConditionsInitialState);
  const [simulationState, setSimulationState] = useState<HmsSimulationTime>(hmsSimulationTimeInitialState);
  const [modelState, setModelState] = useState<HmsModelFeatures>(hmsModelFeaturesInitialState);
  const [energyState, setEnergyState] = useState<HmsEnergyDissipation>(hmsEnergyDissipationInitialState);
  const [outputState, setOutputState] = useState<HmsOutput>(hmsOutputInitialState);
  const [submissionState, setSubmissionState] = useState<PmsSubmission>(pmsSubmissionInitialState);
  const [areaInitial, setAreaInitial] = useState<HmsPlotArea>(hmsPlotAreasInitialState);
  const [lineInitial, setLineInitial] = useState<HmsPlotLine>(hmsPlotLinesInitialState);
  const [saveNoOutput, setSaveNoOutput] = useState(false);
  const [stateHasChanged, setStateHasChanged] = useState(false);
  const { preparedData, error, currentFile } = useSimulationPreparation(ModelEnum.HMS);
  const [disable, setDisable] = useState(false);

  useEffect(() => {
    if (profile?.profile) {
      setDisable(!profile.profile.enable_models.includes('hms') && !profile.profile.enable_models.includes('all'));
    }
  }, [profile]);

  useEffect(() => {
    if (preparedData && !error && currentFile) {
      onSimulationFileChange(preparedData, currentFile);
    }
  }, [preparedData, error, currentFile]);

  useEffect(() => {
    if (!stateHasChanged) {
      const numChanged = numericalState != pmsNumericDomainInitialState;
      const boundChanged = boundaryState != hmsBoundaryConditionsInitialState;
      const modChanged = modelState != hmsModelFeaturesInitialState;
      const simChanged = simulationState != hmsSimulationTimeInitialState;
      const enChanged = energyState != hmsEnergyDissipationInitialState;
      const outChanged = outputState != hmsOutputInitialState;
      const subChanged = submissionState != pmsSubmissionInitialState;
      setStateHasChanged(
        boundChanged || enChanged || numChanged || modChanged || subChanged || simChanged || outChanged,
      );
    }
  }, [numericalState, boundaryState, modelState, simulationState, energyState, outputState, submissionState]);

  useEffect(() => {
    setAreaInitial({
      ...areaInitial,
      ne_x: numericalState.cellX ?? hmsPlotAreasInitialState.ne_x,
      ne_y: numericalState.cellY ?? hmsPlotAreasInitialState.ne_y,
    });
  }, [numericalState.cellX, numericalState.cellY]);

  useEffect(() => {
    const floorValue = Math.floor(simulationState.simulationTime / simulationState.timeStep);
    setAreaInitial({
      ...areaInitial,
      end_step: floorValue,
      interval: floorValue,
    });
    setLineInitial({
      ...lineInitial,
      end_step: floorValue,
      interval: floorValue,
    });
  }, [simulationState.simulationTime, simulationState.timeStep]);

  const plotAreaTabs = useMemo(() => {
    return plotTabPreparation(outputState.plotAreas, 'area');
  }, [outputState.plotAreas]);

  const plotLineTabs = useMemo(() => {
    return plotTabPreparation(outputState.plotLines, 'line');
  }, [outputState.plotLines]);

  const selectedProjectName = useMemo(() => {
    if (data?.length) {
      const found = data.find((item) => item.id.toString() === numericalState.project.toString());
      return found?.name;
    }
  }, [numericalState.project]);

  const onLineChange = (event: any, newValue: number) => {
    setSelectedPlots({ ...selectedPlots, [event.currentTarget.id]: newValue });
  };

  const onNoOutputAccept = () => {
    const preparedValue = HmsPreparation(
      numericalState,
      boundaryState,
      simulationState,
      modelState,
      energyState,
      outputState,
      submissionState,
    );
    setSaveNoOutput(false);
    if (preparedValue) createJob(preparedValue);
  };

  const onSubmissionClick = (event: FormEvent<any>) => {
    event.preventDefault();
    if (!outputState.plotAreas && !outputState.plotLines) {
      setSaveNoOutput(true);
      return;
    }
    const preparedValue = HmsPreparation(
      numericalState,
      boundaryState,
      simulationState,
      modelState,
      energyState,
      outputState,
      submissionState,
    );
    if (preparedValue) createJob(preparedValue);
  };

  const onSimulationSave = () => {
    const preparedValue = HmsPreparation(
      numericalState,
      boundaryState,
      simulationState,
      modelState,
      energyState,
      outputState,
      submissionState,
    );
    if (preparedValue && numericalState.project && submissionState.simulationFile) {
      uploadFile({
        id: numericalState.project,
        name: `${submissionState?.simulationFile}.json`,
        file: preparedValue,
      });
    } else {
      dispatch(
        openWarningToast(
          !numericalState.project ? 'You have to select project first!' : 'simulation file name required!',
        ),
      );
    }
  };

  const onSimulationFileChange = (
    sim: PmsSimulationPreparationResponse | HmsSimulationPreparationResponse,
    file?: FileType,
  ) => {
    const value = sim as HmsSimulationPreparationResponse;
    setNumericalState({ ...value.numerical, simulationSetup: file });
    setBoundaryState(value.boundary);
    setSimulationState(value.simulation);
    setModelState(value.modelFeature);
    setEnergyState(value.energyDissipation);
    setOutputState(value.output);
    setSubmissionState({ ...value.submission, simulationFile: file?.fileName?.split('.json')[0] ?? '' });
  };

  const onSimulationChange = (sim: HmsSimulationTime) => {
    if (simulationState.timeStep !== sim.timeStep) {
      setModelState({ ...modelState, startingTime: sim.timeStep });
    }
    setSimulationState(sim);
  };

  return (
    <PageContainer title={'NUMERICAL MODELS'} subTitle={'Maris HMS'} projectName={selectedProjectName}>
      <CustomPrompt
        when={stateHasChanged}
        message={(params) =>
          params.pathname == '/numerical-models/maris-hms' ? true : 'Changes that you made may not be saved.'
        }
      />

      <Typography variant={'h4'}>Numerical Domain</Typography>
      <form onSubmit={onSubmissionClick}>
        <BoxInputContainer borderRadius={'4px'} mt={2}>
          <PmsNumericalDomainInputsGroup
            modelType={ModelEnum.HMS}
            simulationChangeFile={onSimulationFileChange}
            inputState={numericalState}
            setInputState={setNumericalState}
          />
        </BoxInputContainer>
        <Typography mt={2} variant={'h4'}>
          Boundary Conditions
        </Typography>
        <BoxInputContainer borderRadius={'4px'} mt={2}>
          <HmsBoundaryConditionsInputsGroup
            numericalState={numericalState}
            energyState={energyState}
            inputState={boundaryState}
            setInputState={setBoundaryState}
          />
        </BoxInputContainer>
        <Typography mt={2} variant={'h4'}>
          Simulation Time
        </Typography>
        <BoxInputContainer borderRadius={'4px'} mt={2}>
          <HmsSimulationInputsGroup inputState={simulationState} setInputState={onSimulationChange} />
        </BoxInputContainer>
        <Typography mt={2} variant={'h4'}>
          Model Features
        </Typography>
        <BoxInputContainer borderRadius={'4px'} mt={2}>
          <HmsModelFeaturesInputsGroup
            projectId={numericalState.project}
            inputState={modelState}
            setInputState={setModelState}
          />
        </BoxInputContainer>
        <Typography mt={2} variant={'h4'}>
          Energy Dissipation
        </Typography>
        <BoxInputContainer borderRadius={'4px'} mt={2}>
          <HmsEnergyDissipationInputsGroup
            projectId={numericalState.project}
            inputState={energyState}
            setInputState={setEnergyState}
          />
        </BoxInputContainer>
        <Typography mt={2} variant={'h4'}>
          Output
        </Typography>
        <BoxInputContainer borderRadius={'4px'} mt={2}>
          <HmsOutputInputsGroup
            lineInitial={lineInitial}
            inputState={outputState}
            setInputState={setOutputState}
            areaInitial={areaInitial}
          />
        </BoxInputContainer>
        <Typography mt={2} variant={'h4'}>
          Plot Areas
        </Typography>
        <BoxInputContainer borderRadius={'4px'} mt={2}>
          <Collapse in={outputState.plotAreas !== 0} collapsedSize={1}>
            <CustomTabs
              variant={'scrollable'}
              scrollButtons={'auto'}
              value={selectedPlots.area}
              name={'area'}
              onChange={onLineChange}
            >
              {plotAreaTabs}
            </CustomTabs>
            {outputState.plotAreaValues[selectedPlots.area] ? (
              <HmsPlotAreasInputsGroup
                inputState={outputState.plotAreaValues[selectedPlots.area]}
                setInputState={(newArea) => {
                  const newPlotArray = outputState.plotAreaValues.slice();
                  newPlotArray[selectedPlots.area] = { ...newPlotArray[selectedPlots.area], ...newArea };
                  setOutputState({ ...outputState, plotAreaValues: newPlotArray });
                }}
              />
            ) : undefined}
          </Collapse>
        </BoxInputContainer>
        <Typography mt={2} variant={'h4'}>
          Plot Lines
        </Typography>
        <BoxInputContainer borderRadius={'4px'} mt={2}>
          <Collapse in={outputState.plotLines !== 0} collapsedSize={5}>
            <CustomTabs
              variant={'scrollable'}
              scrollButtons={'auto'}
              value={selectedPlots.line}
              name={'line'}
              onChange={onLineChange}
            >
              {plotLineTabs}
            </CustomTabs>
            {outputState.plotLineValues[selectedPlots.line] ? (
              <HmsPlotLineInputsGroup
                inputState={outputState.plotLineValues[selectedPlots.line]}
                setInputState={(newLine) => {
                  const newPlotArray = outputState.plotLineValues.slice();
                  newPlotArray[selectedPlots.line] = newLine;
                  setOutputState({ ...outputState, plotLineValues: newPlotArray });
                }}
              />
            ) : undefined}
          </Collapse>
        </BoxInputContainer>
        <Typography mt={2} variant={'h4'}>
          Submission
        </Typography>
        <BoxInputContainer borderRadius={'4px'} mt={2}>
          <PmsSubmissionInputsGroup
            onSaveClick={onSimulationSave}
            inputState={submissionState}
            setInputState={setSubmissionState}
            disable={disable}
          />
        </BoxInputContainer>
      </form>
      <ConfirmationModal
        open={saveNoOutput}
        handleClose={() => setSaveNoOutput(false)}
        message={'You are about to make a job without output! Are you sure you want to do that?'}
        handleAccept={onNoOutputAccept}
      />
    </PageContainer>
  );
};

export default MarisHmsPage;
