import React, { FC, memo, useMemo, useEffect, useState } from 'react';
import { Box, Typography, Theme, InputAdornment, IconButton } from '@mui/material';
import CustomButton from '../../../custom/CustomButton';
import BoxInputContainer from '../../../custom/BoxInputContainer';
import { HmsBoundaryConditions } from '../../../../models/inputTypes/HmsFields';
import LabelWrapper from '../../../common/LabelWrapper';
import CustomInputWithLabel from '../../../common/CustomInputWithLabel';

const styles = {
  boxInputs: {
    padding: (theme: Theme) => theme.spacing(1.5, 4, 1.5, 2),
    background: '#DFE1E6',
  },
  buttonPosition: {
    display: 'flex',
    margin: 'auto',
    marginTop: 6,
    background: (theme: Theme) => theme.palette.primary.dark,
  },
  spacingContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  childSpacing: {
    '& >:nth-child(n)': {
      my: 1,
      mr: { xs: 2, md: 3, xl: 4 },
    },
  },
  flexStartBox: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  coordinates: {
    '& >:nth-child(2)': {
      ml: 1,
    },
  },
} as const;

type HeatMapInputProps = {
  timeframe: number;
  drawStates: boolean[];
  setDrawStates: (drawStates: boolean[]) => void;
  onClose: () => void;
  inputState: HmsBoundaryConditions;
  setInputState: (value: HmsBoundaryConditions) => void;
  onValidate: (index: number) => void;
  dimensions?: number[];
};
const FileDetailCard: FC<HeatMapInputProps> = ({
  timeframe,
  drawStates,
  setDrawStates,
  onClose,
  inputState,
  setInputState,
  onValidate,
  dimensions,
  ...rest
}) => {
  const [valueX, setValueX] = useState(0);
  const [valueY, setValueY] = useState(0);

  const onDynamicInputChange = (index: number, e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const generatorsArray = inputState.wave_generators_line_coordinates.slice();
    generatorsArray[index] = { ...generatorsArray[index], [e.target.name]: +e.target.value };
    setInputState({ ...inputState, wave_generators_line_coordinates: generatorsArray });
  };

  const onDynamicInputChangeX = (index: number, e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setValueX(+e.target.value);
    const generatorsArray = inputState.wave_generators_line_coordinates.slice();
    generatorsArray[index] = { ...generatorsArray[index], [e.target.name]: +e.target.value };
    setInputState({ ...inputState, wave_generators_line_coordinates: generatorsArray });
  };

  const onDynamicInputChangeY = (index: number, e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setValueY(+e.target.value);
    const generatorsArray = inputState.wave_generators_line_coordinates.slice();
    generatorsArray[index] = { ...generatorsArray[index], [e.target.name]: +e.target.value };
    setInputState({ ...inputState, wave_generators_line_coordinates: generatorsArray });
  };

  const handleDrawClick = (index: number) => {
    const newDrawStates = new Array(drawStates.length).fill(false);
    newDrawStates[index] = !drawStates[index];
    setDrawStates(newDrawStates);
  };

  const landInputs = useMemo(() => {
    return (
      <>
        {inputState.wave_generators_line_coordinates.map((item, i) => (
          <Box key={i}>
            <Box mt={3}>
              <CustomButton
                minWidth={'120px'}
                size={'small'}
                variant={'contained'}
                color={drawStates[i] ? 'info' : 'primary'}
                onClick={() => handleDrawClick(i)}
              >
                Draw Line
              </CustomButton>
            </Box>
            <Box sx={{ ...styles.spacingContainer, ...styles.childSpacing }}>
              <Box>
                <LabelWrapper label={'First point'}>
                  <Box sx={{ ...styles.flexStartBox, ...styles.coordinates }}>
                    <CustomInputWithLabel
                      minWidth={'64px'}
                      required
                      min={1}
                      step={1}
                      errorText={'Enter positive integer'}
                      type={'number'}
                      value={inputState.wave_generators_line_coordinates[i].wg_fpx}
                      onChange={(e) => onDynamicInputChange(i, e)}
                      name={'wg_fpx'}
                      label={'X'}
                    />
                    <CustomInputWithLabel
                      minWidth={'64px'}
                      required
                      min={1}
                      step={1}
                      errorText={'Enter positive integer'}
                      type={'number'}
                      value={inputState.wave_generators_line_coordinates[i].wg_fpy}
                      onChange={(e) => onDynamicInputChange(i, e)}
                      name={'wg_fpy'}
                      label={'Y'}
                    />
                  </Box>
                </LabelWrapper>
              </Box>
              <Box>
                <LabelWrapper label={'Last point'}>
                  <Box sx={{ ...styles.flexStartBox, ...styles.coordinates }}>
                    <CustomInputWithLabel
                      minWidth={'64px'}
                      required
                      min={1}
                      step={1}
                      errorText={'Enter positive integer'}
                      type={'number'}
                      value={inputState.wave_generators_line_coordinates[i].wg_lpx}
                      onChange={(e) => onDynamicInputChangeX(i, e)}
                      name={'wg_lpx'}
                      label={'X'}
                    />
                    <CustomInputWithLabel
                      minWidth={'64px'}
                      required
                      min={1}
                      step={1}
                      errorText={'Enter positive integer'}
                      type={'number'}
                      value={inputState.wave_generators_line_coordinates[i].wg_lpy}
                      onChange={(e) => onDynamicInputChangeY(i, e)}
                      name={'wg_lpy'}
                      label={'Y'}
                    />
                  </Box>
                </LabelWrapper>
              </Box>
              <Box>
                <CustomButton
                  minWidth={'100px'}
                  size={'small'}
                  variant={'contained'}
                  color={'primary'}
                  sx={{ marginTop: 3 }}
                  onClick={() => onValidate(i)}
                >
                  Validate
                </CustomButton>
              </Box>
            </Box>
          </Box>
        ))}
      </>
    );
  }, [inputState, onDynamicInputChange]);

  return (
    <BoxInputContainer sx={{ ...styles.boxInputs }} borderRadius={'6px'}>
      <Box>
        <Typography variant={'subtitle1'}>Wave Generation Coordinates</Typography>
        {landInputs}
      </Box>
      <CustomButton
        minWidth={'120px'}
        size={'small'}
        sx={styles.buttonPosition}
        variant={'contained'}
        color={'primary'}
        onClick={onClose}
      >
        Save
      </CustomButton>
    </BoxInputContainer>
  );
};

export default memo(FileDetailCard);
