import React, { FC, useMemo } from 'react';
import { Backdrop, Typography, Box } from '@mui/material';
import Lottie from 'react-lottie';
import * as animationData from '../../pinjump.json';
import { useAppSelector } from '../../redux/store';
import CustomProgress from '../custom/CustomProgress';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData.default,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const GlobalLoading: FC = () => {
  const downloadingZip = useAppSelector((state) => state.app.downloading);
  const globalLoading = useAppSelector((state) => state.app.globalLoading);
  const zipping = useAppSelector((state) => state.app.zipping);
  const progress = useAppSelector((state) => state.app.progress);

  const backdrop = useMemo(() => {
    if (zipping && !downloadingZip) {
      return (
        <Backdrop sx={{ zIndex: 2000 }} open={zipping && !downloadingZip}>
          <Box>
            <Lottie options={defaultOptions} height={'120px'} width={'120px'} />
            <Typography color="white">Zipping your project. Please wait...</Typography>
          </Box>
        </Backdrop>
      );
    }
    if (downloadingZip) {
      return (
        <Backdrop sx={{ zIndex: 2000 }} open={downloadingZip}>
          <Box sx={{ width: 200 }}>
            <Lottie options={defaultOptions} height={'120px'} width={'120px'} />
            <Typography color="white">Downloading</Typography>
            <CustomProgress
              progresValue={progress}
              progresStatus={'running'}
              title={progress.toString()}
              value={progress}
            />
          </Box>
        </Backdrop>
      );
    }
    if (globalLoading) {
      return (
        <Backdrop sx={{ zIndex: 2000 }} open={globalLoading}>
          <Lottie options={defaultOptions} height={'120px'} width={'120px'} />
        </Backdrop>
      );
    }
  }, [globalLoading, downloadingZip, zipping, progress]);

  return <>{backdrop}</>;
};

export default GlobalLoading;
