import React, { FC } from 'react';
import './Heatmap/Heatmap.css';
import { Box } from '@mui/system';

const styles = {
  mapBox: {
    // position: 'relative', // Set relative positioning to the container
    width: '100%',
    height: '100%',
  },
} as const;

const DxfMap: FC<{ dxfMap: any }> = ({ dxfMap, ...rest }) => {
  return (
    <Box py={1} sx={styles.mapBox} {...rest}>
      <Box mt={6} dangerouslySetInnerHTML={{ __html: dxfMap }}></Box>
    </Box>
  );
};
export default DxfMap;
