import React, { FC, useEffect, useState } from 'react';
import { Box, CircularProgress, Grid, styled, Typography } from '@mui/material';
import loginIcon from '../../assets/images/login.png';
import { Link } from 'react-router-dom';
import { ReactComponent as ScientiaLogo } from '../../assets/images/scientiaMaris.svg';

const BoxBackground = styled(Box)(() => ({
  overflow: 'auto',
  background: `url(${loginIcon})`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  height: '100vh',
  width: '100vw',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const Card = styled(Box)(({ theme }) => ({
  background: '#FAFAFA',
  width: '280px',
  height: '345px',
  borderRadius: 4,
  boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.24)',
  padding: '38px 32px',
  '& >:first-child': {
    display: 'flex',
    margin: theme.spacing(0, 'auto', 4.5, 'auto'),
  },

  form: {
    '& div': {
      marginBottom: theme.spacing(2),
    },
  },
}));

const ResetPasswordLogin: FC = () => {
  return (
    <BoxBackground>
      <Card>
        <ScientiaLogo />
        <Box>
          <Typography variant={'h5'} sx={{ color: 'success' }} align={'center'}>
            Password reset complete!
          </Typography>
          <Typography variant={'subtitle2'} mt={'25px'} fontSize={13} align={'center'}>
            Your password has been set successfully.
          </Typography>
          <Link to="/login">
            <Typography variant={'subtitle2'} align={'center'} padding={'20px'} fontSize={13}>
              Log in
            </Typography>
          </Link>
        </Box>
      </Card>
    </BoxBackground>
  );
};

export default ResetPasswordLogin;
