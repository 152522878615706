import React, { FC, memo, useMemo } from 'react';
import { Box, SelectChangeEvent, Typography } from '@mui/material';
import CustomMenuItem from '../../custom/CustomMenuItem';
import CustomSelect from '../../custom/CustomSelect';
import CustomInput from '../../custom/CustomInput';
import LabelWrapper from '../../common/LabelWrapper';
import { FileType } from '../../../models/inputTypes/FileType';
import { useGetProjectsQuery } from '../../../redux/RTK/queries/projectQuery';
import { useHistory, useParams } from 'react-router';
import { EviNumerical } from '../../../models/inputTypes/EviFields';
import EviBedLevelInputs from './EviBedLevelInputs';
import EviSedimentInputs from './EviSedimentInputs';
import SimulationFileContainer, {
  SimulationContainerSetter,
} from '../../common/fileComponents/SimulationFileContainer';
import { ModelEnum } from '../../../models/types/ModelEnum';

const styles = {
  spaceBetweenContainer: {
    flexWrap: 'wrap',
    display: 'flex',
    maxWidth: '730px',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& >:nth-child(n)': {
      my: 1,
    },
  },
  flexStartBox: {
    '& > *': {
      my: 1,
      mr: { xs: 3, lg: 4 },
    },
    display: 'flex',
    justifyContent: 'flex-start',
  },
} as const;

type EviNumericalInputsGroupProps = {
  inputState: EviNumerical;
  setInputState: React.Dispatch<React.SetStateAction<EviNumerical>>;
  simulationChangeFile?: (value: any, file?: FileType) => void;
};

const EviNumericalInputsGroup: FC<EviNumericalInputsGroupProps> = ({
  simulationChangeFile,
  inputState,
  setInputState,
}) => {
  const { data, isFetching } = useGetProjectsQuery({});
  const history = useHistory();
  const { name, fileId } = useParams<{ id?: string; name?: string; fileId?: string }>();

  const projectItems = useMemo(() => {
    if (data?.length) {
      return data.map((item, i) => (
        <CustomMenuItem key={i} value={item.id}>
          <Typography variant={'subtitle2'}>{item.name} </Typography>
        </CustomMenuItem>
      ));
    }
  }, [data]);

  const onInputChange = (
    event: SelectChangeEvent<unknown> | React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setInputState({
      ...inputState,
      [event.target.name]: event.target.value,
    });

    if (name && fileId) {
      history.push('/designTools/maris-evi');
    }
  };

  const onSimulationFileChange = ({ loadedSimulation, file }: SimulationContainerSetter) => {
    if (simulationChangeFile && loadedSimulation && file) {
      return simulationChangeFile(loadedSimulation, file);
    }
    setInputState({ ...inputState, simulationSetup: undefined });
  };

  return (
    <>
      <Box sx={{ ...styles.spaceBetweenContainer }}>
        <Box>
          <LabelWrapper label={'Select project'}>
            <CustomSelect
              required
              value={inputState.project}
              name={'project'}
              onChange={onInputChange}
              minWidth={'132px'}
              isLoading={isFetching}
              defaultValue={''}
              displayEmpty
            >
              <CustomMenuItem value={''}>
                <Typography variant={'subtitle2'}>select project</Typography>
              </CustomMenuItem>
              {projectItems}
            </CustomSelect>
          </LabelWrapper>
        </Box>
        <Box>
          <LabelWrapper label={'Insert simulation setup'}>
            <SimulationFileContainer
              typePage={'EVI'}
              modelType={ModelEnum.EVI}
              redirectLocation={'/designTools/maris-evi'}
              project={inputState.project}
              file={inputState.simulationSetup}
              setSimulationInputs={onSimulationFileChange}
            />
          </LabelWrapper>
        </Box>
      </Box>
      <Box sx={{ ...styles.spaceBetweenContainer }}>
        <Box sx={{ ...styles.flexStartBox }}>
          <Box>
            <LabelWrapper label={'Filetype'}>
              <CustomSelect
                required
                value={inputState.fileType}
                name={'fileType'}
                onChange={onInputChange}
                minWidth={'132px'}
                isLoading={isFetching}
                defaultValue={''}
                displayEmpty
              >
                <CustomMenuItem value={'1'}>
                  <Typography variant={'subtitle2'}>Rate of bed level change</Typography>
                </CustomMenuItem>
                <CustomMenuItem value={'2'}>
                  <Typography variant={'subtitle2'}>Sediment Transport Rates</Typography>
                </CustomMenuItem>
              </CustomSelect>
            </LabelWrapper>
          </Box>
          <Box mr={2}>
            <LabelWrapper label={'Frame'}>
              <CustomInput
                maxWidth={'57px'}
                required
                min={1}
                step={1}
                errorText={'Enter positive integer'}
                type={'number'}
                value={inputState.frame}
                name={'frame'}
                onChange={onInputChange}
              />
            </LabelWrapper>
          </Box>
        </Box>
      </Box>
      {inputState.fileType == 1 ? (
        <EviBedLevelInputs inputState={inputState} setInputState={setInputState} projectId={inputState.project} />
      ) : (
        <EviSedimentInputs inputState={inputState} setInputState={setInputState} projectId={inputState.project} />
      )}
    </>
  );
};

export default memo(EviNumericalInputsGroup);
