import React, { FC } from 'react';
import { Slider, SliderProps, SliderThumb, styled, Theme, Typography } from '@mui/material';
import { Box } from '@mui/system';

const FrameSlider = styled((props: SliderProps) => <Slider {...props} />)(({ theme }) => ({
  color: theme.palette.primary.main,
  height: 8,
  '&.Mui-disabled': {
    color: theme.palette.primary.main,
    opacity: 0.5,
  },
  '& .MuiSlider-rail': {
    color: theme.palette.info.main,
  },
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-thumb': {
    height: 0,
    width: 0,
  },
}));

const typoStyle = {
  borderRadius: '4px',
  color: 'white',
  padding: (theme: Theme) => theme.spacing(0.1, 2, 0),
  background: (theme: Theme) => theme.palette.primary.dark,
};

const MiniComponent: FC = (props: any) => {
  const { children, ...other } = props;
  return (
    <SliderThumb {...other}>
      {children}
      <Typography variant={'subtitle1'} sx={typoStyle}>
        {children.props.value}
      </Typography>
    </SliderThumb>
  );
};

const CustomFrameSlider: FC<SliderProps> = ({ ...props }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Typography variant={'subtitle2'} mr={1}>
        {props.min}
      </Typography>
      <FrameSlider components={{ Thumb: MiniComponent }} {...props} />
      <Typography variant={'subtitle2'} ml={1}>
        {props.max}
      </Typography>
    </Box>
  );
};

export default CustomFrameSlider;
