import { AnyAction, configureStore, ThunkAction } from '@reduxjs/toolkit';
import appSlice from './slices/appSlice';
import authenticationSlice from './slices/authenticationSlice';
import { customApi } from './RTK/Api';
import { authenticatedMiddleware } from './middleware/authenticatedMiddleware';
import filesReducer from './slices/editFileSlice';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import modalSlice from './slices/modalSlice';

const store = configureStore({
  reducer: {
    editFile: filesReducer,
    app: appSlice,
    authentication: authenticationSlice,
    modal: modalSlice,
    [customApi.reducerPath]: customApi.reducer,
  },
  devTools: process.env.NODE_ENV === 'development',
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat([authenticatedMiddleware, customApi.middleware]),
});

export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, AnyAction>;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
