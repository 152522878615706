export const codeListPreparation = (values: any[], newValue: number) => {
  const tempArray = values;
  const tempLength = values.length;
  if (tempLength !== newValue) {
    if (newValue > tempLength) {
      for (let i = tempLength; i < newValue; i++) {
        tempArray.push({ code: 10, base: 1, layers: 4 });
      }
    } else {
      for (let i = 0; i < tempLength - newValue; i++) {
        tempArray.pop();
      }
    }
  }
  return tempArray;
};
