import React, { FC } from 'react';
import ButtonFileDialog from '../ButtonFileDialog';
import { FileType } from '../../../models/inputTypes/FileType';
import { openErrorToast } from '../../../redux/slices/appSlice';
import { useAppDispatch } from '../../../redux/store';
import { useHistory, useParams } from 'react-router';
import {
  useChainProjectFileMutation,
  useGetProjectFileCustomMutation,
} from '../../../redux/RTK/mutations/projectFileMutations';
import { ModelEnum } from '../../../models/types/ModelEnum';
import switchFilePreparation from '../../../utils/functions/switchFilePreparation';

export type SimulationContainerSetter = { loadedSimulation?: any; file?: FileType };

type SimulationFileContainerProps = {
  file?: FileType;
  project: string;
  setSimulationInputs: (values: SimulationContainerSetter) => void;
  modelType: ModelEnum;
  redirectLocation: string;
  typePage: 'BTG' | 'SRF' | 'EVI' | 'PMS' | 'HMS' | 'HYD' | 'SDT' | 'PLOT' | 'SHORELINES' | 'ALL';
};

const SimulationFileContainer: FC<SimulationFileContainerProps> = ({
  redirectLocation,
  modelType,
  setSimulationInputs,
  file,
  project,
  typePage,
}) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { name, fileId } = useParams<{ id?: string; name?: string; fileId?: string }>();
  const [getFileData] = useGetProjectFileCustomMutation();
  const [chainProjectFile] = useChainProjectFileMutation();

  const onSimulationFileChange = async (file?: FileType) => {
    const fileName = file?.currentFile?.name;
    const project = file?.currentFile?.project;

    if (name && fileId) {
      history.push(redirectLocation);
    }

    if (fileName && project) {
      try {
        const resMutation: any = await chainProjectFile({
          projectId: project.toString(),
          fileName: fileName,
          file: file,
        });
        if (resMutation.data.status === 'ok') {
          const res: any = await getFileData({ projectId: project.toString(), fileName: fileName });
          if (res.data.simulation === modelType) {
            const loadedSimulation = switchFilePreparation(res.data, modelType);
            if (loadedSimulation) {
              setSimulationInputs({ loadedSimulation, file });
              return;
            }
          }
        }
        setSimulationInputs({ loadedSimulation: undefined, file: undefined });
        dispatch(
          openErrorToast(
            `Simulation file not valid. You have not loaded a correct design tool (${modelType?.toUpperCase()})`,
          ),
        );
      } catch (e) {
        dispatch(openErrorToast(`Error loading simulation file!`));
      }
    }
  };

  return (
    <ButtonFileDialog
      typePage={typePage}
      simFileOnly
      projectId={project}
      disabled={!project}
      file={file}
      setFile={onSimulationFileChange}
    />
  );
};

export default SimulationFileContainer;
