import { HydBoundaries } from '../../models/inputTypes/HydFields';
import { hydBoundaryObjectInitialState } from '../initialStates/hydInputState';

export const boundaryArrayPreparation = (boundaries: HydBoundaries[], newValue: number) => {
  const tempArray = boundaries;
  const tempLength = boundaries.length;
  if (tempLength !== newValue) {
    if (newValue > tempLength) {
      for (let i = tempLength; i < newValue; i++) {
        tempArray.push(hydBoundaryObjectInitialState);
      }
    } else {
      for (let i = 0; i < tempLength - newValue; i++) {
        tempArray.pop();
      }
    }
  }
  return tempArray;
};
