import React, { FC } from 'react';
import CustomInput, { CustomInputProps } from '../custom/CustomInput';
import { Box, styled, Typography } from '@mui/material';

type CustomInputWithLabelProps = {
  label?: string;
};

const CustomBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  '&>:first-child': {
    marginRight: theme.spacing(1),
  },
}));

const CustomInputWithLabel: FC<
  React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> &
    CustomInputWithLabelProps &
    CustomInputProps
> = ({ label, maxWidth = '48px', ...props }) => {
  return (
    <CustomBox>
      <Typography variant={'subtitle1'}>{label}</Typography>
      <CustomInput maxWidth={maxWidth} {...props} />
    </CustomBox>
  );
};

export default CustomInputWithLabel;
