import React, { FC, memo, useMemo } from 'react';
import { Box, SelectChangeEvent } from '@mui/material';
import LabelWrapper from '../../common/LabelWrapper';
import CustomInput from '../../custom/CustomInput';
import { ShorelineOutput } from '../../../models/inputTypes/ShorelineFields';

type InputsProps = {
  inputState: ShorelineOutput;
  setInputState: (value: ShorelineOutput) => void;
};
const ShorelineOutputGroup: FC<InputsProps> = ({ inputState, setInputState }) => {
  const onInputChange = (
    event: SelectChangeEvent<unknown> | React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setInputState({ ...inputState, [event.target.name]: event.target.value });
  };

  return (
    <Box>
      <LabelWrapper label={'Enter Output filename'}>
        <CustomInput
          type={'text'}
          required
          maxWidth={'200px'}
          placeholder={'Enter name'}
          value={inputState.outputFileName}
          name={'outputFileName'}
          onChange={onInputChange}
        />
      </LabelWrapper>
    </Box>
  );
};

export default memo(ShorelineOutputGroup);
