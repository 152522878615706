import { HydPlotLine } from '../../models/inputTypes/HydFields';
import { hydPlotLinesInitialState } from '../initialStates/hydInputState';

export const hydPlotLineArrayPreparation = (
  plotLineValues: HydPlotLine[],
  newValue: number,
  customInitial?: HydPlotLine,
) => {
  const plotLineStartState = customInitial ?? hydPlotLinesInitialState;
  const tempArray = plotLineValues;
  const tempLength = plotLineValues.length;
  if (tempLength !== newValue) {
    if (newValue > tempLength) {
      for (let i = tempLength; i < newValue; i++) {
        tempArray.push({
          ...plotLineStartState,
          name_z: plotLineStartState.name_z + `_${i + 1}`,
          name_u: plotLineStartState.name_u + `_${i + 1}`,
          name_v: plotLineStartState.name_v + `_${i + 1}`,
          name_speed: plotLineStartState.name_speed + `_${i + 1}`,
          name_direction: plotLineStartState.name_direction + `_${i + 1}`,
          name_depth: plotLineStartState.name_depth + `_${i + 1}`,
        });
      }
    } else {
      for (let i = 0; i < tempLength - newValue; i++) {
        tempArray.pop();
      }
    }
  }
  return tempArray;
};
