import { PlotArea, PlotLine } from '../../../models/inputTypes/PmsFields';
import { OutputFile } from '../../../models/jobModels/ModelsFileTypes';
import {
  plotAreasInitialState,
  plotLinesInitialState,
  pmsPlotAreasAliases,
  pmsPlotLinesAliases,
} from '../../initialStates/pmsInputStates';

export const pmsOutputAreaFilePreparation = (plotAreas: PlotArea[], projectId: string) => {
  const areaOutput: OutputFile[] = [];
  plotAreas.forEach((area, i) => {
    if (area.h) {
      areaOutput.push({
        alias: pmsPlotAreasAliases.name_h + `_${i + 1}.csv`,
        project: projectId.toString(),
        name: (area.name_h || plotAreasInitialState.name_h + `_${i + 1}`) + '.csv',
      });
    }
    if (area.c) {
      areaOutput.push({
        alias: pmsPlotAreasAliases.name_c + `_${i + 1}.csv`,
        project: projectId.toString(),
        name: (area.name_c || plotAreasInitialState.name_c + `_${i + 1}`) + '.csv',
      });
    }
    if (area.z) {
      areaOutput.push({
        alias: pmsPlotAreasAliases.name_z + `_${i + 1}.csv`,
        project: projectId.toString(),
        name: (area.name_z || plotAreasInitialState.name_z + `_${i + 1}`) + '.csv',
      });
    }
    if (area.dir) {
      areaOutput.push({
        alias: pmsPlotAreasAliases.name_dir + `_${i + 1}.csv`,
        project: projectId.toString(),
        name: (area.name_dir || plotAreasInitialState.name_dir + `_${i + 1}`) + '.csv',
      });
    }
    if (area.rad) {
      areaOutput.push({
        alias: pmsPlotAreasAliases.name_rad_xx + `_${i + 1}.csv`,
        project: projectId.toString(),
        name: (area.name_rad_xx || plotAreasInitialState.name_rad_xx + `_${i + 1}`) + '.csv',
      });
      areaOutput.push({
        alias: pmsPlotAreasAliases.name_rad_xy + `_${i + 1}.csv`,
        project: projectId.toString(),
        name: (area.name_rad_xy || plotAreasInitialState.name_rad_xy + `_${i + 1}`) + '.csv',
      });
      areaOutput.push({
        alias: pmsPlotAreasAliases.name_rad_yy + `_${i + 1}.csv`,
        project: projectId.toString(),
        name: (area.name_rad_yy || plotAreasInitialState.name_rad_yy + `_${i + 1}`) + '.csv',
      });
    }
    if (area.init_depths) {
      areaOutput.push({
        alias: pmsPlotAreasAliases.name_init_depths + `_${i + 1}.csv`,
        project: projectId.toString(),
        name: (area.name_init_depths || plotAreasInitialState.name_init_depths + `_${i + 1}`) + '.csv',
      });
    }
  });
  return areaOutput;
};

export const pmsOutputLineFilePreparation = (plotLines: PlotLine[], projectId: string) => {
  const lineOutput: OutputFile[] = [];
  plotLines.forEach((line, i) => {
    if (line.h)
      lineOutput.push({
        alias: pmsPlotLinesAliases.name_h + `_${i + 1}.csv`,
        project: projectId.toString(),
        name: (line.name_h || plotLinesInitialState.name_h + `_${i + 1}`) + '.csv',
      });
    if (line.c)
      lineOutput.push({
        alias: pmsPlotLinesAliases.name_c + `_${i + 1}.csv`,
        project: projectId.toString(),
        name: (line.name_c || plotLinesInitialState.name_c + `_${i + 1}`) + '.csv',
      });
    if (line.z)
      lineOutput.push({
        alias: pmsPlotLinesAliases.name_z + `_${i + 1}.csv`,
        project: projectId.toString(),
        name: (line.name_z || plotLinesInitialState.name_z + `_${i + 1}`) + '.csv',
      });
    if (line.dir)
      lineOutput.push({
        alias: pmsPlotLinesAliases.name_dir + `_${i + 1}.csv`,
        project: projectId.toString(),
        name: (line.name_dir || plotLinesInitialState.name_dir + `_${i + 1}`) + '.csv',
      });
  });
  return lineOutput;
};
