import { useGetProjectFileCustomMutation } from '../../redux/RTK/mutations/projectFileMutations';
import { useParams } from 'react-router';
import { openErrorToast } from '../../redux/slices/appSlice';
import { useEffect, useState } from 'react';
import { useAppDispatch } from '../../redux/store';
import { FileType } from '../../models/inputTypes/FileType';
import switchFilePreparation from '../functions/switchFilePreparation';
import { ModelEnum } from '../../models/types/ModelEnum';

const useSimulationPreparation: (simulationType: ModelEnum) => {
  preparedData: any;
  error: boolean;
  currentFile: FileType;
} = (simulationType) => {
  const dispatch = useAppDispatch();
  const { id, name, fileId } = useParams<{ id?: string; name?: string; fileId?: string }>();

  const [getFileData] = useGetProjectFileCustomMutation();
  const [error, setError] = useState(false);
  const [preparedData, setPreparedData] = useState<any>(undefined);
  const [currentFile, setCurrentFile] = useState<any>(undefined);

  const asyncFetchJsonFile = async () => {
    if (id && name && fileId) {
      try {
        const res: any = await getFileData({ projectId: id.toString(), fileName: name });
        if (res.data.simulation === simulationType) {
          const newStateObject = switchFilePreparation(res.data, simulationType);
          setPreparedData(newStateObject);
          setCurrentFile({
            currentFile: {
              name: name,
              uuid: fileId,
            },
            fileName: name,
          });
        } else {
          throw 'error';
        }
      } catch (e) {
        setError(true);
        if (e === 'error')
          dispatch(
            openErrorToast(
              `Simulation file not valid. You have not loaded a correct design tool (${simulationType.toUpperCase()})`,
            ),
          );
        else dispatch(openErrorToast('Simulation file not valid.'));
      }
    }
  };

  useEffect(() => {
    setPreparedData(undefined);
    setError(false);
    asyncFetchJsonFile();
  }, [id, name, fileId]);

  return {
    error,
    preparedData,
    currentFile,
  };
};

export default useSimulationPreparation;
