import React, { FC, memo, useState, useMemo } from 'react';
import { Box, Grid, Typography, SelectChangeEvent } from '@mui/material';
import CustomInput from '../../custom/CustomInput';
import CustomInputWithLabel from '../../common/CustomInputWithLabel';
import LabelWrapper from '../../common/LabelWrapper';
import CustomSelect from '../../custom/CustomSelect';
import CustomMenuItem from '../../custom/CustomMenuItem';
import BoxComponent from '../../custom/BoxComponent';
import { ShorelinePlotProperties } from '../../../models/inputTypes/ShorelineFields';
import CustomSwitch from '../../custom/CustomSwitch';
import { FileType } from '../../../models/inputTypes/FileType';
import ButtonFileDialog from '../../common/ButtonFileDialog';
import { PlotObject } from '../../../models/inputTypes/ShorelineFields';
import { PlotInitialObject } from '../../../utils/initialStates/shorelineInputState';

const styles = {
  childSpacing: {
    '& >:nth-child(n)': {
      my: 1,
      mr: { xs: 2, md: 3, xl: 4 },
    },
  },
  flexStartContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
  },
  childMargin: {
    '& >:nth-child(n)': {
      my: 1,
      mr: 4,
    },
  },
  coordinates: {
    '& >:nth-child(2)': {
      ml: 1,
    },
  },
  flexStartBox: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
  },
} as const;

type InputsProps = {
  projectId: string;
  inputState: ShorelinePlotProperties;
  setInputState: (value: ShorelinePlotProperties) => void;
};

const ShorelinePlotPropertiesInputsGroup: FC<InputsProps> = ({ projectId, inputState, setInputState }) => {
  const [valueX, setValueX] = useState(1);
  const [valueY, setValueY] = useState(1);
  const colors = ['k', 'b', 'g', 'r', 'y'];

  const onInputChange = (
    event: SelectChangeEvent<unknown> | React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    const newValue = event.target.value as any;
    if (event.target.name === 'pltObjNum') {
      const refCoast: PlotObject[] = [];
      for (let i = 0; i < newValue; i++) {
        if (inputState.refCoast[i]) {
          refCoast.push(inputState.refCoast[i]);
        } else {
          refCoast.push({ ...PlotInitialObject, color: colors[i] });
        }
      }
      setInputState({ ...inputState, [event.target.name]: newValue, refCoast: refCoast });
      return;
    }
    setInputState({ ...inputState, [event.target.name]: event.target.value });
  };

  const onNumberChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (event.target.name == 'neX') {
      setValueX(+event.target.value);
      setInputState({ ...inputState, [event.target.name]: +event.target.value });
      return;
    }
    if (event.target.name == 'neY') {
      setValueY(+event.target.value);
      setInputState({ ...inputState, [event.target.name]: +event.target.value });
      return;
    }
    if (event.target.name == 'plotX' || event.target.name == 'plotY' || event.target.name == 'scale') {
      setInputState({
        ...inputState,
        [event.target.name]: event.target.value === '' ? undefined : +event.target.value,
      });
      return;
    }
    setInputState({ ...inputState, [event.target.name]: event.target.value === '' ? undefined : +event.target.value });
  };

  const switchChange = (e: any) => {
    setInputState({ ...inputState, [e.target.name]: e.target.checked });
  };

  const onDynamicFileChange = (file: FileType | undefined, fieldName: string, index: number) => {
    const newCodeArray = inputState.refCoast.slice();
    newCodeArray[index] = { ...newCodeArray[index], [fieldName]: file };
    setInputState({ ...inputState, refCoast: newCodeArray });
  };

  const onDynamicInputChange = (
    index: number,
    e: SelectChangeEvent<unknown> | React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    const newCodeArray = inputState.refCoast.slice();
    newCodeArray[index] = { ...newCodeArray[index], [e.target.name]: e.target.value };
    setInputState({ ...inputState, refCoast: newCodeArray });
  };

  const plotProp = useMemo(() => {
    return inputState.refCoast.map((item, i) => (
      <>
        <BoxComponent sx={{ ...styles.flexStartBox, ...styles.childSpacing }}>
          <Box>
            <LabelWrapper label={'Insert coastline file'}>
              <ButtonFileDialog
                typePage={'SHORELINES'}
                txtFilesOnly
                required
                projectId={projectId}
                disabled={!projectId}
                file={item.file}
                setFile={(file) => onDynamicFileChange(file, 'file', i)}
              />
            </LabelWrapper>
          </Box>
          <Box>
            <LabelWrapper label={'Label'}>
              <CustomInput
                required
                type={'text'}
                value={item.label}
                placeholder={`Label ${i + 1}`}
                onChange={(e) => onDynamicInputChange(i, e)}
                name={'label'}
              />
            </LabelWrapper>
          </Box>
          <Box>
            <LabelWrapper label={'Color'}>
              <CustomSelect
                value={item.color}
                onChange={(e) => onDynamicInputChange(i, e)}
                name={'color'}
                // minWidth={'100px'}s
                defaultValue={'k'}
                displayEmpty
              >
                <CustomMenuItem value={'k'}>
                  <Typography variant={'subtitle2'}>black</Typography>
                </CustomMenuItem>
                <CustomMenuItem value={'b'}>
                  <Typography variant={'subtitle2'}>blue</Typography>
                </CustomMenuItem>
                <CustomMenuItem value={'g'}>
                  <Typography variant={'subtitle2'}>green</Typography>
                </CustomMenuItem>
                <CustomMenuItem value={'r'}>
                  <Typography variant={'subtitle2'}>red</Typography>
                </CustomMenuItem>
                <CustomMenuItem value={'y'}>
                  <Typography variant={'subtitle2'}>yellow</Typography>
                </CustomMenuItem>
              </CustomSelect>
            </LabelWrapper>
          </Box>
        </BoxComponent>
      </>
    ));
  }, [inputState.refCoast, projectId]);

  const required = useMemo(() => {
    return [inputState.plotX, inputState.plotY, inputState.scale].some((value) => value !== undefined);
  }, [inputState.plotX, inputState.plotY, inputState.scale]);

  const warning = useMemo(
    () => ({
      plotX: inputState.plotX === undefined && !required ? 'Leave empty for auto-calculation' : undefined,
      plotY: inputState.plotY === undefined && !required ? 'Leave empty for auto-calculation' : undefined,
      scale: inputState.scale === undefined && !required ? 'Leave empty for auto-calculation' : undefined,
    }),
    [inputState.plotX, inputState.plotY, inputState.scale, required],
  );

  return (
    <>
      <Box>
        <LabelWrapper label={'Fill coastline'}>
          <Grid container justifyContent={'center'} width={'100px'}>
            <CustomSwitch checked={inputState.usefill} name={'usefill'} onChange={switchChange} />
          </Grid>
        </LabelWrapper>
      </Box>
      <Box my={3} sx={{ ...styles.flexStartBox, ...styles.childSpacing }}>
        <Box>
          <LabelWrapper label={'Plot wave quiver'}>
            <Grid container justifyContent={'center'} width={'100px'}>
              <CustomSwitch checked={inputState.plotwavequiver} name={'plotwavequiver'} onChange={switchChange} />
            </Grid>
          </LabelWrapper>
        </Box>
        <BoxComponent
          disabled={!inputState.plotwavequiver}
          my={3}
          sx={{ ...styles.flexStartBox, ...styles.childSpacing }}
        >
          <Box>
            <LabelWrapper label={'X'}>
              <CustomInput
                required={required}
                maxWidth={'80px'}
                errorText={required ? 'All fields required' : 'Enter positive integer'}
                warningText={warning.plotX}
                type={'number'}
                onChange={onNumberChange}
                value={inputState.plotX}
                name={'plotX'}
              />
            </LabelWrapper>
          </Box>
          <Box>
            <LabelWrapper label={'Y'}>
              <CustomInput
                required={required}
                maxWidth={'80px'}
                errorText={required ? 'All fields required' : 'Enter positive integer'}
                warningText={warning.plotY}
                type={'number'}
                onChange={onNumberChange}
                value={inputState.plotY}
                name={'plotY'}
              />
            </LabelWrapper>
          </Box>
          <Box>
            <LabelWrapper label={'scale'}>
              <CustomInput
                required={required}
                maxWidth={'80px'}
                step={'any'}
                errorText={required ? 'All fields required' : 'Enter positive integer'}
                warningText={warning.scale}
                type={'number'}
                onChange={onNumberChange}
                value={inputState.scale}
                name={'scale'}
              />
            </LabelWrapper>
          </Box>
        </BoxComponent>
      </Box>
      <Typography my={1} variant={'subtitle1'}>
        Plot Area Coordinates
      </Typography>
      <BoxComponent sx={{ ...styles.flexStartContainer, ...styles.childSpacing }}>
        <Box>
          <LabelWrapper label={'Southwestern point'}>
            <Box sx={{ ...styles.flexStartContainer, ...styles.coordinates }}>
              <CustomInputWithLabel
                step={'any'}
                maxWidth={'100px'}
                errorText={'Enter number'}
                type={'number'}
                value={inputState.swX}
                onChange={onNumberChange}
                name={'swX'}
                label={'X'}
              />
              <CustomInputWithLabel
                step={'any'}
                maxWidth={'100px'}
                errorText={'Enter number'}
                type={'number'}
                value={inputState.swY}
                onChange={onNumberChange}
                name={'swY'}
                label={'Y'}
              />
            </Box>
          </LabelWrapper>
        </Box>
        <Box ml={4}>
          <LabelWrapper label={'Northeastern point'}>
            <Box sx={{ ...styles.flexStartContainer, ...styles.coordinates }}>
              <CustomInputWithLabel
                min={inputState.swX}
                step={'any'}
                maxWidth={'100px'}
                errorText={inputState.swX > valueX ? 'Number should be >= than first point' : 'Enter numer'}
                type={'number'}
                value={inputState.neX}
                onChange={onNumberChange}
                name={'neX'}
                label={'X'}
              />
              <CustomInputWithLabel
                min={inputState.swY}
                step={'any'}
                maxWidth={'100px'}
                errorText={inputState.swY > valueY ? 'Number should be >= than first point' : 'Enter number'}
                type={'number'}
                value={inputState.neY}
                onChange={onNumberChange}
                name={'neY'}
                label={'Y'}
              />
            </Box>
          </LabelWrapper>
        </Box>
      </BoxComponent>
      <Typography my={3} variant={'subtitle1'}>
        Time Parameters
      </Typography>
      <Box>
        <LabelWrapper label={'Figures per year'}>
          <CustomInput
            required
            maxWidth={'80px'}
            errorText={'Enter positive integer'}
            type={'number'}
            onChange={onNumberChange}
            value={inputState.fignryear}
            name={'fignryear'}
          />
        </LabelWrapper>
      </Box>
      <Typography my={3} variant={'subtitle1'}>
        Reference Coastlines
      </Typography>
      <Box>
        <LabelWrapper label={'Select reference coastlines'}>
          <CustomSelect
            value={inputState.pltObjNum}
            onChange={onInputChange}
            name={'pltObjNum'}
            minWidth={'100px'}
            defaultValue={0}
            displayEmpty
          >
            <CustomMenuItem value={0}>
              <Typography variant={'subtitle2'}>{0}</Typography>
            </CustomMenuItem>
            <CustomMenuItem value={1}>
              <Typography variant={'subtitle2'}>{1}</Typography>
            </CustomMenuItem>
            <CustomMenuItem value={2}>
              <Typography variant={'subtitle2'}>{2}</Typography>
            </CustomMenuItem>
            <CustomMenuItem value={3}>
              <Typography variant={'subtitle2'}>{3}</Typography>
            </CustomMenuItem>
            <CustomMenuItem value={4}>
              <Typography variant={'subtitle2'}>{4}</Typography>
            </CustomMenuItem>
            <CustomMenuItem value={5}>
              <Typography variant={'subtitle2'}>{5}</Typography>
            </CustomMenuItem>
          </CustomSelect>
        </LabelWrapper>
        {plotProp}
        {inputState.pltObjNum != 0 && (
          <Box>
            <LabelWrapper label={'Label position'}>
              <CustomSelect
                required
                value={inputState.llocation}
                name={'llocation'}
                onChange={onInputChange}
                minWidth={'140px'}
                defaultValue={'SouthWest'}
                displayEmpty
              >
                <CustomMenuItem value={'NorthEast'}>
                  <Typography variant={'subtitle2'}>Top Right</Typography>
                </CustomMenuItem>
                <CustomMenuItem value={'SouthEast'}>
                  <Typography variant={'subtitle2'}>Bottom Right</Typography>
                </CustomMenuItem>
                <CustomMenuItem value={'SouthWest'}>
                  <Typography variant={'subtitle2'}>Bottom Left</Typography>
                </CustomMenuItem>
                <CustomMenuItem value={'NorthWest'}>
                  <Typography variant={'subtitle2'}>Top Left</Typography>
                </CustomMenuItem>
              </CustomSelect>
            </LabelWrapper>
          </Box>
        )}
      </Box>
      <Box mt={3}>
        <LabelWrapper label={'Generate pdf plot'}>
          <Grid container justifyContent={'center'} width={'100px'}>
            <CustomSwitch checked={inputState.pdf} name={'pdf'} onChange={switchChange} />
          </Grid>
        </LabelWrapper>
      </Box>
    </>
  );
};

export default memo(ShorelinePlotPropertiesInputsGroup);
