import CustomTab from '../../components/custom/CustomTab';
import { Typography } from '@mui/material';
import React from 'react';

export const plotTabPreparation = (plot: number, id = 'Plot Area') => {
  const value = [];
  const title = id === 'area' ? 'Plot Area' : 'Plot Line';
  for (let i = 0; i < plot; i++) {
    value.push(
      <CustomTab
        id={id}
        value={i}
        label={
          <Typography variant={'subtitle1'}>
            {title} {i + 1}
          </Typography>
        }
      />,
    );
  }
  return value;
};
