import React, { FC } from 'react';
import { Radio, RadioProps } from '@mui/material';
import { ReactComponent as CheckedIcon } from '../../assets/images/checkedRadio.svg';
import { ReactComponent as UncheckedIcon } from '../../assets/images/uncheckedRadio.svg';

const CustomRadio: FC<RadioProps> = ({ ...props }) => {
  return <Radio disableRipple color="default" checkedIcon={<CheckedIcon />} icon={<UncheckedIcon />} {...props} />;
};

export default CustomRadio;
