import { HmsPlotLine } from '../../models/inputTypes/HmsFields';
import { hmsPlotLinesInitialState } from '../initialStates/hmsInputState';

export const hmsPlotLineArrayPreparation = (
  plotLineValues: HmsPlotLine[],
  newValue: number,
  customInitial?: HmsPlotLine,
) => {
  const plotLineStartState = customInitial ?? hmsPlotLinesInitialState;

  const tempArray = plotLineValues;
  const tempLength = plotLineValues.length;
  if (tempLength !== newValue) {
    if (newValue > tempLength) {
      for (let i = tempLength; i < newValue; i++) {
        tempArray.push({
          ...plotLineStartState,
          name_h: plotLineStartState.name_h + `_${i + 1}`,
          name_z: plotLineStartState.name_z + `_${i + 1}`,
        });
      }
    } else {
      for (let i = 0; i < tempLength - newValue; i++) {
        tempArray.pop();
      }
    }
  }
  return tempArray;
};
