import React, { ChangeEvent, FC, memo, useState } from 'react';
import CustomInput from '../../custom/CustomInput';
import { Box, Grid, Typography } from '@mui/material';
import CustomImageButton from '../../custom/CustomImageButton';
import { useProjectMutation } from '../../../redux/RTK/mutations/projectMutations';

const styles = {
  root: { display: 'flex', columnGap: '32px' },
} as const;

const CreateProject: FC = () => {
  const [project, { isLoading }] = useProjectMutation();
  const [projectName, setProjectName] = useState('');

  const handleCreateClick = () => {
    project({ name: projectName });
  };

  const onInputChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setProjectName(e.target.value);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography my={1} variant="subtitle2">
          Enter project&apos;s name
        </Typography>
      </Grid>
      <Box sx={styles.root}>
        <CustomInput
          maxWidth="132px"
          placeholder="Enter name"
          name={'name'}
          value={projectName}
          onChange={onInputChange}
        />
        <CustomImageButton
          variant={'contained'}
          color={'success'}
          loading={isLoading}
          onClick={handleCreateClick}
          disabled={!projectName || isLoading}
        >
          <Typography variant={'subtitle2'} color={'white'}>
            Create
          </Typography>
        </CustomImageButton>
      </Box>
    </Grid>
  );
};
export default memo(CreateProject);
