import React, { FC, memo, useMemo } from 'react';
import { Box, Fade, Grid, SelectChangeEvent, Typography } from '@mui/material';
import CustomInput from '../../custom/CustomInput';
import CustomSelect from '../../custom/CustomSelect';
import CustomMenuItem from '../../custom/CustomMenuItem';
import LabelWrapper from '../../common/LabelWrapper';
import { SrfReflection } from '../../../models/inputTypes/SrfFields';
import { codeListPreparation } from '../../../utils/functions/codeListPreparation';
import RefEddyCalculator from './RefEddyCalculator';

const styles = {
  spacingContainer: {
    flexWrap: 'wrap',
    display: 'flex',
    alignItems: 'flex-end',
  },
  childMarginBig: {
    '& >:nth-child(n)': {
      width: '194px',
      my: 1,
      mr: { xs: 3, lg: 4 },
    },
  },
  childMarginBigSecond: {
    '& >:nth-child(n)': {
      width: '194px',
      my: 1,
      mr: { xs: 4, lg: 4 },
    },
  },
} as const;

type SrfRefInputsGroupProps = {
  inputState: SrfReflection;
  setInputState: (value: SrfReflection) => void;
};

const SrfRefInputsGroup: FC<SrfRefInputsGroupProps> = ({ inputState, setInputState }) => {
  const onInputChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | SelectChangeEvent<unknown>,
  ) => {
    setInputState({ ...inputState, [event.target.name]: event.target.value });
  };

  const onSelectChange = (event: SelectChangeEvent<any>) => {
    const codeLength = parseInt(event.target.value);
    const newCodes = codeListPreparation(inputState.codes_list.slice(), codeLength);
    setInputState({ ...inputState, codes_list: newCodes, landCodeValues: event.target.value });
  };

  const onDynamicInputChange = (index: number, e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const newCodeArray = inputState.codes_list.slice();
    newCodeArray[index] = { ...newCodeArray[index], [e.target.name]: e.target.value };
    setInputState({ ...inputState, codes_list: newCodeArray });
  };

  const prepareSelectItems = () => {
    return [2, 3, 4, 5, 6, 7, 8, 9, 10].map((item, i) => (
      <CustomMenuItem key={i} value={item}>
        <Typography variant={'subtitle2'}>{item}</Typography>
      </CustomMenuItem>
    ));
  };

  const landInputs = useMemo(() => {
    const inputs = [];
    for (let i = 0; i < inputState.landCodeValues; i++) {
      inputs.push(
        <Box mt={1} sx={{ ...styles.childMarginBigSecond, ...styles.spacingContainer }}>
          <Box>
            <LabelWrapper label={'Land code value'}>
              <CustomInput
                required
                errorText={'Enter number'}
                type={'number'}
                step={'1'}
                onChange={(e) => onDynamicInputChange(i, e)}
                value={inputState.codes_list[i].code}
                name={'code'}
              />
            </LabelWrapper>
          </Box>
          <Box>
            <LabelWrapper label={'Coefficient value'}>
              <CustomInput
                required
                step={'any'}
                errorText={'Enter number'}
                type={'number'}
                onChange={(e) => onDynamicInputChange(i, e)}
                value={inputState.codes_list[i].base}
                name={'base'}
              />
            </LabelWrapper>
          </Box>
          <Box>
            <LabelWrapper label={'Number of layers'}>
              <CustomInput
                required
                errorText={'Enter positive integer'}
                type={'number'}
                step={'1'}
                min={0}
                onChange={(e) => onDynamicInputChange(i, e)}
                value={inputState.codes_list[i].layers}
                name={'layers'}
              />
            </LabelWrapper>
          </Box>
        </Box>,
      );
    }
    return inputs;
  }, [inputState.landCodeValues, onDynamicInputChange]);

  return (
    <Fade in={true}>
      <Box>
        <Grid container>
          <Grid item xs={12} md={5} lg={7}>
            <Box sx={{ ...styles.childMarginBig, ...styles.spacingContainer }}>
              <Box>
                <LabelWrapper label={'Name Eddy/FrictionMap'}>
                  <CustomInput
                    maxLength={30}
                    errorText={'Please enter valid text'}
                    required
                    onChange={onInputChange}
                    value={inputState.reflectionMap}
                    name={'reflectionMap'}
                  />
                </LabelWrapper>
              </Box>
              <Box>
                <LabelWrapper label={'Background value'}>
                  <CustomInput
                    required
                    errorText={'Enter positive number'}
                    min={0}
                    step={'any'}
                    type={'number'}
                    onChange={onInputChange}
                    value={inputState.backgroundValue}
                    name={'backgroundValue'}
                  />
                </LabelWrapper>
              </Box>
              <Box>
                <LabelWrapper label={'Number of land code values'}>
                  <CustomSelect
                    fullWidth
                    displayEmpty
                    defaultValue={''}
                    onChange={onSelectChange}
                    value={inputState.landCodeValues}
                    name={'landCodeValues'}
                  >
                    <CustomMenuItem value={1}>
                      <Typography variant={'subtitle2'}>1</Typography>
                    </CustomMenuItem>
                    {prepareSelectItems()}
                  </CustomSelect>
                </LabelWrapper>
              </Box>
            </Box>
            {landInputs}
          </Grid>
          <RefEddyCalculator />
        </Grid>
      </Box>
    </Fade>
  );
};

export default memo(SrfRefInputsGroup);
