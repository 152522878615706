import { SdtPlotLine } from '../../models/inputTypes/SdtFields';
import { sdtPlotLineInitialState } from '../initialStates/sdtInputState';

export const sdtPlotLineArrayPreparation = (
  plotLineValues: SdtPlotLine[],
  newValue: number,
  customInitialState?: SdtPlotLine,
) => {
  const plotLineStartState = customInitialState ?? sdtPlotLineInitialState;
  const tempArray = plotLineValues;
  const tempLength = plotLineValues.length;
  if (tempLength !== newValue) {
    if (newValue > tempLength) {
      for (let i = tempLength; i < newValue; i++) {
        tempArray.push({
          ...plotLineStartState,
          name_bl: plotLineStartState.name_bl + `_${i + 1}`,
          name_dhdt: plotLineStartState.name_dhdt + `_${i + 1}`,
        });
      }
    } else {
      for (let i = 0; i < tempLength - newValue; i++) {
        tempArray.pop();
      }
    }
  }
  return tempArray;
};
