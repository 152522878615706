import React, { FC, memo } from 'react';
import {
  Box,
  Typography,
  Theme,
  Checkbox,
  FormControlLabel,
  RadioGroup,
  InputAdornment,
  IconButton,
} from '@mui/material';
import CustomButton from '../../custom/CustomButton';
import CustomInput from '../../custom/CustomInput';
import BoxInputContainer from '../../custom/BoxInputContainer';
import LabelWrapper from '../../common/LabelWrapper';

const styles = {
  inputGrid: { display: 'grid', alignItems: 'center', gap: '12px 24px' },
  boxInputs: {
    padding: (theme: Theme) => theme.spacing(1.5, 4, 1.5, 2),
    background: '#DFE1E6',
  },
  buttonPosition: {
    display: 'flex',
    margin: 'auto',
    marginTop: 3,
    background: (theme: Theme) => theme.palette.primary.dark,
  },
  valuesPosition: {
    display: 'flex',
    marginTop: 1,
    marginBottom: 3,
    gridTemplateColumns: '1.5fr 1fr',
    gap: '12px 12px',
    alignItems: 'center',
  },
} as const;

type HeatMapInputProps = {
  coastFilename: string | undefined;
  setCoastFilename: (coastFilename: string) => void;
  groynesFilename: string | undefined;
  setGroynesFilename: (groynesFilename: string) => void;
  revetFilename: string | undefined;
  setRevetFilename: (revetFilename: string) => void;
  onSave: () => void;
};
const FileSaveCard: FC<HeatMapInputProps> = ({
  coastFilename,
  setCoastFilename,
  groynesFilename,
  setGroynesFilename,
  revetFilename,
  setRevetFilename,
  onSave,
  ...rest
}) => {
  return (
    <BoxInputContainer sx={{ ...styles.boxInputs }} borderRadius={'6px'}>
      <Typography mb={3} variant={'subtitle1'}>
        Save files as:
      </Typography>

      <Box sx={styles.inputGrid}>
        <Box>
          <LabelWrapper label={'Coastline file'}>
            <CustomInput value={coastFilename} onChange={(e) => setCoastFilename(e.target.value)} maxWidth={'300px'} />
          </LabelWrapper>
        </Box>
        <Box>
          <LabelWrapper label={'Groynes / Breakwater file'}>
            <CustomInput
              value={groynesFilename}
              onChange={(e) => setGroynesFilename(e.target.value)}
              maxWidth={'300px'}
            />
          </LabelWrapper>
        </Box>
        <Box>
          <LabelWrapper label={'Revetment file'}>
            <CustomInput value={revetFilename} onChange={(e) => setRevetFilename(e.target.value)} maxWidth={'300px'} />
          </LabelWrapper>
        </Box>
      </Box>

      <CustomButton
        // loading={saveLoading}
        // disabled={!saveName || saveLoading}
        minWidth={'120px'}
        sx={styles.buttonPosition}
        size={'small'}
        variant={'contained'}
        color={'primary'}
        onClick={onSave}
      >
        Save
      </CustomButton>
      {/* <FormControlLabel
        control={<Checkbox color={'primary'} checked={autoSave} onChange={handleAutoSaveChange} name="autoSave" />}
        label={<Typography variant={'body2'}>Auto Save</Typography>}
      /> */}
    </BoxInputContainer>
  );
};

export default memo(FileSaveCard);
