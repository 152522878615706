import { customApi } from '../Api';
import { User } from '../../../models/types/User';
import { openErrorToast, openSuccessToast } from '../../slices/appSlice';

const extendedApi = customApi.injectEndpoints({
  endpoints: (builder) => ({
    editUser: builder.mutation<unknown, User>({
      query: ({ username, first_name, last_name }) => ({
        url: '/dj-rest-auth/user/',
        method: 'PUT',
        body: { username, first_name, last_name },
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(openSuccessToast('User data updated successfully!'));
        } catch (err) {
          dispatch(openErrorToast('Error updating user data. Please try again!'));
        }
      },
      invalidatesTags: ['user'],
    }),
  }),
  overrideExisting: false,
});

export const { useEditUserMutation } = extendedApi;
