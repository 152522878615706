import { HydPlotArea } from '../../models/inputTypes/HydFields';
import { hydPlotAreasInitialState } from '../initialStates/hydInputState';

export const hydPlotAreaArrayPreparation = (
  plotAreaValues: HydPlotArea[],
  newValue: number,
  customInitialArea?: HydPlotArea,
) => {
  const initialArea = customInitialArea ?? hydPlotAreasInitialState;
  const tempArray = plotAreaValues;
  const tempLength = plotAreaValues.length;
  if (tempLength !== newValue) {
    if (newValue > tempLength) {
      for (let i = tempLength; i < newValue; i++) {
        tempArray.push({
          ...initialArea,
          name_z: initialArea.name_z + `_${i + 1}`,
          name_u: initialArea.name_u + `_${i + 1}`,
          name_v: initialArea.name_v + `_${i + 1}`,
          name_speed: initialArea.name_speed + `_${i + 1}`,
          name_direction: initialArea.name_direction + `_${i + 1}`,
          name_depth: initialArea.name_depth + `_${i + 1}`,
        });
      }
    } else {
      for (let i = 0; i < tempLength - newValue; i++) {
        tempArray.pop();
      }
    }
  }
  return tempArray;
};
