import React, { FC } from 'react';
import { Typography, TypographyProps } from '@mui/material';

type LabelWrapperProps = {
  label: string;
  color?: string;
};

const LabelWrapper: FC<LabelWrapperProps & TypographyProps> = ({ label, color, children, ...props }) => {
  return (
    <>
      <Typography mb={1} variant={'subtitle2'} {...props} style={{ color: color }}>
        {label}
      </Typography>
      {children}
    </>
  );
};

export default LabelWrapper;
