import React, { FC, memo, useEffect, useRef, useState } from 'react';
import { ReactComponent as ScientiaLogo } from '../assets/images/scientiaMaris.svg';
import { Box } from '@mui/material';
import Lottie from 'react-lottie';
import * as animationData from '../pinjump.json';
import { useGetProjectsQuery } from '../redux/RTK/queries/projectQuery';
import { useUserQuery } from '../redux/RTK/queries/userQuery';
import { useStorageQuery } from '../redux/RTK/queries/storageQuery';
import { useTimezoneQuery } from '../redux/RTK/queries/timezoneQuery';

const styles = {
  box: {
    zIndex: 10000,
    background: 'white',
    position: 'absolute',
    display: 'flex',
    'flex-direction': 'column',
    height: '100vh',
    width: '100vw',
    alignItems: 'center',
    justifyContent: 'center',
  },
} as const;
const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData.default,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const Loading: FC = () => {
  const timer = useRef<any>();
  const [hasFinished, setHasFinished] = useState(false);

  const { isSuccess, isError } = useGetProjectsQuery({}, { skip: hasFinished });
  const { isSuccess: userSuccess, isError: userError } = useUserQuery({}, { skip: hasFinished });
  const { isSuccess: storageSuccess, isError: storageError } = useStorageQuery({}, { skip: hasFinished });
  const { isSuccess: timezoneSuccess, isError: timezoneError } = useTimezoneQuery({}, { skip: hasFinished });

  useEffect(() => {
    if (
      !hasFinished &&
      (isSuccess || isError) &&
      (userSuccess || userError) &&
      (storageSuccess || storageError) &&
      (timezoneSuccess || timezoneError)
    ) {
      timer.current = setTimeout(() => {
        setHasFinished(true);
      }, 1000);
    }
  }, [userError, storageSuccess, storageError, userSuccess, isSuccess, isError, timezoneSuccess, timezoneError]);

  useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  return (
    <Box sx={hasFinished ? { display: 'none' } : styles.box}>
      {!hasFinished && (
        <>
          <ScientiaLogo width={200} height={77} />
          <Lottie options={defaultOptions} height={'120px'} width={'120px'} />
        </>
      )}
    </Box>
  );
};

export default memo(Loading);
