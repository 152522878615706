import { customApi } from '../Api';
import { openErrorToast } from '../../slices/appSlice';

const extendedApi = customApi.injectEndpoints({
  endpoints: (builder) => ({
    timezone: builder.query({
      query: () => ({
        url: '/api/rectifytimezone',
        method: 'GET',
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (err: any) {
          dispatch(openErrorToast(err.error.data.error));
          // dispatch(clearUser());
        }
      },
    }),
  }),
  overrideExisting: false,
});

export const { useTimezoneQuery } = extendedApi;
