import React, { FC, memo } from 'react';
import { Box, Typography, Theme, Grid } from '@mui/material';
import BoxInputContainer from '../../custom/BoxInputContainer';

const styles = {
  boxInputs: {
    padding: (theme: Theme) => theme.spacing(1.5, 2),
    background: '#DFE1E6',
  },
} as const;

type ProjectCardProps = {
  projectName: string;
  fileName: string;
  dimensions?: number[];
};

const FileNameCard: FC<ProjectCardProps> = ({ dimensions, projectName, fileName }) => {
  return (
    <BoxInputContainer borderRadius={'6px'} sx={{ ...styles.boxInputs }}>
      <Grid container alignItems={'center'} justifyContent={'space-between'}>
        <Box display={'flex'} mr={2}>
          <Typography variant={'subtitle2'} mr={1}>
            Project Name:
          </Typography>
          <Typography variant={'subtitle1'}> {projectName}</Typography>
        </Box>
        {dimensions?.[0] && dimensions?.[1] ? (
          <Box display={'flex'}>
            <Typography variant={'subtitle2'} mr={1}>
              Nodes:
            </Typography>
            <Typography variant={'subtitle1'}>
              X={dimensions[1]} Y={dimensions[0]}
            </Typography>
          </Box>
        ) : undefined}
      </Grid>
      <Box display={'flex'}>
        <Typography variant={'subtitle2'} mr={1}>
          Name of input file:
        </Typography>
        <Typography variant={'subtitle1'}>{fileName}</Typography>
      </Box>
    </BoxInputContainer>
  );
};

export default memo(FileNameCard);
