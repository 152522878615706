import { customApi } from '../Api';
import { openErrorToast, openSuccessToast, setGlobalLoading } from '../../slices/appSlice';

const extendedApi = customApi.injectEndpoints({
  endpoints: (builder) => ({
    createPlots: builder.mutation<any, any>({
      query: (job) => ({
        url: `/api/plots/`,
        method: 'POST',
        body: job,
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          dispatch(setGlobalLoading(true));
          await queryFulfilled;
          dispatch(openSuccessToast('Plot created successfully!'));
        } catch (err) {
          dispatch(openErrorToast('Error creating Plot. Please try again!'));
        }
        dispatch(setGlobalLoading(false));
      },
      invalidatesTags: ['jobs'],
    }),
  }),
  overrideExisting: false,
});

export const { useCreatePlotsMutation } = extendedApi;
