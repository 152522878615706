import React, { FC, useEffect, useMemo, useState } from 'react';
import { ReactComponent as ArrowLeft } from '../../../assets/images/arrowLeft.svg';
import { ReactComponent as ArrowRight } from '../../../assets/images/arrowRight.svg';
import BoxInputContainer from '../../custom/BoxInputContainer';
import CustomFrameSlider from '../../custom/CustomFrameSlider';
import { debounce, Grid, IconButton, Typography } from '@mui/material';

const styles = {
  container: { padding: '6px', display: 'grid', gridTemplateColumns: '90px 1fr 80px', alignItems: 'center' },
} as const;

const MIN_VALUE = 1;
const STEP = 1;

const FileTimeframeCard: FC<{ timeframe: number; setTimeframe: (timeframe: number) => void; totalTimeFrames: number }> =
  ({ timeframe, setTimeframe, totalTimeFrames = 1, ...rest }) => {
    const [timeframeVal, setTimeframeVal] = useState(timeframe);

    const debouncedChangeHandler = useMemo(() => debounce(setTimeframe, 1000), [timeframe]);

    useEffect(() => {
      if (timeframe !== timeframeVal) {
        debouncedChangeHandler(timeframeVal);
      }
    }, [timeframeVal, debouncedChangeHandler]);

    const handleChange = (event: any, newValue: number | number[]) => {
      if ((newValue as number) !== timeframeVal) {
        setTimeframeVal(newValue as number);
      }
    };

    const handleIncrease = (event: any) => {
      if (timeframeVal < totalTimeFrames) {
        setTimeframeVal(timeframeVal + 1);
      }
    };

    const handleDecrease = (event: any) => {
      if (timeframeVal > MIN_VALUE) {
        setTimeframeVal(timeframeVal - 1);
      }
    };

    return (
      <BoxInputContainer borderRadius={'4px'} sx={{ ...styles.container }}>
        <Typography variant={'subtitle2'} color={'textPrimary'}>
          Timeframe
        </Typography>
        <CustomFrameSlider
          track={false}
          value={timeframeVal}
          onChange={handleChange}
          step={STEP}
          min={MIN_VALUE}
          max={totalTimeFrames}
        />
        <Grid container alignItems={'center'} justifyContent={'flex-end'} wrap={'nowrap'}>
          <IconButton color={'primary'} size={'small'} onClick={handleDecrease} disabled={timeframe <= MIN_VALUE}>
            <ArrowLeft />
          </IconButton>
          <IconButton color={'primary'} size={'small'} onClick={handleIncrease} disabled={timeframe >= totalTimeFrames}>
            <ArrowRight />
          </IconButton>
        </Grid>
      </BoxInputContainer>
    );
  };

export default FileTimeframeCard;
