import { EviNumerical, EviSubmission } from '../../models/inputTypes/EviFields';

export const bedLevelInitialObject = { file: undefined, weight: 0 };
export const SedimentInitialObject = { qtx: undefined, qty: undefined, weight: 0 };

export const eviNumericalInitialState: EviNumerical = {
  project: '',
  simulationSetup: undefined,
  fileType: 1,
  frame: 1,
  calmPeriod: 1,
  bedLevelList: [bedLevelInitialObject, bedLevelInitialObject],
  sedimentList: [SedimentInitialObject, SedimentInitialObject],
  magnitude: '',
  direction: '',
  outputFileName: '',
};

export const eviSubmissionInitialState: EviSubmission = {
  simulationFile: '',
  vCpus: 1,
};
