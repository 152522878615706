import React, { FC } from 'react';
import { Route, RouteProps } from 'react-router-dom';
import Layout from '../components/structural/Layout';

const LayoutRoute: FC<RouteProps> = ({ children, ...props }) => {
  return (
    <Route {...props}>
      <Layout>{children}</Layout>
    </Route>
  );
};

export default LayoutRoute;
