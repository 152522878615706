import React, { FC } from 'react';
import { Typography, TypographyProps } from '@mui/material';

type CpuInputLabelProps = {
  label?: string;
  leftCount?: number;
  totalCount?: number;
};

const CpuInputLabel: FC<CpuInputLabelProps & TypographyProps> = ({
  label = 'vCPUs',
  leftCount = 0,
  totalCount = 0,
  ...props
}) => {
  return (
    <Typography variant={'subtitle2'} {...props}>
      {label}
      <Typography ml={1} variant={'caption'} color={'warning.main'}>
        Left: {leftCount}
      </Typography>
      <Typography ml={1} variant={'caption'} color={'info.light'}>
        Total: {totalCount}
      </Typography>
    </Typography>
  );
};

export default CpuInputLabel;
